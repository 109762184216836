import { Plumbing } from '@mui/icons-material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableColumn, PaginatedProps, StandardListViewV2 } from '../../Components/CoreLib/library';
import { ClientJobFunctionDto } from '../../dtos';
import { useGetJobFunctionQuery, useJobFunctionBulkArchiveMutation, useJobFunctionBulkUnarchiveMutation } from '../../store/generated/generatedApi';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'NAME',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const JobFunctionListView: FC = () => {
    const navigate = useNavigate();

    const tableColumns: DataTableColumn<ClientJobFunctionDto>[] = [
        { key: 'name', label: 'Name', sortKey: 'NAME' },
        { key: 'description', label: 'Description', sortKey: 'DESCRIPTION' },
    ];

    const handleCreateNew = () => {
        navigate('/jobFunction/create');
    };

    const handleTableEdit = (id: string) => {
        navigate(`/jobFunction/${id}`);
    };

    return (
        <StandardListViewV2
            headerIcon={<Plumbing />}
            headerTitle='Job Functions'
            breadCrumbs={[
                { label: 'Home', navLink: '/' },
                { label: 'Admin', navLink: '', isText: true },
            ]}
            defaultPaginationProps={defaultPaginationProps}
            getDataQuery={useGetJobFunctionQuery}
            isShowActiveToggleVisible={true}
            inactivateMutation={useJobFunctionBulkArchiveMutation}
            activateMutation={useJobFunctionBulkUnarchiveMutation}
            deleteMutation={useJobFunctionBulkArchiveMutation}
            tableColumns={tableColumns}
            entityNameSingular='Job Function'
            permissionName='jobFunctions'
            handleAdd={handleCreateNew}
            handleEdit={handleTableEdit}
        />
    );
};
