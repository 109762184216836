import { Route } from "@mui/icons-material";
import { FC, useCallback, useContext } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../../dtos";
import { StepValidator } from "../../../Utils";
import { PlayEditorContext } from "../../../Utils/PlayEditorContext";
import { PlayViewerNode } from "../../Components/PlayViewerNode";
import { SUBPLAY_NODE_DESCRIPTION } from "../../utils";

export const SubplayPlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = (props) => {
    const { playVariables } = useContext(PlayEditorContext);

    const isStepValid = useCallback((step: StepDto) => {
        const stepValidator = new StepValidator(step, playVariables, step.subplay!);
        return stepValidator.isValidEnoughToSave && stepValidator.isInputMappingValid && stepValidator.isOutputMappingValid;
    }, [playVariables]);

    return <PlayViewerNode {...props} icon={<Route fontSize='large' />} description={SUBPLAY_NODE_DESCRIPTION} additionalStepValidation={isStepValid} />;
}