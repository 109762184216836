import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import ReactFlow, { Background, Controls, MarkerType, NodeMouseHandler, XYPosition } from 'reactflow';
import { PlayExecutionDto, StepExecutionDto } from '../../../dtos';
import { NodeTypeFactory } from '../../Plays/NodeTypes/utils';
import { useFlowManager } from '../../Plays/Utils';

export interface IPlayViewerProps {
    playExecution: PlayExecutionDto;
    onStepNodeClicked: (stepExecution: StepExecutionDto) => void;
    focusCoordinates?: XYPosition
}

export const PlayViewer: FC<IPlayViewerProps> = (props) => {
    const { playExecution, onStepNodeClicked, focusCoordinates } = props;
    const { reactFlowWrapper, nodes, edges, nodeTypes, edgeTypes, centerOnCoordinates } = useFlowManager<StepExecutionDto>(playExecution);

    const handleNodeClick: NodeMouseHandler = (_, node) => {
        if (!node.type) {
            return;
        }
        var nodeTypeFactory = new NodeTypeFactory();
        var nodeType = nodeTypeFactory.getNodeTypeForStepType(node.type);
        if (!nodeType.isExecutionViewable) {
            return;
        }
        onStepNodeClicked(node.data);
    };

    useEffect(() => {
        if (focusCoordinates) {
            centerOnCoordinates(focusCoordinates);
        }
    }, [focusCoordinates, centerOnCoordinates]);

    return (
        <Box width={1} height={1} ref={reactFlowWrapper}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                defaultEdgeOptions={{
                    deletable: true,
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                    },
                    type: 'deletable'
                }}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onNodeClick={handleNodeClick}>
                <Controls />
                <Background />
            </ReactFlow>
        </Box>
    );
};
