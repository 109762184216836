import { FC } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../dtos";
import { NodeType } from "../NodeType";
import { NodeTypes } from "../utils";
import { INodeRules, IStepEditModalProps, IStepExecutionDetailsProps } from "../types";
import { StartNodeEditForm, StartNodeExecutionDetails, StartPlayViewerNode } from "./Components";

export class StartNodeType extends NodeType {
    public readonly nodeType: string = NodeTypes.START;
    public override readonly validationRules: INodeRules = { maxInputs: 0, maxOutputs: 1 };
    
    public readonly PlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = StartPlayViewerNode;
    public readonly EditModalContent?: FC<IStepEditModalProps> = StartNodeEditForm;
    public readonly ExecutionDetailsContent?: FC<IStepExecutionDetailsProps> = StartNodeExecutionDetails;
}