import { AccountBox, Help, ContactMail } from '@mui/icons-material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableColumn, PaginatedProps, StandardListViewV2 } from '../../Components/CoreLib/library';
import { UserDto } from '../../dtos';
import { useGetUserQuery, useUserBulkArchiveMutation, useUserBulkDeleteMutation, useUserBulkUnarchiveMutation } from '../../store/generated/generatedApi';
import { IconButton, Tooltip } from '@mui/material';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'FIRST_NAME',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const UsersListView: FC = () => {
    const navigate = useNavigate();

    const tableColumns: DataTableColumn<UserDto>[] = [
        { key: 'firstName', label: 'First Name', sortKey: 'FIRST_NAME' },
        { key: 'lastName', label: 'Last Name', sortKey: 'LAST_NAME' },
        { key: 'role', label: 'User Role', sortKey: 'ROLE' },
        { key: 'email', label: 'Email', sortKey: 'EMAIL' },
        { key: 'phoneNumber', label: 'Phone', sortKey: 'PHONE_NUMBER' },
        { key: 'icons', label: '', sortKey: '', fieldMapper: (user) => (user.auth0UserId) ? <></> : <Tooltip title="Invitation Pending"><ContactMail /></Tooltip> },
    ];

    const handleCreateNew = () => {
        navigate('/user/create');
    };

    const handleTableEdit = (id: string) => {
        navigate(`/user/${id}`);
    };

    return (
        <StandardListViewV2
            headerIcon={<AccountBox />}
            headerTitle='Users'
            breadCrumbs={[
                { label: 'Home', navLink: '/' },
                { label: 'Admin', navLink: '', isText: true },
            ]}
            endTitleSlot={<Tooltip title="Edit a user by hovering over the line with the user, then clicking the &quot;edit&quot; symbol that shows up to the right of the line."><IconButton><Help /></IconButton></Tooltip>}
            defaultPaginationProps={defaultPaginationProps}
            getDataQuery={useGetUserQuery}
            isShowActiveToggleVisible={true}
            inactivateMutation={useUserBulkArchiveMutation}
            activateMutation={useUserBulkUnarchiveMutation}
            deleteMutation={useUserBulkDeleteMutation}
            tableColumns={tableColumns}
            entityNameSingular='User'
            permissionName='users'
            handleAdd={handleCreateNew}
            handleEdit={handleTableEdit}
            disableInactiveForRecord={(record : UserDto) => !record.auth0UserId}
        />
    );
};
