import { DoubleArrow } from '@mui/icons-material';
import { Box, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { LoadingIndicator } from '../../../../Components/CoreLib/library';
import { StepDataDto, StepDataType, StepDto } from '../../../../dtos';
import { NodeTypeFactory, NodeTypes } from '../../NodeTypes/utils';
import { useStepForm } from '../../Utils';
import { StepEditorContext } from '../../Utils/StepEditorContext';
import { ModalSaveCloseFooter, ModalTitle } from '../SubComponents';
import { DataCollectedSlice } from './EditStepModalForms/StepFormParts';
import { useDragStatus } from '../../../../Hooks';
import { emptyGuid } from '../../../../util';
import { ExpandableSection } from '../../../../Components/ExpandableSection/ExpandableSection';
import Cookies from 'universal-cookie';
import { ExpandableSectionHandle } from '../../../../Components/ExpandableSection';

export interface IEditStepModalProps {
    step?: StepDto;
    collectedData?: StepDataDto[];
    updateStep: (updatedStep: StepDto) => void;
    clearSelectedStep: () => void;
}

const DEFAULT_STEP_DATA: StepDataDto = {
    id: emptyGuid,
    stepId: emptyGuid,
    name: '',
    isRequired: true,
    dataType: StepDataType.TEXT,
    isFutureDate: false,
    orderIndex: 0,
};

export const EditStepModal: FC<IEditStepModalProps> = (props) => {
    const { step, updateStep, clearSelectedStep, collectedData = [] } = props;
    const isDragging = useDragStatus();
    const cookies = useMemo(() => new Cookies(null), []);
    const isCollectedDataPanelOpenInRoute = cookies.get('cpd');
    const isNullOrOne = (val: string | null) => val === null || val === '1';
    const [isCollectedDataPanelExpanded, setIsCollectedDataPanelExpanded] = useState(isNullOrOne(isCollectedDataPanelOpenInRoute));

    useEffect(() => {
        cookies.set('cpd', isCollectedDataPanelExpanded ? '1' : '0');
    }, [isCollectedDataPanelExpanded, cookies]);

    const stepForm = useStepForm(
        {
            save: updateStep,
            cancel: clearSelectedStep,
            initValues: step,
            isLoading: false,
        },
        step?.key
    );

    const playData: StepDataDto[] = useMemo(
        () => [
            { ...DEFAULT_STEP_DATA, id: 'pd-1', name: "Play Starter's First Name", orderIndex: 1 },
            { ...DEFAULT_STEP_DATA, id: 'pd-2', name: "Play Starter's Last Name", orderIndex: 2 },
            { ...DEFAULT_STEP_DATA, id: 'pd-3', name: "Play Starter's Email", orderIndex: 3 },
        ],
        []
    );

    const stepData: StepDataDto[] = useMemo(() => {
        var dataValues: StepDataDto[] = [];
        if (step?.type === NodeTypes.STEP) {
            if (!stepForm.formStep.isExternal) {
                dataValues.push(
                    { ...DEFAULT_STEP_DATA, id: 'sd-1', name: "Assigned User's First Name", orderIndex: 1 },
                    { ...DEFAULT_STEP_DATA, id: 'sd-2', name: "Assigned User's Last Name", orderIndex: 2 }
                );
            }
            dataValues.push({ ...DEFAULT_STEP_DATA, id: 'sd-3', name: "Assigned User's Email", orderIndex: 3 });
        }

        return dataValues;
    }, [step?.type, stepForm.formStep.isExternal]);

    const allAccessibleSmartValues: StepDataDto[] = useMemo(() => {
        if (step?.name === 'Automation') {
            return [...collectedData, ...stepData];
        } else {
            return [...collectedData, ...playData, ...stepData];
        }
    }, [collectedData, playData, stepData, step?.name]);


    const renderFormLayout = useMemo(() => {
        if (!step) {
            return <LoadingIndicator />;
        }

        var nodeTypeFactory = new NodeTypeFactory();
        var nodeType = nodeTypeFactory.getNodeTypeForStepType(step.type);
        if (nodeType.isEditable) {
            const EditModalContent = nodeType.EditModalContent!;
            return <EditModalContent key={step.key} step={step} collectedData={allAccessibleSmartValues} />;
        }

        return (
            <Grid item>
                <Typography>There are no editable fields on this step. Click the Close/Cancel button below to close this modal.</Typography>
            </Grid>
        );
    }, [step, allAccessibleSmartValues]);

    return (
        <Dialog
            PaperProps={{
                sx: {
                    height: '80vh',
                    backgroundColor: isDragging ? '#787878' : undefined,
                },
            }}
            open={!!step}
            fullWidth
            maxWidth='lg'>
            <DialogContent sx={{ padding: 0 }}>
                <Box flexDirection='column' display='flex' height='100%'>
                    <ModalTitle icon={<DoubleArrow sx={{ mr: 1 }} />} title='Edit Step' />
                    <Box flexDirection='row' display='flex' overflow='hidden' justifyContent='start' alignItems='stretch' height='100%'>
                        <Box height='100%'>
                            {step?.type !== NodeTypes.START && (
                                <ExpandableSection isExpanded={isCollectedDataPanelExpanded} direction='right'>
                                    <DataCollectedSlice collectedData={collectedData} playData={playData} userData={stepData} />
                                </ExpandableSection>
                            )}
                        </Box>
                        <Box flexGrow={1} display='flex' flexDirection='column' justifyContent='space-between' position='relative'>
                            {step?.type !== NodeTypes.START && (
                                <ExpandableSectionHandle
                                    isExpanded={isCollectedDataPanelExpanded}
                                    setIsExpanded={setIsCollectedDataPanelExpanded}
                                    direction='right'
                                    backgroundColor='#f3f5f6'
                                />
                            )}
                            <Box overflow='auto' sx={{ height: '100%' }} className={isDragging ? 'draggable-input-area' : undefined}>
                                <StepEditorContext.Provider value={stepForm}>{renderFormLayout}</StepEditorContext.Provider>
                            </Box>
                            <Box px={1} sx={{ borderTop: '1px solid #e4e4e4', background: 'white' }}>
                                <ModalSaveCloseFooter
                                    onCancelCloseClicked={clearSelectedStep}
                                    isFormDirty={stepForm.isFormDirty}
                                    onSaveClicked={stepForm.handleSave}
                                    onDeleteClicked={stepForm.handleDelete}
                                    hideDeleteButton={step?.type === NodeTypes.START}
                                    deletePermissions={[`delete:steps`]}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
