import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientJobFunctionDto } from '../../dtos';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { useCreateJobFunctionMutation } from '../../store/generated/generatedApi';
import { JobFunctionForm } from '../../Components/Forms/JobFunctionForm/JobFunctionForm';

export const AddJobFunctionView: FC = () => {
    const navigate = useNavigate();
    const [createClientJobFunction, { data, isLoading, isError, isSuccess, reset }] = useCreateJobFunctionMutation();

    useSuccessfulCreateSnackbar('Job Function', isSuccess, () => navigate(`/jobFunction/${data?.id}`));
    useFailedCreateSnackbar('job function', isError, reset)

    const handleSave = (values: ClientJobFunctionDto) => {
        createClientJobFunction(values);
    };

    const handleCancel = () => {
        navigate('/jobFunctions');
    };

    return <JobFunctionForm save={handleSave} cancel={handleCancel} isLoading={isLoading} />;
};
