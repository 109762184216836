import { FC } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../dtos";
import { NodeType } from "../NodeType";
import { NodeTypes } from "../utils";
import { DelayNodeEditForm, DelayNodeLibraryListItem, DelayPlayViewerNode } from "./Components";
import { IStepEditModalProps, IStepExecutionDetailsProps } from "../types";
import { DelayNodeExecutionDetails } from "./Components/DelayNodeExecutionDetails";

export class DelayNodeType extends NodeType {
    public readonly nodeType: string = NodeTypes.DELAY;

    public readonly PlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = DelayPlayViewerNode;
    public readonly EditModalContent?: FC<IStepEditModalProps> = DelayNodeEditForm;
    public readonly NodeLibraryListItem: FC = DelayNodeLibraryListItem;
    public readonly ExecutionDetailsContent?: FC<IStepExecutionDetailsProps> = DelayNodeExecutionDetails;
}