import { RemoveCircle } from '@mui/icons-material';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Switch } from '@mui/material';
import _ from 'lodash';
import { ChangeEvent, FC, useCallback, useContext, useMemo } from 'react';
import { PlayVariableAutocompleteWithAdd } from '../../../../../../Components/CommonInputs';
import { PlayVariableDto, StepDataDto } from '../../../../../../dtos';
import { StepDataType } from '../../../../../../dtos/generated/StepDataType';
import { PlayEditorContext } from '../../../../Utils/PlayEditorContext';
import { TextFieldWithDataReferences } from '../../../../../../Components/Wysiwyg';
import { AuthenticatedComponent } from '../../../../../../Components/CoreLib/library';

export interface IStepDataInputRowProps {
    index: number;
    isUsingChatDataCollection: boolean;
    stepData: StepDataDto;
    allStepDataEntries: StepDataDto[];
    updateSelectedStepData: (stepEntryIndex: number, step: StepDataDto) => void;
    removeStep: (stepEntryIndex: number) => void;
}

export const StepDataInputRow: FC<IStepDataInputRowProps> = ({
    index,
    stepData,
    isUsingChatDataCollection,
    allStepDataEntries,
    updateSelectedStepData,
    removeStep,
}) => {
    const { playVariables } = useContext(PlayEditorContext);

    const applyStepDataChange = useCallback(
        (value: PlayVariableDto | null) => {
            var updatedStepData = _.cloneDeep(stepData);
            updatedStepData.name = value?.name ?? '';
            updatedStepData.dataType = value?.dataType ?? StepDataType.TEXT;
            updatedStepData.isFutureDate = value?.isFutureDate ?? true;
            updateSelectedStepData(index, updatedStepData);
        },
        [index, stepData, updateSelectedStepData]
    );

    const handleIsRequiredChange = useCallback(
        (__: ChangeEvent<HTMLInputElement>, checked: boolean) => {
            var updatedStepData = _.cloneDeep(stepData);
            updatedStepData.isRequired = checked;
            updateSelectedStepData(index, updatedStepData);
        },
        [index, stepData, updateSelectedStepData]
    );

    const handleDataPromptChange = useCallback(
        (value: string) => {
            var updatedStepData = _.cloneDeep(stepData);
            updatedStepData.collectionPrompt = value;
            updateSelectedStepData(index, updatedStepData);
        },
        [index, stepData, updateSelectedStepData]
    );

    var selectedPlayVariable = useMemo(() => {
        return playVariables.find((pv) => pv.name === stepData.name) ?? null;
    }, [playVariables, stepData.name]);

    const selectedPlayVariableErrorMessage = useMemo(() => {
        if (!selectedPlayVariable) {
            return 'A data value must be selected';
        }
    }, [selectedPlayVariable]);

    const availablePlayVariables = useMemo(() => {
        var variablesUsedByOtherEntries = allStepDataEntries.map((x) => x.name ?? '');
        variablesUsedByOtherEntries.splice(index, 1);
        var result: PlayVariableDto[] = playVariables.filter((pv) => !variablesUsedByOtherEntries.includes(pv.name));
        return result;
    }, [allStepDataEntries, playVariables, index]);

    return (
        <Grid item container direction='row' alignItems='center'>
            <Grid item xs={7} mt='23px'>
                <FormControl error={!!selectedPlayVariableErrorMessage} fullWidth className='non-draggable-element'>
                    <FormLabel>Data</FormLabel>
                    <PlayVariableAutocompleteWithAdd
                        value={selectedPlayVariable}
                        onChange={applyStepDataChange}
                        playVariableOptions={availablePlayVariables}
                        error={!!selectedPlayVariableErrorMessage}
                    />
                    <FormHelperText>{selectedPlayVariableErrorMessage}</FormHelperText>
                </FormControl>
                {isUsingChatDataCollection && (
                    <AuthenticatedComponent requiredPermissions={['feature:chatDataCollection']}>
                        <FormControl fullWidth>
                            <FormLabel>Prompt</FormLabel>
                            <TextFieldWithDataReferences
                                initialValue={stepData.collectionPrompt ?? ''}
                                onChange={handleDataPromptChange}
                                dataReferences={allStepDataEntries.map((x) => x.name ?? '')}
                                placeholder='Specific format or data validation rules'
                            />
                        </FormControl>
                    </AuthenticatedComponent>
                )}
            </Grid>
            <Grid item display='flex' justifyContent='space-between' mt={4}>
                <FormControlLabel
                    control={<Switch checked={stepData.isRequired} onChange={handleIsRequiredChange} />}
                    label='Required'
                    labelPlacement='start'
                />
                <span>
                    <IconButton sx={{ marginLeft: '10px' }} color='error' onClick={() => removeStep(index)}>
                        <RemoveCircle />
                    </IconButton>
                </span>
            </Grid>
        </Grid>
    );
};
