import { Plumbing } from '@mui/icons-material';
import { Button, FormControl, FormHelperText, FormLabel, Grid, MenuItem, Select } from '@mui/material';
import { FC, useCallback, useContext, useState } from 'react';
import { ClientJobFunctionSelect } from '../../../../../Components/CommonInputs';
import { ClientJobFunctionDto } from '../../../../../dtos';
import { NotificationType } from '../../../../../dtos/generated/NotificationType';
import { NameInput, WYSIWYGDescription } from '../../../Components/Modals/EditStepModalForms/StepFormParts';
import { EmailsToNotifyInput } from '../../../Components/Modals/EditStepModalForms/StepFormParts/EmailsToNotifyInput';
import { ViewJobFunctionMembersModal } from '../../../Components/Modals/ViewJobFunctionMembersModal';
import { StepEditorContext } from '../../../Utils/StepEditorContext';
import { IStepEditModalProps } from '../../types';

export const NotificationNodeEditForm: FC<IStepEditModalProps> = ({ step, collectedData }) => {
    const {
        formStep,
        fieldErrors,
        handleNameChange,
        handleDescriptionChange,
        handleNotificationTypeChange,
        handleInternalJobFunctionChange,
        handleEmailsToNotifyChange
    } = useContext(StepEditorContext);
    const [selectedJobFunction, setSelectedJobFunction] = useState<ClientJobFunctionDto>();

    const handleViewJobFunctionMembersClicked = useCallback(() => {
        setSelectedJobFunction(formStep.internalJobFunction);
    }, [formStep.internalJobFunction]);

    const clearSelectedJobFunction = useCallback(() => {
        setSelectedJobFunction(undefined);
    }, []);

    return (
        <Grid container direction='column' height='calc(100%-30px)' mb={5}>
            <Grid item px={4} pt={3}>
                <NameInput fieldErrors={fieldErrors} name={formStep.name ?? ''} handleNameChange={handleNameChange} dataOptions={collectedData} />
            </Grid>
            <Grid item px={4} pt={3}>
                <WYSIWYGDescription
                    fieldErrors={fieldErrors}
                    description={step?.description ?? ''}
                    handleDescriptionChange={handleDescriptionChange}
                    collectedData={collectedData}
                />
            </Grid>
            <Grid item container direction='row' px={4} pt={3} spacing={3}>
                <Grid item xs={4}>
                    <FormControl fullWidth required error={!!fieldErrors.get('notificationType')}>
                        <FormLabel sx={{ marginTop: '8px', marginBottom: '10px' }}>Notification Method</FormLabel>
                        <Select
                            value={formStep.notificationType ?? ''}
                            onChange={(e) => handleNotificationTypeChange(e.target.value as NotificationType)}>
                            <MenuItem value={NotificationType.ACTIVE_JOB_FUNCTION_MEMBERS}>Notify member active in this play</MenuItem>
                            <MenuItem value={NotificationType.All_JOB_FUNCTION_MEMBERS}>Notify all members of job function</MenuItem>
                            <MenuItem value={NotificationType.EMAILS}>Notify by email</MenuItem>
                        </Select>
                        <FormHelperText>{fieldErrors.get('notificationType')}</FormHelperText>
                    </FormControl>
                </Grid>
                {formStep.notificationType !== NotificationType.EMAILS && (
                    <>
                        <Grid item xs={4}>
                            <ClientJobFunctionSelect
                                selectedJobFunctionId={formStep.internalJobFunctionId ?? ''}
                                handleSelectedJobFunctionChange={handleInternalJobFunctionChange}
                                jobFunctionsMustHaveUsers
                                errorMessage={fieldErrors.get('internalJobFunctionId')}
                                required
                            />
                        </Grid>
                        <Grid item xs={4} mt={6}>
                            <Button
                                disabled={!formStep.internalJobFunction}
                                variant='contained'
                                fullWidth
                                startIcon={<Plumbing />}
                                onClick={handleViewJobFunctionMembersClicked}>
                                View Job Function Members
                            </Button>
                        </Grid>
                        <ViewJobFunctionMembersModal jobFunction={selectedJobFunction} onClose={clearSelectedJobFunction} />
                    </>
                )}
            </Grid>
            {formStep.notificationType === NotificationType.EMAILS && (
                <Grid item xs={12} px={4} pt={1}>
                    <EmailsToNotifyInput emailsToNotify={formStep.emailsToNotify ?? []} updateEmailsToNotify={handleEmailsToNotifyChange} dataOptions={collectedData} />
                </Grid>
            )}
        </Grid>
    );
};
