import { FC } from 'react';
import { StepConditionDetails, StepDescriptionDetails, StepNameDetails } from '../../../../PlayHistory/Components/StepDetailSections/Components';
import { IStepExecutionDetailsProps } from '../../types';
import { MatchType } from '../../../../../dtos';

export const ConditionalNodeExecutionDetails: FC<IStepExecutionDetailsProps> = ({ stepExecution }) => {
    return (
        <>
            <StepNameDetails name={stepExecution.name ?? 'Unnamed Step'} />
            <StepDescriptionDetails description={stepExecution.description ?? ''} />
            <StepConditionDetails stepConditions={stepExecution.stepConditions ?? []} conditionMatchType={stepExecution.conditionalMatchType ?? MatchType.ALL} evaluationResult={stepExecution.conditionalEvaluationResult} />
        </>
    );
}