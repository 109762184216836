import { SmartToy } from "@mui/icons-material";
import { FC, useCallback } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../../dtos";
import { PlayViewerNode } from "../../Components/PlayViewerNode";
import { AUTOMATION_NODE_DESCRIPTION } from "../../utils";

export const AutomationPlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = (props) => {
    const isStepValid = useCallback((step: StepDto) => {
        var isEndpointSpecified = !!step.targetEndpoint;
        var isAnyRequestDataSelected = !!step.requestDataLabels && step.requestDataLabels.length > 0;
        return isEndpointSpecified && isAnyRequestDataSelected;
    }, []);

    return <PlayViewerNode {...props} icon={<SmartToy fontSize='large' />} description={AUTOMATION_NODE_DESCRIPTION} additionalStepValidation={isStepValid} />;
}