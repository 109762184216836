import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { DragEvent, useCallback, useMemo, useRef } from 'react';
import {
    Connection,
    Edge,
    EdgeTypes,
    MarkerType,
    Node,
    NodeTypes as ReactFlowNodeTypes,
    XYPosition,
    addEdge,
    useEdgesState,
    useNodesState,
    useReactFlow,
} from 'reactflow';
import { PlayDto, PlayExecutionDto, StepDependencyDto, StepDto, StepExecutionDto } from '../../../../dtos';
import { DelayMethod } from '../../../../dtos/generated/DelayMethod';
import { NotificationType } from '../../../../dtos/generated/NotificationType';
import { DeletableEdge } from '../../Components/Edges/DeletableEdge';
import { FALSE_HANDLE_ID, TRUE_HANDLE_ID } from '../../NodeTypes/Conditional/Components';
import { NodeTypes, getAllNodeTypes } from '../../NodeTypes/utils';
import { DEFAULT_STEP } from '../Constants';
import { DependentNodeKeyFinder } from '../DependentNodeKeyFinder';
import { GraphConnectionValidator } from '../Validators';
import { mapDependenciesToEdges, mapStepsToNodes } from './FlowMappers';
import { useTempKeyGenerator } from './useTempKeyGenerator';

export function useFlowManager<S extends StepDto | StepExecutionDto>(play: PlayDto | PlayExecutionDto) {
    const reactFlowWrapper = useRef<any>(null);
    const reactFlowInstance = useReactFlow();
    const generateTempKey = useTempKeyGenerator();
    const { enqueueSnackbar } = useSnackbar();
    const [nodes, setNodes, onNodesChange] = useNodesState(mapStepsToNodes<S>((play.steps as S[]) ?? []));
    const [edges, setEdges, onEdgesChange] = useEdgesState(
        mapDependenciesToEdges(removeDependenciesWithMissingNodes(play.stepDependencies ?? [], play.steps?.map((s) => s.key ?? '') ?? []) ?? [])
    );

    const reloadPlay = useCallback(
        (updatedPlay: PlayDto | PlayExecutionDto) => {
            // mapping steps
            const stepsMappedToNodes = mapStepsToNodes<S>((updatedPlay.steps as S[]) ?? []);

            // mapping step dependencies
            const playDependencies = play.stepDependencies ?? [];
            const allPlayStepKeys = play.steps?.map((s) => s.key ?? '') ?? [];
            const activeDependencies = removeDependenciesWithMissingNodes(playDependencies, allPlayStepKeys);
            const dependenciesMappedToEdges = mapDependenciesToEdges(activeDependencies);

            // populate graph
            setNodes(stepsMappedToNodes);
            setEdges(dependenciesMappedToEdges);
        },
        [play, setEdges, setNodes]
    );

    const getDependentNodeKeys = useCallback(
        (nodeKey: string) => {
            const dependentNodeKeyFinder = new DependentNodeKeyFinder(edges);
            return dependentNodeKeyFinder.getDependentNodeKeysForKey(nodeKey);
        },
        [edges]
    );

    const verifyConnectionBetweenNodesIsValid = useCallback(
        (newEdge: Connection, existingEdges: Edge<any>[]) => {
            if (!newEdge.target || !newEdge.source) {
                return false;
            }

            const graphConnectionValidator = new GraphConnectionValidator(nodes, existingEdges);
            const errorMessage = graphConnectionValidator.validateNewConnection(newEdge);
            if (errorMessage) {
                enqueueSnackbar(errorMessage, { variant: 'warning' });
                return false;
            }

            return true;
        },
        [nodes, enqueueSnackbar]
    );

    const onConnect = useCallback(
        (newNodeConnection: Connection) =>
            setEdges((existingEdges) => {
                const isConnectionValid = verifyConnectionBetweenNodesIsValid(newNodeConnection, existingEdges);
                if (!isConnectionValid) {
                    return existingEdges;
                }

                var newEdge: Edge = {
                    id: `${newNodeConnection.source}-${newNodeConnection.target}`,
                    source: newNodeConnection.source ?? '',
                    target: newNodeConnection.target ?? '',
                    sourceHandle: newNodeConnection.sourceHandle,
                    targetHandle: newNodeConnection.targetHandle,
                };

                newEdge = applyCustomEdgeStyling(newEdge);

                return addEdge(newEdge, existingEdges);
            }),
        [setEdges, verifyConnectionBetweenNodesIsValid]
    );

    const onDragOver = useCallback((event: DragEvent) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const centerOnCoordinates = useCallback(
        (positionToCenterOn: XYPosition) => {
            const NodeSizeOffset = 20;
            const x = positionToCenterOn.x + NodeSizeOffset;
            const y = positionToCenterOn.y + NodeSizeOffset;
            const zoom = 1.85;

            reactFlowInstance.setCenter(x, y, { zoom, duration: 1000 });
        },
        [reactFlowInstance]
    );

    const onDrop = useCallback(
        (event: DragEvent) => {
            event.preventDefault();

            const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');

            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }

            const nodeSizeOffset = 25;
            const position = reactFlowInstance?.project({
                x: event.clientX - reactFlowBounds.left - nodeSizeOffset,
                y: event.clientY - reactFlowBounds.top - nodeSizeOffset,
            });

            if (!position) {
                return;
            }

            const stepKey = generateTempKey();

            var defaultEstimatedTimeInMinutes = 0;
            if (type === NodeTypes.STEP) {
                defaultEstimatedTimeInMinutes = 15;
            }

            var defaultStep = {
                ...DEFAULT_STEP,
                playId: play.id,
                key: stepKey,
                type: type ?? NodeTypes.STEP,
                estimatedExecutionTimeInMinutes: defaultEstimatedTimeInMinutes,
                graphXPos: position.x,
                graphYPos: position.y,
            } as S;

            defaultStep = _setDefaultValuesForStepType<S>(defaultStep);

            const newNode: Node<S> = {
                id: stepKey,
                type,
                position,
                data: defaultStep,
            };

            setNodes((nds) => nds.concat(newNode));
        },
        [reactFlowInstance, setNodes, generateTempKey, play.id]
    );

    const nodeTypes: ReactFlowNodeTypes = useMemo(() => {
        var allNodeTypes = getAllNodeTypes();
        var reactFlowNodeTypes: ReactFlowNodeTypes = {};
        allNodeTypes.forEach((nt) => (reactFlowNodeTypes[nt.nodeType] = nt.PlayViewerNode));
        return reactFlowNodeTypes;
    }, []);

    const edgeTypes: EdgeTypes = useMemo(
        () => ({
            deletable: DeletableEdge,
        }),
        []
    );

    return {
        reactFlowWrapper,
        nodes,
        edges,
        reloadPlay,
        onNodesChange,
        onEdgesChange,
        onConnect,
        onDrop,
        onDragOver,
        nodeTypes,
        setNodes,
        setEdges,
        getDependentNodeKeys,
        centerOnCoordinates,
        edgeTypes,
    };
}

export function isPlayExecutionDto(play: PlayDto | PlayExecutionDto): play is PlayExecutionDto {
    return Object.hasOwn(play, 'sourcePlayId');
}

export function removeDependenciesWithMissingNodes(stepDependencies: StepDependencyDto[], allStepKeys: string[]): StepDependencyDto[] {
    return stepDependencies.filter((stepDep) => allStepKeys.includes(stepDep.stepKey) && allStepKeys.includes(stepDep.dependentUponStepKey));
}

export function applyCustomEdgeStyling(newEdge: Edge) {
    switch (newEdge.sourceHandle) {
        case TRUE_HANDLE_ID:
            const trueEdgeStyles: Partial<Edge> = {
                style: { stroke: 'green', strokeWidth: '2px' },
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    color: 'green',
                },
                label: 'True',
                labelStyle: {
                    color: 'green',
                    borderColor: 'green',
                },
            };
            newEdge = { ...newEdge, ...trueEdgeStyles };
            break;
        case FALSE_HANDLE_ID:
            const falseEdgeStyles: Partial<Edge> = {
                style: { stroke: 'red', strokeWidth: '2px' },
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    color: 'red',
                },
                label: 'False',
                labelStyle: {
                    color: 'red',
                    borderColor: 'red',
                },
            };
            newEdge = { ...newEdge, ...falseEdgeStyles };
            break;
    }
    return newEdge;
}

function _setDefaultValuesForStepType<S extends StepDto | StepExecutionDto>(defaultStep: S): S {
    var updatedStep = _.cloneDeep(defaultStep);
    switch (defaultStep.type) {
        case NodeTypes.STOP:
            updatedStep.name = 'Stop';
            break;
        case NodeTypes.PARALLEL:
            updatedStep.name = 'Parallel';
            break;
        case NodeTypes.JOIN:
            updatedStep.name = 'Join';
            break;
        case NodeTypes.CONDITIONAL:
            updatedStep.name = 'Conditional';
            break;
        case NodeTypes.MERGE:
            updatedStep.name = 'Merge';
            break;
        case NodeTypes.AUTOMATION:
            updatedStep.name = 'Automation';
            break;
        case NodeTypes.SUBPLAY:
            updatedStep.name = 'Subplay';
            break;
        case NodeTypes.NOTIFICATION:
            updatedStep.name = 'Notification';
            updatedStep.notificationType = NotificationType.ACTIVE_JOB_FUNCTION_MEMBERS;
            break;
        case NodeTypes.DELAY:
            updatedStep.name = 'Delay';
            updatedStep.delayMethod = DelayMethod.MINUTES;
            break;
        default:
            break;
    }

    return updatedStep;
}
