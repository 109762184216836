export const formatTime = (minutes?: number, length?: string): string => {
    if (length === undefined || length === 'long') {
        return formatTimeLong(minutes);
    } else if (length === 'short') {
        return formatTimeShort(minutes);
    } else {
        return '';
    }
};

const formatTimeShort = (minutes?: number) => {
    if (minutes === undefined) {
        return '';
    }

    var days = Math.trunc(minutes / 1440);
    minutes -= days * 1440;
    var hours = Math.trunc(minutes / 60);
    minutes -= hours * 60;

    var timeStr = '';
    if (days > 0) {
        timeStr = days.toString() + 'd ';
    }
    if (hours > 0) {
        timeStr += hours.toString() + 'h ';
    }
    if (minutes > 0 && days <= 0) {
        timeStr += minutes.toFixed(2) + 'm ';
    }

    if (timeStr === '' || timeStr === '-') {
        return '0m';
    }
    return timeStr.trim();
};

const formatTimeLong = (minutes?: number) => {
    if (minutes === undefined) {
        return '';
    }

    var days = Math.trunc(minutes / 1440);
    minutes -= days * 1440;
    var hours = Math.trunc(minutes / 60);
    minutes -= hours * 60;

    var timeStr = '';
    if (days > 0) {
        timeStr = days.toString() + ' day(s) ';
    }
    if (hours > 0) {
        timeStr += hours.toString() + ' hour(s) ';
    }
    if (minutes > 0 && days <= 0) {
        timeStr += minutes.toFixed(2) + ' min(s) ';
    }

    if (timeStr === '' || timeStr === '-') {
        return '0 min(s)';
    }
    return timeStr.trim();
};
