import { Plumbing } from '@mui/icons-material';
import { Dialog, DialogContent, Grid, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { ClientJobFunctionDto } from '../../../../dtos';
import { QuickAddJobFunctionView } from '../../../JobFunction/QuickAddJobFunctionView';

export interface IQuickAddJobFunctionModalProps {
    isOpen?: boolean;
    onClose: () => void;
    setNewJobFunction: (newJobFunction: ClientJobFunctionDto | undefined) => void;
}

export const QuickAddJobFunctionModal: FC<IQuickAddJobFunctionModalProps> = (props) => {
    const { isOpen, onClose, setNewJobFunction } = props;
    const theme = useTheme();

    return (
        <Dialog open={!!isOpen} fullWidth maxWidth='lg' onClose={onClose}>
            <DialogContent sx={{ padding: 0 }}>
                <Grid container direction='column' height='100%'>
                    {/* TOD: Replace with ModalTitle or other shared component */}
                    <Grid
                        item
                        container
                        direction='row'
                        alignItems='center'
                        p={1}
                        sx={{ background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                        position='sticky'
                        top={0}
                        zIndex={5}>
                        {<Plumbing sx={{ mr: 1 }} />}
                        <Typography fontWeight='bold'> Add Job Function</Typography>
                    </Grid>
                    <Grid item container direction='row' height='calc(100% - 40px)'>
                        {<QuickAddJobFunctionView onCloseView={onClose} setNewJobFunctionView={setNewJobFunction} />}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
