import { CompositeDecorator } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { FC } from 'react';
import { DataReferenceSpan, DraftWysiwygTextField, dataReferenceStrategy } from '..';

interface ITextFieldWithDataReferencesProps {
  initialValue: string;
  onChange: (newValue: string) => void;
  dataReferences: string[];
  placeholder?: string;
}

export const TextFieldWithDataReferences: FC<ITextFieldWithDataReferencesProps> = ({ initialValue, onChange, dataReferences, placeholder }) => {
  const wysiwygDecorators = new CompositeDecorator([
    {
      strategy: dataReferenceStrategy,
      component: DataReferenceSpan,
      props: { dataReferences }
    },
  ]);

  return (
    <DraftWysiwygTextField
      initialValue={initialValue}
      onContentChange={onChange}
      decorators={wysiwygDecorators}
      placeholder={placeholder}
    />
  );
};