import { EditorState, Modifier, RichUtils } from 'draft-js';

// This is based off of a method defined here: https://github.com/facebookarchive/draft-js/issues/182#issuecomment-416743487
// I made modifications so the logic was not dependent on the current location of the cursor. It previously pulled the contentBlock
// based on the current selection state. I updated this to loop through each block and set the entitySelection when the correct contentBlock was found
// I am just noting that here because my understanding of editor, node, states, and the different maps is still unclear so if this causes issues
// we can refer to the original.
export function removeDraftEntityFromEditor(editorState: EditorState, entityKey: string) {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    let entitySelection = null;

    contentState.getBlockMap().forEach((contentBlock) => {
        if (!contentBlock) {
            return;
        }
        contentBlock.findEntityRanges(
            (character) => {
                const entity = character.getEntity();
                return entity !== null && entity === entityKey;
            },
            (start, end) => {
                entitySelection = selectionState.merge({
                    anchorOffset: start,
                    focusOffset: end,
                });
            }
        );
    });

    if (!entitySelection) {
        // If we didn't find the entity then just return the original unedited state
        return editorState;
    }

    const newContentState = Modifier.applyEntity(contentState, entitySelection, null);
    const newEditorState = EditorState.push(editorState, newContentState, 'apply-entity');
    return newEditorState;
}

export function createDraftLinkOnCurrentlySelectedContent(editorState: EditorState, linkValue: string) {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const contentStateWithLink = contentState.createEntity('LINK', 'MUTABLE', { url: linkValue, href: linkValue });
    const entityKey = contentStateWithLink.getLastCreatedEntityKey();
    
    return RichUtils.toggleLink(editorState, selection, entityKey);
}
