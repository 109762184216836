import { FC } from 'react';
import { EmbeddingsDataView } from '../../../../PlayExecution/Components/StepEmbeddingsView';
import { StepAssignmentDetails, StepDataDetails, StepDescriptionDetails, StepFileAttachmentDetails, StepNameDetails } from '../../../../PlayHistory/Components/StepDetailSections/Components';
import { IStepExecutionDetailsProps } from '../../types';

export const StepNodeExecutionDetails: FC<IStepExecutionDetailsProps> = ({ stepExecution, handleStepExecutionUpdate }) => {
    return (
        <>
            <StepAssignmentDetails stepExecution={stepExecution} handleStepExecutionUpdate={handleStepExecutionUpdate} />
            <StepNameDetails name={stepExecution.name ?? 'Unnamed Step'} />
            <StepDescriptionDetails description={stepExecution.description ?? ''} />
            {stepExecution.fileAttachments && stepExecution.fileAttachments.length > 0 && <StepFileAttachmentDetails fileAttachments={stepExecution.fileAttachments} />}
            <StepDataDetails stepDataEntries={stepExecution.stepData} stepAttachments={stepExecution.fileAttachments} />
            <EmbeddingsDataView embeddingInputs={stepExecution.embedInputs} />
        </>
    );
}