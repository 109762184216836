import { Box, Paper } from '@mui/material';
import { CompositeDecorator, ContentState, Editor, EditorState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DraftEditorContext, EntityDataUpdater, LinkDecoratorEditState } from './DraftEditorContext';
import { DraftWysiwygTitleBar } from './DraftWysiwygTitleBar';
import { LinkDecoratorEditModal } from './TextDecorators';
import { createDraftLinkOnCurrentlySelectedContent, removeDraftEntityFromEditor } from './Utils';

interface IDraftWysiwygEditorProps {
    initialValue: string;
    onChange: (newValue: string) => void;
    decorators: CompositeDecorator;
}

export const DraftWysiwygEditor: FC<IDraftWysiwygEditorProps> = ({ initialValue, onChange, decorators }) => {
    const prevInitialValue = useRef(initialValue);
    const [hasManuallyChangedValue, setHasManuallyChangedValue] = useState(false);
    const initialState = useMemo(() => {
        const blocksFromHTML = convertFromHTML(initialValue);
        const initialState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        return EditorState.createWithContent(initialState, decorators);
    }, [initialValue, decorators])
    const [editorState, setEditorState] = useState(initialState);
    const [linkDecoratorState, setLinkDecoratorState] = useState<LinkDecoratorEditState>();

    // There are times where we render the component before the initial value has been set.
    // This will update the state to match the initial value as long as the user has not manually interacted with the field.
    useEffect(() => {
        const hasInitialValueChanged = prevInitialValue.current !== initialValue;
        if (hasInitialValueChanged && !hasManuallyChangedValue) {
            setEditorState(initialState);
        }
        prevInitialValue.current = initialValue;
    }, [initialValue, initialState, hasManuallyChangedValue, decorators]);

    const handleEditorChange = useCallback(
        (newEditorState: EditorState) => {
            setEditorState(newEditorState);
            onChange(stateToHTML(newEditorState.getCurrentContent()));
            setHasManuallyChangedValue(true);
        },
        [onChange]
    );

    const updateEntityData: EntityDataUpdater = useCallback(
        (entityKey, updatedData) => {
            editorState.getCurrentContent().mergeEntityData(entityKey, updatedData);
        },
        [editorState]
    );

    const handleLinkUpdate = useCallback(
        (updatedLink: string) => {
            if (linkDecoratorState?.entityKey) {

                if (linkDecoratorState.currentLinkValue) {
                    // update existing link
                    updateEntityData(linkDecoratorState.entityKey, { url: updatedLink, href: updatedLink });
                } else {
                    // create new link
                    setEditorState(createDraftLinkOnCurrentlySelectedContent(editorState, updatedLink));
                }
            }
            setLinkDecoratorState(undefined);
        },
        [linkDecoratorState, updateEntityData, editorState]
    );

    const handleRemoveLink = useCallback(() => {
        if (linkDecoratorState?.entityKey) {
            setEditorState(removeDraftEntityFromEditor(editorState, linkDecoratorState.entityKey));
        }
        setLinkDecoratorState(undefined);
    }, [editorState, linkDecoratorState]);

    const handleLinkCancel = useCallback(() => setLinkDecoratorState(undefined), []);

    return (
        <DraftEditorContext.Provider value={{ updateEntityData, linkDecoratorState, setLinkDecoratorState }}>
            <>
                <Paper sx={{ width: '100%', minHeight: 24, borderRadius: '4px' }}>
                    <DraftWysiwygTitleBar editorState={editorState} setEditorState={setEditorState} />
                    <Box sx={{ padding: '8px 16px 0px 16px', overflowY: 'auto', '& .DraftEditor-editorContainer': { height: '240px' } }} className='draggable-element'>
                        <Editor
                            editorState={editorState}
                            onChange={handleEditorChange}
                            // customStyleMap={CUSTOM_COLORS}
                            spellCheck
                        />
                    </Box>
                </Paper>
                <LinkDecoratorEditModal
                    linkDecoratorState={linkDecoratorState}
                    onUpdate={handleLinkUpdate}
                    onCancel={handleLinkCancel}
                    onDelete={handleRemoveLink}
                />
            </>
        </DraftEditorContext.Provider>
    );
};
