import { DoubleArrow } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { FC, useMemo } from 'react';
import { NodeType } from '../NodeTypes/NodeType';
import { NodeTypes, getAllNodeTypes } from '../NodeTypes/utils';
import { ModalTitle } from './SubComponents';
import { usePermissionChecker } from '../../../Hooks';

export const NodeLibrary: FC = () => {
    const { hasAllPermissions } = usePermissionChecker()

    const nodeLibraryNodeTypes: NodeType[] = useMemo(() => {
        let nodeTypes = getAllNodeTypes();
        nodeTypes = nodeTypes.filter(nt => nt.isVisibleInNodeLibrary);
        if (!hasAllPermissions(['feature:automation'])) {
            nodeTypes = nodeTypes.filter(nt => nt.nodeType !== NodeTypes.AUTOMATION)
        }
        return nodeTypes;
    }, [hasAllPermissions]);

    return (
        <Grid
            container
            width='180px'
            height='100%'
            direction='row'
            textAlign='center'
            alignContent='flex-start'
            sx={{ background: '#f3f5f6', overflowY: 'auto' }}
            rowGap={1}
        >
            <Grid item xs={12}>
                <ModalTitle icon={<DoubleArrow sx={{ mr: 1 }} />} title='Step Library' />
            </Grid>
            {nodeLibraryNodeTypes.map(nt => !!nt.NodeLibraryListItem && <Grid item xs={6} key={nt.nodeType}><nt.NodeLibraryListItem /></Grid>)}
        </Grid>
    );
};
