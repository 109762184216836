import { Check, Clear, Edit } from '@mui/icons-material';
import {
    Box,
    Button,
    Chip,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import _ from 'lodash';
import { ChangeEventHandler, FC, KeyboardEventHandler, useCallback, useContext, useMemo, useState } from 'react';
import { DataTypeSelect } from '../../../../Components/CommonInputs';
import { PlayVariableDto, StepDataType } from '../../../../dtos';
import { PlayEditorContext } from '../../Utils/PlayEditorContext';

export interface IPlayVariableInputRowProps {
    playVariable: PlayVariableDto;
    currentIndex?: number;
    updateVariable: (value: PlayVariableDto) => void;
    hideRenameButtons?: boolean;
}

export const PlayVariableInputRow: FC<IPlayVariableInputRowProps> = ({ playVariable, updateVariable, hideRenameButtons, currentIndex }) => {
    const { renamePlayVariable, validatePlayVariableName } = useContext(PlayEditorContext);
    const isNewVariable = playVariable.name === '';
    const [isRenaming, setIsRenaming] = useState(isNewVariable);
    const [name, setName] = useState(playVariable.name);
    const textType = useMemo(() => (playVariable.dataType === StepDataType.TEXT && !!playVariable.options ? 'fl' : 'ff'), [playVariable]);
    const [newOption, setNewOption] = useState('');

    const handleRemoveOption = useCallback((idx: number) => {
        var updatedPlayVariable = _.cloneDeep(playVariable);
        updatedPlayVariable.options?.splice(idx, 1);
        updateVariable(updatedPlayVariable);
    }, [playVariable, updateVariable]);

    const handleNewOptionChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback((e) => {
        setNewOption(e.target.value);
    }, []);

    const handleAddOptionClicked = useCallback(() => {
        // if the new option is empty, do nothing
        if (newOption.trim() === '') {
            return;
        }
        // if the option already exists, just clear the input
        if (playVariable.options?.includes(newOption)) {
            setNewOption('');
            return;
        }
        var updatedPlayVariable = _.cloneDeep(playVariable);
        if (updatedPlayVariable.options === undefined) {
            updatedPlayVariable.options = [];
        }
        updatedPlayVariable.options?.push(newOption);
        updatedPlayVariable.options?.sort((a, b) => a.localeCompare(b));
        updateVariable(updatedPlayVariable);
        setNewOption('');
    }, [newOption, playVariable, updateVariable]);

    const handleNewOptionKeyPress: KeyboardEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            if (e.key === 'Enter') {
                handleAddOptionClicked()
            }
        },
        [handleAddOptionClicked]
    );

    const nameValidationResult = useMemo(() => validatePlayVariableName(name, currentIndex), [validatePlayVariableName, name, currentIndex]);

    const handleNameChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
        (e) => {
            setName(e.target.value);
            if (hideRenameButtons) {
                // If we hide the rename buttons then the name change needs to take effect immediately rather than waiting for a confirm/rename
                var updatedPlayVariable = _.cloneDeep(playVariable);
                updatedPlayVariable.name = e.target.value;
                updateVariable(updatedPlayVariable);
            }
        },
        [playVariable, updateVariable, hideRenameButtons]
    );

    const handleTypeChange = useCallback(
        (value: StepDataType) => {
            var updatedPlayVariable = _.cloneDeep(playVariable);
            updatedPlayVariable.dataType = value;
            updateVariable(updatedPlayVariable);
        },
        [playVariable, updateVariable]
    );

    const handleValidationChange = useCallback(
        (e: SelectChangeEvent<boolean>) => {
            var updatedPlayVariable = _.cloneDeep(playVariable);
            updatedPlayVariable.isFutureDate = e.target.value === 'true';
            updateVariable(updatedPlayVariable);
        },
        [playVariable, updateVariable]
    );

    const handleTextTypeChange = useCallback(
        (e: SelectChangeEvent<string>) => {
            var updatedPlayVariable = _.cloneDeep(playVariable);
            if (e.target.value === 'fl') {
                updatedPlayVariable.options = [];
            } else {
                updatedPlayVariable.options = undefined;
            }
            updateVariable(updatedPlayVariable);
        },
        [playVariable, updateVariable]
    );

    const handleCancelRenameClicked = useCallback(() => {
        setName(playVariable.name);
        setIsRenaming(false);
    }, [playVariable.name]);

    const handleRenameClicked = useCallback(() => {
        renamePlayVariable(playVariable.name, name);
        setIsRenaming(false);
    }, [name, playVariable.name, renamePlayVariable]);

    const nameInputEndAdornment = useMemo(() => {
        if (hideRenameButtons) {
            return;
        }
        if (isRenaming) {
            return (
                <Box display='flex'>
                    <IconButton onClick={handleCancelRenameClicked} color='error'>
                        <Clear />
                    </IconButton>
                    <IconButton onClick={handleRenameClicked} color='success' disabled={!nameValidationResult.isValid}>
                        <Check />
                    </IconButton>
                </Box>
            );
        } else {
            return (
                <IconButton onClick={() => setIsRenaming(true)}>
                    <Edit />
                </IconButton>
            );
        }
    }, [isRenaming, handleCancelRenameClicked, handleRenameClicked, nameValidationResult.isValid, hideRenameButtons]);

    return (
        <>
            <Grid item xs={12} container direction='row' gap={1} flexWrap='nowrap'>
                <Grid item flexGrow={1} pl={1}>
                    {/* TODO: replace with FormInput component */}
                    <FormControl fullWidth required error={!nameValidationResult.isValid}>
                        <FormLabel>Name</FormLabel>
                        <OutlinedInput value={name} onChange={handleNameChange} endAdornment={nameInputEndAdornment} disabled={!isRenaming} />
                        <FormHelperText>{nameValidationResult.errorMessage}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item width={100}>
                    <DataTypeSelect value={playVariable.dataType!} onChange={handleTypeChange} />
                </Grid>
                {playVariable.dataType === StepDataType.DATE && (
                    <Grid item width={100}>
                        <FormControl fullWidth required>
                            <FormLabel>Validation</FormLabel>
                            <Select value={playVariable.isFutureDate} onChange={handleValidationChange}>
                                <MenuItem value={'true'}>Future</MenuItem>
                                <MenuItem value={'false'}>Any</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                {playVariable.dataType === StepDataType.TEXT && (
                    <Grid item width={120}>
                        <FormControl fullWidth required>
                            <FormLabel>Type</FormLabel>
                            <Select value={textType} onChange={handleTextTypeChange}>
                                <MenuItem value={'ff'}>Free Form</MenuItem>
                                <MenuItem value={'fl'}>Fixed List</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
            {textType === 'fl' && (
                <Grid item xs={12} pl={1}>
                    <FormLabel>Fixed List Options</FormLabel>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, border: '1px solid rgba(0, 0, 0, 0.26)', borderRadius: 1 }} p={1}>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                            {playVariable.options?.map((option, idx) => (
                                <Chip key={idx} label={option} onDelete={() => handleRemoveOption(idx)} />
                            ))}
                        </Box>
                        <Box display='flex' gap={1}>
                            <TextField
                                size='small'
                                label='Add Option'
                                variant='outlined'
                                fullWidth
                                value={newOption}
                                onChange={handleNewOptionChange}
                                onKeyUp={handleNewOptionKeyPress}
                            />
                            <Button variant='contained' color='primary' disabled={newOption.trim() === ''} onClick={handleAddOptionClicked}>
                                Add
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            )}
        </>
    );
};
