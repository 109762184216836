import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    useCreateEmailGlobalBlackListMutation,
    useDeleteEmailGlobalBlackListMutation,
    useGetEmailGlobalBlackListQuery,
} from '../../store/generated/generatedApi';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../Components/CoreLib/library';

const EmailUnsubscribeView: FC = () => {
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email') || '';

    const [addEmailToBlackList, { isSuccess: isSuccessfullyAddedToBlackList, isError: isErrorAdded }] = useCreateEmailGlobalBlackListMutation();
    const { data: isBlackListed, isLoading } = useGetEmailGlobalBlackListQuery({ email });
    const [deleteEmailFromBlackList, { isSuccess: isSuccessfullyDeletedFromBlackList, isError: isErrorDeleted }] = useDeleteEmailGlobalBlackListMutation();

    const handleUnsubscribeButton = async () => {
        try {
            addEmailToBlackList({ email });
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubscribeButton = async () => {
        try {
            deleteEmailFromBlackList({ email });
        } catch (error) {
            console.error(error);
        }
    };

    useSuccessfulActionSnackbar('unsubscribed', 'email', isSuccessfullyAddedToBlackList);
    useFailedActionSnackbar('unsubscribed', 'email', isErrorAdded);

    useSuccessfulActionSnackbar('subscribed', 'email', isSuccessfullyDeletedFromBlackList);
    useFailedActionSnackbar('subscribed', 'email', isErrorDeleted);

    const renderSubscriptionUI = () => (
        <>
            <Typography variant='h1'>Would you like to subscribe to recieve email notifications?</Typography>
            <Button variant='contained' color='primary' onClick={handleSubscribeButton}>
                Subscribe
            </Button>
        </>
    );

    const renderUnsubscribeUI = () => (
        <>
            <Typography variant='h1'>Are you sure you want to unsubscribe from recieving email notifications?</Typography>
            <Button variant='contained' color='primary' onClick={handleUnsubscribeButton}>
                Unsubscribe
            </Button>
        </>
    );

    return (
        <Box display='flex' alignItems='center' p={2} flexDirection='column' rowGap={1}>
            {isBlackListed && !isLoading ? renderSubscriptionUI() : renderUnsubscribeUI()}
        </Box>
    );
};

export default EmailUnsubscribeView;
