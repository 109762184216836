import { AutoAwesome } from '@mui/icons-material';
import { FC, useCallback, useContext } from 'react';
import { NodeProps } from 'reactflow';
import { StepDto, StepExecutionDto } from '../../../../../dtos';
import { StepValidator } from '../../../Utils';
import { PlayEditorContext } from '../../../Utils/PlayEditorContext';
import { PlayViewerNode } from '../../Components/PlayViewerNode';
import { ARTIFICIAL_INTELLIGENCE_NODE_DESCRIPTION } from '../../utils';

export const ArtificialIntelligencePlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = (props) => {
    const { playVariables } = useContext(PlayEditorContext);

    // TODO: I need to look over this validation pattern again and figure out if it still meets our needs
    const isStepValid = useCallback(
        (step: StepDto) => {
            const stepValidator = new StepValidator(step, playVariables);
            const isValid = stepValidator.isValidEnoughToSave && stepValidator.isPromptValid && stepValidator.isOutputDataLabelValid;
            return isValid;
        },
        [playVariables]
    );

    return (
        <PlayViewerNode
            {...props}
            icon={<AutoAwesome fontSize='large' />}
            description={ARTIFICIAL_INTELLIGENCE_NODE_DESCRIPTION}
            additionalStepValidation={isStepValid}
        />
    );
};
