import { useCallback } from 'react';
import { StepDto } from '../../../dtos';

export const usePlayVariableRenameUtil = () => {
    const createExactMatchReplacer = useCallback(
        (searchingFor: string, replacingWith: string) => (value: string | undefined) => {
            if (value === searchingFor) {
                return replacingWith;
            }
            return value;
        },
        []
    );

    const createSubStringReplacer = useCallback(
        (searchingFor: string, replacingWith: string) => (value: string | undefined) => {
            if (!value) {
                return value;
            }
            return value.replaceAll(`{{${searchingFor}}}`, `{{${replacingWith}}}`);
        },
        []
    );

    // TODO: make this more readable
    const renameVariableInStep = useCallback((step: StepDto, oldName: string, newName: string) => {
        const replaceIfExactMatch = createExactMatchReplacer(oldName, newName);
        const replaceAllReferences = createSubStringReplacer(oldName, newName);

        step.description = replaceAllReferences(step.description);
        step.stepData = step.stepData.map((sd) => ({ ...sd, name: replaceIfExactMatch(sd.name) }));
        step.delayDateLabel = replaceIfExactMatch(step.delayDateLabel);
        step.requestDataLabels = step.requestDataLabels?.map((rdl) => replaceIfExactMatch(rdl)!);
        step.subplayDataInputConfig = step.subplayDataInputConfig?.map((sdic) => ({
            ...sdic,
            sourceFieldName: replaceAllReferences(sdic.sourceFieldName)!,
        }));
        step.subplayDataOutputConfig = step.subplayDataOutputConfig?.map((sdoc) => ({
            ...sdoc,
            destinationFieldName: replaceIfExactMatch(sdoc.destinationFieldName)!,
        }));
        step.outputDataLabel = replaceIfExactMatch(step.outputDataLabel);
        step.stepConditions = step.stepConditions?.map((stepCondition) => ({
            ...stepCondition,
            leftOperand: replaceAllReferences(stepCondition.leftOperand)!,
            rightOperand: replaceAllReferences(stepCondition.rightOperand)!
        }));

        return step;
    }, [createExactMatchReplacer, createSubStringReplacer]);

    return { renameVariableInStep };
};
