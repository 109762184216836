import { StepEmbedInputDto } from "../../../../dtos";

export class EmbedLinkValidator {
    private readonly allEmbeddingInputEntries: StepEmbedInputDto[];

    constructor(embeddingInputEntries: StepEmbedInputDto[]) {
        this.allEmbeddingInputEntries = embeddingInputEntries ?? [];
    }
    
    public validateEmbedLink(embedInput: StepEmbedInputDto): boolean {
        const isEmbeddedLinkProvided = !!embedInput.embedLink;
        const isEmbedLinkUnique = this.allEmbeddingInputEntries.filter((sd) => sd.embedLink?.trim().toLowerCase() === embedInput.embedLink?.trim().toLowerCase()).length === 1;
        const embedLinkRegExp = /<iframe.*src=".*".*><\/iframe>/;
        const isEmbedLinkValid = !!embedInput.embedLink ? embedLinkRegExp.test(embedInput.embedLink) : false;

        return isEmbeddedLinkProvided && isEmbedLinkUnique && isEmbedLinkValid;
    }

    public get areAllEmbeddingLinksValid(): boolean {
        return this.allEmbeddingInputEntries.every(stepData => this.validateEmbedLink(stepData));
    }
}