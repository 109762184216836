import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useRef, useState } from 'react';
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';
import { EventWrapper } from '../dtos';

export function useYjsDocumentManager(documentName: string, applyEvents: (events: EventWrapper[]) => void, onClosed: () => void) {
    const docRef = useRef<Y.Doc | null>(null);
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const { getAccessTokenSilently } = useAuth0();
    const providerRef = useRef<WebsocketProvider | null>(null);
    const [failedAttempts, setFailedAttempts] = useState<number>(0);

    useEffect(() => {
        getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                redirect_uri: window.location.origin,
                scope: "SCOPE profile email offline_access",
            }
        }).then(token => setAccessToken(token));
    }, [getAccessTokenSilently]);

    useEffect(() => {
        if (!process.env.REACT_APP_YJS_SERVER_URL) {
            console.error('no yjs server url configured');
            return
        }
        if (!accessToken) {
            return;
        }
        // Initialize Yjs document
        if (!docRef.current) {
            docRef.current = new Y.Doc();
        }
        
        providerRef.current = new WebsocketProvider(process.env.REACT_APP_YJS_SERVER_URL, documentName, docRef.current, {
            params: {
                token: accessToken,
            },
        });

        providerRef.current.on('sync', (isSynced: boolean) => {
            console.log('sync event: ', isSynced);
            if (isSynced) {
                if (!docRef.current) {
                    return;
                }
                // Initialize a shared array (or use an existing one)
                const yEvents = docRef.current.getArray<EventWrapper>('events');
                applyEvents(yEvents.toArray())
                // Listen for changes
                yEvents.observe((event) => {
                    console.log('observed change to the event array');
                    event.changes.added.forEach((item) => {
                        console.log('applying events from observed change: ', item.content.getContent());
                        applyEvents(item.content.getContent());
                    });
                });
            }
        });

        providerRef.current.on('status', (event: any) => {
            console.log('status event: ', event);
            if (event.status === 'disconnected') {
                setFailedAttempts(prevAttempts => prevAttempts + 1);
            }
        });

        // Cleanup
        return () => {
            providerRef.current?.disconnect();
            docRef.current?.destroy();
            docRef.current = null;
            onClosed();
        };
    }, [documentName, applyEvents, onClosed, accessToken]);

    useEffect(() => {
        if (failedAttempts >= 5) {
            console.warn('Too many failed connection attempts. Disconnecting from provider.');
            providerRef.current?.disconnect();
        }
    }, [failedAttempts]);

    return { doc: docRef.current };
}
