import { FormControl, FormLabel, Grid, TextField, Link, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IStepExecutionDetailsProps } from '../../types';
import { renderWithRequiredIndicator } from '../../../../../util';
import { StepDescriptionDetails, StepFileAttachmentDetails } from '../../../../PlayHistory/Components/StepDetailSections/Components';

export const SubplayNodeExecutionDetails: FC<IStepExecutionDetailsProps> = ({ stepExecution }) => {
    const navigate = useNavigate();

    const getMappedInputFieldValue = useCallback((sourceFieldName: string, placeholder: string) => {
        if (!!stepExecution.startedOn) {
            if (!stepExecution.subplayDataInputConfigValues) {
                return '';
            }
            return stepExecution.subplayDataInputConfigValues[sourceFieldName] ?? '';
        } else {
            return placeholder;
        }
    }, [stepExecution]);

    const getMappedOutputFieldValue = useCallback((sourceFieldName: string, isRequired: boolean) => {
        if (!!stepExecution.startedOn) {
            if (!stepExecution.subplayDataOutputConfigValues) {
                return '';
            }
            return stepExecution.subplayDataOutputConfigValues[sourceFieldName] ?? '';
        } else {
            return renderWithRequiredIndicator(sourceFieldName, isRequired);
        }
    }, [stepExecution]);

    const goToSubplayRunHistoryPage = () => {navigate(`/playbook/${stepExecution.subplayPlaybookId}/play/${stepExecution.subplaySourcePlayId}/history?runId=${stepExecution.subplayId}`)};

    const renderInputDetails = useMemo(() => {
        const inputConfigs = stepExecution.subplayDataInputConfig;
        if (!inputConfigs || inputConfigs.length === 0) {
            return <Typography fontStyle='italic'>No Subplay Input values were mapped</Typography>
        }
        
        return inputConfigs.map((inputConfig, idx) => (
            <Grid item key={`input-map-${idx}`} xs={8}>
                <FormControl fullWidth>
                    <FormLabel>{renderWithRequiredIndicator(inputConfig.destinationFieldName, !!inputConfig.isDestinationARequiredField)}</FormLabel>
                    {/* TODO: with some of the logic we have removed we can no longer tell if this is required or not. We should determine if and how this should be re-implemented */}
                    <TextField disabled variant='outlined' value={getMappedInputFieldValue(inputConfig.destinationFieldName, renderWithRequiredIndicator(inputConfig.sourceFieldName, false))} />
                </FormControl>
            </Grid>
        ))
    }, [stepExecution.subplayDataInputConfig, getMappedInputFieldValue]);

    const renderOutputDetails = useMemo(() => {
        const outputConfigs = stepExecution.subplayDataOutputConfig;
        if (!outputConfigs || outputConfigs.length === 0) {
            return <Typography fontStyle='italic'>No Subplay Output values were mapped</Typography>
        }
        
        return outputConfigs.map((inputConfig, idx) => (
            <Grid item key={`input-map-${idx}`} xs={8}>
                <FormControl fullWidth>
                    <FormLabel>{renderWithRequiredIndicator(inputConfig.destinationFieldName, !!inputConfig.isDestinationARequiredField)}</FormLabel>
                    {/* TODO: with some of the logic we have removed we can no longer tell if this is required or not. We should determine if and how this should be re-implemented */}
                    <TextField disabled variant='outlined' value={getMappedOutputFieldValue(inputConfig.sourceFieldName, false)} />
                </FormControl>
            </Grid>
        ))
    }, [stepExecution.subplayDataOutputConfig, getMappedOutputFieldValue]);

    return (
        <>
            <StepDescriptionDetails description={stepExecution.description ?? ''} />
            <Grid item mt={2}>
                <Typography>Play: {stepExecution.subplayName}</Typography>
                {stepExecution.startedOn && (
                    <>
                        <Typography>Status: {stepExecution.subplayStatus ?? 'Starting'}</Typography>
                        <Typography>Run #: {stepExecution.subplayRunNumber ? <Link underline='hover' onClick={goToSubplayRunHistoryPage} href='#'>{stepExecution.subplayRunNumber}</Link> : ''}</Typography>  
                    </>
                )}
                <Typography>Wait until Subplay is Completed: {stepExecution.isImmediatelyCompleted ? 'False' : 'True'}</Typography>
            </Grid>
            {stepExecution.fileAttachments && stepExecution.fileAttachments.length > 0 && <StepFileAttachmentDetails fileAttachments={stepExecution.fileAttachments} />}
            <Grid item container direction='row' mt={2} mb={1}>
                <Grid item>
                    <Typography variant='h2'>Input Data</Typography>
                </Grid>
            </Grid>
            {renderInputDetails}
            <Grid item container direction='row' mt={2} mb={1}>
                <Grid item>
                    <Typography variant='h2'>Output Data</Typography>
                </Grid>
            </Grid>
            {renderOutputDetails}
        </>
    );
}