import { Connection, Edge, Node } from "reactflow";
import { DependentNodeKeyFinder } from "../DependentNodeKeyFinder";
import { TRUE_HANDLE_ID, FALSE_HANDLE_ID } from "../../NodeTypes/Conditional/Components";
import { getNodeRules } from "../../NodeTypes/utils";

export class GraphConnectionValidator {
    private readonly existingNodes: Node[];
    private readonly existingEdges: Edge[];

    constructor(existingNodes: Node[], existingEdges: Edge[]) {
        this.existingNodes = existingNodes;
        this.existingEdges = existingEdges;
    }

    public validateNewConnection(connection: Connection): string {
        const isNodeLinkedToSelf = this.checkIfNodeIsLinkedToItself(connection);
        if (isNodeLinkedToSelf) {
            return 'A step cannot be linked to itself';
        }

        const { isViolatingHandleSpecificRule, handleErrorMessage } = this.checkForHandleSpecificRules(connection);
        if (isViolatingHandleSpecificRule) {
            return handleErrorMessage;
        }
        
        const { isSourceAtOrAboveMaxOutputs, sourceNodeType, sourceNodeRules } = this.checkIfSourceCanHaveAnotherOutput(connection);
        if (isSourceAtOrAboveMaxOutputs) {
            return `A ${sourceNodeType} step can only have ${sourceNodeRules.maxOutputs} output${sourceNodeRules.maxOutputs === 1 ? '' : 's'}`;
        }

        const { isTargetAtOrAboveMaxInputs, targetNodeType, targetNodeRules } = this.checkIfTargetCanHaveAnotherInput(connection);
        if (isTargetAtOrAboveMaxInputs) {
            return `A ${targetNodeType} step can only have ${targetNodeRules.maxInputs} input${sourceNodeRules.maxInputs === 1 ? '' : 's'}`;
        }

        const isLinkingToDependentStep = this.checkIfNodeIsLinkingBackToAPreviousStep(connection);
        if (isLinkingToDependentStep) {
            return 'A step cannot be linked back to a previous step';
        }

        return '';
    }

    private checkIfNodeIsLinkedToItself(connection: Connection) {
        return connection.target === connection.source;
    }

    private checkForHandleSpecificRules(connection: Connection) {
        var handleViolation = '';

        switch (connection.sourceHandle) {
            case TRUE_HANDLE_ID:
            case FALSE_HANDLE_ID:
                const isExistingConnectionToSameHandle = this.existingEdges.some(exEdg => exEdg.source === connection.source && exEdg.sourceHandle === connection.sourceHandle);
                if (isExistingConnectionToSameHandle) {
                    handleViolation = 'A conditional step can only have 1 output for true and 1 output for false';
                    break;
                }

                const isOtherHandleConnectedToSameNode = this.existingEdges.some(exEdg => exEdg.source === connection.source && exEdg.target === connection.target);
                if (isOtherHandleConnectedToSameNode) {
                    handleViolation = 'The true and false of a conditional step must be connected to different steps';
                    break;
                }
                break;
        }

        return { isViolatingHandleSpecificRule: !!handleViolation, handleErrorMessage: handleViolation };
    }

    private checkIfSourceCanHaveAnotherOutput(connection: Connection) {
        const sourceNodeType = this.existingNodes.find((node) => node.id === connection.source)?.type ?? 'unknown';
        const sourceNodeRules = getNodeRules(sourceNodeType);
        const currentNumOutputs = this.existingEdges.filter((edge) => edge.source === connection.source).length;
        const isSourceAtOrAboveMaxOutputs = currentNumOutputs >= sourceNodeRules.maxOutputs;
        return { isSourceAtOrAboveMaxOutputs, sourceNodeType, sourceNodeRules };
    }

    private checkIfTargetCanHaveAnotherInput(connection: Connection) {
        const targetNodeType = this.existingNodes.find((node) => node.id === connection.target)?.type ?? 'unknown';
        const targetNodeRules = getNodeRules(targetNodeType);
        const currentNumInputs = this.existingEdges.filter((edge) => edge.target === connection.target).length;
        const isTargetAtOrAboveMaxInputs = currentNumInputs >= targetNodeRules.maxInputs;
        return { isTargetAtOrAboveMaxInputs, targetNodeType, targetNodeRules };
    }

    private checkIfNodeIsLinkingBackToAPreviousStep(connection: Connection) {
        const dependentNodeKeyFinder = new DependentNodeKeyFinder(this.existingEdges);
        const isLinkingToDependentStep = dependentNodeKeyFinder.getDependentNodeKeysForKey(connection.source ?? '').includes(connection.target ?? '');
        return isLinkingToDependentStep;
    }
}