import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC, FocusEventHandler, SyntheticEvent, useMemo } from 'react';
import { PlayDto } from '../../dtos';
import { useGetPlayQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';


export interface IClientPlaySelectProps {
    selectedPlayId: string;
    handleSelectedPlayChange: (selectedPlay?: PlayDto) => void;
    required?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    onBlur?: FocusEventHandler<HTMLDivElement>;
    label?: string;
    playFilter?: (p: PlayDto) => boolean;
}

export const ClientPlaySelect: FC<IClientPlaySelectProps> = props => {
    const { selectedPlayId, handleSelectedPlayChange, required, errorMessage, disabled, onBlur, playFilter = (_) => true, label = 'Play' } = props;
    const { data: plays, isLoading: isLoadingPlays, isError: isErrorLoadingPlays } = useGetPlayQuery({
        sortKey: 'NAME',
        sortAsc: true,
        page: 0,
        pageSize: 10000,
        includeInactive: false,
    });

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: PlayDto | null) => {
        handleSelectedPlayChange(value ?? undefined);
    }

    const selectedPlay = useMemo(() => {
        if (selectedPlayId === '' || selectedPlayId === emptyGuid) {
            return null;
        }
        return plays?.pageResults.find(x => x.id === selectedPlayId) ?? null;
    }, [selectedPlayId, plays])

    return (
        <FormControl error={isErrorLoadingPlays || !!errorMessage} fullWidth required={required} disabled={!plays} className='non-draggable-element'>
            <FormLabel>{label}</FormLabel>
            <Autocomplete
                value={selectedPlay}
                onChange={handleSelectChange}
                loading={isLoadingPlays}
                options={plays?.pageResults.filter(playFilter) ?? []}
                getOptionLabel={x => `${x.playbookName ?? 'un-named playbook'} - ${x.name ?? 'un-named play'}`}
                renderInput={(params) => (
                    <TextField
                        error={!!errorMessage}
                        {...params}
                    />
                )}
                onBlur={onBlur}
                disabled={disabled}
                onDrop={(e) => e.preventDefault()}
            />
            <FormHelperText>{isErrorLoadingPlays ? 'Failed to load play options' : errorMessage ?? ''}</FormHelperText>
        </FormControl>
    )
}