import { Dashboard, History } from '@mui/icons-material';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ApiError, LoadingIndicator, SlimPageHeader, useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { useGetPlayByIdQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';
import { DistributionOfAverageTimePerStep } from './Cards/DistributionOfAverageTimePerStep';
import { RunsListView } from './Cards/RunsListView';
import { StepExecutionListView } from './Cards/StepExecutionListView';
import { StepsDataListView } from './Cards/StepsDataListView';
import { useStepExecutionFilters } from './Util/useStepExecutionFilters';
import { TitleBarEndSlot } from '../Plays/Components/SubComponents/TitleBarEndSlot';

function setTabValueProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const PlayDashboardView: FC = () => {
    const { playId } = useParams();
    const {
        data: playDetails,
        isLoading: isLoadingPlayDetails,
        isError: isErrorLoadingPlayDetails,
        refetch: refetchPlayDetails,
    } = useGetPlayByIdQuery({ id: playId ?? emptyGuid });
    useFailedActionSnackbar('retrieve', 'play details', isErrorLoadingPlayDetails);
    const [searchParams, setSearchParams] = useSearchParams();
    const paramCurrentTab = searchParams.get('tab');


    const tabToIndexes = useMemo(() => new Map<string, number>([
        ['overview', 0],
        ['data', 1]
    ]), []);

    const indexToTabs = useMemo(() => new Map<number, string>([
        [0, 'overview'],
        [1, 'data']
    ]), []);

    const {
        stepExecutionFilters,
        handleSearchTermFilterChange,
        getFilterChips,
        handleRemoveFilterChip
    } = useStepExecutionFilters();

    const [tabIndex, setTabValue] = useState(0);
    const handleChangeTabIndex = (event: React.SyntheticEvent, newValue: number) => {
        searchParams.set('tab', indexToTabs.get(newValue) ?? 'overview');
        setSearchParams(searchParams);
        setTabValue(newValue);
    };

    const navigate = useNavigate();

    const handleViewRunHistory = () => {
        if (!playDetails) {
            return;
        }
        navigate(`/playbook/${playDetails.playbookId}/play/${playDetails.id}/history`)
    };

    useEffect(() => {
        setTabValue(tabToIndexes.get(paramCurrentTab ?? 'overview') ?? 0);
    }, [paramCurrentTab, tabToIndexes]);

    if (isLoadingPlayDetails) {
        return <LoadingIndicator />;
    }

    if (isErrorLoadingPlayDetails || !playDetails) {
        return <ApiError onReloadClick={refetchPlayDetails} />;
    }

    return (
        <Grid container direction='column'>
            <SlimPageHeader
                icon={<Dashboard />}
                title={playDetails.name ?? 'Unnamed Play'}
                breadCrumbProps={{
                    links: [
                        { label: 'Home', navLink: '/' },
                        { label: 'Playbooks', navLink: '/playbooks' },
                        { label: playDetails.playbookName ?? 'Playbook', navLink: `/playbook/${playDetails.playbookId}/plays` },
                    ],
                    currentPageLabel: playDetails.name ?? 'Unnamed Play'
                }}
                disableBorderRadius
                endSlot={<TitleBarEndSlot handleViewOtherPage={handleViewRunHistory} otherPageName='Run History' otherPageIcon={<History />} playDetails={playDetails} />
                }
            />
            <Grid zIndex={5} item position='sticky' top={0}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: 1, background: 'white' }}>
                    <Tabs value={tabIndex} onChange={handleChangeTabIndex} aria-label='edit step tabs' variant='fullWidth'>
                        <Tab label='OVERVIEW' {...setTabValueProps(0)} />
                        <Tab label='REPORTING' {...setTabValueProps(1)} />
                    </Tabs>
                </Box>
            </Grid>
            <Grid item container>
                <Box sx={{ height: '100%', width: '100%' }} px={4} pt={3}>
                    {tabIndex === 0 && (
                        <>
                            <Grid container direction='column' spacing={3} p={2}>
                                <Grid item>
                                    <DistributionOfAverageTimePerStep playId={playId ?? emptyGuid} />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {tabIndex === 1 && (
                        <>
                            <Grid container direction='column' spacing={3} p={2}>
                                <Grid item>
                                    <StepsDataListView
                                        playbookId={playDetails.playbookId}
                                        playId={playDetails.id}
                                        stepExecutionFilters={stepExecutionFilters}
                                        handleSearchTermFilterChange={handleSearchTermFilterChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <RunsListView
                                        playId={playId ?? emptyGuid}
                                        stepExecutionFilters={stepExecutionFilters}
                                        handleSearchTermFilterChange={handleSearchTermFilterChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <StepExecutionListView
                                        playbookId={playDetails.playbookId}
                                        playId={playDetails.id}
                                        stepExecutionFilters={stepExecutionFilters}
                                        handleSearchTermFilterChange={handleSearchTermFilterChange}
                                        getFilterChips={getFilterChips}
                                        handleRemoveFilterChip={handleRemoveFilterChip}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};
