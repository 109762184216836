import { FilterList, History } from '@mui/icons-material';
import { FC, useCallback, useMemo } from 'react';
import { DataTableColumn, PaginatedProps, StandardListViewV2, StandardCardWithHeader, IStandardListViewExtraItems } from '../../../Components/CoreLib/library';
import { PlayExecutionDto } from '../../../dtos';
import { useGetPlayExecutionQuery } from '../../../store/generated/generatedApi';
import { IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IStepExecutionFilterOptions } from '../Util/useStepExecutionFilters';
import { formatTimestamp } from '../../../util';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'RUN_NUMBER',
    sortAsc: false,
    page: 0,
    pageSize: 100,
};

export interface IRunsListView {
    playId: string;
    stepExecutionFilters: IStepExecutionFilterOptions;
    handleSearchTermFilterChange: (updatedFilters: IStepExecutionFilterOptions) => void;
}

export const RunsListView: FC<IRunsListView> = props => {
    const { playId, stepExecutionFilters, handleSearchTermFilterChange } = props;
    const navigate = useNavigate();
    const theme = useTheme();

    const goToRunHistoryPage = useCallback((row: PlayExecutionDto) => {
        navigate(`/playbook/${row.playbookId}/play/${row.sourcePlayId}/history?runId=${row.id}`);
    }, [navigate]) 

    const rowMenu = (row: PlayExecutionDto) => {
        return (
            <>
                <Tooltip title="View">
                    <IconButton onClick={ () => {goToRunHistoryPage(row);} } className='hover-content'>
                        <History fontSize='inherit' />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const formatExecutionTime = (minutes:number|undefined, isComplete:boolean, isEstimation:boolean) => {
        var execTimeStr = '';
        if (minutes !== undefined && (isComplete || (!isComplete && isEstimation))) {
            var days = Math.trunc(minutes/1440);
            if (days > 0) { execTimeStr = days.toString()+'d '; }
            minutes -= (days * 1440);
            var hours = Math.trunc(minutes/60);
            if (hours > 0) { execTimeStr += hours.toString()+'h '; }
            minutes -= (hours * 60);
            if (minutes > 0 && days <= 0) { execTimeStr += minutes.toString()+'m '; }
        } else {
            return '';
        }
        if (execTimeStr === '' || execTimeStr === '-') {
            return '0m';
        }
        return execTimeStr;
    }

    const formatLagExecutionTime = (lag:number|undefined, isComplete:boolean) => {
        if (lag !== undefined && isComplete) {
            var minutes = lag;
            if (minutes < 0) {
                minutes *= (-1);
                return <Typography sx={{color: theme.palette.success.main}}>{'-' + formatExecutionTime(minutes,isComplete,false)}</Typography>;
            } else if (minutes === 0) {
                return <Typography sx={{color: theme.palette.success.main}}>{formatExecutionTime(minutes,isComplete,false)}</Typography>;
            } else {
                return <Typography sx={{color: theme.palette.error.main}}>{formatExecutionTime(minutes,isComplete,false)}</Typography>;
            }
        } else {
            return '';
        }
    }

    const tableColumns: DataTableColumn<PlayExecutionDto>[] = [
        { label: 'Run Number', key: 'runNumber', sortKey: 'RUN_NUMBER' },
        { label: 'Run Owner', key: 'runOwner', sortKey: 'CREATED_BY', fieldMapper: (row) => (row.runOwner ? row.runOwner : 'Unknown owner') },
        { label: 'Started By', key: 'startedBy', sortKey: 'STARTED_BY', fieldMapper: (row) => (row.startedBy ? (row.startedBy === 'Manual' ? row.runOwner : row.startedBy) : 'Unknown initiator') },
        { label: 'Start Time', key: 'createdOn', sortKey: 'STARTED_ON', fieldMapper: (row) => (row.startedOn ? formatTimestamp(row.startedOn) : '') },
        { label: 'End Time', key: 'completedOn', sortKey: 'COMPLETED_ON', fieldMapper: (row) => (row.completedOn ? formatTimestamp(row.completedOn) : '') },
        { label: 'Status', key: 'status', sortKey: 'STATUS' },
        { label: 'Execution Time', key: 'executionTime', sortKey: 'EXECUTION_TIME_IN_MINUTES_ACTUAL', fieldMapper: (row) => (formatExecutionTime(row.actualExecutionTimeInMinutes,row.isComplete,false)) },
        { label: 'Estimated Execution Time', key: 'estimatedExecutionTime', sortKey: 'EXECUTION_TIME_IN_MINUTES_ESTIMATED', fieldMapper: (row) => (formatExecutionTime(row.estimatedExecutionTimeInMinutes,row.isComplete,true)) },
        { label: 'Lag Time', key: 'lagTime', sortKey: 'EXECUTION_TIME_IN_MINUTES_LAG', fieldMapper: (row) => (formatLagExecutionTime(row.lagExecutionTimeInMinutes,row.isComplete)) },
        { label: '', key: '', sortKey: '', fieldMapper: row => rowMenu(row) }
    ]

    const clickableRowStyles = useCallback(() => ({ cursor: 'pointer' }), [])

    const handleRunChange = useCallback((selectedPlayExecution?: PlayExecutionDto) => {
        handleSearchTermFilterChange({
            ...stepExecutionFilters,
            runId: selectedPlayExecution?.id ?? '',
            run: selectedPlayExecution,
        });
    }, [stepExecutionFilters, handleSearchTermFilterChange]);

    const verifyOnlyOneIsSelected = useCallback((selectedItems: PlayExecutionDto[]) => {
        return selectedItems.length === 1;
    }, []);

    const handleFilterStepExecutionsClicked = useCallback((selectedItems: PlayExecutionDto[]) => {
        if (selectedItems.length !== 1) {
            return;
        }
        const selectedItem = selectedItems[0];
        handleRunChange(selectedItem)
    }, [handleRunChange]);

    const additionalTableButtons: IStandardListViewExtraItems[] = useMemo(() => [
        {
            enabledCheck: verifyOnlyOneIsSelected,
            onClick: handleFilterStepExecutionsClicked,
            icon: <FilterList />,
            text: 'Filter Step Executions',
            clearSelectedItems: true
        }
    ], [verifyOnlyOneIsSelected, handleFilterStepExecutionsClicked]);

    const handleViewMenuClick = useCallback(
        (selectedItems: PlayExecutionDto[]) => {
            if (selectedItems.length !== 1) {
                return;
            }
            goToRunHistoryPage(selectedItems[0]);
        },
        [goToRunHistoryPage]
    );

    const additionalMenuItems: IStandardListViewExtraItems[] = useMemo(() => [
        {
            enabledCheck: verifyOnlyOneIsSelected,
            onClick: handleViewMenuClick,
            text: 'View'
        },
        {
            enabledCheck: verifyOnlyOneIsSelected,
            onClick: handleFilterStepExecutionsClicked,
            text: 'Filter Step Executions',
            clearSelectedItems: true
        }
    ], [handleFilterStepExecutionsClicked, verifyOnlyOneIsSelected, handleViewMenuClick]);

    return (
        <>
        <StandardCardWithHeader headerTitle='Runs (Play Executions)' headerIcon={<History />}>
            <StandardListViewV2
                headerIcon={<></>}
                headerTitle=''
                defaultPaginationProps={defaultPaginationProps}
                getDataQuery={useGetPlayExecutionQuery}
                tableColumns={tableColumns}
                additionalQueryParameters={{
                    PlayId: playId
                }}
                entityNameSingular={'Play Execution'}
                permissionName={'playExecutions'}
                disableGutters
                hideMenuDivider
                customRowStyling={clickableRowStyles}
                additionalActionItems={additionalTableButtons}
                additionalMenuItems={additionalMenuItems}
            />
        </StandardCardWithHeader>
        </>
    );
}