import { DoubleArrow } from '@mui/icons-material';
import { Alert, Button, Dialog, DialogContent, FormControl, FormLabel, Grid, OutlinedInput } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { ApiError, LoadingIndicator, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../Components/CoreLib/library';
import { OpenAIConfigurationDto } from '../../../dtos';
import { useGetClientOpenAIConfigQuery, useUpdateClientOpenAIConfigMutation } from '../../../store/generated/generatedApi';
import { ModalTitle } from '../../Plays/Components/SubComponents';

export interface IConfigureOpenAICardProps {
    configureOpenAIModalOpen: boolean;
    setConfigureOpenAIModalOpen: (open: boolean) => void;
}

export const ConfigureOpenAICard: FC<IConfigureOpenAICardProps> = ({ configureOpenAIModalOpen, setConfigureOpenAIModalOpen }) => {
    const { data: openAiConfigDetails, error: isOpenAIConfigError, isLoading: isLoadingOpenAIConfig, refetch: refetchOpenAiConfig } = useGetClientOpenAIConfigQuery();
    const [
        updateOpenAIConfig,
        { isError: isErrorUpdatingOpenAIConfig, isSuccess: isOpenAIConfigUpdated, isLoading: isUpdatingOpenAIConfig, reset: resetUpdateOpenAiConfig },
    ] = useUpdateClientOpenAIConfigMutation();

    const [hasAttemptedSave, setHasAttemptedSave] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [organizationId, setOrganizationId] = useState('');

    const openAIConfigDTO: OpenAIConfigurationDto = {
        openAIKey: apiKey,
        organizationID: organizationId,
    };

    const handleClose = useCallback(() => {
        setApiKey('');
        setHasAttemptedSave(false);
        setConfigureOpenAIModalOpen(false);
    }, [setConfigureOpenAIModalOpen]);

    useSuccessfulActionSnackbar('updated', 'OpenAI settings', isOpenAIConfigUpdated, handleClose);
    useFailedActionSnackbar('update', 'OpenAI settings', isErrorUpdatingOpenAIConfig, resetUpdateOpenAiConfig);

    const handleSaveClicked = () => {
        if (!hasAttemptedSave) {
            setHasAttemptedSave(true);
        }

        if (apiKey !== '') {
            updateOpenAIConfig(openAIConfigDTO);
        }
    };

    const handleCancel = useCallback(() => {
        setOrganizationId(openAiConfigDetails?.organizationID ?? '');
        handleClose()
    }, [openAiConfigDetails, handleClose]);

    // Populate the form after loading the configuration details
    useEffect(() => {
        if (openAiConfigDetails) {
            setApiKey('');
            setOrganizationId(openAiConfigDetails.organizationID ?? '');
        }
    }, [openAiConfigDetails]);

    if (isUpdatingOpenAIConfig || isLoadingOpenAIConfig) {
        return <LoadingIndicator />;
    }

    if (isOpenAIConfigError) {
        return <ApiError onReloadClick={refetchOpenAiConfig} />
    }

    return (
        <Dialog open={configureOpenAIModalOpen ?? false} fullWidth maxWidth='md'>
            <ModalTitle icon={<DoubleArrow sx={{ mr: 1 }} />} title='Configure OpenAI' />
            <DialogContent>
                <Grid container direction='column' spacing={2}>
                    {
                        openAiConfigDetails?.isOpenAiKeySet ? (
                            <Grid item>
                                <Alert severity='info'>
                                    OpenAI key has been set for this organization. The key is not displayed below for security reasons but can be updated.
                                </Alert>
                            </Grid>
                        ) : (
                            <Grid item>
                                <Alert severity='warning'>
                                    OpenAI key has not been set for this organization. Please enter the key below.
                                </Alert>
                            </Grid>
                        )
                    }
                    <Grid item>
                        <FormControl error={hasAttemptedSave && apiKey.trim() === ''} required fullWidth>
                            <FormLabel>API Key</FormLabel>
                            <OutlinedInput value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth>
                            <FormLabel>Organization Id</FormLabel>
                            <OutlinedInput
                                value={organizationId}
                                onChange={(e) => {
                                    setOrganizationId(e.target.value);
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <Grid item xs={12} display='flex' justifyContent='end' gap={2} position='sticky' bottom={0} pb={2} px={3} sx={{ background: 'white' }}>
                <Button variant='outlined' color='secondary' size='small' onClick={handleCancel} sx={{ width: 90 }}>
                    Cancel
                </Button>
                <Button variant='contained' color='secondary' size='small' onClick={handleSaveClicked} sx={{ width: 90 }}>
                    Set Key
                </Button>
            </Grid>
        </Dialog>
    );
};
