import { Delete, Edit, Help, Visibility, Error } from '@mui/icons-material';
import { Badge, Box, SxProps, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, MouseEventHandler, ReactElement, useCallback, useMemo, useState } from 'react';
import { Handle, HandleProps, NodeProps, Position } from 'reactflow';
import { AuthenticatedComponent } from '../../../../Components/CoreLib/library';
import { StepDto, StepExecutionDto } from '../../../../dtos';
import { INodeInteractionButton, NodeInteractionButtons } from '../../Components';
import { IBadgeProps, NodeColorPalette, getStepExecutionBadgeProps, isStepExecutionDto, useDeleteNode, useStepNodePaletteSelector } from '../../Utils';

const DEFAULT_HANDLES: HandleProps[] = [
    { type: 'target', position: Position.Left, id: 'l' },
    { type: 'source', position: Position.Right, id: 'r' },
];

export interface IPlayViewerNodeProps {
    icon: ReactElement;
    description: string;
    handles?: HandleProps[];
    disableEdit?: boolean;
    disableDelete?: boolean;
    disableViewExecutionDetails?: boolean;
    additionalStepValidation?: (step: StepDto) => boolean;
    shape?: 'circle' | 'diamond';
    activeColorPallet?: NodeColorPalette;
}

export const PlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto> & IPlayViewerNodeProps> = (props) => {
    const {
        data: stepData,
        id: nodeId,
        disableDelete,
        disableEdit,
        additionalStepValidation = () => true,
        handles = DEFAULT_HANDLES,
        description,
        activeColorPallet,
        icon: nodeIcon,
        shape = 'circle',
        disableViewExecutionDetails,
    } = props;
    const theme = useTheme();
    const { deleteNodeById } = useDeleteNode();
    const [isNodeHovered, setIsNodeHovered] = useState(false);
    const palette = useStepNodePaletteSelector(nodeId, activeColorPallet);
    const isStepValid = useMemo(() => {
        if (isStepExecutionDto(stepData)) {
            return true;
        }
        return !!stepData.name && additionalStepValidation(stepData);
    }, [stepData, additionalStepValidation]);

    const badgeProps: IBadgeProps = useMemo(() => {
        var step = stepData;
        if (isStepExecutionDto(step)) {
            return getStepExecutionBadgeProps(step);
        } else {
            return {
                color: 'default',
                badgeContent: <Error color='error' sx={{ background: 'white', borderRadius: 3 }} />,
                invisible: isStepValid,
            };
        }
    }, [stepData, isStepValid]);

    const handleDelete: MouseEventHandler<HTMLButtonElement> = useCallback(
        (e) => {
            e.stopPropagation();
            deleteNodeById(nodeId);
        },
        [nodeId, deleteNodeById]
    );

    const isStepExecution = useMemo(() => isStepExecutionDto(stepData), [stepData]);

    const deleteIcon = useMemo(
        () => (
            <Tooltip title='Delete' placement='top'>
                <Delete />
            </Tooltip>
        ),
        []
    );

    const InfoIcon = useMemo(
        () => (
            <Tooltip title={description} placement='top'>
                <Help />
            </Tooltip>
        ),
        [description]
    );

    const editIcon = useMemo(
        () => (
            <Tooltip title='Edit' placement='top'>
                <Edit />
            </Tooltip>
        ),
        []
    );

    const viewIcon = useMemo(
        () => (
            <Tooltip title='View' placement='top'>
                <Visibility />
            </Tooltip>
        ),
        []
    );

    const availableInteractionButtons = useMemo(() => {
        var options: INodeInteractionButton[] = [];
        // This is a shorthand syntax that can be a little difficult to get use to but it will short circuit if one of the booleans is true.
        // If they are all false then the option will be added.
        // Also, setting the action to an empty method allows the click event to propagate down to the base step component so it is the same as clicking on the step directly.
        isStepExecution || disableDelete || options.push({ icon: deleteIcon, action: handleDelete, requiredPermissions: ['delete:steps'] });
        options.push({ icon: InfoIcon, action: (e) => e.stopPropagation() });
        isStepExecution || disableEdit || options.push({ icon: editIcon, action: () => { }, requiredPermissions: ['edit:steps'] });
        !isStepExecution || disableViewExecutionDetails || options.push({ icon: viewIcon, action: () => { } });
        return options;
    }, [disableDelete, disableEdit, handleDelete, InfoIcon, isStepExecution, disableViewExecutionDetails, viewIcon, deleteIcon, editIcon]);

    const nodeBoxStyles: SxProps<Theme> = useMemo(() => {
        var styles: SxProps<Theme> = {
            color: palette.textColor,
            borderRadius: '50%',
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center',
            p: 2,
        };

        switch (shape) {
            case 'circle':
                styles.background = palette.background;
                styles.transition = 'background .3s ease-in-out, color .3s ease-in-out .15s';
                break;
        }

        return styles;
    }, [palette, shape]);

    const diamondBackground = useMemo(() => {
        return (
            <Box
                sx={{
                    background: palette.background,
                    transition: 'background .3s ease-in-out, color .3s ease-in-out .15s',
                    height: '50px',
                    width: '50px',
                    position: 'absolute',
                    transform: 'rotate(45deg)',
                    zIndex: -2,
                    top: 9,
                }}
            />
        );
    }, [palette]);

    return (
        <Badge {...badgeProps} overlap='circular'>
            <Box sx={nodeBoxStyles} onMouseEnter={() => setIsNodeHovered(true)} onMouseLeave={() => setIsNodeHovered(false)}>
                {shape === 'diamond' && diamondBackground}
                <NodeInteractionButtons isVisible={isNodeHovered} buttonOptions={availableInteractionButtons} />
                {nodeIcon}
                {handles.map((handle) => (
                    <Handle key={handle.id} {...handle} />
                ))}
                <Box sx={{ width: 100, top: 64, position: 'absolute', textAlign: 'center' }}>
                    <Typography color={theme.palette.text.primary}>
                        {stepData.name || <Typography fontStyle='italic'>Unnamed</Typography>} <AuthenticatedComponent requiredPermissions={['read:developer']} children={<span>({stepData.key})</span>} />
                    </Typography>
                </Box>
            </Box>
        </Badge>
    );
};
