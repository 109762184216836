import { FormControl, FormHelperText, FormLabel, Grid } from '@mui/material';
import { DateTimePicker, DateTimeValidationError } from '@mui/x-date-pickers';
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types';
import { FC, useCallback, useMemo } from 'react';
import { IStepDataInputProps } from './StepDataInput';
import { stepDataFieldLabelBuilder } from './Utils';
import dayjs from 'dayjs';

type DateTimePickerChangeHandler = (value: string | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => void;

export const DateStepDataInput: FC<IStepDataInputProps> = ({
    stepData,
    onChange,
    errorMessage,
    fieldLabelSuffix,
    disabled,
    showDataLabel
}) => {
    const fieldLabel = useMemo(() => stepDataFieldLabelBuilder(stepData.name ?? 'Date', { isRequired: stepData.isRequired, labelSuffix: fieldLabelSuffix }), [stepData, fieldLabelSuffix]);

    const handleChange: DateTimePickerChangeHandler = useCallback((val) => {
        onChange(val ?? '');
    }, [onChange]);

    return (
        <Grid item>
            <FormControl error={!!errorMessage} fullWidth disabled={disabled}>
                {showDataLabel && <FormLabel>{fieldLabel}</FormLabel>}
                <DateTimePicker
                    disablePast={stepData.isFutureDate}
                    formatDensity='spacious'
                    slotProps={{ textField: { error: !!errorMessage }}}
                    timeSteps={{ minutes: 1 }}
                    value={dayjs(stepData.value) as any}
                    onChange={handleChange}
                    timezone='system'
                    disabled={disabled}
                />
                <FormHelperText>{errorMessage}</FormHelperText>
            </FormControl>
        </Grid>
    );
};