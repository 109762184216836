import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiError, LoadingIndicator, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../Components/CoreLib/library';
import { useGetUserNotificationConfigQuery, useUpdateUserNotificationConfigMutation } from '../../store/generated/generatedApi';
import { UserSettingsForm } from './Form/UserSettingsForm';
import { UserNotificationConfigDto } from '../../dtos';

export const UserSettingsView: FC = () => {
    const { data: currentConfig, isLoading: isLoadingCurrentConfig, isError: isErrorLoadingCurrentConfig, refetch: refetchCurrentConfig } = useGetUserNotificationConfigQuery();
    const [updateNotificationConfig, { isError: isErrorUpdatingConfig, isSuccess: isConfigUpdated, isLoading: isUpdatingConfig, reset: resetUpdateConfig }] = useUpdateUserNotificationConfigMutation();

    const navigate = useNavigate();
    useSuccessfulActionSnackbar('updated', 'User settings', isConfigUpdated, resetUpdateConfig);
    useFailedActionSnackbar('update', 'User settings', isErrorUpdatingConfig, resetUpdateConfig);

    const handleSave = (values: UserNotificationConfigDto) => {
        updateNotificationConfig(values);
    };

    const handleCancel = () => {
        navigate(`/`);
    };

    if (isLoadingCurrentConfig) {
        return <LoadingIndicator />;
    }

    // Error Loading Config
    if (isErrorLoadingCurrentConfig || !currentConfig) {
        return <ApiError onReloadClick={refetchCurrentConfig} />
    }

    // User Connected With Provided Config
    return (
        <UserSettingsForm save={handleSave} cancel={handleCancel} initValues={currentConfig} isLoading={isUpdatingConfig} />
    )
};
