export class DataLabelValidator {
    private readonly currentStepDataLabels: string[];

    constructor(stepDataEntries: string[]) {
        this.currentStepDataLabels = stepDataEntries;
    }
    
    public validateDataLabel(label: string): boolean {
        const isLabelProvided = !!label;
        const isLabelUnique = this.currentStepDataLabels.filter((dataLabelEntry) => dataLabelEntry?.trim().toLowerCase() === label?.trim().toLowerCase()).length <= 1;

        return isLabelProvided && isLabelUnique;
    }

    public get isEveryDataLabelValid(): boolean {
        return this.currentStepDataLabels.every(dataLabel => this.validateDataLabel(dataLabel));
    }
}