import { FC } from 'react';
import { MatchType, StepExecutionConditionDto } from '../../../../../dtos';
import { Grid, Typography } from '@mui/material';
import { StepConditionDetailsLineItem } from './StepConditionDetailsLineItem';

export interface IStepConditionDetails {
    stepConditions: StepExecutionConditionDto[];
    conditionMatchType: MatchType;
    evaluationResult?: boolean;
}

export const StepConditionDetails: FC<IStepConditionDetails> = ({ stepConditions, conditionMatchType, evaluationResult }) => {
    return (
        <>
            <Grid item xs={12} mt={2} mb={1}>
                <Typography variant='h2'>Conditions</Typography>
            </Grid>
            <Grid item container direction='column' xs={12}>
                {stepConditions.map((con, idx) => (
                    <>
                        <StepConditionDetailsLineItem stepCondition={con} key={`step-condition-${idx}`} />
                        {idx < (stepConditions.length - 1) && (
                            <Grid item xs={12} key={`step-condition-${idx}-joining-text`}>
                                {conditionMatchType === MatchType.ALL ? 'And' : 'Or'}
                            </Grid>
                        )}
                    </>
                ))}
            </Grid>
            {evaluationResult !== null && (
                <Grid>
                    <Typography variant='h2' mt={2}>Result: {evaluationResult ? 'True' : 'False'}</Typography>
                </Grid>
            )}
        </>
    )
}