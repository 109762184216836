import { FormControl, FormHelperText, FormLabel, Grid, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { IStepDataInputProps } from './StepDataInput';
import { stepDataFieldLabelBuilder } from './Utils';

export const TextStepDataInput: FC<IStepDataInputProps> = ({
    stepData,
    onChange,
    errorMessage,
    fieldLabelSuffix,
    disabled,
    showDataLabel
}) => {
    const hasPredefinedOptions = useMemo(() => stepData.options && stepData.options?.length > 0, [stepData.options]);
    const fieldLabel = useMemo(() => stepDataFieldLabelBuilder(stepData.name ?? 'Value', { isRequired: stepData.isRequired, labelSuffix: fieldLabelSuffix }), [stepData, fieldLabelSuffix]);

    if (hasPredefinedOptions) {
        return (
            <Grid item>
                <FormControl error={!!errorMessage} fullWidth disabled={disabled}>
                    {showDataLabel && <FormLabel>{fieldLabel}</FormLabel>}
                    <Select
                        value={stepData.value}
                        onChange={(e) => onChange(e.target.value)}
                    >
                        <MenuItem value=''>
                            <Typography fontStyle='italic'>No Value</Typography>
                        </MenuItem>
                        {stepData.options?.map((option, idx) => (
                            <MenuItem key={idx} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errorMessage}</FormHelperText>
                </FormControl>
            </Grid>
        )
    } else {
    return (
        <Grid item>
            <FormControl error={!!errorMessage} fullWidth disabled={disabled}>
                {showDataLabel && <FormLabel>{fieldLabel}</FormLabel>}
                <OutlinedInput value={stepData.value} onChange={(e) => onChange(e.target.value)} />
                <FormHelperText>{errorMessage}</FormHelperText>
            </FormControl>
        </Grid>
    );
}
};