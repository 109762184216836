import { Book, Cancel, Error, MoreVert, Save, Warning } from '@mui/icons-material';
import { Box, Button, Divider, FormControlLabel, Grid, IconButton, Menu, MenuItem, Switch, Tooltip, Typography } from '@mui/material';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    AuthenticatedComponent,
    LoadingBackdrop,
    SlimPageHeader,
    useFailedActionSnackbar,
    useSuccessfulActionSnackbar,
} from '../../../Components/CoreLib/library';
import { useConfirmDelete } from '../../../customHooks';
import { PlayDto } from '../../../dtos';
import { useCreatePlayCloneMutation, usePlayByIdArchiveMutation } from '../../../store/generated/generatedApi';
import { emptyGuid } from '../../../util';
import { EstimatedExecutionTimeInfo } from './SubComponents/EstimatedExecutionTimeInfo';
import { usePermissionChecker } from '../../../Hooks';
import { BetaPlayEditorContext } from '../Contexts';

export interface IPlayEditorTitleBarProps {
    play: PlayDto;
    handleSave: () => void;
    onEditPlayDetailsClicked: () => void;
    isSaving: boolean;
    playIsSavedAndUnedited: boolean;
}

export const PlayEditorTitleBar: FC<IPlayEditorTitleBarProps> = (props) => {
    const { play, handleSave, onEditPlayDetailsClicked, isSaving, playIsSavedAndUnedited } = props;
    const { canUserEditPlay } = usePermissionChecker();
    const navigate = useNavigate();
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [createClonePlay, { data: newPlay, isLoading: isCloning, isSuccess: isCloneSuccess, isError: isCloneError, reset: resetClonePlay }] =
        useCreatePlayCloneMutation();
    useSuccessfulActionSnackbar('cloned', 'Play', isCloneSuccess, resetClonePlay);
    useFailedActionSnackbar('clone', 'Play', isCloneError, resetClonePlay);
    const { isPlayEditorBetaEnabled, setIsPlayEditorBetaEnabled } = useContext(BetaPlayEditorContext);

    const canEdit = useMemo(() => canUserEditPlay(play), [canUserEditPlay, play]);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    };

    const navigateUrl = `/playbook/${play.playbookId}/plays`;
    const { setObjectToDelete, isDeletingObject, renderDeleteConfirmationModal } = useConfirmDelete('Play', usePlayByIdArchiveMutation, navigateUrl);

    const handleShowDeleteConfirmationModal = useCallback(
        (objectToDelete: any) => {
            setObjectToDelete(objectToDelete);
        },
        [setObjectToDelete]
    );

    useEffect(() => {
        if (newPlay) {
            navigate(`/playbook/${newPlay.playbookId}/play/${newPlay.id}/dashboard`);
        }
    }, [newPlay, navigate]);

    const handleClone = useCallback(() => {
        createClonePlay(play);
    }, [createClonePlay, play]);

    const handleCancel = useCallback(() => {
        if (play.playbookId !== emptyGuid && play.id !== emptyGuid) {
            navigate(`/playbook/${play.playbookId}/play/${play.id}/dashboard`);
        } else if (play.playbookId !== emptyGuid) {
            navigate(`/playbook/${play.playbookId}/plays`);
        } else {
            navigate('/playbooks');
        }
    }, [navigate, play]);

    return (
        <>
            {renderDeleteConfirmationModal}
            <SlimPageHeader
                icon={<Book />}
                title={play.name || 'Unnamed'}
                titleStyles={!!play.name ? undefined : { fontStyle: 'italic', pr: 1 }}
                onEditClicked={canEdit ? onEditPlayDetailsClicked : undefined}
                breadCrumbProps={{
                    links: [
                        { label: 'Home', navLink: '/' },
                        { label: 'Playbooks', navLink: '/playbooks' },
                        { label: play.playbookName || 'Playbook', navLink: `/playbook/${play.playbookId}/plays` },
                        { label: play.name || 'New Play', navLink: `/playbook/${play.playbookId}/play/${play.id}/dashboard`, isText: play.id === emptyGuid },
                    ],
                    currentPageLabel: canEdit ? 'Edit Play' : 'View Play',
                }}
                endSlot={
                    <Grid item container direction='row' alignItems='start' py={1}>
                        <Grid item display='flex' alignItems='center' justifyContent={'end'} xs={12} sx={{ gap: 1 }} pr={2}>
                            <AuthenticatedComponent requiredPermissions={['feature:autosave']}>
                                <FormControlLabel
                                    control={<Switch checked={isPlayEditorBetaEnabled} onChange={(_, isChecked) => setIsPlayEditorBetaEnabled(isChecked)} />}
                                    label='Beta Play Editor'
                                />
                            </AuthenticatedComponent>
                            <EstimatedExecutionTimeInfo />
                            {!play.isValid && (
                                <>
                                    <Divider flexItem orientation='vertical' />
                                    <Tooltip
                                        title={
                                            <React.Fragment>
                                                <Typography variant='h3' color='inherit'>
                                                    Error: Invalid Play{' '}
                                                </Typography>
                                                <Typography>
                                                    {play.violations.map((violation, index) => {
                                                        return (
                                                            <Box>
                                                                {index + 1}. {violation.description}
                                                            </Box>
                                                        );
                                                    })}
                                                </Typography>
                                            </React.Fragment>
                                        }>
                                        <Error color='error' />
                                    </Tooltip>
                                </>
                            )}
                            {!playIsSavedAndUnedited && (
                                <>
                                    <Divider flexItem orientation='vertical' />
                                    <Tooltip title='You have unsaved changes...'>
                                        <Warning color='warning' />
                                    </Tooltip>
                                </>
                            )}
                            <Divider flexItem orientation='vertical' />
                            <Button variant='outlined' color='primary' size='small' onClick={handleCancel} startIcon={<Cancel />} sx={{ width: 90 }}>
                                {playIsSavedAndUnedited ? 'Close' : 'Cancel'}
                            </Button>
                            {canEdit && (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    onClick={handleSave}
                                    disabled={isSaving}
                                    startIcon={<Save />}
                                    sx={{ width: 90 }}>
                                    Save
                                </Button>
                            )}
                            <IconButton onClick={handleMenuOpen}>
                                <MoreVert fontSize='inherit' />
                            </IconButton>
                            <Menu
                                anchorEl={menuAnchorEl}
                                open={!!menuAnchorEl}
                                onClose={handleMenuClose()}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}>
                                {canEdit && <MenuItem onClick={handleMenuClose(handleSave)}>Save</MenuItem>}
                                <MenuItem onClick={handleMenuClose(handleCancel)}>{playIsSavedAndUnedited ? 'Close' : 'Cancel'}</MenuItem>
                                {canEdit && play.id && play.id !== emptyGuid && (
                                    <>
                                        <Divider />
                                        <MenuItem onClick={handleClone}>Clone</MenuItem>
                                    </>
                                )}
                                {play.id && play.id !== emptyGuid && (
                                    <AuthenticatedComponent requiredPermissions={[`delete:plays`]}>
                                        <Divider />
                                        <MenuItem onClick={handleMenuClose(() => handleShowDeleteConfirmationModal(play))} disabled={isDeletingObject}>
                                            Delete
                                        </MenuItem>
                                    </AuthenticatedComponent>
                                )}
                            </Menu>
                        </Grid>
                        <LoadingBackdrop
                            loadingOptions={[
                                { isLoading: isCloning, loadingMessage: 'Cloning play...' },
                                { isLoading: isSaving, loadingMessage: 'Saving play...' },
                            ]}
                        />
                    </Grid>
                }
            />
        </>
    );
};
