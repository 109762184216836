import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

export interface IStepNameDetailsProps {
    name: string;
}

export const StepNameDetails: FC<IStepNameDetailsProps> = ({ name }) => {
    return (
        <>
            <Grid item container direction='row' mt={2}>
                <Grid item>
                    <Typography variant='h2'>Name</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography>{name}</Typography>
            </Grid>
        </>
    )
}