import { FC } from 'react';
import { ChatStepDataCollection } from './ChatStepDataCollection';
import { FormStepDataCollection } from './FormStepDataCollection';
import { StepExecutionDataDto } from '../../../dtos';

export type DataCollectionMode = 'chat' | 'form';

export interface IStepDataCollectionSectionProps {
    mode: DataCollectionMode;
    stepExecutionId: string;
    playExecutionId: string;
    stepData: StepExecutionDataDto[];
    handleStepDataChange: (updatedData: StepExecutionDataDto[]) => void;
    additionalStepData: StepExecutionDataDto[];
    handleAdditionalStepDataChange: (updatedData: StepExecutionDataDto[]) => void;
    validateAdditionalStepLabel: (stepData: StepExecutionDataDto) => string;
    handleStepCompleted: () => void;
    threadId?: string;
    readonly?: boolean;
}

export const StepDataCollectionSection: FC<IStepDataCollectionSectionProps> = ({
    mode,
    stepExecutionId,
    playExecutionId,
    stepData,
    handleStepDataChange,
    additionalStepData,
    handleAdditionalStepDataChange,
    validateAdditionalStepLabel,
    threadId,
    readonly,
    handleStepCompleted,
}) => {
    switch (mode) {
        case 'chat':
            return <ChatStepDataCollection
                stepExecutionId={stepExecutionId}
                playExecutionId={playExecutionId}
                stepData={stepData}
                initialThreadId={threadId}
                onStepCompleted={handleStepCompleted}
            />;
        case 'form':
            return (
                <FormStepDataCollection
                    stepExecutionId={stepExecutionId}
                    playExecutionId={playExecutionId}
                    stepData={stepData}
                    handleStepDataChange={handleStepDataChange}
                    additionalStepData={additionalStepData}
                    handleAdditionalStepDataChange={handleAdditionalStepDataChange}
                    validateAdditionalStepLabel={validateAdditionalStepLabel}
                    readonly={readonly}
                />
            );
        default:
            return null;
    }
};
