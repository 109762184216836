import { useCallback, useMemo, useState } from "react";
import { PlayDto } from "../dtos";
import { MovePlayModal } from "../Views/Plays/Components/Modals/MovePlayModal";

export function useMovePlayStarter() {
    const [selectedPlayDetails, setSelectedPlayDetails] = useState<PlayDto | undefined>();
    
    const clearSelectedPlayDetails = () => {
        setSelectedPlayDetails(undefined);
    }
    
    const handleMovePlay = useCallback(async (play: PlayDto) => {
        setSelectedPlayDetails(play);
    }, [setSelectedPlayDetails]);

    const renderMovePlayModal = useMemo(() => {
        return <MovePlayModal play={selectedPlayDetails} clearSelectedPlay={clearSelectedPlayDetails} />
    }, [selectedPlayDetails])

    return {
        handleMovePlay,
        renderMovePlayModal
    }
}