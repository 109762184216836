import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const SignUp: FC = () => {
    const location = useLocation();
    const { loginWithRedirect } = useAuth0();
    const queryParams = new URLSearchParams(location.search);

    const invitation = queryParams.get("invitation") ?? '';
    const organization = queryParams.get("organization") ?? '';
    const organization_name = queryParams.get("organization_name") ?? '';

    useEffect(() => {
        if (invitation && organization && organization_name) {
            loginWithRedirect({
                authorizationParams: { invitation, organization, organization_name }
            });
        }
    }, [invitation, loginWithRedirect, organization, organization_name]);

    return (
        <Typography variant="h1" sx={{ padding: 2 }}>Accepting Invitation...</Typography>
    );
}