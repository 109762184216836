import { DragIndicator, Help } from '@mui/icons-material';
import { Box, Grid, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback } from 'react';
import { StepDataDto } from '../../../../../../dtos';
import { renderWithRequiredIndicator } from '../../../../../../util';

export interface IDataCollectedSliceProps {
    collectedData?: StepDataDto[];
    playData?: StepDataDto[];
    userData?: StepDataDto[];
}

export const DataCollectedSlice: FC<IDataCollectedSliceProps> = ({ collectedData = [], playData = [], userData = [] }) => {
    
    const renderDataSetSection = useCallback((sectionName: string, dataOptions: StepDataDto[]) => {
        return (
            <>
                <TableRow key={sectionName}>
                    <TableCell size='small' sx={{ fontWeight: 'bold' }}>
                        {sectionName}
                    </TableCell>
                </TableRow>
                {dataOptions.length === 0 && (
                    <TableRow>
                        <TableCell>No Data Collected So Far</TableCell>
                    </TableRow>
                )}
                {
                    _.uniqBy(dataOptions, 'name').map((cd) => (
                        <TableRow key={cd.id}>
                            <TableCell
                                size='small'
                                draggable
                                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                onDragStart={(e) => e.dataTransfer.setData('text/plain', `{{${cd.name}}}`)}>
                                {renderWithRequiredIndicator(cd.name ?? '', cd.isRequired)}
                                <DragIndicator sx={{ cursor: 'grab' }} />
                            </TableCell>
                        </TableRow>
                    ))
                }
            </>
        )
    }, []);

    return (
        <Grid item height='100%' direction='column' width='200px' sx={{ background: '#adcbee' }}>
            <Typography
                fontSize='0.875rem'
                fontWeight='500'
                display='flex'
                justifyContent='center'
                alignItems='center'
                gap={0.5}
                p={1.7}
                height='49px'
                borderBottom='1px solid rgba(0, 0, 0, .2)'>
                DATA COLLECTED
                <Tooltip title='These are the data values that have been collected by previous steps in this play.'>
                    <Help fontSize='inherit' />
                </Tooltip>
            </Typography>
            <Box sx={{ maxHeight: '90%', maxWidth: '100%', overflowY: 'auto', p: 1 }}>
                <Table sx={{ background: 'white' }}>
                    <TableBody>
                        {renderDataSetSection('Previous Steps', collectedData)}
                        {userData.length > 0 && renderDataSetSection('Assigned User', userData)}
                        {renderDataSetSection('Play Starter', playData)}
                    </TableBody>
                </Table>
            </Box>
        </Grid>
    );
};
