import { PlayCircleOutlineOutlined } from '@mui/icons-material';
import { FC } from 'react';
import { NodeProps, Position } from 'reactflow';
import { StepDto, StepExecutionDto } from '../../../../../dtos';
import { PlayViewerNode } from '../../Components/PlayViewerNode';
import { NodeColorPalette } from '../../../Utils';
import { START_NODE_DESCRIPTION } from '../../utils';

export const StartPlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = (props) => {
    const customColorPallet: NodeColorPalette = {
        background: '#bec830',
        textColor: 'white',
    };

    return (
        <PlayViewerNode
            {...props}
            icon={<PlayCircleOutlineOutlined fontSize='large' />}
            description={START_NODE_DESCRIPTION}
            handles={[{ type: 'source', position: Position.Right, id: 'r' }]}
            activeColorPallet={customColorPallet}
            disableDelete
        />
    );
};
