import { FC } from 'react';
import { StepDataDto, StepDto } from '../../../../../../dtos';
import { WYSIWYGInputField } from './WYSIWYGInputField';

export interface IWYSIWYGDescriptionProps {
    fieldErrors: Map<keyof StepDto, string>;
    description: string;
    handleDescriptionChange: (value: string) => void;
    collectedData: StepDataDto[];
}

export const WYSIWYGDescription: FC<IWYSIWYGDescriptionProps> = ({ fieldErrors, description, handleDescriptionChange, collectedData }) => {

    return <WYSIWYGInputField label='Description' tooltip='Whatever you write here will show up on the emails I send to your job function members.' errorMessage={fieldErrors.get('description')} value={description} handleValueChange={handleDescriptionChange} collectedData={collectedData} />
}
