import { NotificationsPaused, NotificationsActive } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Grid } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { StepExecutionDto } from '../../../dtos';
import { formatTimestamp } from '../../../util';
import { NodeTypeFactory } from '../../Plays/NodeTypes/utils';
import { StepNameDetails } from './StepDetailSections/Components/StepNameDetails';
import { useDeletePlayByPlayIdExecutionByStepExecutionIdSnoozeMutation } from '../../../store/generated/generatedApi';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../Components/CoreLib/library';

export interface IStepExecutionDetailsProps {
    stepExecution: StepExecutionDto;
    handleStepExecutionUpdate: () => void;
}

export const StepExecutionDetails: FC<IStepExecutionDetailsProps> = props => {
    const { stepExecution, handleStepExecutionUpdate } = props;
    const [deleteSnooze, { isLoading: isCancelingSnooze, isSuccess: isSnoozeCanceled, isError: isErrorCancelingSnooze, reset: resetCancelSnoozeMutation }] = useDeletePlayByPlayIdExecutionByStepExecutionIdSnoozeMutation();
    useSuccessfulActionSnackbar('cancel', 'snooze', isSnoozeCanceled, resetCancelSnoozeMutation);
    useFailedActionSnackbar('cancel', 'snooze', isErrorCancelingSnooze, resetCancelSnoozeMutation);

    const handleCancelSnoozeClicked = useCallback(() => {
        if (isCancelingSnooze) {
            return;
        }
        deleteSnooze({ playId: stepExecution.playId, stepExecutionId: stepExecution.id })
    }, [isCancelingSnooze, deleteSnooze, stepExecution]);

    const renderContent = useMemo(() => {
        var nodeTypeFactory = new NodeTypeFactory();
        var nodeType = nodeTypeFactory.getNodeTypeForStepType(stepExecution.type);
        if (nodeType.isExecutionViewable) {
            const ExecutionDetailsContent = nodeType.ExecutionDetailsContent!;
            return <ExecutionDetailsContent stepExecution={stepExecution} handleStepExecutionUpdate={handleStepExecutionUpdate} />;
        }
        return <StepNameDetails name={stepExecution.name ?? 'Unnamed Step'} />;
    }, [stepExecution, handleStepExecutionUpdate]);

    return (
        <Grid item container direction='row' alignContent='start' alignItems='center' px={1}>
            {stepExecution.errorCode && (
                <Alert severity='error' sx={{ mt: 1 }}>
                    <AlertTitle>Whoops! Looks like this step has encountered an error</AlertTitle>
                    {stepExecution.errorCode}: {stepExecution.errorMessage}
                </Alert>
            )}
            {stepExecution.snoozeUntil && (
                <Alert severity='warning' sx={{ mt: 1 }} icon={<NotificationsPaused color='secondary' />}>
                    <span style={{ fontWeight: 'bold' }}>Snoozed until {formatTimestamp(stepExecution.snoozeUntil)} by {stepExecution.snoozedByUserName}<br/></span>
                    {stepExecution.snoozeReason && `Reason: ${stepExecution.snoozeReason}`}{stepExecution.snoozeReason && <br/>}
                    {!stepExecution.isComplete && new Date(stepExecution.snoozeUntil+'Z') > new Date() && <Button startIcon={<NotificationsActive />} variant='contained' color='secondary' onClick={handleCancelSnoozeClicked} disabled={isCancelingSnooze}>Cancel Snooze</Button>}
                </Alert>
            )}
            {renderContent}
        </Grid>
    )
}