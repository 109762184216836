import { isNotBlank, isValidEmail, runFieldValidation, runFormValidation } from '../../../../Components/CoreLib/library';
import { PlayVariableDto, StepDataType, StepDto } from '../../../../dtos';
import { NotificationType } from '../../../../dtos/generated/NotificationType';
import { SubplayDto } from '../../../../dtos/generated/SubplayDto';
import { DataLabelValidator } from './DataLabelValidator';
import { EmbedLinkValidator } from './EmbedLinkValidator';
import { StepConditionValidator } from './StepConditionValidator';
import { StepValidationConfigBuilder } from './StepValidationConfigBuilder';

export class StepValidator {
    private readonly stepFormValues: StepDto;
    private readonly dataLabelValidator: DataLabelValidator;
    private readonly stepConditionValidator: StepConditionValidator;
    private readonly embedLinkValidator: EmbedLinkValidator;
    private readonly selectedSubplay?: SubplayDto;
    private readonly playVariables: PlayVariableDto[];
    private readonly validationConfigBuilder: StepValidationConfigBuilder;

    constructor(formValues: StepDto, playVariables: PlayVariableDto[], selectedSubplay?: SubplayDto) {
        this.stepFormValues = formValues;
        this.selectedSubplay = selectedSubplay;
        this.dataLabelValidator = new DataLabelValidator([
            ...formValues.stepData.map((sd) => sd.name ?? ''),
        ]);
        this.stepConditionValidator = new StepConditionValidator();
        this.embedLinkValidator = new EmbedLinkValidator(formValues.embedInputs);
        this.playVariables = playVariables;
        this.validationConfigBuilder = new StepValidationConfigBuilder(formValues);
    }

    public validateField(fieldName: keyof StepDto): string {
        const validationConfig = this.validationConfigBuilder.fullValidationConfig.get(fieldName);
        if (!validationConfig) {
            return '';
        }
        const fieldValue = this.stepFormValues[fieldName];
        const { errorMessage } = runFieldValidation(fieldValue, validationConfig);
        return errorMessage;
    }

    public get validationErrorMessages(): Map<keyof StepDto, string> {
        const { errorMessages } = runFormValidation<Partial<StepDto>>(this.stepFormValues, this.validationConfigBuilder.fullValidationConfig);
        if (!this.stepFormValues.isExternal && !this.stepFormValues.internalJobFunctionHasMembers && !errorMessages.get('internalJobFunctionId')) {
            errorMessages.set('internalJobFunctionId', 'Job Function selected has no members.');
        }
        return errorMessages;
    }

    public get isValidEnoughToSave(): boolean {
        const { isValid: isEveryBaseStepValueValid } = runFormValidation<Partial<StepDto>>(
            this.stepFormValues,
            this.validationConfigBuilder.validationConfigRequiredForSave
        );
        const isEveryDataLabelValid = this.dataLabelValidator.isEveryDataLabelValid;
        const isEveryEmbedInputValid = this.embedLinkValidator.areAllEmbeddingLinksValid;
        const isEveryStepConditionValid = (this.stepFormValues.stepConditions ?? []).every(
            (stepCon) => !this.stepConditionValidator.validateStepCondition(stepCon).isError
        );
        const isEveryOutputSourceSpecified = (this.stepFormValues.subplayDataOutputConfig ?? []).every(
            (x) => !!x.sourceFieldName && this.selectedSubplay?.playDataOutput?.some((output) => output.name === x.sourceFieldName)
        );
        const isEveryDelayStepValid = this.isDelayInputValid;
        const isNotificationDataValid = this.isNotificationDataValid;

        return (
            isEveryBaseStepValueValid &&
            isEveryDataLabelValid &&
            isEveryStepConditionValid &&
            isEveryEmbedInputValid &&
            isEveryOutputSourceSpecified &&
            isEveryDelayStepValid &&
            isNotificationDataValid
        );
    }

    public get isNotificationDataValid(): boolean {
        const shouldBypassEmailValidation = this.stepFormValues.notificationType !== NotificationType.EMAILS;
        const dataReferenceRegex = /{{[^{}]*}}$/;
        const emailsToNotifyAreValid =
            this.stepFormValues.emailsToNotify &&
            this.stepFormValues.emailsToNotify.length > 0 &&
            this.stepFormValues.emailsToNotify?.every((email) => isNotBlank(email).isValid && (dataReferenceRegex.test(email) || isValidEmail(email).isValid));
        return shouldBypassEmailValidation || !!emailsToNotifyAreValid;
    }

    public get isStepDataValid(): boolean {
        return this.dataLabelValidator.isEveryDataLabelValid;
    }

    public get isPromptValid(): boolean {
        const strippedPrompt = this.stepFormValues.prompt?.replace(/<[^>]+>/g, '');
        return strippedPrompt?.trim() !== '';
    }

    public get isDelayInputValid(): boolean {
        const { errorMessages: baseValidationErrorMessages } = runFormValidation<Partial<StepDto>>(
            this.stepFormValues,
            this.validationConfigBuilder.validationConfigRequiredForSave
        );
        return !this.isErrorMessageInFields(baseValidationErrorMessages, ['delayMinutes', 'delayMethod', 'delayDateLabel']);
    }

    public get isInputMappingValid(): boolean {
        if (!this.selectedSubplay || !this.selectedSubplay.playDataInput) {
            return true;
        }
        return this.selectedSubplay.playDataInput.every((subplayInput) => {
            var inputConfigEntry = this.stepFormValues.subplayDataInputConfig?.find((conf) => conf.destinationFieldName === subplayInput.name);
            var isValueMapped = !!inputConfigEntry && !!inputConfigEntry.sourceFieldName;

            if (subplayInput.isRequired && !isValueMapped) {
                return false;
            }

            if (inputConfigEntry && subplayInput.dataType === StepDataType.FILE) {
                const selectedPlayVariableForInput = this.playVariables.find((playVariable) => playVariable.name === inputConfigEntry!.sourceFieldName);
                if (selectedPlayVariableForInput && selectedPlayVariableForInput.dataType !== StepDataType.FILE) {
                    return false;
                }
            }

            return true;
        });
    }

    public get isOutputMappingValid(): boolean {
        const isEveryDataLabelValid = this.dataLabelValidator.isEveryDataLabelValid;
        const isEveryOutputSourceSpecified = this.stepFormValues.subplayDataOutputConfig?.every((x) => {
            return !!x.sourceFieldName && this.selectedSubplay?.playDataOutput?.some((output) => output.name === x.sourceFieldName);
        });
        return isEveryDataLabelValid && !!isEveryOutputSourceSpecified;
    }

    public get isOutputDataLabelValid(): boolean {
        return !!this.stepFormValues.outputDataLabel;
    }

    public get isEmbeddingInputsValid(): boolean {
        return this.embedLinkValidator.areAllEmbeddingLinksValid;
    }

    public get isFileAttachmentsValid(): boolean {
        return !this.validationErrorMessages.get('fileAttachments');
    }

    private isErrorMessageInFields(errorMessages: Map<keyof StepDto, string>, fields: (keyof StepDto)[]): boolean {
        return fields.some((field) => !!errorMessages.get(field));
    }
}
