import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserDto } from '../../dtos';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { useCreateUserMutation } from '../../store/generated/generatedApi';
import { UserForm } from '../../Components/Forms/UserForm';
import { useSnackbar } from 'notistack';

export const AddUserView: FC = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [createUser, { isError, error, isSuccess, reset, isLoading }] = useCreateUserMutation();

    useSuccessfulCreateSnackbar('User', isSuccess, () => {
        enqueueSnackbar('An invitation email has been sent to the new user.', { variant: 'success' });
        navigate('/users');
    });
    useFailedCreateSnackbar('user', isError, reset, error);

    const handleSave = (values: UserDto) => {
        createUser(values);
    };

    const handleCancel = () => {
        navigate('/users');
    };

    return <UserForm save={handleSave} cancel={handleCancel} isLoading={isLoading} />;
};
