import { Box, Grid, Tabs } from '@mui/material';
import { FC, useContext } from 'react';
import { emptyGuid } from '../../../../../util';
import {
    EditStepDataInput,
    EmbeddingInput,
    FileAttachmentInput,
    NameInput,
    WYSIWYGDescription,
} from '../../../Components/Modals/EditStepModalForms/StepFormParts';
import { TabWithErrorIndicator } from '../../../Components/Modals/EditStepModalForms/StepFormParts/TabWithErrorIndicator';
import { StepEditorContext } from '../../../Utils/StepEditorContext';
import { IStepEditModalProps } from '../../types';

export const StartNodeEditForm: FC<IStepEditModalProps> = ({ step, collectedData }) => {
    const {
        formStep,
        tabIndex,
        setTabIndex,
        handleChangeTabIndex,
        handleNameChange,
        handleDescriptionChange,
        handleEmbeddingInputsChange,
        handleStepDataChange,
        isGeneralValid,
        isDataValid,
        fieldErrors
    } = useContext(StepEditorContext);

    return (
        <Grid container direction='column' height='calc(100%-30px)' mb={5}>
            <Grid zIndex={5} item position='sticky' top={0}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: 1, background: 'white' }} className='draggable-ignored'>
                    <Tabs value={tabIndex} onChange={handleChangeTabIndex} aria-label='edit step tabs' variant='fullWidth'>
                        <TabWithErrorIndicator label='GENERAL' index={0} isValid={isGeneralValid} setValue={setTabIndex} />
                        <TabWithErrorIndicator label='DATA' index={1} isValid={isDataValid} setValue={setTabIndex} />
                    </Tabs>
                </Box>
            </Grid>
            <Grid item>
                <Box sx={{ height: '100%', width: '100%' }} px={4} pt={3}>
                    <div style={{ display: tabIndex === 0 ? 'block' : 'none' }}>
                        <NameInput fieldErrors={fieldErrors} name={formStep.name ?? ''} handleNameChange={handleNameChange} dataOptions={collectedData} />
                        <WYSIWYGDescription
                            fieldErrors={fieldErrors}
                            description={step?.description ?? ''}
                            handleDescriptionChange={handleDescriptionChange}
                            collectedData={collectedData}
                        />
                        <EmbeddingInput
                            stepId={step?.id ?? emptyGuid}
                            embeddingInputs={formStep.embedInputs}
                            updateEmbeddingInputs={handleEmbeddingInputsChange}
                        />
                        <FileAttachmentInput
                            stepId={step?.id ?? emptyGuid}
                            stepKey={step.key!}
                            fieldErrors={fieldErrors}
                        />
                    </div>
                    {tabIndex === 1 && (
                        <EditStepDataInput
                            stepId={step?.id ?? emptyGuid}
                            stepData={formStep.stepData}
                            updateStepData={handleStepDataChange}
                            description='What (if any) information should be gathered when this play is started?'
                            hideChatDataCollectionToggle
                        />
                    )}
                </Box>
            </Grid>
        </Grid>
    );
}