import { useCallback, useContext } from "react";
import { UserPermissionContext, userHasAllPermissions, userHasAnyPermissions } from "../Components/CoreLib/library";
import { PlayDto } from "../dtos";

export function usePermissionChecker() {
    const { permissions: currentUserPermissions } = useContext(UserPermissionContext);

    const hasAnyPermission = useCallback((permissions: string[]) => userHasAnyPermissions(permissions, currentUserPermissions), [currentUserPermissions]);
    const hasAllPermissions = useCallback((permissions: string[]) => userHasAllPermissions(permissions, currentUserPermissions), [currentUserPermissions]);
    const hasPermission = useCallback((permission: string) => hasAllPermissions([permission]), [hasAllPermissions]);

    const canUserEditPlay = useCallback((play: PlayDto) => {
        var requiredPermissions = ['edit:plays'];
        if (play.containsAISteps) {
            requiredPermissions.push('feature:ai');
        }
        if (play.containsAutomationSteps) {
            requiredPermissions.push('feature:automation');
        }
        return hasAllPermissions(requiredPermissions);
    }, [hasAllPermissions]);

    return {
        hasAnyPermission,
        hasAllPermissions,
        canUserEditPlay,
        hasPermission
    }
}