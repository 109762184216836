import { ThumbsUpDown } from '@mui/icons-material';
import { FC, useCallback, useMemo } from 'react';
import { HandleProps, NodeProps, Position, useEdges } from 'reactflow';
import { StepDto, StepExecutionDto } from '../../../../../dtos';
import { PlayViewerNode } from '../../Components/PlayViewerNode';
import { Box, Typography, useTheme } from '@mui/material';
import { CONDITIONAL_NODE_DESCRIPTION } from '../../utils';

export const TRUE_HANDLE_ID = 'r-true';
export const FALSE_HANDLE_ID = 'r-false';

export const ConditionalPlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = (props) => {
    const edges = useEdges();
    const theme = useTheme();

    const isStepValid = useCallback((step: StepDto) => {
        const isAtLeastOneConditionDefined = !!step.stepConditions && step.stepConditions.length > 0;
        return isAtLeastOneConditionDefined;
    }, []);

    const nodeHandles: HandleProps[] = useMemo(
        () => [
            { type: 'target', position: Position.Left, id: 'l' },
            { type: 'source', position: Position.Right, style: { top: 18, right: 10, background: theme.palette.success.main }, id: TRUE_HANDLE_ID },
            { type: 'source', position: Position.Right, style: { right: 10, top: 52, background: theme.palette.error.main }, id: FALSE_HANDLE_ID },
        ],
        [theme]
    );

    const isTrueHandleConnected = useMemo(() => {
        return edges.some((edge) => edge.source === props.data.key && edge.sourceHandle === TRUE_HANDLE_ID);
    }, [edges, props.data.key]);

    const isFalseHandleConnected = useMemo(() => {
        return edges.some((edge) => edge.source === props.data.key && edge.sourceHandle === FALSE_HANDLE_ID);
    }, [edges, props.data.key]);

    const renderTrueHandleLabel = useMemo(() => {
        if (isTrueHandleConnected) {
            return null;
        }
        return <Typography sx={{ position: 'absolute', top: 0, right: -8, lineHeight: 1, color: theme.palette.success.main, fontSize: 12 }}>True</Typography>;
    }, [isTrueHandleConnected, theme]);

    const renderFalseHandleLabel = useMemo(() => {
        if (isFalseHandleConnected) {
            return null;
        }
        return <Typography sx={{ position: 'absolute', top: 58, right: -14, lineHeight: 1, color: theme.palette.error.main, fontSize: 12 }}>False</Typography>;
    }, [isFalseHandleConnected, theme]);

    return (
        <>
            <PlayViewerNode
                {...props}
                icon={
                    <Box sx={{ fontSize: 28, height: '36px', width: '36px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ThumbsUpDown fontSize='inherit' />
                    </Box>
                }
                description={CONDITIONAL_NODE_DESCRIPTION}
                additionalStepValidation={isStepValid}
                handles={nodeHandles}
                shape='diamond'
            />
            {renderTrueHandleLabel}
            {renderFalseHandleLabel}
        </>
    );
};
