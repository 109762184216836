import { StepDto } from '../../../dtos';
import { MatchType } from '../../../dtos/generated/MatchType';
import { emptyGuid } from '../../../util';

export const DEFAULT_STEP: StepDto = {
    id: emptyGuid,
    playId: emptyGuid,
    isExternal: false,
    stepData: [],
    isActive: true,
    isUsingChatDataCollection: false,
    createdOn: new Date(),
    type: 'DEFAULT',
    graphXPos: 0,
    graphYPos: 0,
    matchType: MatchType.ALL,
    estimatedExecutionTimeInMinutes: 0,
    shouldSendOverdueNotification: false,
    executionTimeInMinutesAverage: 0,
    executionTimeInMinutesLag: 0,
    embedInputs: [],
};
