import { useState } from "react";

export function useTempKeyGenerator() {
    const [tempKeyCount, setTempKeyCount] = useState(0);

    return () => generateTempKey(tempKeyCount, setTempKeyCount);
}

function generateTempKey(tempKeyCount: number, setTempKeyCount: (value: number) => void) {
    var newKeyNumber = tempKeyCount + 1;
    var tempKey = `temp-${(newKeyNumber).toString().padStart(4, '0')}`;
    setTempKeyCount(newKeyNumber);
    return tempKey;
}
