import { AttachFile } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { ChangeEvent, DragEvent, FC, useCallback } from 'react';
import { FileAttachmentDto } from '../../dtos';
import { emptyGuid } from '../../util';
import { useCreateAttachmentMutation } from '../../store/generated/generatedApi';
import { useSnackbar } from 'notistack';

export interface IFileDropperAreaProps {
    entityId?: string;
    temporaryEntityId?: string;
    linkedEntityType: string;
    addFileAttachment: (newFileAttachment: FileAttachmentDto) => void;
}

export const FileDropperArea: FC<IFileDropperAreaProps> = ({ entityId = emptyGuid, temporaryEntityId = '', linkedEntityType, addFileAttachment }) => {
    const [createAttachment] = useCreateAttachmentMutation();
    const { enqueueSnackbar } = useSnackbar();

    const handleFileUpload = useCallback(
        async (fileToUpload: File) => {
            let attachmentFormData = new FormData();
            attachmentFormData.append('file', fileToUpload);
            attachmentFormData.append('name', fileToUpload.name);
            attachmentFormData.append('linkedEntityId', entityId);
            attachmentFormData.append('temporaryLinkingIdentifier', temporaryEntityId);
            attachmentFormData.append('linkedEntityType', linkedEntityType);
            try {
                const newFileAttachment = await createAttachment(attachmentFormData).unwrap();
                addFileAttachment(newFileAttachment);
            } catch (error) {
                console.error('An error occurred when attempting to create a new file attachment: ', error);
                enqueueSnackbar(`An error occurred when attempting to create a new file attachment.`, { variant: 'error', preventDuplicate: true });
            }
        },
        [entityId, temporaryEntityId, linkedEntityType, createAttachment, addFileAttachment, enqueueSnackbar]
    );

    const uploadFileList = useCallback((fileList: FileList) => {
        for (let fileIndex = 0; fileIndex < fileList.length; fileIndex++) {
            const fileToUpload = fileList.item(fileIndex)!;
            handleFileUpload(fileToUpload);
        }
    }, [handleFileUpload]);

    const handleFileDialogUpload = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            console.warn('no files found from file selector dialog');
            return;
        }
        const file = event.target.files[0];
        if (file.size > 30000000) {
            enqueueSnackbar(`An error occurred when attempting to create a new file attachment: Files cannot exceed 30MB.`, { variant: 'error', preventDuplicate: true });
            event.target.value = '';
        } else {
            uploadFileList(event.target.files);
        }
    }, [enqueueSnackbar, uploadFileList]);

    const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
    }, []);

    const handleDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        uploadFileList(event.dataTransfer.files);
    }, [uploadFileList]);

    return (
        <Grid item xs={12} onDragOver={handleDragOver} onDrop={handleDrop} className='non-draggable-element'>
            <label htmlFor='choose-file'>
                <input
                    name='choose-file'
                    id='choose-file'
                    type='file'
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleFileDialogUpload}
                />
                <Box
                    sx={{
                        width: '100%',
                        height: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        gap: '16px',
                        border: '2px dashed lightgray',
                        backgroundColor: '#eee',
                        cursor: 'pointer',
                    }}>
                    <Typography>Attach Files</Typography>
                    <AttachFile fontSize='large' />
                </Box>
            </label>
        </Grid>
    );
};
