import { useCallback, useEffect, useMemo, useState } from 'react';
import { isNotBlank } from '../../../Components/CoreLib/library';
import { StepExecutionDataDto, StepExecutionDto } from '../../../dtos';
import { useGetPlayExecutionByPlayIdReservedNamesQuery } from '../../../store/generated/generatedApi';
import { useStepDataValidator } from '../../Plays/Hooks';

export interface IStepFormValues {
    stepData: StepExecutionDataDto[];
}

export function useCompleteStepForm(initialStepExecution: StepExecutionDto) {
    const [formStepData, setFormStepData] = useState<StepExecutionDataDto[]>([]);
    const [formAdditionalStepData, setFormAdditionalStepData] = useState<StepExecutionDataDto[]>([]);
    const {data: reservedDataLabels = [], isLoading: isLoadingReservedLabels} = useGetPlayExecutionByPlayIdReservedNamesQuery({ playId: initialStepExecution.playId });
    const { isStepDataValid } = useStepDataValidator();

    useEffect(() => {
        setFormStepData(initialStepExecution.stepData);
        setFormAdditionalStepData([]);
    }, [initialStepExecution.stepData]);

    const validateAdditionalStepLabel = useCallback((stepData: StepExecutionDataDto) => {
        const {isValid: isNameValid} = isNotBlank(stepData.name)
        // make sure name is not blank
        if (!isNameValid) {
            return 'Label is required for manually added data'
        }

        // make sure name is unique on page
        const allStepNames = [...formStepData, ...formAdditionalStepData].map(x => x.name);
        if (allStepNames.filter(x => (x ?? '').toUpperCase().trim() === (stepData.name ?? '').toUpperCase().trim()).length > 1) {
            return 'Label must be unique';
        }

        // make sure name is not one of the existing reserved labels
        if (reservedDataLabels.map(x => x.toUpperCase().trim()).includes((stepData.name ?? '').toUpperCase().trim())) {
            return 'This label is used on another step and cannot be used here';
        }

        return '';
    }, [formStepData, formAdditionalStepData, reservedDataLabels]);

    const isFormValid = useMemo(() => {
        const isEveryDataEntryValid = formStepData.every(sd => isStepDataValid(sd));
        const isEveryAdditionalDataEntryValid = formAdditionalStepData.every(asd => validateAdditionalStepLabel(asd) === '' && !!asd.value.trim());
        return isEveryDataEntryValid && isEveryAdditionalDataEntryValid;
    }, [formStepData, formAdditionalStepData, isStepDataValid, validateAdditionalStepLabel]);

    const isFormDirty = () => {
        var isDirty = formStepData.some(x => x.value !== '');
        return isDirty;
    };

    const handleStepDataChange = (updatedData: StepExecutionDataDto[]) => {
        setFormStepData(updatedData);
    };

    const handleAdditionalStepDataChange = (updatedData: StepExecutionDataDto[]) => {
        setFormAdditionalStepData(updatedData);
    };

    return {
        isFormDirty,
        isFormValid,
        handleStepDataChange,
        formStepData,
        formAdditionalStepData,
        validateAdditionalStepLabel,
        handleAdditionalStepDataChange,
        isLoading: isLoadingReservedLabels
    };
}
