import { FC, useMemo } from 'react';
import { EmbeddingsDataView } from '../../../../PlayExecution/Components/StepEmbeddingsView';
import { StepDescriptionDetails, StepNameDetails } from '../../../../PlayHistory/Components/StepDetailSections/Components';
import { IStepExecutionDetailsProps } from '../../types';
import { Grid, Typography } from '@mui/material';
import { NotificationType } from '../../../../../dtos/generated/NotificationType';

export const NotificationNodeExecutionDetails: FC<IStepExecutionDetailsProps> = ({ stepExecution }) => {

    const renderNotificationMethod = useMemo(() => {
        return (
            <Grid item xs={12}>
                <Typography>Notification Method: 
                    {
                        (stepExecution.notificationType === NotificationType.All_JOB_FUNCTION_MEMBERS ?
                            ' Notify all members of job function' :
                        (stepExecution.notificationType === NotificationType.ACTIVE_JOB_FUNCTION_MEMBERS ?
                            ' Notify member active in this play' :
                        (stepExecution.notificationType === NotificationType.EMAILS ?
                            ' Notify by email' :
                            ' ERROR'
                        )))
                    }
                </Typography>
            </Grid>
        );
    }, [stepExecution]);

    const renderNotified = useMemo(() => {
        if (stepExecution.status === "Pending") { return; }
        return (
            <Grid item xs={12}>
                <Typography>Notified: {stepExecution.emailsNotified}</Typography>
            </Grid>
        );
    }, [stepExecution]);

    const renderNotificationDetails = useMemo(() => {
        return (
            <Grid item container direction='row' mt={1}>
                {renderNotificationMethod}
                {renderNotified}
            </Grid>
        );
    }, [renderNotificationMethod, renderNotified]);

    return (
        <>
            {renderNotificationDetails}
            <StepNameDetails name={stepExecution.name ?? 'Unnamed Step'} />
            <StepDescriptionDetails description={stepExecution.description ?? ''} />
            <EmbeddingsDataView embeddingInputs={stepExecution.embedInputs} />
        </>
    );
}