import { useState, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSuccessfulActionSnackbar, useFailedActionSnackbar } from "../../../Components/CoreLib/library";
import { PlayExecutionDto, StepExecutionDto } from "../../../dtos";
import { useCreatePlayExecutionByPlayIdStepByStepIdForceMutation } from "../../../store/generated/generatedApi";

export function useSelectedStepManager(playExecution: PlayExecutionDto | undefined) {
    const [searchParams, setSearchParams] = useSearchParams();
    const paramRunId = searchParams.get('runId');
    const paramStepKey = searchParams.get('stepKey');
    const [completeStep, { isSuccess: isCompleteStepSuccess, isError: isCompleteStepError, reset: resetCompleteStep }] =
        useCreatePlayExecutionByPlayIdStepByStepIdForceMutation();
    useSuccessfulActionSnackbar('completed', 'step', isCompleteStepSuccess, resetCompleteStep);
    useFailedActionSnackbar('complete', 'step', isCompleteStepError, resetCompleteStep);
    const [selectedStepExecution, setSelectedStepExecution] = useState<StepExecutionDto | undefined>();
    
    const clearSelectedStep = useCallback(() => {
        setSelectedStepExecution(undefined)
        searchParams.delete('stepKey');
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);

    const handleSetSelectedStep = useCallback((stepExecution: StepExecutionDto) => {
        setSelectedStepExecution(stepExecution);
        searchParams.set('stepKey', stepExecution.key ?? '');
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams])

    const handleSkip = () => {
        if (!selectedStepExecution) {
            return;
        }
        completeStep({
            params: {
                playId: selectedStepExecution.playId,
                stepId: selectedStepExecution.id,
            },
            payload: selectedStepExecution,
        });
    };

    useEffect(() => {
        const isRunLoaded = playExecution && playExecution.id === paramRunId;
        if (isRunLoaded) {
            const stepExecution = playExecution.steps?.find(step => step.key === paramStepKey);
            if (stepExecution) {
                setSelectedStepExecution(stepExecution);
            }
        }
    }, [playExecution, paramRunId, selectedStepExecution, paramStepKey])

    return {
        setSelectedStepExecution,
        selectedStepExecution,
        clearSelectedStep,
        handleSetSelectedStep,
        handleSkip
    }
}