import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { StepDataDto } from '../../../../../../dtos';
import _ from 'lodash';
import { renderWithRequiredIndicator } from '../../../../../../util';

export interface IEditStepRequestDataInputProps {
    errorMessage?: string;
    dataOptions: StepDataDto[];
    selectedDataOptions: string[];
    updateSelectedDataOptions: (updatedData: string[]) => void;
}

export const EditStepRequestDataInput: FC<IEditStepRequestDataInputProps> = props => {
    const { errorMessage, dataOptions, selectedDataOptions, updateSelectedDataOptions } = props;

    const isChecked = useCallback((value: string) => {
        return selectedDataOptions.includes(value);
    }, [selectedDataOptions]);

    const toggleLabelSelected = useCallback((value: string) => {
        var updatedSelectedOptions = _.cloneDeep(selectedDataOptions);
        var isSelected = isChecked(value);
        if (isSelected) {
            updatedSelectedOptions = updatedSelectedOptions.filter(selectedOption => selectedOption !== value);
        } else {
            updatedSelectedOptions.push(value);
        }
        updateSelectedDataOptions(updatedSelectedOptions);
    }, [isChecked, selectedDataOptions, updateSelectedDataOptions]);

    return (
        <Grid item xs={12}>
            <FormControl error={!!errorMessage} fullWidth>
                {/* TODO: show validation here if there are no items selected (right now if there are no values selected the UI will look correct but will not save the play properly) */}
                <FormLabel>Request Data</FormLabel>
                <Grid item xs={12} container direction='column' border='1px solid #c4c4c4' p={1} borderRadius={1}>
                    {dataOptions.length === 0 && (
                        <Grid item>
                            <Typography>No data collected so far</Typography>
                        </Grid>
                    )}
                    {dataOptions.map((requestDataOption, i) => (
                        <Grid item key={`request-data-option-${i}`}>
                            <FormControlLabel control={<Checkbox checked={isChecked(requestDataOption.name ?? '')} onClick={() => toggleLabelSelected(requestDataOption.name ?? '')} />} label={renderWithRequiredIndicator(requestDataOption.name ?? '', requestDataOption.isRequired)} />
                        </Grid>
                    ))}
                </Grid>
                <FormHelperText>{errorMessage}</FormHelperText>
            </FormControl>
        </Grid>
    )
}