import { ArrowBack } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import { DataMappingConfigDto, StepDataDto, StepDataType } from '../../../../../../dtos';
import { StepDataTypeToName, renderWithRequiredIndicator } from '../../../../../../util';
import { DataOrTextInput } from './DataOrTextInput';

const DEFAULT_DATA_MAPPING_CONFIG: DataMappingConfigDto = {
    sourceFieldName: '',
    destinationFieldName: ''
}

export interface IPlayInputDataMappingInputProps {
    playInputData: StepDataDto[];
    collectedData: StepDataDto[];
    dataMappingConfigs: DataMappingConfigDto[];
    onDataMappingConfigsUpdated: (updatedConfigs: DataMappingConfigDto[]) => void;
}

export const PlayInputDataMappingInput: FC<IPlayInputDataMappingInputProps> = props => {
    const { playInputData, dataMappingConfigs, onDataMappingConfigsUpdated, collectedData } = props;

    const updateDataMapping = useCallback((idx: number, mappedTo: string, val: string) => {
        var updatedConfig = _.cloneDeep(dataMappingConfigs);
        var isNewEntry = idx === -1;
        var dataMapping = isNewEntry ? _.cloneDeep(DEFAULT_DATA_MAPPING_CONFIG) : updatedConfig[idx];
        dataMapping.sourceFieldName = val;
        if (isNewEntry) {
            dataMapping.destinationFieldName = mappedTo;
            updatedConfig.push(dataMapping)
        } else {
            updatedConfig[idx] = dataMapping
        }
        onDataMappingConfigsUpdated(updatedConfig);
    }, [onDataMappingConfigsUpdated, dataMappingConfigs]);

    const getInputConfigRowErrorMessage = useCallback((playInputEntry: StepDataDto, inputMappingConfig: DataMappingConfigDto) => {
        if (playInputEntry.isRequired && !inputMappingConfig.sourceFieldName) {
            return 'A value must be provided for required play input fields';
        }
        if (playInputEntry.dataType === StepDataType.FILE) {
            if (inputMappingConfig.sourceFieldName && collectedData.find(cd => `{{${cd.name}}}` === inputMappingConfig.sourceFieldName)?.dataType !== StepDataType.FILE) {
                return 'Invalid value was selected for file input. Please select a new value.'
            }
        }
        return '';
    }, [collectedData]);

    const renderInputRow = useCallback((inputData: StepDataDto) => {
        var configIndex = dataMappingConfigs.findIndex(conf => conf.destinationFieldName === inputData.name);
        var config = configIndex !== -1 ? dataMappingConfigs[configIndex] : _.cloneDeep(DEFAULT_DATA_MAPPING_CONFIG);

        return (
            <Grid container direction='row' item py={2} alignItems='center' borderBottom='1px solid #e0e0e0' key={`config-mapping-${inputData.id}`}>
                <Grid item xs={5}>
                    {renderWithRequiredIndicator(`${inputData.name} (${StepDataTypeToName.get(inputData.dataType ?? 0)})`, inputData.isRequired)}
                </Grid>
                <Grid item xs={1}>
                    <ArrowBack />
                </Grid>
                <Grid item xs={6} display='flex' alignItems='center'>
                    <DataOrTextInput
                        dataType={inputData.dataType ?? StepDataType.TEXT}
                        required={inputData.isRequired}
                        value={config.sourceFieldName}
                        handleValueChange={(val) => updateDataMapping(configIndex, inputData.name ?? '', val)}
                        dataOptions={collectedData.filter(cd => inputData.dataType !== StepDataType.FILE || cd.dataType === StepDataType.FILE)}
                        errorMessage={getInputConfigRowErrorMessage(inputData, config)}
                        disableAddNew
                    />
                </Grid>
            </Grid>
        )
    }, [updateDataMapping, dataMappingConfigs, collectedData, getInputConfigRowErrorMessage]);

    const renderInputItems = useMemo(() => {
        if (playInputData.length === 0) {
            return (
                <Grid item py={2}>
                    <Typography fontStyle='italic' textAlign='center'>
                        Selected subplay does not accept any input values.
                    </Typography>
                </Grid>
            )
        }

        return playInputData.map(renderInputRow)
    }, [playInputData, renderInputRow]);

    return (
        <Grid container direction='column'>
            <Grid item borderBottom='1px solid #e0e0e0' pb={2}>
                <Typography>
                    Please map data or provide a custom value for any required data inputs for the subplay.
                </Typography>
            </Grid>
            { renderInputItems }
        </Grid>
    )
}