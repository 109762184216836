import { FormControl, FormHelperText, FormLabel, Grid } from '@mui/material';
import { FC } from 'react';
import { TextFieldWithDataReferences } from '../../../../../../Components/Wysiwyg';
import { StepDataDto, StepDto } from '../../../../../../dtos';

export interface INameInputProps {
    fieldErrors: Map<keyof StepDto, string>;
    name: string;
    handleNameChange: (name: string) => void;
    dataOptions: StepDataDto[];
}

export const NameInput: FC<INameInputProps> = (props) => {
    const { fieldErrors, name, handleNameChange, dataOptions } = props;

    return (
        <Grid item xs={12}>
            <FormControl error={!!fieldErrors.get('name')} fullWidth required>
                <FormLabel>Name</FormLabel>
                <TextFieldWithDataReferences initialValue={name} onChange={handleNameChange} dataReferences={dataOptions.map((x) => x.name ?? '')} />
                <FormHelperText>{fieldErrors.get('name')}</FormHelperText>
            </FormControl>
        </Grid>
    );
};
