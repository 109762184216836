import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: { main: '#5588C7', contrastText: '#ffffff' },
		secondary: { main: '#F7931E', contrastText: '#ffffff' },
		error: { main: '#D42F2F', contrastText: '#ffffff' },
		info: { main: '#cccccc', contrastText: '#ffffff' },
		background: {
			default: '#fafafa',
		},
		text: {
			primary: '#58585A',
		},
	},
	components: {
		MuiTableRow: {
			styleOverrides: {
				root: {
					"& .MuiTableCell-root .hover-actions": {
						display: 'none'
					},
					"&:hover .MuiTableCell-root .hover-actions": {
						display: 'block'
					}
				}
			}
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					backgroundColor: '#5588C7',
					color: '#ffffff',
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				h1: {
					display: 'inline-flex',
					verticalAlign: 'middle',
					fontSize: '32px',
					fontWeight: 'bold',
					'& .MuiSvgIcon-root': {
						fontSize: '32px',
						marginRight: '12px',
					},
				},
				h2: {
					display: 'inline-flex',
					verticalAlign: 'middle',
					fontSize: '24px',
					fontWeight: 'bold',
					'& .MuiSvgIcon-root': {
						fontSize: '32px',
						marginRight: '12px',
					},
				},
				subtitle1: {
					color: '#58585A',
					fontSize: '20px',
					fontWeight: 'normal',
				},
				h3: {
					display: 'inline-flex',
					verticalAlign: 'middle',
					fontSize: '24px',
					fontWeight: 'normal',
					'& .MuiSvgIcon-root': {
						fontSize: '24px',
						marginRight: '8px',
					},
				},
			},
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					backgroundColor: '#ffffff',
					color: '#2e394c',
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				root: {
					'& .MuiDrawer-paper': {
						backgroundColor: '#5588C7',
						color: '#ffffff',
						padding: '0px 8px 0px 20px',
					},
					'& .MuiDrawer-paper .MuiButtonBase-root': {
						color: '#ffffff',
						fontSize: '16px',
						textTransform: 'none',
					},
					'& .MuiDrawer-paper .MuiButtonBase-root.nav-button': {
						padding: '8px',
						marginLeft: '-8px',
						justifyContent: 'start',
					},
					'& .MuiDrawer-paper .MuiButtonBase-root.nav-button.full-width': {
						padding: '8px 12px',
						marginLeft: '-12px',
						width: 'calc(100% + 12px)',
					},
					'& .MuiDrawer-paper .MuiButtonBase-root.nav-button:hover': {
						backgroundColor: 'rgba(255,255,255,.2)',
					},
					'& .MuiDrawer-paper .MuiButtonBase-root .MuiSvgIcon-root': {
						fontSize: '32px',
					},
					'& .MuiDrawer-paper .MuiButtonBase-root .MuiButton-iconSizeMedium': {
						marginRight: '24px',
					},
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					padding: '8px 24px',
					'& .MuiButton-root': {
						minWidth: '100px',
					},
					'& > :not(:first-of-type)': {
						marginLeft: '24px',
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'capitalize'
				}
			}
		}
	},
});

export default theme;
