import { ArrowDownward, BuildCircle } from '@mui/icons-material';
import { Alert, AlertTitle, FormControl, FormHelperText, FormLabel, Grid, IconButton, LinearProgress, useTheme } from '@mui/material';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PlayVariableAutocompleteWithAdd } from '../../../../../Components/CommonInputs';
import { useFailedActionSnackbar } from '../../../../../Components/CoreLib/library';
import { StepDataType } from '../../../../../dtos';
import { useCreateAITestPromptMutation } from '../../../../../store/generated/generatedApi';
import { StepDataTypeToName } from '../../../../../util';
import { ReactComponent as TestConfigureIcon } from '../../../Assets/AIStepTestConfigure.svg';
import { ReactComponent as TestRunIcon } from '../../../Assets/AIStepTestRun.svg';
import { NameInput, WYSIWYGInputField } from '../../../Components/Modals/EditStepModalForms/StepFormParts';
import { PlayEditorContext } from '../../../Utils/PlayEditorContext';
import { StepEditorContext } from '../../../Utils/StepEditorContext';
import { IStepEditModalProps } from '../../types';
import { SetTestConfigurationModal } from './SetTestConfigurationModal';
import _ from 'lodash';
import { AIStepFileAttachmentDto } from '../../../../../dtos/generated/AIStepFileAttachmentDto';

interface PromptResponseData {
    outputLabelName: string;
    outputLabelType: StepDataType;
    response: string;
}

export const ArtificialIntelligenceNodeEditForm: FC<IStepEditModalProps> = ({ collectedData }) => {
    const [testPrompt, { data: promptTestResponse, isLoading: isTestingPrompt, isError: isErrorTestingPrompt, reset: resetTestPrompt }] =
        useCreateAITestPromptMutation();
    useFailedActionSnackbar('test', 'prompt', isErrorTestingPrompt, resetTestPrompt);
    const {
        formStep,
        fieldErrors,
        handleNameChange,
        handlePromptChange,
        handleOutputDataLabelChange
    } = useContext(StepEditorContext);
    const { playVariables } = useContext(PlayEditorContext);
    const theme = useTheme();
    const [promptTestResponses, setPromptTestResponses] = useState<PromptResponseData[]>([]);
    const [testConfiguration, setTestConfiguration] = useState<Map<string, string> | null>(null);
    const [isTestConfigurationModalOpen, setIsTestConfigurationModalOpen] = useState(false);
    const isTestConfigurationSet = useMemo(() => testConfiguration !== null, [testConfiguration]);
    const isPromptSet = useMemo(() => {
        const strippedText = formStep.prompt?.replace(/<[^>]+>/g, '') ?? '';
        return strippedText.trim().length > 0;
    }, [formStep.prompt]);
    const isOutputDataLabelSet = useMemo(() => (formStep.outputDataLabel ?? '').trim().length > 0, [formStep.outputDataLabel]);
    const isPromptUsingSmartValues = useMemo(
        () => collectedData.some((cd) => formStep.prompt?.includes(`{{${cd.name}}}`)),
        [collectedData, formStep.prompt]
    );
    const smartValuesUsedInPrompt = useMemo(
        () => _.uniq(collectedData.filter((cd) => formStep.prompt?.includes(`{{${cd.name}}}`))),
        [collectedData, formStep.prompt]
    );

    const getPlayVariableByName = useCallback(
        (variableName: string) => {
            return playVariables.find((pv) => pv.name === variableName);
        },
        [playVariables]
    );

    const selectedPlayVariable = useMemo(
        () => getPlayVariableByName(formStep.outputDataLabel ?? '') ?? null,
        [getPlayVariableByName, formStep.outputDataLabel]
    );

    const getFileNameFromFilePath = useCallback((filePath: string) => filePath.split('/').pop() ?? '', []);

    const testPromptWithConfiguration = useCallback(
        (configuration: Map<string, string>) => {
            // replace smart values with their configuration values
            let promptWithSmartValues = formStep.prompt ?? '';
            smartValuesUsedInPrompt.forEach((sv) => {
                promptWithSmartValues = (promptWithSmartValues ?? '').replaceAll(`{{${sv.name}}}`, sv.dataType === StepDataType.FILE ? getFileNameFromFilePath(configuration.get(sv.name ?? '') ?? '') : configuration.get(sv.name ?? '') ?? '');
            });

            // build list of file attachments
            let fileAttachments: AIStepFileAttachmentDto[] = [];
            smartValuesUsedInPrompt.filter((sv => sv.dataType === StepDataType.FILE)).forEach(smartValueFile => {
                let smartValueFilePath = configuration.get(smartValueFile.name ?? '');
                if (smartValueFilePath) {
                    fileAttachments.push({
                        fileName: getFileNameFromFilePath(smartValueFilePath),
                        pathAndFileName: smartValueFilePath
                    });
                }
            });

            // send request to test prompt
            testPrompt({ prompt: promptWithSmartValues, dataType: selectedPlayVariable?.dataType ?? StepDataType.TEXT, attachments: fileAttachments });
        },
        [formStep.prompt, smartValuesUsedInPrompt, testPrompt, selectedPlayVariable?.dataType, getFileNameFromFilePath]
    );

    const handleTestConfigurationChange = useCallback(
        (newTestConfiguration: Map<string, string>) => {
            setTestConfiguration(newTestConfiguration);
            setIsTestConfigurationModalOpen(false);
            testPromptWithConfiguration(newTestConfiguration);
        },
        [testPromptWithConfiguration]
    );

    const handleTestPromptClicked = useCallback(() => {
        if (isTestingPrompt) {
            return;
        }
        testPromptWithConfiguration(testConfiguration ?? new Map<string, string>());
    }, [isTestingPrompt, testPromptWithConfiguration, testConfiguration]);

    // clear out test configuration if the user stops using smart values
    useEffect(() => {
        if (testConfiguration !== null && !isPromptUsingSmartValues) {
            setTestConfiguration(null);
        }
    }, [testConfiguration, isPromptUsingSmartValues]);

    // add prompt response to list of responses
    useEffect(() => {
        if (promptTestResponse) {
            setPromptTestResponses((prev) => [{
                outputLabelName: selectedPlayVariable?.name ?? '',
                outputLabelType: selectedPlayVariable?.dataType ?? StepDataType.TEXT,
                response: promptTestResponse.response
            }, ...prev]);
            resetTestPrompt();
        }
    }, [promptTestResponse, resetTestPrompt, selectedPlayVariable]);

    return (
        <>
            <Grid container direction='column' height='calc(100%-30px)' mb={5}>
                <Grid item container direction='row' px={4} pt={3} spacing={1}>
                    <NameInput
                        fieldErrors={fieldErrors}
                        name={formStep.name ?? ''}
                        handleNameChange={handleNameChange}
                        dataOptions={collectedData}
                    />
                    <WYSIWYGInputField
                        label='Prompt'
                        tooltip='Enter a prompt that will be evaluated by AI. You can use {{smart values}} to reference data collected in previous steps and test the prompt using sample values.'
                        errorMessage={fieldErrors.get('prompt')}
                        value={formStep.prompt ?? ''}
                        handleValueChange={handlePromptChange}
                        collectedData={collectedData}
                        required
                    />
                    <Grid item xs={12} display='flex' justifyContent='center'>
                        <ArrowDownward />
                    </Grid>
                    <Grid item xs={12} display='flex' justifyContent='center'>
                        <FormControl error={!!fieldErrors.get('outputDataLabel')} fullWidth required sx={{ maxWidth: '280px' }}>
                            <FormLabel>Data</FormLabel>
                            <PlayVariableAutocompleteWithAdd
                                value={selectedPlayVariable}
                                onChange={handleOutputDataLabelChange}
                                playVariableOptions={playVariables.filter((pv) => pv.dataType !== StepDataType.FILE)}
                                error={!!fieldErrors.get('outputDataLabel')}
                            />
                            <FormHelperText>{fieldErrors.get('outputDataLabel')}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} display='flex' justifyContent='center' gap={1}>
                        <IconButton
                            color='secondary'
                            disabled={!isPromptSet || !isOutputDataLabelSet || isTestingPrompt || (isPromptUsingSmartValues && !isTestConfigurationSet)}
                            onClick={handleTestPromptClicked}>
                            <TestRunIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => setIsTestConfigurationModalOpen(true)}
                            disabled={!isPromptSet || !isOutputDataLabelSet || isTestingPrompt || !isPromptUsingSmartValues}>
                            <TestConfigureIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} display='flex' flexDirection='column' justifyContent='center' gap={1}>
                        {isTestingPrompt && <LinearProgress color='secondary' />}
                        
                        {promptTestResponses.map((ptr, i) => (
                            <Alert
                                key={i}
                                variant='filled'
                                sx={{ background: theme.palette.secondary.main, width: '100%' }}
                                icon={<BuildCircle sx={{ transform: 'scaleX(-1)' }} />}>
                                <AlertTitle>
                                    {ptr.outputLabelName} ({StepDataTypeToName.get(ptr.outputLabelType)})
                                </AlertTitle>
                                {ptr.response}
                            </Alert>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <SetTestConfigurationModal
                isOpen={isTestConfigurationModalOpen}
                handleClose={() => setIsTestConfigurationModalOpen(false)}
                handleSave={handleTestConfigurationChange}
                testConfiguration={testConfiguration}
                smartValuesInPrompt={smartValuesUsedInPrompt}
            />
        </>
    );
};
