import { AddCircle } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import _ from 'lodash';
import { FC } from 'react';
import { EmailToNotifyDataRow } from './EmailToNotifyDataRow';
import { StepDataDto } from '../../../../../../dtos';

export interface IEmailToNotifyProps {
    emailsToNotify: string[];
    updateEmailsToNotify: (updatedData: string[]) => void;
    dataOptions: StepDataDto[];
}

export const EmailsToNotifyInput: FC<IEmailToNotifyProps> = ({ emailsToNotify: allEmailToNotifyEntries, updateEmailsToNotify, dataOptions }) => {
    const addData = () => {
        var updatedEmailsToNotify = [...allEmailToNotifyEntries, ''];
        updateEmailsToNotify(updatedEmailsToNotify);
    };

    const removeEmailToNotify = (emailToNotifyIndex: number) => {
        var updatedEmailsToNotify = [...allEmailToNotifyEntries];
        updatedEmailsToNotify.splice(emailToNotifyIndex, 1);
        updateEmailsToNotify(updatedEmailsToNotify);
    };

    const updateEmailToNotify = (emailToNotifyIndex: number, emailToNotify: string) => {
        var updatedEmailsToNotify = _.cloneDeep(allEmailToNotifyEntries);
        updatedEmailsToNotify[emailToNotifyIndex] = emailToNotify;
        updateEmailsToNotify(updatedEmailsToNotify);
    };

    return (
        <>
            <Grid item mt={2} container direction='row'>
                <Grid item>
                    <Typography variant='h6'>Emails</Typography>
                </Grid>
            </Grid>
            {allEmailToNotifyEntries.map((emailToNotify, i) => (
                <EmailToNotifyDataRow
                    key={`email-to-notify-${i}`}
                    index={i}
                    disableDelete={allEmailToNotifyEntries.length === 1}
                    emailToNotify={emailToNotify}
                    updateEmailToNotify={updateEmailToNotify}
                    removeEmailToNotify={removeEmailToNotify}
                    dataOptions={dataOptions}
                />
            ))}
            <Grid item>
                <IconButton color='primary' onClick={addData}>
                    <AddCircle />
                </IconButton>
            </Grid>
        </>
    );
};
