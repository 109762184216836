import { Autocomplete, Box, FormControl, FormHelperText, FormLabel, Grid, IconButton, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { NameInput, WYSIWYGDescription } from '../../../Components/Modals/EditStepModalForms/StepFormParts';
import { StepEditorContext } from '../../../Utils/StepEditorContext';
import { IStepEditModalProps } from '../../types';
import { StepDataType } from '../../../../../dtos/generated/StepDataType';
import { Help } from '@mui/icons-material';
import { DelayMethod } from '../../../../../dtos/generated/DelayMethod';
import { TimeInput } from '../../../Components/Modals/EditStepModalForms/StepFormParts/TimeInput';
import { renderWithRequiredIndicator } from '../../../../../util';

export const DelayNodeEditForm: FC<IStepEditModalProps> = ({ step, collectedData }) => {
    const {
        formStep,
        fieldErrors,
        handleNameChange,
        handleDelayMethodChange,
        handleDelayMinutesChange,
        handleDelayDateLabelChange,
        handleDescriptionChange
    } = useContext(StepEditorContext);

    const dataOptions = useMemo(() => {
        return collectedData.filter((data) => data.dataType === StepDataType.DATE && data.isFutureDate === true);
    }, [collectedData]);

    const renderOptionLabel = useCallback(
        (option: string | undefined) => {
            var dataEntry = dataOptions.find((x) => x.name === option);
            if (!dataEntry) {
                return option ?? '';
            }
            return renderWithRequiredIndicator(dataEntry.name!, dataEntry.isRequired);
        },
        [dataOptions]
    );

    return (
        <Grid container direction='column' height='calc(100%-30px)' mb={5}>
            <Grid item px={4} pt={3}>
                <NameInput fieldErrors={fieldErrors} name={formStep.name ?? ''} handleNameChange={handleNameChange} dataOptions={collectedData} />
            </Grid>
            <div style={{ paddingLeft: 32, paddingRight: 32, paddingTop: 16 }}>
                <WYSIWYGDescription
                    fieldErrors={fieldErrors}
                    description={step?.description ?? ''}
                    handleDescriptionChange={handleDescriptionChange}
                    collectedData={collectedData}
                />
            </div>
            <Grid item px={4} pt={3} width='500px'>
                <FormControl fullWidth required error={!!fieldErrors.get('delayMethod')}>
                    <FormLabel>Delay Method</FormLabel>
                    <Select value={formStep.delayMethod ?? ''} onChange={(e) => handleDelayMethodChange(e.target.value as DelayMethod)}>
                        <MenuItem value={DelayMethod.MINUTES}>Delay for an amount of time</MenuItem>
                        <MenuItem value={DelayMethod.UNTIL_DATE}>Delay until a date</MenuItem>
                    </Select>
                    <FormHelperText>{fieldErrors.get('delayMethod')}</FormHelperText>
                </FormControl>
            </Grid>
            {formStep.delayMethod === DelayMethod.MINUTES ? (
                <Grid item px={4} pt={3}>
                    <TimeInput
                        label='Please input the amount of time this step should delay for:'
                        error={fieldErrors.get('delayMinutes')}
                        minutesValue={formStep.delayMinutes ?? 0}
                        handleTimeChange={handleDelayMinutesChange}
                    />
                </Grid>
            ) : (
                <Grid item px={4} pt={3}>
                    <FormControl error={!!fieldErrors.get('delayDateLabel')} fullWidth required>
                        <Box display='flex' justifyContent='space-between' alignItems='end'>
                            <Box>
                                <FormLabel>Data Value (Date)</FormLabel>
                                <Tooltip title="The date must be a previously collected piece of data that has validation set to 'Future'.">
                                    <IconButton>
                                        <Help />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Autocomplete
                            value={formStep.delayDateLabel}
                            onChange={(_event, value) => {
                                handleDelayDateLabelChange(value ?? '');
                            }}
                            options={dataOptions.map((data) => data.name)}
                            getOptionLabel={renderOptionLabel}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <FormHelperText>{fieldErrors.get('delayDateLabel')}</FormHelperText>
                    </FormControl>
                </Grid>
            )}
        </Grid>
    );
};
