import { Grid, Tabs } from '@mui/material';
import { FC, useContext } from 'react';
import { emptyGuid } from '../../../../../util';
import {
    EditStepDataInput,
    EditStepRequestDataInput,
    EndpointInput,
    NameInput,
    WYSIWYGDescription,
} from '../../../Components/Modals/EditStepModalForms/StepFormParts';
import { TabWithErrorIndicator } from '../../../Components/Modals/EditStepModalForms/StepFormParts/TabWithErrorIndicator';
import { StepEditorContext } from '../../../Utils/StepEditorContext';
import { IStepEditModalProps } from '../../types';

export const AutomationNodeEditForm: FC<IStepEditModalProps> = ({ step, collectedData }) => {
    const {
        tabIndex,
        setTabIndex,
        handleChangeTabIndex,
        handleNameChange,
        handleDescriptionChange,
        handleStepDataChange,
        handleTargetEndpointChange,
        handleRequestDataLabelsChange,
        isGeneralValid,
        isDataValid,
        isDevelopmentValid,
        fieldErrors,
        formStep,
    } = useContext(StepEditorContext);

    return (
        <Grid container direction='column' height='calc(100%-30px)' mb={5}>
            <Grid
                item
                xs={12}
                borderBottom={'1px solid #e0e0e0'}
                zIndex={5}
                position='sticky'
                top={0}
                sx={{ background: 'white' }}
                className='draggable-ignored'>
                <Tabs value={tabIndex} onChange={handleChangeTabIndex} aria-label='edit step tabs' variant='fullWidth'>
                    <TabWithErrorIndicator label='GENERAL' index={0} isValid={isGeneralValid} setValue={setTabIndex} />
                    <TabWithErrorIndicator label='DATA' index={1} isValid={isDataValid} setValue={setTabIndex} />
                    <TabWithErrorIndicator label='DEVELOPMENT' index={2} isValid={isDevelopmentValid} setValue={setTabIndex} />
                </Tabs>
            </Grid>
            {tabIndex === 0 && (
                <Grid item container direction='row' px={4} pt={3} spacing={1}>
                    <NameInput fieldErrors={fieldErrors} name={formStep.name ?? ''} handleNameChange={handleNameChange} dataOptions={collectedData} />
                    <WYSIWYGDescription
                        fieldErrors={fieldErrors}
                        description={step?.description ?? ''}
                        handleDescriptionChange={handleDescriptionChange}
                        collectedData={collectedData}
                    />
                </Grid>
            )}
            {tabIndex === 1 && (
                <Grid item container direction='row' px={4} pt={3} spacing={1}>
                    <EditStepDataInput
                        stepId={step?.id ?? emptyGuid}
                        stepData={formStep.stepData}
                        updateStepData={handleStepDataChange}
                        description='What (if any) information should be gathered from this step?'
                    />
                </Grid>
            )}
            {tabIndex === 2 && (
                <Grid item container direction='row' px={4} pt={3} spacing={1}>
                    <EndpointInput fieldErrors={fieldErrors} endpoint={formStep.targetEndpoint ?? ''} handleEndpointChange={handleTargetEndpointChange} />
                    <EditStepRequestDataInput
                        errorMessage={fieldErrors.get('requestDataLabels')}
                        dataOptions={collectedData}
                        selectedDataOptions={formStep.requestDataLabels ?? []}
                        updateSelectedDataOptions={handleRequestDataLabelsChange}
                    />
                </Grid>
            )}
        </Grid>
    );
};
