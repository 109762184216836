import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react'
import { DataTableColumn, IStandardListViewExtraItems, PaginatedProps, StandardListViewV2, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../Components/CoreLib/library'
import { UserDto } from '../../../dtos';
import { useGetJobFunctionByJobFunctionIdUsersQuery, useUpdateJobFunctionByJobFunctionIdRemoveMutation } from '../../../store/generated/generatedApi';
import { JobFunctionAddUsersModal } from './JobFunctionAddUsersModal';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'FIRST_NAME',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

const tableColumns: DataTableColumn<UserDto>[] = [
    { key: 'firstName', label: 'First Name', sortKey: 'FIRST_NAME' },
    { key: 'lastName', label: 'Last Name', sortKey: 'LAST_NAME' },
    { key: 'role', label: 'User Role', sortKey: 'ROLE' },
    { key: 'email', label: 'Email', sortKey: 'EMAIL' },
    { key: 'phoneNumber', label: 'Phone', sortKey: 'PHONE_NUMBER' },
];

export interface IJobFunctionUserListViewProps {
    jobFunctionId: string;
    jobFunctionName: string;
}

export const JobFunctionUserListView: FC<IJobFunctionUserListViewProps> = props => {
    const { jobFunctionId, jobFunctionName } = props;
    const [isQuickAddDialogOpen, setIsQuickAddDialogOpen] = useState(false);
    const [removeUsersFromJobFunction, {isSuccess, isError, isLoading: isRemovingUsers, reset: resetUserBulkRemove}] = useUpdateJobFunctionByJobFunctionIdRemoveMutation();
    useSuccessfulActionSnackbar('removed', 'user(s)', isSuccess, resetUserBulkRemove);
    useFailedActionSnackbar('add', 'user(s)', isError, resetUserBulkRemove)

    const removeUsersEnableCheck = useCallback((selectedItems: UserDto[]) => {
        return selectedItems.length > 0
    }, []);

    const handleRemoveUsersFromJobFunction = useCallback((selectedItems: UserDto[]) => {
        if (isRemovingUsers) {
            return;
        }
        const userIds = selectedItems.map(u => u.id);
        removeUsersFromJobFunction({ params: { jobFunctionId: jobFunctionId }, payload: userIds })
    }, [jobFunctionId, isRemovingUsers, removeUsersFromJobFunction]);

    const openQuickAddDialog = useCallback(() => {
        setIsQuickAddDialogOpen(true);
    }, [setIsQuickAddDialogOpen]);

    const closeQuickAddDialog = useCallback(() => {
        setIsQuickAddDialogOpen(false);
    }, [setIsQuickAddDialogOpen]);

    const extraItems: IStandardListViewExtraItems[] = useMemo(() => [
        {
            requiredPermissions: ['edit:jobFunctions'],
            enabledCheck: removeUsersEnableCheck,
            onClick: handleRemoveUsersFromJobFunction,
            text: 'Remove User(s)',
            icon: <RemoveCircle />,
            preventTextTransform: true
        },
        {
            requiredPermissions: ['edit:jobFunctions'],
            onClick: openQuickAddDialog,
            text: 'Add User(s)',
            icon: <AddCircle />,
            preventTextTransform: true
        }
    ], [removeUsersEnableCheck, handleRemoveUsersFromJobFunction, openQuickAddDialog]);

    return (
        <Box pb={2} width='100%'>
            <StandardListViewV2
                headerIcon={<></>}
                headerTitle=''
                breadCrumbs={[]}
                defaultPaginationProps={defaultPaginationProps}
                getDataQuery={useGetJobFunctionByJobFunctionIdUsersQuery}
                tableColumns={tableColumns}
                entityNameSingular='user'
                permissionName='users'
                isShowActiveToggleVisible={true}
                additionalQueryParameters={{
                    jobFunctionId
                }}
                additionalActionItems={extraItems}
                additionalMenuItems={extraItems}
            />
            <JobFunctionAddUsersModal jobFunctionId={jobFunctionId} jobFunctionName={jobFunctionName} isOpen={isQuickAddDialogOpen} handleClose={closeQuickAddDialog} />
        </Box>
    )
}