import { useSnackbar } from 'notistack';
import { DragEvent, useCallback, useMemo, useRef } from 'react';
import {
    Connection,
    Edge,
    EdgeTypes,
    NodeDragHandler,
    NodeTypes as ReactFlowNodeTypes,
    XYPosition,
    addEdge,
    useEdgesState,
    useNodesState,
    useReactFlow,
} from 'reactflow';
import {
    CollectedDataDto,
    CollectedDataMap,
    Condition,
    DataMappingConfigDto,
    DelayMethod,
    EmbeddingLinkDto,
    MatchType,
    NotificationType,
    PlayDto,
    PlayExecutionDto,
    StepConditionDto,
    StepDataDto,
    StepDataType,
    StepDto,
    StepEmbedInputDto,
    StepExecutionDto,
    StepType,
    UpdateStepRequestDto,
} from '../../../../dtos';
import {
    useCreateAutoSavePlayByPlayIdStepMutation,
    usePatchAutoSavePlayByPlayIdStepByStepKeyMoveMutation,
    useUpdateAutoSavePlayByPlayIdMutation,
} from '../../../../store/generated/generatedApi';
import { DeletableEdge } from '../../Components/Edges/DeletableEdge';
import { getAllNodeTypes } from '../../NodeTypes/utils';
import { DependentNodeKeyFinder } from '../DependentNodeKeyFinder';
import { GraphConnectionValidator } from '../Validators';
import { mapDependenciesToEdges, mapStepsToNodes } from './FlowMappers';
import { applyCustomEdgeStyling, removeDependenciesWithMissingNodes } from './useFlowManager';

export function useAutosaveFlowManager<S extends StepDto | StepExecutionDto>(play: PlayDto | PlayExecutionDto) {
    const reactFlowWrapper = useRef<any>(null);
    const reactFlowInstance = useReactFlow();
    const { enqueueSnackbar } = useSnackbar();
    const [nodes, setNodes, onNodesChange] = useNodesState(mapStepsToNodes<S>((play.steps as S[]) ?? []));
    const [edges, setEdges, onEdgesChange] = useEdgesState(
        mapDependenciesToEdges(removeDependenciesWithMissingNodes(play.stepDependencies ?? [], play.steps?.map((s) => s.key ?? '') ?? []) ?? [])
    );
    const [createStep] = useCreateAutoSavePlayByPlayIdStepMutation();
    const [moveStep] = usePatchAutoSavePlayByPlayIdStepByStepKeyMoveMutation();
    const [updateStep] = useUpdateAutoSavePlayByPlayIdMutation();

    const reloadPlay = useCallback(
        (updatedPlay: PlayDto | PlayExecutionDto) => {
            // mapping steps
            const stepsMappedToNodes = mapStepsToNodes<S>((updatedPlay.steps as S[]) ?? []);

            // mapping step dependencies
            const playDependencies = play.stepDependencies ?? [];
            const allPlayStepKeys = play.steps?.map((s) => s.key ?? '') ?? [];
            const activeDependencies = removeDependenciesWithMissingNodes(playDependencies, allPlayStepKeys);
            const dependenciesMappedToEdges = mapDependenciesToEdges(activeDependencies);

            // populate graph
            setNodes(stepsMappedToNodes);
            setEdges(dependenciesMappedToEdges);
        },
        [play, setEdges, setNodes]
    );

    const getDependentNodeKeys = useCallback(
        (nodeKey: string) => {
            const dependentNodeKeyFinder = new DependentNodeKeyFinder(edges);
            return dependentNodeKeyFinder.getDependentNodeKeysForKey(nodeKey);
        },
        [edges]
    );

    const verifyConnectionBetweenNodesIsValid = useCallback(
        (newEdge: Connection, existingEdges: Edge<any>[]) => {
            if (!newEdge.target || !newEdge.source) {
                return false;
            }

            const graphConnectionValidator = new GraphConnectionValidator(nodes, existingEdges);
            const errorMessage = graphConnectionValidator.validateNewConnection(newEdge);
            if (errorMessage) {
                enqueueSnackbar(errorMessage, { variant: 'warning' });
                return false;
            }

            return true;
        },
        [nodes, enqueueSnackbar]
    );

    const onConnect = useCallback(
        (newNodeConnection: Connection) =>
            setEdges((existingEdges) => {
                const isConnectionValid = verifyConnectionBetweenNodesIsValid(newNodeConnection, existingEdges);
                if (!isConnectionValid) {
                    return existingEdges;
                }

                var newEdge: Edge = {
                    id: `${newNodeConnection.source}-${newNodeConnection.target}`,
                    source: newNodeConnection.source ?? '',
                    target: newNodeConnection.target ?? '',
                    sourceHandle: newNodeConnection.sourceHandle,
                    targetHandle: newNodeConnection.targetHandle,
                };

                newEdge = applyCustomEdgeStyling(newEdge);

                return addEdge(newEdge, existingEdges);
            }),
        [setEdges, verifyConnectionBetweenNodesIsValid]
    );

    const onDragOver = useCallback((event: DragEvent) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const centerOnCoordinates = useCallback(
        (positionToCenterOn: XYPosition) => {
            const NodeSizeOffset = 20;
            const x = positionToCenterOn.x + NodeSizeOffset;
            const y = positionToCenterOn.y + NodeSizeOffset;
            const zoom = 1.85;

            reactFlowInstance.setCenter(x, y, { zoom, duration: 1000 });
        },
        [reactFlowInstance]
    );

    const onDrop = useCallback(
        (event: DragEvent) => {
            event.preventDefault();
            if (!reactFlowInstance || !reactFlowWrapper.current) {
                return;
            }

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');
            const nodeSizeOffset = 25;
            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left - nodeSizeOffset,
                y: event.clientY - reactFlowBounds.top - nodeSizeOffset,
            });

            console.log('Creating new step', type, position);

            createStep({
                params: {
                    playId: play.id,
                },
                payload: {
                    type: StepType[type as keyof typeof StepType],
                    location: {
                        x: position.x,
                        y: position.y,
                    },
                },
            });
        },
        [reactFlowInstance, createStep, play.id]
    );

    const nodeTypes: ReactFlowNodeTypes = useMemo(() => {
        var allNodeTypes = getAllNodeTypes();
        var reactFlowNodeTypes: ReactFlowNodeTypes = {};
        allNodeTypes.forEach((nt) => (reactFlowNodeTypes[nt.nodeType] = nt.PlayViewerNode));
        return reactFlowNodeTypes;
    }, []);

    const edgeTypes: EdgeTypes = useMemo(
        () => ({
            deletable: DeletableEdge,
        }),
        []
    );

    const handleNodeMoved: NodeDragHandler = useCallback(
        (_, node) => {
            console.log('Node moved', node);
            moveStep({
                params: {
                    playId: play.id,
                    stepKey: node.data.key,
                },
                payload: {
                    x: node.position.x,
                    y: node.position.y,
                },
            });
        },
        [play.id, moveStep]
    );

    const handleStepUpdated = useCallback(
        (updatedStep: StepDto) => {
            if (!updatedStep.type || !updatedStep.key) {
                console.error('Invalid step update request. Not enough information to identify step: ', updatedStep);
                return;
            }
            const stepType = StepType[updatedStep.type as keyof typeof StepType];
            let payload: UpdateStepRequestDto = {
                type: stepType,
                key: updatedStep.key,
                name: updatedStep.name ?? '',
                description: updatedStep.description ?? '',
                executionTimeInMinutesEstimated: updatedStep.estimatedExecutionTimeInMinutes,
                shouldSendOverdueNotification: updatedStep.shouldSendOverdueNotification,
                stepData: updatedStep.stepData.map(formatStepData),
                embeddingLinks: updatedStep.embedInputs.filter(isEmbedLinkEmpty).map(formatEmbedLink),
            };

            // TODO: ideally this will not be needed in the future. I am keeping the existing StepDto format for now to make projecting easier but we can/should just match what the back-end wants to make communication easier
            switch (stepType) {
                case StepType.DEFAULT:
                    // no additional configuration needed
                    break;
                case StepType.START:
                    // no additional configuration needed
                    break;
                case StepType.CONDITIONAL:
                    payload.conditionalStepConfig = {
                        matchType: updatedStep.matchType ?? MatchType.ALL,
                        conditions: updatedStep.stepConditions?.map<Condition>(formatStepCondition) ?? [],
                        trueStepKey: '', // TODO: this still needs to be separated into a separate command
                        falseStepKey: '', // TODO: this still needs to be separated into a separate command
                    };
                    break;
                case StepType.POST:
                    payload.postStepConfig = {
                        targetEndpoint: updatedStep.targetEndpoint ?? '',
                        collectedDataToSend: updatedStep.requestDataLabels ?? [],
                    };
                    break;
                case StepType.SUB_PLAY:
                    payload.subplayStepConfig = {
                        subPlayId: updatedStep.subplayId ?? '',
                        inputMapping: updatedStep.subplayDataInputConfig?.map(formatCollectedData) ?? [],
                        outputMapping: updatedStep.subplayDataOutputConfig?.map(formatCollectedData) ?? [],
                        isImmediatelyCompleted: updatedStep.isImmediatelyCompleted ?? false,
                    };
                    break;
                case StepType.DELAY:
                    payload.delayStepConfig = {
                        delayMethod: updatedStep.delayMethod ?? DelayMethod.MINUTES,
                        delayMinutes: updatedStep.delayMinutes ?? 0,
                        delayDateLabel: updatedStep.delayDateLabel ?? '',
                    };
                    break;
                case StepType.NOTIFICATION:
                    payload.notificationStepConfig = {
                        notificationType: updatedStep.notificationType ?? NotificationType.EMAILS,
                        notificationRecipients: updatedStep.emailsToNotify ?? [],
                    };
                    break;
                case StepType.ARTIFICIAL_INTELLIGENCE:
                    payload.aIStepConfig = {
                        prompt: updatedStep.prompt ?? '',
                        outputDataLabel: updatedStep.outputDataLabel ?? '',
                    };
                    break;
                default:
                    console.error('Invalid or uneditable step type: ', stepType);
                    return;
            }

            updateStep({
                params: {
                    playId: play.id,
                },
                payload,
            });
        },
        [play.id, updateStep]
    );

    return {
        reactFlowWrapper,
        nodes,
        edges,
        reloadPlay,
        onNodesChange,
        onEdgesChange,
        onConnect,
        onDrop,
        onDragOver,
        nodeTypes,
        setNodes,
        setEdges,
        getDependentNodeKeys,
        centerOnCoordinates,
        edgeTypes,
        handleNodeMoved,
        handleStepUpdated,
    };
}

function isEmbedLinkEmpty(ei: StepEmbedInputDto): boolean {
    return !ei.embedLink || ei.embedLink.trim() === '';
}

function formatEmbedLink(ei: StepEmbedInputDto): EmbeddingLinkDto {
    return {
        embedLink: ei.embedLink ?? '',
        orderIndex: ei.orderIndex,
    };
}
function formatStepData(sd: StepDataDto): CollectedDataDto {
    return {
        name: sd.name ?? '',
        isRequired: sd.isRequired,
        orderIndex: sd.orderIndex,
        dataType: sd.dataType ?? StepDataType.TEXT,
        isFutureDate: sd.isFutureDate ?? false,
        collectionPrompt: sd.collectionPrompt ?? '',
    };
}

function formatStepCondition(sc: StepConditionDto): Condition {
    return {
        left: sc.leftOperand,
        comparison: sc.operator,
        right: sc.rightOperand,
        orderIndex: sc.orderIndex
    };   
}

function formatCollectedData(dmc: DataMappingConfigDto): CollectedDataMap {
    return {
        fromLabel: dmc.sourceFieldName,
        toLabel: dmc.destinationFieldName
    };
}