import { Cancel, MoreVert, Save, Settings } from '@mui/icons-material';
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import {
    AuthenticatedComponent,
    FormSection,
    IFormProps,
    LoadingIndicator,
    SlimPageHeader,
    useFailedActionSnackbar,
    useSuccessfulActionSnackbar,
} from '../../../Components/CoreLib/library';
import { UserNotificationConfigDto } from '../../../dtos';
import { useDeleteUserSlackAccountMutation } from '../../../store/generated/generatedApi';
import { useUserSettingsForm } from './useUserSettingsForm';
import { ConfigureOpenAICard } from './ConfigureOpenAICard';

export const UserSettingsForm: FC<IFormProps<UserNotificationConfigDto>> = (props) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [, { isLoading: isUnlinking, isError: isErrorUnlinking, isSuccess: isUnlinkSuccess, reset: resetUnlinking }] = useDeleteUserSlackAccountMutation();
    useSuccessfulActionSnackbar('unlinked', 'Slack account', isUnlinkSuccess, resetUnlinking);
    useFailedActionSnackbar('unlink', 'Slack account', isErrorUnlinking, resetUnlinking);

    const {
        formNotificationConfig,
        isFormDirty,
        handleCancel,
        handleSave,
        handleShouldNotifyByEmailChange,
        handleShouldNotifyBySlackMessageChange,
        handleDailyDigestDaysConfigChange,
        isAtLeastOneNotificationOptionEnabled,
        dailyDigestDays,
    } = useUserSettingsForm(props);

    const [configureOpenAIModalOpen, setConfigureOpenAIModalOpen] = useState(false);

    const handleConfigureOpenAIClicked = useCallback(() => {
        setConfigureOpenAIModalOpen(true);
    }, []);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    };

    const getEmailTooltip = useMemo(() => {
        if (!isAtLeastOneNotificationOptionEnabled) {
            return 'At least one notification option must be selected.';
        }
        return '';
    }, [isAtLeastOneNotificationOptionEnabled]);

    const getSlackTooltip = useMemo(() => {
        if (!isAtLeastOneNotificationOptionEnabled) {
            return 'At least one notification option must be selected.';
        }
        const isNoSlackLinkedToUser = !props.initValues?.isSlackConfigured;
        if (isNoSlackLinkedToUser) {
            return 'There is no slack account currently linked';
        }
        return '';
    }, [isAtLeastOneNotificationOptionEnabled, props.initValues?.isSlackConfigured]);

    if (isUnlinking) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column' spacing={3} mb={10}>
            <Grid item>
                <SlimPageHeader
                    icon={<Settings />}
                    title={'Settings'}
                    breadCrumbProps={{
                        links: [{ label: 'Home', navLink: '/' }],
                        currentPageLabel: 'Settings',
                    }}
                    endSlot={
                        <Grid item container direction='row' alignItems='start' py={1}>
                            <Grid item display='flex' alignItems='center' justifyContent={'end'} xs={12} sx={{ gap: 1 }} pr={2}>
                                <Divider flexItem orientation='vertical' sx={{ mx: 1 }} />
                                <Button variant='outlined' color='primary' size='small' onClick={handleCancel} startIcon={<Cancel />} sx={{ width: 90 }}>
                                    {isFormDirty ? 'Cancel' : 'Close'}
                                </Button>
                                <Button variant='contained' color='primary' size='small' onClick={handleSave} startIcon={<Save />} sx={{ width: 90 }}>
                                    Save
                                </Button>
                                <IconButton onClick={handleMenuOpen}>
                                    <MoreVert fontSize='inherit' />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={!!menuAnchorEl}
                                    onClose={handleMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <MenuItem onClick={handleMenuClose(handleSave)}>Save</MenuItem>
                                    <MenuItem onClick={handleMenuClose(handleCancel)}>{isFormDirty ? 'Cancel' : 'Close'}</MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                    }
                />
            </Grid>
            <Grid item mx={2}>
                <FormSection>
                    <Grid item container direction='column' spacing={1}>
                        <Grid item>
                            <Typography variant='h6' sx={{ paddingTop: '10px' }}>
                                Daily Digest Configuration (8AM EST)
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ToggleButtonGroup value={dailyDigestDays} onChange={(_, value) => handleDailyDigestDaysConfigChange(value)} color='primary'>
                                {['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((day) => (
                                    <ToggleButton sx={{ width: '112px' }} key={day} value={day} disabled={day === 'sunday' || day === 'saturday'}>
                                        {day}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </Grid>
                        <AuthenticatedComponent requiredPermissions={['feature:slack']}>
                            <Grid item>
                                <Typography variant='h6' sx={{ paddingTop: '10px' }}>
                                    Notify Me By *
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Tooltip followCursor title={getEmailTooltip} placement='top'>
                                    <FormControlLabel
                                        disabled={!!getEmailTooltip}
                                        control={<Checkbox checked={formNotificationConfig.shouldNotifyByEmail} onChange={handleShouldNotifyByEmailChange} />}
                                        label='Email'
                                    />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <Tooltip followCursor title={getSlackTooltip} placement='top'>
                                    <FormControlLabel
                                        disabled={!!getSlackTooltip}
                                        control={
                                            <Checkbox
                                                checked={formNotificationConfig.shouldNotifyBySlackMessage}
                                                onChange={handleShouldNotifyBySlackMessageChange}
                                            />
                                        }
                                        label='Slack (coming soon...)'
                                    />
                                </Tooltip>
                            </Grid>
                            <AuthenticatedComponent requiredPermissions={[`edit:clientAIConfig`]}>
                                <Grid item>
                                    <Typography variant='h6' sx={{ paddingTop: '10px' }}>
                                        My Company's API Keys
                                    </Typography>
                                </Grid>
                                <Grid item container direction='row' spacing={3} alignItems='center'>
                                    <Grid item xs={6}>
                                        <Button variant='contained' color='primary' onClick={handleConfigureOpenAIClicked}>
                                            Configure OpenAI
                                        </Button>
                                    </Grid>
                                </Grid>
                            </AuthenticatedComponent>
                        </AuthenticatedComponent>
                    </Grid>
                </FormSection>
            </Grid>
            <AuthenticatedComponent requiredPermissions={['edit:clientAIConfig']}>
                <ConfigureOpenAICard configureOpenAIModalOpen={configureOpenAIModalOpen} setConfigureOpenAIModalOpen={setConfigureOpenAIModalOpen} />
            </AuthenticatedComponent>
        </Grid>
    );
};
