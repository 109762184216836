import { Edit } from '@mui/icons-material';
import { Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import _ from 'lodash';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
    FormInput,
    IFormFieldValidationConfig,
    createValidatorConfig,
    isNotBlank,
    isShorterThanMaxLength,
    runFieldValidation,
    runFormValidation,
} from '../../../../Components/CoreLib/library';
import { areObjectsDifferent } from '../../../../util';
import { ModalSaveCloseFooter, ModalTitle } from '../SubComponents';

export interface PlayDetails {
    name: string;
    description: string;
}

export interface IEditPlayDetailsModalProps {
    closeModal: () => void;
    onUpdatePlayDetails: (updatedPlayDetails: PlayDetails) => void;
    playDetails: PlayDetails;
    isOpen: boolean;
}

export const EditPlayDetailsModal: FC<IEditPlayDetailsModalProps> = ({ closeModal, onUpdatePlayDetails, playDetails: initialPlayDetails, isOpen }) => {
    const { playDetails, isFormDirty, fieldErrors, validateForm, handleTextFieldChange, reset } = usePlayDetailsForm(initialPlayDetails);

    useEffect(() => {
        if (isOpen) {
            reset();
        }
    }, [isOpen, reset]);

    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            onUpdatePlayDetails(playDetails!);
        }
    }, [validateForm, playDetails, onUpdatePlayDetails]);

    return (
        <Dialog open={isOpen} fullWidth maxWidth='md'>
            <ModalTitle icon={<Edit sx={{ mr: 1 }} />} title={`Edit Play`} />
            <DialogContent sx={{ pt: 1, pb: 0 }}>
                <Box display='flex' flexDirection='column' gap={1}>
                    <FormInput
                        name='name'
                        label='Name'
                        value={playDetails.name}
                        onChange={handleTextFieldChange}
                        errorText={fieldErrors.get('name')}
                        fullWidth
                        required
                    />
                    <FormInput
                        name='description'
                        label='Description'
                        value={playDetails.description ?? ''}
                        onChange={handleTextFieldChange}
                        errorText={fieldErrors.get('description')}
                        fullWidth
                        rows={3}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <ModalSaveCloseFooter isFormDirty={isFormDirty} onCancelCloseClicked={closeModal} onSaveClicked={handleSave} />
            </DialogActions>
        </Dialog>
    );
};

const PLAY_DETAILS_VALIDATION_CONFIG = new Map<keyof PlayDetails, IFormFieldValidationConfig>([
    ['name', createValidatorConfig([isNotBlank, isShorterThanMaxLength(250)], 'Name')],
    ['description', createValidatorConfig([isShorterThanMaxLength(1000)], 'Description')],
]);

function usePlayDetailsForm(initialPlayDetails: PlayDetails) {
    const [playDetails, setPlayDetails] = useState(_.cloneDeep(initialPlayDetails));
    const isFormDirty = useMemo(() => areObjectsDifferent(playDetails, initialPlayDetails), [initialPlayDetails, playDetails]);
    const [fieldErrors, setFieldErrors] = useState<Map<keyof PlayDetails, string>>(new Map());

    const validateForm = useCallback(() => {
        if (!playDetails) {
            setFieldErrors(new Map());
            return false;
        }
        const validationResult = runFormValidation<PlayDetails>(playDetails, PLAY_DETAILS_VALIDATION_CONFIG);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [playDetails]);

    const validateField = useCallback(
        (fieldName: keyof PlayDetails, updatedValue: any) => {
            const validationConfig = PLAY_DETAILS_VALIDATION_CONFIG.get(fieldName);
            if (validationConfig) {
                const { errorMessage } = runFieldValidation(updatedValue, validationConfig);

                if (errorMessage !== fieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(fieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setFieldErrors(updatedFieldErrors);
                }
            }
        },
        [fieldErrors]
    );

    const handleTextFieldChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            let targetName = event.target.name as keyof PlayDetails;
            setPlayDetails((playDetails) => ({ ..._.set(playDetails, targetName, event.target.value) }));
            validateField(targetName, event.target.value);
        },
        [validateField]
    );

    const reset = useCallback(() => {
        setPlayDetails(_.cloneDeep(initialPlayDetails));
    }, [initialPlayDetails]);

    return {
        playDetails,
        isFormDirty,
        fieldErrors,
        validateForm,
        handleTextFieldChange,
        reset
    };
}
