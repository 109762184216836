import { Plumbing } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError, LoadingIndicator, StandardCardWithHeader, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { JobFunctionForm } from '../../Components/Forms/JobFunctionForm/JobFunctionForm';
import { ClientJobFunctionDto } from '../../dtos';
import { useGetJobFunctionByIdQuery, useUpdateJobFunctionMutation } from '../../store/generated/generatedApi';
import { JobFunctionUserListView } from './Components';

export const EditJobFunctionView: FC = () => {
    const { id: jobFunctionId } = useParams();
    const navigate = useNavigate();
    const { data: JobFunction, isLoading: isLoadingJobFunction, error: errorLoadingJobFunction, refetch: refetchJobFunction } = useGetJobFunctionByIdQuery({ id: jobFunctionId! });
    const [updateClientJobFunction, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate, isLoading: isUpdatingJobFunction }] = useUpdateJobFunctionMutation();

    useSuccessfulUpdateSnackbar('Job Function', isUpdateSuccess, resetUpdate);
    useFailedUpdateSnackbar('job function', isUpdateError, resetUpdate);

    const handleSave = (values: ClientJobFunctionDto) => {
        updateClientJobFunction(values);
    };

    const handleCancel = () => {
        navigate('/jobFunctions');
    };

    if (isLoadingJobFunction) {
        return <LoadingIndicator />;
    }

    if (errorLoadingJobFunction || !jobFunctionId || !JobFunction) {
        return <ApiError onReloadClick={refetchJobFunction} />;
    }

    return (
        <Grid container direction='column'>
            <JobFunctionForm save={handleSave} cancel={handleCancel} initValues={JobFunction} isLoading={isUpdatingJobFunction} />
            <Box px={2} py={2}>
                <StandardCardWithHeader headerTitle='Members' headerIcon={<Plumbing />}>
                    <JobFunctionUserListView jobFunctionId={jobFunctionId} jobFunctionName={JobFunction.name} />
                </StandardCardWithHeader>
            </Box>
        </Grid>
    );
};
