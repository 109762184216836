import { serverApi } from '../api';
import _ from 'lodash';
import {
	TestPromptRequestDto,
	UpdateStepRequestDto,
	CreateStepRequestDto,
	PlayCoordinates,
	UpdatePlayNameAndDescriptionRequestDto,
	ClientDto,
	OpenAIConfigurationDto,
	ClientJobFunctionDto,
	MessageDto,
	SuppressionRequestDto,
	PlayDto,
	StartPlayDto,
	PlaybookDto,
	PlayExecutionDto,
	StepExecutionDto,
	ReassignStepPayloadDto,
	StepDto,
	StepExecutionSnoozeDto,
	UserDto,
	UserSlackConfigDto,
	UserNotificationConfigDto,
	TestPromptResponseDto,
	FileAttachmentDto,
	DownloadLinkResultDto,
	ClientOpenAIConfigurationDetailsDto,
	QueryResultsDto,
	BulkUpdateResultDto,
	MessageResponseDto,
	EventWrapper,
	TodoListNotificationDto,
	PlayExecutionHistoryAggregateDto,
	PlayStartInputDto,
	StepExecutionMessageResponseDto,
	StepAssignmentDto,
	UserRoleDto
} from '../../dtos';

import {
	DeleteAttachmentQueryParameters,
	CreateAttachmentByIdDownloadQueryParameters,
	CreateAutoSavePlayQueryParameters,
	DeleteAutoSavePlayByPlayIdQueryParameters,
	UpdateAutoSavePlayByPlayIdQueryParameters,
	CreateAutoSavePlayByPlayIdStepQueryParameters,
	DeleteAutoSavePlayByPlayIdStepByStepKeyQueryParameters,
	PatchAutoSavePlayByPlayIdStepByStepKeyMoveQueryParameters,
	CreateAutoSavePlayByPlayIdStepByStepIdDependencyByDependentStepIdQueryParameters,
	DeleteAutoSavePlayByPlayIdStepByStepIdDependencyByDependentStepIdQueryParameters,
	ClientByIdArchiveQueryParameters,
	ClientByIdDeleteQueryParameters,
	ClientByIdDeletePermanentlyQueryParameters,
	GetClientByIdQueryParameters,
	ClientByIdRestoreQueryParameters,
	ClientByIdUnarchiveQueryParameters,
	GetJobFunctionQueryParameters,
	GetJobFunctionByJobFunctionIdUsersQueryParameters,
	GetJobFunctionByJobFunctionIdAddableUsersQueryParameters,
	UpdateJobFunctionByJobFunctionIdAddQueryParameters,
	UpdateJobFunctionByJobFunctionIdRemoveQueryParameters,
	GetJobFunctionByIdQueryParameters,
	JobFunctionByIdArchiveQueryParameters,
	JobFunctionByIdUnarchiveQueryParameters,
	JobFunctionByIdDeleteQueryParameters,
	JobFunctionByIdRestoreQueryParameters,
	JobFunctionByIdDeletePermanentlyQueryParameters,
	GetCoachByThreadIdQueryParameters,
	GetCoachByThreadIdFileByFileIdQueryParameters,
	GetEmailGlobalBlackListQueryParameters,
	GetEventsStreamQueryParameters,
	GetPlayQueryParameters,
	CreatePlayMoveByPlaybookIdQueryParameters,
	GetPlayByPlayIdMetricsStepTimesByUserQueryParameters,
	GetPlayByIdQueryParameters,
	PlayByIdArchiveQueryParameters,
	PlayByIdUnarchiveQueryParameters,
	PlayByIdDeleteQueryParameters,
	PlayByIdRestoreQueryParameters,
	PlayByIdDeletePermanentlyQueryParameters,
	GetPlayByPlayIdStartDataQueryParameters,
	GetPlaybookQueryParameters,
	GetPlaybookByIdQueryParameters,
	PlaybookByIdArchiveQueryParameters,
	PlaybookByIdUnarchiveQueryParameters,
	PlaybookByIdDeleteQueryParameters,
	PlaybookByIdRestoreQueryParameters,
	PlaybookByIdDeletePermanentlyQueryParameters,
	GetPlayExecutionQueryParameters,
	GetPlayExecutionMetricsQueryParameters,
	GetPlayExecutionByIdQueryParameters,
	DeletePlayExecutionByIdQueryParameters,
	PlayExecutionByIdArchiveQueryParameters,
	PlayExecutionByIdUnarchiveQueryParameters,
	PlayExecutionByIdDeleteQueryParameters,
	PlayExecutionByIdRestoreQueryParameters,
	PlayExecutionByIdDeletePermanentlyQueryParameters,
	GetPlayExecutionByPlayIdStepByStepIdQueryParameters,
	CreatePlayExecutionByPlayIdStepByStepIdQueryParameters,
	GetPlayExecutionByPlayIdStepByStepIdChatQueryParameters,
	CreatePlayExecutionByPlayIdStepByStepIdChatDataQueryParameters,
	UpdatePlayExecutionByPlayIdStepByStepIdReassignQueryParameters,
	CreatePlayExecutionByPlayIdStepByStepIdForceQueryParameters,
	GetPlayExecutionStepsForUserByUserIdQueryParameters,
	CreatePlayExecutionByPlayExecutionIdNextStepQueryParameters,
	GetPlayExecutionByPlayIdReservedNamesQueryParameters,
	CreateRemindersSendStepByStepExecutionIdQueryParameters,
	GetPlayByPlayIdStepsQueryParameters,
	CreatePlayByPlayIdStepsQueryParameters,
	UpdatePlayByPlayIdStepsQueryParameters,
	PlayByPlayIdStepsByIdArchiveQueryParameters,
	PlayByPlayIdStepsBulkArchiveQueryParameters,
	PlayByPlayIdStepsBulkDeleteQueryParameters,
	PlayByPlayIdStepsBulkDeletePermanentlyQueryParameters,
	PlayByPlayIdStepsBulkRestoreQueryParameters,
	PlayByPlayIdStepsBulkUnarchiveQueryParameters,
	PlayByPlayIdStepsByIdDeleteQueryParameters,
	PlayByPlayIdStepsByIdDeletePermanentlyQueryParameters,
	GetPlayByPlayIdStepsByIdQueryParameters,
	PlayByPlayIdStepsByIdRestoreQueryParameters,
	PlayByPlayIdStepsByIdUnarchiveQueryParameters,
	GetPlayByPlayIdExecutionStepQueryParameters,
	UpdatePlayByPlayIdExecutionByStepExecutionIdSnoozeQueryParameters,
	DeletePlayByPlayIdExecutionByStepExecutionIdSnoozeQueryParameters,
	PlayByPlayIdExecutionByIdArchiveQueryParameters,
	PlayByPlayIdExecutionBulkArchiveQueryParameters,
	PlayByPlayIdExecutionBulkDeleteQueryParameters,
	PlayByPlayIdExecutionBulkDeletePermanentlyQueryParameters,
	PlayByPlayIdExecutionBulkRestoreQueryParameters,
	PlayByPlayIdExecutionBulkUnarchiveQueryParameters,
	CreatePlayByPlayIdExecutionQueryParameters,
	UpdatePlayByPlayIdExecutionQueryParameters,
	PlayByPlayIdExecutionByIdDeleteQueryParameters,
	PlayByPlayIdExecutionByIdDeletePermanentlyQueryParameters,
	GetPlayByPlayIdExecutionByIdQueryParameters,
	PlayByPlayIdExecutionByIdRestoreQueryParameters,
	PlayByPlayIdExecutionByIdUnarchiveQueryParameters,
	GetUserQueryParameters,
	GetUserByUserIdJobFunctionsQueryParameters,
	GetUserByUserIdAddableJobFunctionsQueryParameters,
	UpdateUserByUserIdAddQueryParameters,
	UpdateUserByUserIdRemoveQueryParameters,
	CreateUserByUserIdWelcomeQueryParameters,
	GetUserByIdQueryParameters,
	UserByIdArchiveQueryParameters,
	UserByIdUnarchiveQueryParameters,
	UserByIdDeleteQueryParameters,
	UserByIdRestoreQueryParameters,
	UserByIdDeletePermanentlyQueryParameters,
	GetUserAuth0UserIdByIdQueryParameters
} from './generatedParameterTypes';

const buildQueryString = (obj: any, routeParams: string[] = []) => {
	var args = _.clone(obj);
	routeParams.forEach(rp => delete args[rp]);
	return new URLSearchParams(args).toString();
}

const generatedApi = serverApi.injectEndpoints({
	endpoints: (builder) => ({
		createAITestPrompt: builder.mutation<TestPromptResponseDto, TestPromptRequestDto>({
			query: (args) => ({
				url: `/api/AI/testPrompt`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['AI']
		}),
		createAttachment: builder.mutation<FileAttachmentDto, FormData>({
			query: (args) => ({
				url: `/api/Attachment`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Attachment']
		}),
		deleteAttachment: builder.mutation<string, DeleteAttachmentQueryParameters>({
			query: (args) => ({
				url: `/api/Attachment?${buildQueryString(args)}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Attachment']
		}),
		createAttachmentByIdDownload: builder.mutation<DownloadLinkResultDto, CreateAttachmentByIdDownloadQueryParameters>({
			query: (args) => ({
				url: `/api/Attachment/${args.id}/download?${buildQueryString(args, ["id"])}`,
				method: 'POST',
			}),
			invalidatesTags: ['Attachment']
		}),
		createAutoSavePlay: builder.mutation<string, CreateAutoSavePlayQueryParameters>({
			query: (args) => ({
				url: `/api/AutoSavePlay?${buildQueryString(args)}`,
				method: 'POST',
			}),
			invalidatesTags: ['AutoSavePlay']
		}),
		deleteAutoSavePlayByPlayId: builder.mutation<void, DeleteAutoSavePlayByPlayIdQueryParameters>({
			query: (args) => ({
				url: `/api/AutoSavePlay/${args.playId}?${buildQueryString(args, ["playId"])}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['AutoSavePlay']
		}),
		updateAutoSavePlayByPlayId: builder.mutation<void, { params: UpdateAutoSavePlayByPlayIdQueryParameters, payload: UpdateStepRequestDto }>({
			query: (args) => ({
				url: `/api/AutoSavePlay/${args.params.playId}`,
				method: 'PUT',
				body: args.payload
			}),
			invalidatesTags: ['AutoSavePlay']
		}),
		createAutoSavePlayByPlayIdStep: builder.mutation<string, { params: CreateAutoSavePlayByPlayIdStepQueryParameters, payload: CreateStepRequestDto }>({
			query: (args) => ({
				url: `/api/AutoSavePlay/${args.params.playId}/step`,
				method: 'POST',
				body: args.payload
			}),
			invalidatesTags: ['AutoSavePlay']
		}),
		deleteAutoSavePlayByPlayIdStepByStepKey: builder.mutation<void, DeleteAutoSavePlayByPlayIdStepByStepKeyQueryParameters>({
			query: (args) => ({
				url: `/api/AutoSavePlay/${args.playId}/step/${args.stepKey}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['AutoSavePlay']
		}),
		patchAutoSavePlayByPlayIdStepByStepKeyMove: builder.mutation<void, { params: PatchAutoSavePlayByPlayIdStepByStepKeyMoveQueryParameters, payload: PlayCoordinates }>({
			query: (args) => ({
				url: `/api/AutoSavePlay/${args.params.playId}/step/${args.params.stepKey}/move`,
				method: 'PATCH',
				body: args.payload
			}),
			invalidatesTags: ['AutoSavePlay']
		}),
		createAutoSavePlayByPlayIdStepByStepIdDependencyByDependentStepId: builder.mutation<void, CreateAutoSavePlayByPlayIdStepByStepIdDependencyByDependentStepIdQueryParameters>({
			query: (args) => ({
				url: `/api/AutoSavePlay/${args.playId}/step/${args.stepId}/dependency/${args.dependentStepId}`,
				method: 'POST',
			}),
			invalidatesTags: ['AutoSavePlay']
		}),
		deleteAutoSavePlayByPlayIdStepByStepIdDependencyByDependentStepId: builder.mutation<void, DeleteAutoSavePlayByPlayIdStepByStepIdDependencyByDependentStepIdQueryParameters>({
			query: (args) => ({
				url: `/api/AutoSavePlay/${args.playId}/step/${args.stepId}/dependency/${args.dependentStepId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['AutoSavePlay']
		}),
		patchAutoSavePlayDetails: builder.mutation<void, UpdatePlayNameAndDescriptionRequestDto>({
			query: (args) => ({
				url: `/api/AutoSavePlay/details`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['AutoSavePlay']
		}),
		clientByIdArchive: builder.mutation<void, ClientByIdArchiveQueryParameters>({
			query: (args) => ({
				url: `/api/Client/${args.id}/archive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Client']
		}),
		clientBulkArchive: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Client/bulkArchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Client']
		}),
		clientBulkDelete: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Client/bulkDelete`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Client']
		}),
		clientBulkDeletePermanently: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Client/bulkDeletePermanently`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Client']
		}),
		clientBulkRestore: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Client/bulkRestore`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Client']
		}),
		clientBulkUnarchive: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Client/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Client']
		}),
		createClient: builder.mutation<void, ClientDto>({
			query: (args) => ({
				url: `/api/Client`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Client']
		}),
		updateClient: builder.mutation<ClientDto, ClientDto>({
			query: (args) => ({
				url: `/api/Client`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Client']
		}),
		clientByIdDelete: builder.mutation<void, ClientByIdDeleteQueryParameters>({
			query: (args) => ({
				url: `/api/Client/${args.id}/delete`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Client']
		}),
		clientByIdDeletePermanently: builder.mutation<void, ClientByIdDeletePermanentlyQueryParameters>({
			query: (args) => ({
				url: `/api/Client/${args.id}/deletePermanently`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Client']
		}),
		getClientById: builder.query<ClientDto, GetClientByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Client/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Client']
		}),
		getClientCurrent: builder.query<ClientDto, void>({
			query: () => ({
				url: `/api/Client/current`,
				method: 'GET',
			}),
			providesTags: ['Client']
		}),
		clientByIdRestore: builder.mutation<void, ClientByIdRestoreQueryParameters>({
			query: (args) => ({
				url: `/api/Client/${args.id}/restore`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Client']
		}),
		clientByIdUnarchive: builder.mutation<void, ClientByIdUnarchiveQueryParameters>({
			query: (args) => ({
				url: `/api/Client/${args.id}/unarchive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Client']
		}),
		updateClientOpenAIConfig: builder.mutation<void, OpenAIConfigurationDto>({
			query: (args) => ({
				url: `/api/Client/OpenAIConfig`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Client']
		}),
		getClientOpenAIConfig: builder.query<ClientOpenAIConfigurationDetailsDto, void>({
			query: () => ({
				url: `/api/Client/OpenAIConfig`,
				method: 'GET',
			}),
			providesTags: ['Client']
		}),
		getJobFunction: builder.query<QueryResultsDto<ClientJobFunctionDto>, GetJobFunctionQueryParameters>({
			query: (args) => ({
				url: `/api/jobFunction?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['ClientJobFunction']
		}),
		createJobFunction: builder.mutation<ClientJobFunctionDto, ClientJobFunctionDto>({
			query: (args) => ({
				url: `/api/jobFunction`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['ClientJobFunction']
		}),
		updateJobFunction: builder.mutation<void, ClientJobFunctionDto>({
			query: (args) => ({
				url: `/api/jobFunction`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['ClientJobFunction']
		}),
		getJobFunctionByJobFunctionIdUsers: builder.query<QueryResultsDto<UserDto>, GetJobFunctionByJobFunctionIdUsersQueryParameters>({
			query: (args) => ({
				url: `/api/jobFunction/${args.jobFunctionId}/users?${buildQueryString(args, ["jobFunctionId"])}`,
				method: 'GET',
			}),
			providesTags: ['ClientJobFunction']
		}),
		getJobFunctionByJobFunctionIdAddableUsers: builder.query<QueryResultsDto<ClientJobFunctionDto>, GetJobFunctionByJobFunctionIdAddableUsersQueryParameters>({
			query: (args) => ({
				url: `/api/jobFunction/${args.jobFunctionId}/addableUsers?${buildQueryString(args, ["jobFunctionId"])}`,
				method: 'GET',
			}),
			providesTags: ['ClientJobFunction']
		}),
		updateJobFunctionByJobFunctionIdAdd: builder.mutation<void, { params: UpdateJobFunctionByJobFunctionIdAddQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/jobFunction/${args.params.jobFunctionId}/add`,
				method: 'PUT',
				body: args.payload
			}),
			invalidatesTags: ['ClientJobFunction']
		}),
		updateJobFunctionByJobFunctionIdRemove: builder.mutation<void, { params: UpdateJobFunctionByJobFunctionIdRemoveQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/jobFunction/${args.params.jobFunctionId}/remove`,
				method: 'PUT',
				body: args.payload
			}),
			invalidatesTags: ['ClientJobFunction']
		}),
		getJobFunctionById: builder.query<ClientJobFunctionDto, GetJobFunctionByIdQueryParameters>({
			query: (args) => ({
				url: `/api/jobFunction/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['ClientJobFunction']
		}),
		jobFunctionByIdArchive: builder.mutation<void, JobFunctionByIdArchiveQueryParameters>({
			query: (args) => ({
				url: `/api/jobFunction/${args.id}/archive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['ClientJobFunction']
		}),
		jobFunctionBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/jobFunction/bulkArchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['ClientJobFunction']
		}),
		jobFunctionByIdUnarchive: builder.mutation<void, JobFunctionByIdUnarchiveQueryParameters>({
			query: (args) => ({
				url: `/api/jobFunction/${args.id}/unarchive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['ClientJobFunction']
		}),
		jobFunctionBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/jobFunction/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['ClientJobFunction']
		}),
		jobFunctionByIdDelete: builder.mutation<void, JobFunctionByIdDeleteQueryParameters>({
			query: (args) => ({
				url: `/api/jobFunction/${args.id}/delete`,
				method: 'DELETE',
			}),
			invalidatesTags: ['ClientJobFunction']
		}),
		jobFunctionBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/jobFunction/bulkDelete`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['ClientJobFunction']
		}),
		jobFunctionByIdRestore: builder.mutation<void, JobFunctionByIdRestoreQueryParameters>({
			query: (args) => ({
				url: `/api/jobFunction/${args.id}/restore`,
				method: 'PATCH',
			}),
			invalidatesTags: ['ClientJobFunction']
		}),
		jobFunctionBulkRestore: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/jobFunction/bulkRestore`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['ClientJobFunction']
		}),
		jobFunctionByIdDeletePermanently: builder.mutation<void, JobFunctionByIdDeletePermanentlyQueryParameters>({
			query: (args) => ({
				url: `/api/jobFunction/${args.id}/deletePermanently`,
				method: 'DELETE',
			}),
			invalidatesTags: ['ClientJobFunction']
		}),
		jobFunctionBulkDeletePermanently: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/jobFunction/bulkDeletePermanently`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['ClientJobFunction']
		}),
		createCoach: builder.mutation<MessageResponseDto, MessageDto>({
			query: (args) => ({
				url: `/api/Coach`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Coach']
		}),
		getCoachByThreadId: builder.query<MessageResponseDto[], GetCoachByThreadIdQueryParameters>({
			query: (args) => ({
				url: `/api/Coach/${args.threadId}`,
				method: 'GET',
			}),
			providesTags: ['Coach']
		}),
		getCoachByThreadIdFileByFileId: builder.query<void, GetCoachByThreadIdFileByFileIdQueryParameters>({
			query: (args) => ({
				url: `/api/Coach/${args.threadId}/file/${args.fileId}`,
				method: 'GET',
			}),
			providesTags: ['Coach']
		}),
		createEmailGlobalBlackList: builder.mutation<void, SuppressionRequestDto>({
			query: (args) => ({
				url: `/api/Email/globalBlackList`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Email']
		}),
		deleteEmailGlobalBlackList: builder.mutation<void, SuppressionRequestDto>({
			query: (args) => ({
				url: `/api/Email/globalBlackList`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Email']
		}),
		getEmailGlobalBlackList: builder.query<boolean, GetEmailGlobalBlackListQueryParameters>({
			query: (args) => ({
				url: `/api/Email/globalBlackList?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Email']
		}),
		getEventsStream: builder.query<EventWrapper[], GetEventsStreamQueryParameters>({
			query: (args) => ({
				url: `/api/Events/stream?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Events']
		}),
		getNotificationCount: builder.query<number, void>({
			query: () => ({
				url: `/api/Notification/count`,
				method: 'GET',
			}),
			providesTags: ['Notification']
		}),
		getNotificationMyTodo: builder.query<TodoListNotificationDto[], void>({
			query: () => ({
				url: `/api/Notification/myTodo`,
				method: 'GET',
			}),
			providesTags: ['Notification']
		}),
		createNotificationDailyDigest: builder.mutation<void, void>({
			query: () => ({
				url: `/api/Notification/dailyDigest`,
				method: 'POST',
			}),
			invalidatesTags: ['Notification']
		}),
		getPlay: builder.query<QueryResultsDto<PlayDto>, GetPlayQueryParameters>({
			query: (args) => ({
				url: `/api/Play?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Play']
		}),
		createPlay: builder.mutation<PlayDto, PlayDto>({
			query: (args) => ({
				url: `/api/Play`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Play','PlayExecution','Playbook']
		}),
		updatePlay: builder.mutation<void, PlayDto>({
			query: (args) => ({
				url: `/api/Play`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Play','PlayExecution','Playbook']
		}),
		createPlayMoveByPlaybookId: builder.mutation<PlayDto, { params: CreatePlayMoveByPlaybookIdQueryParameters, payload: PlayDto }>({
			query: (args) => ({
				url: `/api/Play/move/${args.params.playbookId}`,
				method: 'POST',
				body: args.payload
			}),
			invalidatesTags: ['Play','PlayExecution','Playbook']
		}),
		getPlayByPlayIdMetricsStepTimesByUser: builder.query<PlayExecutionHistoryAggregateDto, GetPlayByPlayIdMetricsStepTimesByUserQueryParameters>({
			query: (args) => ({
				url: `/api/Play/${args.playId}/metrics/stepTimesByUser`,
				method: 'GET',
			}),
			providesTags: ['Play']
		}),
		getPlayById: builder.query<PlayDto, GetPlayByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Play/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Play']
		}),
		createPlayClone: builder.mutation<PlayDto, PlayDto>({
			query: (args) => ({
				url: `/api/Play/clone`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Play','PlayExecution','Playbook']
		}),
		playByIdArchive: builder.mutation<void, PlayByIdArchiveQueryParameters>({
			query: (args) => ({
				url: `/api/Play/${args.id}/archive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Play','PlayExecution','Playbook']
		}),
		playBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Play/bulkArchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Play','PlayExecution','Playbook']
		}),
		playByIdUnarchive: builder.mutation<void, PlayByIdUnarchiveQueryParameters>({
			query: (args) => ({
				url: `/api/Play/${args.id}/unarchive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Play','PlayExecution','Playbook']
		}),
		playBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Play/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Play','PlayExecution','Playbook']
		}),
		playByIdDelete: builder.mutation<void, PlayByIdDeleteQueryParameters>({
			query: (args) => ({
				url: `/api/Play/${args.id}/delete`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Play','PlayExecution','Playbook']
		}),
		playBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Play/bulkDelete`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Play','PlayExecution','Playbook']
		}),
		playByIdRestore: builder.mutation<void, PlayByIdRestoreQueryParameters>({
			query: (args) => ({
				url: `/api/Play/${args.id}/restore`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Play','PlayExecution','Playbook']
		}),
		playBulkRestore: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Play/bulkRestore`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Play','PlayExecution','Playbook']
		}),
		playByIdDeletePermanently: builder.mutation<void, PlayByIdDeletePermanentlyQueryParameters>({
			query: (args) => ({
				url: `/api/Play/${args.id}/deletePermanently`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Play','PlayExecution','Playbook']
		}),
		playBulkDeletePermanently: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Play/bulkDeletePermanently`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Play','PlayExecution','Playbook']
		}),
		createPlayExecute: builder.mutation<string, StartPlayDto>({
			query: (args) => ({
				url: `/api/Play/execute`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Play','PlayExecution','Playbook']
		}),
		getPlayByPlayIdStartData: builder.query<PlayStartInputDto, GetPlayByPlayIdStartDataQueryParameters>({
			query: (args) => ({
				url: `/api/Play/${args.playId}/startData`,
				method: 'GET',
			}),
			providesTags: ['Play']
		}),
		getPlaybook: builder.query<QueryResultsDto<PlaybookDto>, GetPlaybookQueryParameters>({
			query: (args) => ({
				url: `/api/Playbook?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Playbook']
		}),
		createPlaybook: builder.mutation<PlaybookDto, PlaybookDto>({
			query: (args) => ({
				url: `/api/Playbook`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Playbook']
		}),
		updatePlaybook: builder.mutation<void, PlaybookDto>({
			query: (args) => ({
				url: `/api/Playbook`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Playbook']
		}),
		getPlaybookById: builder.query<PlaybookDto, GetPlaybookByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Playbook/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Playbook']
		}),
		playbookByIdArchive: builder.mutation<void, PlaybookByIdArchiveQueryParameters>({
			query: (args) => ({
				url: `/api/Playbook/${args.id}/archive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Playbook']
		}),
		playbookBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Playbook/bulkArchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Playbook']
		}),
		playbookByIdUnarchive: builder.mutation<void, PlaybookByIdUnarchiveQueryParameters>({
			query: (args) => ({
				url: `/api/Playbook/${args.id}/unarchive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Playbook']
		}),
		playbookBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Playbook/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Playbook']
		}),
		playbookByIdDelete: builder.mutation<void, PlaybookByIdDeleteQueryParameters>({
			query: (args) => ({
				url: `/api/Playbook/${args.id}/delete`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Playbook']
		}),
		playbookBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Playbook/bulkDelete`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Playbook']
		}),
		playbookByIdRestore: builder.mutation<void, PlaybookByIdRestoreQueryParameters>({
			query: (args) => ({
				url: `/api/Playbook/${args.id}/restore`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Playbook']
		}),
		playbookBulkRestore: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Playbook/bulkRestore`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Playbook']
		}),
		playbookByIdDeletePermanently: builder.mutation<void, PlaybookByIdDeletePermanentlyQueryParameters>({
			query: (args) => ({
				url: `/api/Playbook/${args.id}/deletePermanently`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Playbook']
		}),
		playbookBulkDeletePermanently: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Playbook/bulkDeletePermanently`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Playbook']
		}),
		getPlayExecution: builder.query<QueryResultsDto<PlayExecutionDto>, GetPlayExecutionQueryParameters>({
			query: (args) => ({
				url: `/api/PlayExecution?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['PlayExecution']
		}),
		createPlayExecution: builder.mutation<PlayExecutionDto, PlayExecutionDto>({
			query: (args) => ({
				url: `/api/PlayExecution`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		updatePlayExecution: builder.mutation<void, PlayExecutionDto>({
			query: (args) => ({
				url: `/api/PlayExecution`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		getPlayExecutionMetrics: builder.query<QueryResultsDto<PlayExecutionDto>, GetPlayExecutionMetricsQueryParameters>({
			query: (args) => ({
				url: `/api/PlayExecution/metrics?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['PlayExecution']
		}),
		getPlayExecutionById: builder.query<PlayExecutionDto, GetPlayExecutionByIdQueryParameters>({
			query: (args) => ({
				url: `/api/PlayExecution/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['PlayExecution']
		}),
		deletePlayExecutionById: builder.mutation<void, DeletePlayExecutionByIdQueryParameters>({
			query: (args) => ({
				url: `/api/PlayExecution/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		playExecutionByIdArchive: builder.mutation<void, PlayExecutionByIdArchiveQueryParameters>({
			query: (args) => ({
				url: `/api/PlayExecution/${args.id}/archive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		playExecutionBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/PlayExecution/bulkArchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		playExecutionByIdUnarchive: builder.mutation<void, PlayExecutionByIdUnarchiveQueryParameters>({
			query: (args) => ({
				url: `/api/PlayExecution/${args.id}/unarchive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		playExecutionBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/PlayExecution/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		playExecutionByIdDelete: builder.mutation<void, PlayExecutionByIdDeleteQueryParameters>({
			query: (args) => ({
				url: `/api/PlayExecution/${args.id}/delete`,
				method: 'DELETE',
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		playExecutionBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/PlayExecution/bulkDelete`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		playExecutionByIdRestore: builder.mutation<void, PlayExecutionByIdRestoreQueryParameters>({
			query: (args) => ({
				url: `/api/PlayExecution/${args.id}/restore`,
				method: 'PATCH',
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		playExecutionBulkRestore: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/PlayExecution/bulkRestore`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		playExecutionByIdDeletePermanently: builder.mutation<void, PlayExecutionByIdDeletePermanentlyQueryParameters>({
			query: (args) => ({
				url: `/api/PlayExecution/${args.id}/deletePermanently`,
				method: 'DELETE',
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		playExecutionBulkDeletePermanently: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/PlayExecution/bulkDeletePermanently`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		createPlayExecutionBulkCancel: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/PlayExecution/bulkCancel`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		getPlayExecutionByPlayIdStepByStepId: builder.query<StepExecutionDto, GetPlayExecutionByPlayIdStepByStepIdQueryParameters>({
			query: (args) => ({
				url: `/api/PlayExecution/${args.playId}/step/${args.stepId}`,
				method: 'GET',
			}),
			providesTags: ['PlayExecution']
		}),
		createPlayExecutionByPlayIdStepByStepId: builder.mutation<StepExecutionDto, { params: CreatePlayExecutionByPlayIdStepByStepIdQueryParameters, payload: StepExecutionDto }>({
			query: (args) => ({
				url: `/api/PlayExecution/${args.params.playId}/step/${args.params.stepId}`,
				method: 'POST',
				body: args.payload
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		getPlayExecutionByPlayIdStepByStepIdChat: builder.query<MessageResponseDto[], GetPlayExecutionByPlayIdStepByStepIdChatQueryParameters>({
			query: (args) => ({
				url: `/api/PlayExecution/${args.playId}/step/${args.stepId}/chat`,
				method: 'GET',
			}),
			providesTags: ['PlayExecution']
		}),
		createPlayExecutionByPlayIdStepByStepIdChatData: builder.mutation<StepExecutionMessageResponseDto, { params: CreatePlayExecutionByPlayIdStepByStepIdChatDataQueryParameters, payload: MessageDto }>({
			query: (args) => ({
				url: `/api/PlayExecution/${args.params.playId}/step/${args.params.stepId}/chat/data`,
				method: 'POST',
				body: args.payload
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		updatePlayExecutionByPlayIdStepByStepIdReassign: builder.mutation<void, { params: UpdatePlayExecutionByPlayIdStepByStepIdReassignQueryParameters, payload: ReassignStepPayloadDto }>({
			query: (args) => ({
				url: `/api/PlayExecution/${args.params.playId}/step/${args.params.stepId}/reassign`,
				method: 'PUT',
				body: args.payload
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		createPlayExecutionComplete: builder.mutation<BulkUpdateResultDto, void>({
			query: () => ({
				url: `/api/PlayExecution/complete`,
				method: 'POST',
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		createPlayExecutionByPlayIdStepByStepIdForce: builder.mutation<StepExecutionDto, { params: CreatePlayExecutionByPlayIdStepByStepIdForceQueryParameters, payload: StepExecutionDto }>({
			query: (args) => ({
				url: `/api/PlayExecution/${args.params.playId}/step/${args.params.stepId}/force`,
				method: 'POST',
				body: args.payload
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		getPlayExecutionStepsForUserByUserId: builder.query<QueryResultsDto<StepAssignmentDto>, GetPlayExecutionStepsForUserByUserIdQueryParameters>({
			query: (args) => ({
				url: `/api/PlayExecution/stepsForUser/${args.userId}?${buildQueryString(args, ["userId"])}`,
				method: 'GET',
			}),
			providesTags: ['PlayExecution']
		}),
		createPlayExecutionByPlayExecutionIdNextStep: builder.mutation<StepAssignmentDto, CreatePlayExecutionByPlayExecutionIdNextStepQueryParameters>({
			query: (args) => ({
				url: `/api/PlayExecution/${args.playExecutionId}/nextStep`,
				method: 'POST',
			}),
			invalidatesTags: ['PlayExecution','Notification']
		}),
		getPlayExecutionByPlayIdReservedNames: builder.query<string[], GetPlayExecutionByPlayIdReservedNamesQueryParameters>({
			query: (args) => ({
				url: `/api/PlayExecution/${args.playId}/reservedNames`,
				method: 'GET',
			}),
			providesTags: ['PlayExecution']
		}),
		createRemindersSend: builder.mutation<BulkUpdateResultDto, void>({
			query: () => ({
				url: `/api/Reminders/send`,
				method: 'POST',
			}),
			invalidatesTags: ['Reminders']
		}),
		createRemindersSendStepByStepExecutionId: builder.mutation<BulkUpdateResultDto, CreateRemindersSendStepByStepExecutionIdQueryParameters>({
			query: (args) => ({
				url: `/api/Reminders/send/step/${args.stepExecutionId}`,
				method: 'POST',
			}),
			invalidatesTags: ['Reminders']
		}),
		getPlayByPlayIdSteps: builder.query<QueryResultsDto<StepDto>, GetPlayByPlayIdStepsQueryParameters>({
			query: (args) => ({
				url: `/api/play/${args.playId}/steps?${buildQueryString(args, ["playId"])}`,
				method: 'GET',
			}),
			providesTags: ['Step']
		}),
		createPlayByPlayIdSteps: builder.mutation<void, { params: CreatePlayByPlayIdStepsQueryParameters, payload: StepDto }>({
			query: (args) => ({
				url: `/api/play/${args.params.playId}/steps`,
				method: 'POST',
				body: args.payload
			}),
			invalidatesTags: ['Step']
		}),
		updatePlayByPlayIdSteps: builder.mutation<void, { params: UpdatePlayByPlayIdStepsQueryParameters, payload: StepDto }>({
			query: (args) => ({
				url: `/api/play/${args.params.playId}/steps`,
				method: 'PUT',
				body: args.payload
			}),
			invalidatesTags: ['Step']
		}),
		playByPlayIdStepsByIdArchive: builder.mutation<void, PlayByPlayIdStepsByIdArchiveQueryParameters>({
			query: (args) => ({
				url: `/api/play/${args.playId}/steps/${args.id}/archive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Step']
		}),
		playByPlayIdStepsBulkArchive: builder.mutation<void, { params: PlayByPlayIdStepsBulkArchiveQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/play/${args.params.playId}/steps/bulkArchive`,
				method: 'PATCH',
				body: args.payload
			}),
			invalidatesTags: ['Step']
		}),
		playByPlayIdStepsBulkDelete: builder.mutation<void, { params: PlayByPlayIdStepsBulkDeleteQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/play/${args.params.playId}/steps/bulkDelete`,
				method: 'PATCH',
				body: args.payload
			}),
			invalidatesTags: ['Step']
		}),
		playByPlayIdStepsBulkDeletePermanently: builder.mutation<void, { params: PlayByPlayIdStepsBulkDeletePermanentlyQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/play/${args.params.playId}/steps/bulkDeletePermanently`,
				method: 'PATCH',
				body: args.payload
			}),
			invalidatesTags: ['Step']
		}),
		playByPlayIdStepsBulkRestore: builder.mutation<void, { params: PlayByPlayIdStepsBulkRestoreQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/play/${args.params.playId}/steps/bulkRestore`,
				method: 'PATCH',
				body: args.payload
			}),
			invalidatesTags: ['Step']
		}),
		playByPlayIdStepsBulkUnarchive: builder.mutation<void, { params: PlayByPlayIdStepsBulkUnarchiveQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/play/${args.params.playId}/steps/bulkUnarchive`,
				method: 'PATCH',
				body: args.payload
			}),
			invalidatesTags: ['Step']
		}),
		playByPlayIdStepsByIdDelete: builder.mutation<void, PlayByPlayIdStepsByIdDeleteQueryParameters>({
			query: (args) => ({
				url: `/api/play/${args.playId}/steps/${args.id}/delete`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Step']
		}),
		playByPlayIdStepsByIdDeletePermanently: builder.mutation<void, PlayByPlayIdStepsByIdDeletePermanentlyQueryParameters>({
			query: (args) => ({
				url: `/api/play/${args.playId}/steps/${args.id}/deletePermanently`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Step']
		}),
		getPlayByPlayIdStepsById: builder.query<void, GetPlayByPlayIdStepsByIdQueryParameters>({
			query: (args) => ({
				url: `/api/play/${args.playId}/steps/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Step']
		}),
		playByPlayIdStepsByIdRestore: builder.mutation<void, PlayByPlayIdStepsByIdRestoreQueryParameters>({
			query: (args) => ({
				url: `/api/play/${args.playId}/steps/${args.id}/restore`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Step']
		}),
		playByPlayIdStepsByIdUnarchive: builder.mutation<void, PlayByPlayIdStepsByIdUnarchiveQueryParameters>({
			query: (args) => ({
				url: `/api/play/${args.playId}/steps/${args.id}/unarchive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Step']
		}),
		getPlayByPlayIdExecutionStep: builder.query<QueryResultsDto<StepExecutionDto>, GetPlayByPlayIdExecutionStepQueryParameters>({
			query: (args) => ({
				url: `/api/play/${args.playId}/execution/step?${buildQueryString(args, ["playId"])}`,
				method: 'GET',
			}),
			providesTags: ['StepExecution']
		}),
		updatePlayByPlayIdExecutionByStepExecutionIdSnooze: builder.mutation<void, { params: UpdatePlayByPlayIdExecutionByStepExecutionIdSnoozeQueryParameters, payload: StepExecutionSnoozeDto }>({
			query: (args) => ({
				url: `/api/play/${args.params.playId}/execution/${args.params.stepExecutionId}/snooze`,
				method: 'PUT',
				body: args.payload
			}),
			invalidatesTags: ['StepExecution','PlayExecution','Step','Notification']
		}),
		deletePlayByPlayIdExecutionByStepExecutionIdSnooze: builder.mutation<void, DeletePlayByPlayIdExecutionByStepExecutionIdSnoozeQueryParameters>({
			query: (args) => ({
				url: `/api/play/${args.playId}/execution/${args.stepExecutionId}/snooze`,
				method: 'DELETE',
			}),
			invalidatesTags: ['StepExecution','PlayExecution','Step','Notification']
		}),
		playByPlayIdExecutionByIdArchive: builder.mutation<void, PlayByPlayIdExecutionByIdArchiveQueryParameters>({
			query: (args) => ({
				url: `/api/play/${args.playId}/execution/${args.id}/archive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['StepExecution','PlayExecution','Step','Notification']
		}),
		playByPlayIdExecutionBulkArchive: builder.mutation<BulkUpdateResultDto, { params: PlayByPlayIdExecutionBulkArchiveQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/play/${args.params.playId}/execution/bulkArchive`,
				method: 'PATCH',
				body: args.payload
			}),
			invalidatesTags: ['StepExecution','PlayExecution','Step','Notification']
		}),
		playByPlayIdExecutionBulkDelete: builder.mutation<BulkUpdateResultDto, { params: PlayByPlayIdExecutionBulkDeleteQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/play/${args.params.playId}/execution/bulkDelete`,
				method: 'PATCH',
				body: args.payload
			}),
			invalidatesTags: ['StepExecution','PlayExecution','Step','Notification']
		}),
		playByPlayIdExecutionBulkDeletePermanently: builder.mutation<BulkUpdateResultDto, { params: PlayByPlayIdExecutionBulkDeletePermanentlyQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/play/${args.params.playId}/execution/bulkDeletePermanently`,
				method: 'PATCH',
				body: args.payload
			}),
			invalidatesTags: ['StepExecution','PlayExecution','Step','Notification']
		}),
		playByPlayIdExecutionBulkRestore: builder.mutation<BulkUpdateResultDto, { params: PlayByPlayIdExecutionBulkRestoreQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/play/${args.params.playId}/execution/bulkRestore`,
				method: 'PATCH',
				body: args.payload
			}),
			invalidatesTags: ['StepExecution','PlayExecution','Step','Notification']
		}),
		playByPlayIdExecutionBulkUnarchive: builder.mutation<BulkUpdateResultDto, { params: PlayByPlayIdExecutionBulkUnarchiveQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/play/${args.params.playId}/execution/bulkUnarchive`,
				method: 'PATCH',
				body: args.payload
			}),
			invalidatesTags: ['StepExecution','PlayExecution','Step','Notification']
		}),
		createPlayByPlayIdExecution: builder.mutation<PlayExecutionDto, { params: CreatePlayByPlayIdExecutionQueryParameters, payload: StepExecutionDto }>({
			query: (args) => ({
				url: `/api/play/${args.params.playId}/execution`,
				method: 'POST',
				body: args.payload
			}),
			invalidatesTags: ['StepExecution','PlayExecution','Step','Notification']
		}),
		updatePlayByPlayIdExecution: builder.mutation<void, { params: UpdatePlayByPlayIdExecutionQueryParameters, payload: StepExecutionDto }>({
			query: (args) => ({
				url: `/api/play/${args.params.playId}/execution`,
				method: 'PUT',
				body: args.payload
			}),
			invalidatesTags: ['StepExecution','PlayExecution','Step','Notification']
		}),
		playByPlayIdExecutionByIdDelete: builder.mutation<void, PlayByPlayIdExecutionByIdDeleteQueryParameters>({
			query: (args) => ({
				url: `/api/play/${args.playId}/execution/${args.id}/delete`,
				method: 'DELETE',
			}),
			invalidatesTags: ['StepExecution','PlayExecution','Step','Notification']
		}),
		playByPlayIdExecutionByIdDeletePermanently: builder.mutation<void, PlayByPlayIdExecutionByIdDeletePermanentlyQueryParameters>({
			query: (args) => ({
				url: `/api/play/${args.playId}/execution/${args.id}/deletePermanently`,
				method: 'DELETE',
			}),
			invalidatesTags: ['StepExecution','PlayExecution','Step','Notification']
		}),
		getPlayByPlayIdExecutionById: builder.query<PlayExecutionDto, GetPlayByPlayIdExecutionByIdQueryParameters>({
			query: (args) => ({
				url: `/api/play/${args.playId}/execution/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['StepExecution']
		}),
		playByPlayIdExecutionByIdRestore: builder.mutation<void, PlayByPlayIdExecutionByIdRestoreQueryParameters>({
			query: (args) => ({
				url: `/api/play/${args.playId}/execution/${args.id}/restore`,
				method: 'PATCH',
			}),
			invalidatesTags: ['StepExecution','PlayExecution','Step','Notification']
		}),
		playByPlayIdExecutionByIdUnarchive: builder.mutation<void, PlayByPlayIdExecutionByIdUnarchiveQueryParameters>({
			query: (args) => ({
				url: `/api/play/${args.playId}/execution/${args.id}/unarchive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['StepExecution','PlayExecution','Step','Notification']
		}),
		getUser: builder.query<QueryResultsDto<UserDto>, GetUserQueryParameters>({
			query: (args) => ({
				url: `/api/User?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		createUser: builder.mutation<UserDto, UserDto>({
			query: (args) => ({
				url: `/api/User`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		updateUser: builder.mutation<void, UserDto>({
			query: (args) => ({
				url: `/api/User`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		getUserByUserIdJobFunctions: builder.query<QueryResultsDto<ClientJobFunctionDto>, GetUserByUserIdJobFunctionsQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.userId}/jobFunctions?${buildQueryString(args, ["userId"])}`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		getUserByUserIdAddableJobFunctions: builder.query<QueryResultsDto<ClientJobFunctionDto>, GetUserByUserIdAddableJobFunctionsQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.userId}/addableJobFunctions?${buildQueryString(args, ["userId"])}`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		updateUserByUserIdAdd: builder.mutation<BulkUpdateResultDto, { params: UpdateUserByUserIdAddQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/User/${args.params.userId}/add`,
				method: 'PUT',
				body: args.payload
			}),
			invalidatesTags: ['User']
		}),
		updateUserByUserIdRemove: builder.mutation<BulkUpdateResultDto, { params: UpdateUserByUserIdRemoveQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/User/${args.params.userId}/remove`,
				method: 'PUT',
				body: args.payload
			}),
			invalidatesTags: ['User']
		}),
		createUserByUserIdWelcome: builder.mutation<void, CreateUserByUserIdWelcomeQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.userId}/welcome`,
				method: 'POST',
			}),
			invalidatesTags: ['User']
		}),
		getUserById: builder.query<UserDto, GetUserByIdQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		userByIdArchive: builder.mutation<void, UserByIdArchiveQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.id}/archive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['User']
		}),
		userBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/User/bulkArchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		userByIdUnarchive: builder.mutation<void, UserByIdUnarchiveQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.id}/unarchive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['User']
		}),
		userBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/User/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		userByIdDelete: builder.mutation<void, UserByIdDeleteQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.id}/delete`,
				method: 'DELETE',
			}),
			invalidatesTags: ['User']
		}),
		userBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/User/bulkDelete`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		userByIdRestore: builder.mutation<void, UserByIdRestoreQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.id}/restore`,
				method: 'PATCH',
			}),
			invalidatesTags: ['User']
		}),
		userBulkRestore: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/User/bulkRestore`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		userByIdDeletePermanently: builder.mutation<void, UserByIdDeletePermanentlyQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.id}/deletePermanently`,
				method: 'DELETE',
			}),
			invalidatesTags: ['User']
		}),
		userBulkDeletePermanently: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/User/bulkDeletePermanently`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		getUserAuth0UserIdById: builder.query<UserDto, GetUserAuth0UserIdByIdQueryParameters>({
			query: (args) => ({
				url: `/api/User/auth0UserId/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		getUserPermissions: builder.query<string[], void>({
			query: () => ({
				url: `/api/User/permissions`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		getUserRoles: builder.query<UserRoleDto[], void>({
			query: () => ({
				url: `/api/User/roles`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		getUserSlackConfig: builder.query<UserSlackConfigDto, void>({
			query: () => ({
				url: `/api/User/slackConfig`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		updateUserSlackConfig: builder.mutation<void, UserSlackConfigDto>({
			query: (args) => ({
				url: `/api/User/slackConfig`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		getUserNotificationConfig: builder.query<UserNotificationConfigDto, void>({
			query: () => ({
				url: `/api/User/notificationConfig`,
				method: 'GET',
			}),
			providesTags: ['User']
		}),
		updateUserNotificationConfig: builder.mutation<void, UserNotificationConfigDto>({
			query: (args) => ({
				url: `/api/User/notificationConfig`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		deleteUserSlackAccount: builder.mutation<void, void>({
			query: () => ({
				url: `/api/User/slackAccount`,
				method: 'DELETE',
			}),
			invalidatesTags: ['User']
		}),
	})
});

export const {
	useCreateAITestPromptMutation,
	useCreateAttachmentMutation,
	useDeleteAttachmentMutation,
	useCreateAttachmentByIdDownloadMutation,
	useCreateAutoSavePlayMutation,
	useDeleteAutoSavePlayByPlayIdMutation,
	useUpdateAutoSavePlayByPlayIdMutation,
	useCreateAutoSavePlayByPlayIdStepMutation,
	useDeleteAutoSavePlayByPlayIdStepByStepKeyMutation,
	usePatchAutoSavePlayByPlayIdStepByStepKeyMoveMutation,
	useCreateAutoSavePlayByPlayIdStepByStepIdDependencyByDependentStepIdMutation,
	useDeleteAutoSavePlayByPlayIdStepByStepIdDependencyByDependentStepIdMutation,
	usePatchAutoSavePlayDetailsMutation,
	useClientByIdArchiveMutation,
	useClientBulkArchiveMutation,
	useClientBulkDeleteMutation,
	useClientBulkDeletePermanentlyMutation,
	useClientBulkRestoreMutation,
	useClientBulkUnarchiveMutation,
	useCreateClientMutation,
	useUpdateClientMutation,
	useClientByIdDeleteMutation,
	useClientByIdDeletePermanentlyMutation,
	useGetClientByIdQuery,
	useLazyGetClientByIdQuery,
	useGetClientCurrentQuery,
	useLazyGetClientCurrentQuery,
	useClientByIdRestoreMutation,
	useClientByIdUnarchiveMutation,
	useUpdateClientOpenAIConfigMutation,
	useGetClientOpenAIConfigQuery,
	useLazyGetClientOpenAIConfigQuery,
	useGetJobFunctionQuery,
	useLazyGetJobFunctionQuery,
	useCreateJobFunctionMutation,
	useUpdateJobFunctionMutation,
	useGetJobFunctionByJobFunctionIdUsersQuery,
	useLazyGetJobFunctionByJobFunctionIdUsersQuery,
	useGetJobFunctionByJobFunctionIdAddableUsersQuery,
	useLazyGetJobFunctionByJobFunctionIdAddableUsersQuery,
	useUpdateJobFunctionByJobFunctionIdAddMutation,
	useUpdateJobFunctionByJobFunctionIdRemoveMutation,
	useGetJobFunctionByIdQuery,
	useLazyGetJobFunctionByIdQuery,
	useJobFunctionByIdArchiveMutation,
	useJobFunctionBulkArchiveMutation,
	useJobFunctionByIdUnarchiveMutation,
	useJobFunctionBulkUnarchiveMutation,
	useJobFunctionByIdDeleteMutation,
	useJobFunctionBulkDeleteMutation,
	useJobFunctionByIdRestoreMutation,
	useJobFunctionBulkRestoreMutation,
	useJobFunctionByIdDeletePermanentlyMutation,
	useJobFunctionBulkDeletePermanentlyMutation,
	useCreateCoachMutation,
	useGetCoachByThreadIdQuery,
	useLazyGetCoachByThreadIdQuery,
	useGetCoachByThreadIdFileByFileIdQuery,
	useLazyGetCoachByThreadIdFileByFileIdQuery,
	useCreateEmailGlobalBlackListMutation,
	useDeleteEmailGlobalBlackListMutation,
	useGetEmailGlobalBlackListQuery,
	useLazyGetEmailGlobalBlackListQuery,
	useGetEventsStreamQuery,
	useLazyGetEventsStreamQuery,
	useGetNotificationCountQuery,
	useLazyGetNotificationCountQuery,
	useGetNotificationMyTodoQuery,
	useLazyGetNotificationMyTodoQuery,
	useCreateNotificationDailyDigestMutation,
	useGetPlayQuery,
	useLazyGetPlayQuery,
	useCreatePlayMutation,
	useUpdatePlayMutation,
	useCreatePlayMoveByPlaybookIdMutation,
	useGetPlayByPlayIdMetricsStepTimesByUserQuery,
	useLazyGetPlayByPlayIdMetricsStepTimesByUserQuery,
	useGetPlayByIdQuery,
	useLazyGetPlayByIdQuery,
	useCreatePlayCloneMutation,
	usePlayByIdArchiveMutation,
	usePlayBulkArchiveMutation,
	usePlayByIdUnarchiveMutation,
	usePlayBulkUnarchiveMutation,
	usePlayByIdDeleteMutation,
	usePlayBulkDeleteMutation,
	usePlayByIdRestoreMutation,
	usePlayBulkRestoreMutation,
	usePlayByIdDeletePermanentlyMutation,
	usePlayBulkDeletePermanentlyMutation,
	useCreatePlayExecuteMutation,
	useGetPlayByPlayIdStartDataQuery,
	useLazyGetPlayByPlayIdStartDataQuery,
	useGetPlaybookQuery,
	useLazyGetPlaybookQuery,
	useCreatePlaybookMutation,
	useUpdatePlaybookMutation,
	useGetPlaybookByIdQuery,
	useLazyGetPlaybookByIdQuery,
	usePlaybookByIdArchiveMutation,
	usePlaybookBulkArchiveMutation,
	usePlaybookByIdUnarchiveMutation,
	usePlaybookBulkUnarchiveMutation,
	usePlaybookByIdDeleteMutation,
	usePlaybookBulkDeleteMutation,
	usePlaybookByIdRestoreMutation,
	usePlaybookBulkRestoreMutation,
	usePlaybookByIdDeletePermanentlyMutation,
	usePlaybookBulkDeletePermanentlyMutation,
	useGetPlayExecutionQuery,
	useLazyGetPlayExecutionQuery,
	useCreatePlayExecutionMutation,
	useUpdatePlayExecutionMutation,
	useGetPlayExecutionMetricsQuery,
	useLazyGetPlayExecutionMetricsQuery,
	useGetPlayExecutionByIdQuery,
	useLazyGetPlayExecutionByIdQuery,
	useDeletePlayExecutionByIdMutation,
	usePlayExecutionByIdArchiveMutation,
	usePlayExecutionBulkArchiveMutation,
	usePlayExecutionByIdUnarchiveMutation,
	usePlayExecutionBulkUnarchiveMutation,
	usePlayExecutionByIdDeleteMutation,
	usePlayExecutionBulkDeleteMutation,
	usePlayExecutionByIdRestoreMutation,
	usePlayExecutionBulkRestoreMutation,
	usePlayExecutionByIdDeletePermanentlyMutation,
	usePlayExecutionBulkDeletePermanentlyMutation,
	useCreatePlayExecutionBulkCancelMutation,
	useGetPlayExecutionByPlayIdStepByStepIdQuery,
	useLazyGetPlayExecutionByPlayIdStepByStepIdQuery,
	useCreatePlayExecutionByPlayIdStepByStepIdMutation,
	useGetPlayExecutionByPlayIdStepByStepIdChatQuery,
	useLazyGetPlayExecutionByPlayIdStepByStepIdChatQuery,
	useCreatePlayExecutionByPlayIdStepByStepIdChatDataMutation,
	useUpdatePlayExecutionByPlayIdStepByStepIdReassignMutation,
	useCreatePlayExecutionCompleteMutation,
	useCreatePlayExecutionByPlayIdStepByStepIdForceMutation,
	useGetPlayExecutionStepsForUserByUserIdQuery,
	useLazyGetPlayExecutionStepsForUserByUserIdQuery,
	useCreatePlayExecutionByPlayExecutionIdNextStepMutation,
	useGetPlayExecutionByPlayIdReservedNamesQuery,
	useLazyGetPlayExecutionByPlayIdReservedNamesQuery,
	useCreateRemindersSendMutation,
	useCreateRemindersSendStepByStepExecutionIdMutation,
	useGetPlayByPlayIdStepsQuery,
	useLazyGetPlayByPlayIdStepsQuery,
	useCreatePlayByPlayIdStepsMutation,
	useUpdatePlayByPlayIdStepsMutation,
	usePlayByPlayIdStepsByIdArchiveMutation,
	usePlayByPlayIdStepsBulkArchiveMutation,
	usePlayByPlayIdStepsBulkDeleteMutation,
	usePlayByPlayIdStepsBulkDeletePermanentlyMutation,
	usePlayByPlayIdStepsBulkRestoreMutation,
	usePlayByPlayIdStepsBulkUnarchiveMutation,
	usePlayByPlayIdStepsByIdDeleteMutation,
	usePlayByPlayIdStepsByIdDeletePermanentlyMutation,
	useGetPlayByPlayIdStepsByIdQuery,
	useLazyGetPlayByPlayIdStepsByIdQuery,
	usePlayByPlayIdStepsByIdRestoreMutation,
	usePlayByPlayIdStepsByIdUnarchiveMutation,
	useGetPlayByPlayIdExecutionStepQuery,
	useLazyGetPlayByPlayIdExecutionStepQuery,
	useUpdatePlayByPlayIdExecutionByStepExecutionIdSnoozeMutation,
	useDeletePlayByPlayIdExecutionByStepExecutionIdSnoozeMutation,
	usePlayByPlayIdExecutionByIdArchiveMutation,
	usePlayByPlayIdExecutionBulkArchiveMutation,
	usePlayByPlayIdExecutionBulkDeleteMutation,
	usePlayByPlayIdExecutionBulkDeletePermanentlyMutation,
	usePlayByPlayIdExecutionBulkRestoreMutation,
	usePlayByPlayIdExecutionBulkUnarchiveMutation,
	useCreatePlayByPlayIdExecutionMutation,
	useUpdatePlayByPlayIdExecutionMutation,
	usePlayByPlayIdExecutionByIdDeleteMutation,
	usePlayByPlayIdExecutionByIdDeletePermanentlyMutation,
	useGetPlayByPlayIdExecutionByIdQuery,
	useLazyGetPlayByPlayIdExecutionByIdQuery,
	usePlayByPlayIdExecutionByIdRestoreMutation,
	usePlayByPlayIdExecutionByIdUnarchiveMutation,
	useGetUserQuery,
	useLazyGetUserQuery,
	useCreateUserMutation,
	useUpdateUserMutation,
	useGetUserByUserIdJobFunctionsQuery,
	useLazyGetUserByUserIdJobFunctionsQuery,
	useGetUserByUserIdAddableJobFunctionsQuery,
	useLazyGetUserByUserIdAddableJobFunctionsQuery,
	useUpdateUserByUserIdAddMutation,
	useUpdateUserByUserIdRemoveMutation,
	useCreateUserByUserIdWelcomeMutation,
	useGetUserByIdQuery,
	useLazyGetUserByIdQuery,
	useUserByIdArchiveMutation,
	useUserBulkArchiveMutation,
	useUserByIdUnarchiveMutation,
	useUserBulkUnarchiveMutation,
	useUserByIdDeleteMutation,
	useUserBulkDeleteMutation,
	useUserByIdRestoreMutation,
	useUserBulkRestoreMutation,
	useUserByIdDeletePermanentlyMutation,
	useUserBulkDeletePermanentlyMutation,
	useGetUserAuth0UserIdByIdQuery,
	useLazyGetUserAuth0UserIdByIdQuery,
	useGetUserPermissionsQuery,
	useLazyGetUserPermissionsQuery,
	useGetUserRolesQuery,
	useLazyGetUserRolesQuery,
	useGetUserSlackConfigQuery,
	useLazyGetUserSlackConfigQuery,
	useUpdateUserSlackConfigMutation,
	useGetUserNotificationConfigQuery,
	useLazyGetUserNotificationConfigQuery,
	useUpdateUserNotificationConfigMutation,
	useDeleteUserSlackAccountMutation
} = generatedApi;