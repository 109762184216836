import { Box, Tooltip, Typography } from '@mui/material';
import { DragEvent, FC, useCallback } from 'react';

export interface INodeLibraryListItemProps {
    label: string;
    description: string;
    nodeType: string;
    imgPath: string;
}

export const NodeLibraryListItem: FC<INodeLibraryListItemProps> = (props) => {
    const { label, description, nodeType, imgPath } = props;
 
    const handleNodeDragStart = useCallback((event: DragEvent, nodeType: string) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    }, []);

    return (
        <Tooltip title={description}>
            <Box position='relative'>
                <img src={imgPath} width={64} alt={`${label} step icon`} onDragStart={(e) => handleNodeDragStart(e, nodeType)} draggable />
                <Typography color='#6a6a6b'>{label}</Typography>
            </Box>
        </Tooltip>
    )
}