import {
    ArtificialIntelligenceNodeType,
    AutomationNodeType,
    ConditionalNodeType,
    DelayNodeType,
    JoinNodeType,
    MergeNodeType,
    NotificationNodeType,
    ParallelNodeType,
    StartNodeType,
    StepNodeType,
    StopNodeType,
    SubplayNodeType,
} from '.';
import { NodeType } from './NodeType';
import { INodeRules } from './types';

export const START_NODE_DESCRIPTION = 'Start Step indicates the beginning of a play and cannot be removed.';
export const STOP_NODE_DESCRIPTION = 'Stop Step indicates the end of a play and will terminate a flow after being reached.';
export const STEP_NODE_DESCRIPTION =
    'Standard Step sends a notification with instructions to the assigned job function or external member to be manually completed.';
export const PARALLEL_NODE_DESCRIPTION = 'Parallel Step allows for multiple steps to be in progress at the same time.';
export const JOIN_NODE_DESCRIPTION = 'Join Step completes once all of the steps before it are completed.';
export const CONDITIONAL_NODE_DESCRIPTION = 'Conditional Step evaluates a condition and then executes one of two next steps based on the outcome.';
export const MERGE_NODE_DESCRIPTION = 'Merge Step completes once any single step before it is completed.';
export const ARTIFICIAL_INTELLIGENCE_NODE_DESCRIPTION = 'AI Step evaluates a prompt and outputs the value to the play.';
export const AUTOMATION_NODE_DESCRIPTION = 'Automation Step sends a request to an automation and is completed automatically by that automation.';
export const SUBPLAY_NODE_DESCRIPTION = 'Subplay Step will kick off the execution of another play and complete itself once the subplay is completed.';
export const NOTIFICATION_NODE_DESCRIPTION = 'Notification Step will notify user(s)/email(s) before continuing.';
export const DELAY_NODE_DESCRIPTION = 'Delay Step will wait a configurable amount of time before continuing.';

// This maps the back-end names with the names we use in the front-end
export const NodeTypes = {
    START: 'START',
    STOP: 'STOP',
    STEP: 'DEFAULT',
    PARALLEL: 'PARALLEL',
    JOIN: 'PARALLEL_JOIN',
    CONDITIONAL: 'CONDITIONAL',
    MERGE: 'CONDITIONAL_MERGE',
    AUTOMATION: 'POST',
    SUBPLAY: 'SUB_PLAY',
    NOTIFICATION: 'NOTIFICATION',
    DELAY: 'DELAY',
    ARTIFICIAL_INTELLIGENCE: 'ARTIFICIAL_INTELLIGENCE',
};

export const getAllNodeTypes = (): NodeType[] => [
    new StartNodeType(),
    new StopNodeType(),
    new StepNodeType(),
    new ParallelNodeType(),
    new JoinNodeType(),
    new ConditionalNodeType(),
    new MergeNodeType(),
    new AutomationNodeType(),
    new SubplayNodeType(),
    new NotificationNodeType(),
    new DelayNodeType(),
    new ArtificialIntelligenceNodeType()
];

export class NodeTypeFactory {
    public getNodeTypeForStepType(stepType: string): NodeType {
        switch (stepType) {
            case NodeTypes.START:
                return new StartNodeType();
            case NodeTypes.STEP:
                return new StepNodeType();
            case NodeTypes.PARALLEL:
                return new ParallelNodeType();
            case NodeTypes.JOIN:
                return new JoinNodeType();
            case NodeTypes.STOP:
                return new StopNodeType();
            case NodeTypes.CONDITIONAL:
                return new ConditionalNodeType();
            case NodeTypes.MERGE:
                return new MergeNodeType();
            case NodeTypes.AUTOMATION:
                return new AutomationNodeType();
            case NodeTypes.SUBPLAY:
                return new SubplayNodeType();
            case NodeTypes.NOTIFICATION:
                return new NotificationNodeType();
            case NodeTypes.DELAY:
                return new DelayNodeType();
            case NodeTypes.ARTIFICIAL_INTELLIGENCE:
                return new ArtificialIntelligenceNodeType();

            default:
                throw new Error(`Invalid node type ${stepType} provided`);
        }
    }
}

export function getNodeRules(stepType: string): INodeRules {
    var nodeTypeFactory = new NodeTypeFactory();
    var nodeType = nodeTypeFactory.getNodeTypeForStepType(stepType);
    return nodeType.validationRules;
}
