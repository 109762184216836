import { useCallback, useMemo, useState, useRef } from 'react';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../Components/CoreLib/library';
import { useNavigate } from 'react-router-dom';
import { emptyGuid } from '../util';

export function useConfirmDelete(objectType: string, useObjectByIdArchiveOrDeleteMutation: any, navigateUrl?: string, successSnackbarFunction?: any) {
    const navigate = useNavigate();
    const objectToDelete = useRef<any>();
    const snackbarObjectDescription = objectType;
    const [deleteObject, { isError: isDeleteObjectError, isSuccess: isDeleteObjectSuccess, isLoading: isDeletingObject, reset: resetDeleteObject }] = useObjectByIdArchiveOrDeleteMutation();
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);

    useSuccessfulActionSnackbar('deleted', snackbarObjectDescription, isDeleteObjectSuccess, () => {
        resetDeleteObject();
        if (navigateUrl) {
            navigate(navigateUrl);
        }
        if (successSnackbarFunction) {
            successSnackbarFunction();
        }
    });

    useFailedActionSnackbar('delete', snackbarObjectDescription, isDeleteObjectError, resetDeleteObject);

    const setObjectToDelete = useCallback((obj: any) => {
        objectToDelete.current = obj;
        if (!!objectToDelete.current) {
            setShowDeleteConfirmationModal(true);
        }
    }, []);

    const handleConfirmDelete = useCallback(async (response: boolean) => {
        setShowDeleteConfirmationModal(false);
        if (response && !isDeletingObject && objectToDelete.current && objectToDelete.current.id !== emptyGuid) {
            deleteObject({ id: objectToDelete.current.id, });
        }
    }, [deleteObject, isDeletingObject, setShowDeleteConfirmationModal]);

    const renderDeleteConfirmationModal = useMemo(() => {
        return <DeleteConfirmationModal objectType={objectType} showModal={showDeleteConfirmationModal} handleResponse={handleConfirmDelete} />
    }, [objectType, handleConfirmDelete, showDeleteConfirmationModal]);

    return {
        setObjectToDelete,
        isDeletingObject,
        renderDeleteConfirmationModal
    }
}