import { Box, Button, useTheme } from '@mui/material';
import { DeepChat } from 'deep-chat-react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import coachBotAvatar from '../../assets/Coach-Bot-Avatar.png';
import { useAuth0 } from '@auth0/auth0-react';
import { usePermissionChecker } from '../../Hooks';
import { Preview } from '@mui/icons-material';

export const AssistantChatWindow: FC = () => {
    const theme = useTheme();
    const { user: auth0User, getAccessTokenSilently } = useAuth0();
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [threadId, setThreadId] = useState<string | undefined>(undefined);
    const threadIdWithNoRerender = useRef<string | null>('');
    const { hasAllPermissions } = usePermissionChecker();

    useEffect(() => {
        if (accessToken) {
            return;
        }
        (async () => {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    redirect_uri: window.location.origin,
                    scope: "SCOPE profile email offline_access",
                }
            });
            setAccessToken(accessToken);
        })();
    }, [accessToken, getAccessTokenSilently]);

    const deepChatRequestInterceptor = useCallback((details: any) => {
        details.headers.authorization = `Bearer ${accessToken}`;
        if (threadIdWithNoRerender.current) {
            details.body.threadId = threadIdWithNoRerender.current;
        }
        return details;
    }, [accessToken]);

    const deepChatResponseInterceptor = useCallback((response: any) => {
        threadIdWithNoRerender.current = response.threadId;
        setThreadId(response.threadId);
        return response;
    }, [setThreadId]);

    const canDebugChat = useCallback(() => {
        return hasAllPermissions(['read:developer']);
    }, [hasAllPermissions]);

    const handleThreadClick = () => {
        const url = `https://platform.openai.com/playground?mode=assistant&thread=${threadId}`;
        window.open(url);
    }

    const MemorizedDeepChatComponent = useMemo(
        () => (
            <DeepChat
                request={{ url: `${process.env.REACT_APP_BASE_URL}/api/coach` }}
                requestInterceptor={deepChatRequestInterceptor}
                responseInterceptor={deepChatResponseInterceptor}
                initialMessages={[{ role: 'ai', text: 'Hello, I am your CoachBot Assistant. How can I help you today?' }]}
                style={{ border: 'none', width: '100%', height: '100%' }}
                avatars={{
                    default: {
                        styles: {
                            avatar: {
                                paddingTop: '0px',
                                marginTop: '5px',
                            },
                        },
                    },
                    user: {
                        src: auth0User?.picture ?? undefined,
                        styles: {
                            avatar: {
                                borderRadius: '50%',
                            },
                        },
                    },
                    ai: {
                        src: coachBotAvatar,
                    },
                }}
                messageStyles={{
                    default: {
                        user: {
                            bubble: {
                                backgroundColor: theme.palette.primary.main,
                            },
                        },
                    },
                }}
            />
        ),
        [auth0User?.picture, deepChatRequestInterceptor, deepChatResponseInterceptor, theme.palette.primary.main]
    );

    return (
        <>
            <Box display='flex' justifyContent='center' height='calc(100vh - 175px)' alignItems='end'>
                {MemorizedDeepChatComponent}
            </Box>
            {threadId && canDebugChat() && (
                <Button onClick={handleThreadClick}><Preview />{threadId}</Button>
            )}
        </>
    );
};
