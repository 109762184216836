import { FC, createContext, useState, useEffect, PropsWithChildren } from 'react';

export interface IBetaPlayEditorContext {
    isPlayEditorBetaEnabled: boolean;
    setIsPlayEditorBetaEnabled: (value: boolean) => void;
}

// Create the context
export const BetaPlayEditorContext = createContext<IBetaPlayEditorContext>({
    isPlayEditorBetaEnabled: false,
    setIsPlayEditorBetaEnabled: () => {},
});

// Create the provider component
export const BetaPlayEditorProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isPlayEditorBetaEnabled, setIsPlayEditorBetaEnabled] = useState<boolean>(localStorage.getItem('enableBetaPlayEditor') === 'true');

    // Update the state and local storage when the value changes
    useEffect(() => {
        localStorage.setItem('enableBetaPlayEditor', isPlayEditorBetaEnabled.toString());
    }, [isPlayEditorBetaEnabled]);

    return <BetaPlayEditorContext.Provider value={{ isPlayEditorBetaEnabled, setIsPlayEditorBetaEnabled }}>{children}</BetaPlayEditorContext.Provider>;
};
