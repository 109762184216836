import { RemoveCircle, AddCircle } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { FC, useCallback } from 'react';
import { StepDataInput, DataTypeSelect } from '../../../Components/CommonInputs';
import { FormInput } from '../../../Components/CoreLib/library';
import { StepDataType, StepExecutionDataDto } from '../../../dtos';
import { emptyGuid } from '../../../util';
import { useStepDataValidator } from '../../Plays/Hooks';

// TODO: It would be nice to access this data via context rather than props so we don't need to pass all this into the stepDataCollectionSection for just this component
export interface IFormStepDataCollectionProps {
    stepExecutionId: string;
    playExecutionId: string;
    stepData: StepExecutionDataDto[];
    handleStepDataChange: (updatedData: StepExecutionDataDto[]) => void;
    additionalStepData: StepExecutionDataDto[];
    handleAdditionalStepDataChange: (updatedData: StepExecutionDataDto[]) => void;
    validateAdditionalStepLabel: (stepData: StepExecutionDataDto) => string;
    readonly?: boolean;
}

export const FormStepDataCollection: FC<IFormStepDataCollectionProps> = ({
    stepExecutionId,
    playExecutionId,
    stepData,
    handleStepDataChange,
    additionalStepData,
    handleAdditionalStepDataChange,
    readonly,
    validateAdditionalStepLabel
}) => {
    const { validateStepData } = useStepDataValidator();
    
    const updateStepDataValue = useCallback(
        (stepEntryIndex: number, value: string) => {
            var updatedStepData = [...stepData];
            updatedStepData[stepEntryIndex] = { ...updatedStepData[stepEntryIndex], value: value };
            handleStepDataChange(updatedStepData);
        },
        [stepData, handleStepDataChange]
    );

    const updateAdditionalStepData = useCallback(
        (stepEntryIndex: number, propertyName: keyof StepExecutionDataDto, propertyValue: any) => {
            const updatedAdditionalStepData = [...additionalStepData];
            updatedAdditionalStepData[stepEntryIndex] = { ...updatedAdditionalStepData[stepEntryIndex], [propertyName]: propertyValue };
            handleAdditionalStepDataChange(updatedAdditionalStepData);
        },
        [additionalStepData, handleAdditionalStepDataChange]
    );

    const addAdditionalStepDataEntry = () => {
        var newAdditionalDataEntry: StepExecutionDataDto = {
            id: emptyGuid,
            playExecutionId: playExecutionId,
            stepId: stepExecutionId,
            name: '',
            isRequired: true,
            value: '',
            collectedOn: new Date(),
            orderIndex: 0,
            dataType: StepDataType.TEXT,
            isFutureDate: false,
        };
        var updatedAdditionalStepData = [...additionalStepData, newAdditionalDataEntry];
        handleAdditionalStepDataChange(updatedAdditionalStepData);
    };

    const removeAdditionalStepDataEntry = useCallback(
        (idx: number) => {
            var updatedAdditionalStepData = [...additionalStepData];
            updatedAdditionalStepData.splice(idx, 1);
            handleAdditionalStepDataChange(updatedAdditionalStepData);
        },
        [additionalStepData, handleAdditionalStepDataChange]
    );

    return (
        <>
            {stepData.map((stepData, idx) => (
                <Grid item xs={12} key={`step-data-${idx}`}>
                    <StepDataInput
                        entityId={stepExecutionId}
                        stepData={stepData}
                        onChange={(val) => updateStepDataValue(idx, val)}
                        errorMessage={validateStepData(stepData)}
                        disabled={readonly}
                    />
                </Grid>
            ))}
            {additionalStepData.map((additionalStepData, idx) => (
                <Grid item container direction='row' alignItems='start' flexWrap='nowrap' key={`additional-step-data-${idx}`} spacing={1} py={2}>
                    <Grid item flexGrow={0.5}>
                        <FormInput
                            fullWidth
                            required
                            disabled={readonly}
                            label='Label'
                            value={additionalStepData.name}
                            onChange={(e) => updateAdditionalStepData(idx, 'name', e.target.value)}
                            errorText={!readonly ? validateAdditionalStepLabel(additionalStepData) : ''}
                        />
                    </Grid>
                    <Grid item width='144px'>
                        <DataTypeSelect value={additionalStepData.dataType!} onChange={(value) => updateAdditionalStepData(idx, 'dataType', value)} />
                    </Grid>
                    <Grid item flexGrow={1} mt={3}>
                        <StepDataInput
                            entityId={stepExecutionId}
                            stepData={additionalStepData}
                            onChange={(val) => updateAdditionalStepData(idx, 'value', val)}
                            errorMessage={validateStepData(additionalStepData)}
                            disabled={readonly}
                            showDataLabel={false}
                        />
                    </Grid>
                    {!readonly && (
                        <Grid item>
                            <IconButton color='error' onClick={() => removeAdditionalStepDataEntry(idx)} sx={{ mt: 3 }}>
                                <RemoveCircle fontSize='large' />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            ))}
            {!readonly && (
                <Grid item>
                    <IconButton color='primary' onClick={addAdditionalStepDataEntry}>
                        <AddCircle fontSize='large' />
                    </IconButton>
                </Grid>
            )}
        </>
    );
};
