import { RemoveCircle } from '@mui/icons-material';
import { FormControl, FormHelperText, Grid, IconButton, Tooltip } from '@mui/material';
import { FC, useMemo } from 'react';
import { isNotBlank, isValidEmail } from '../../../../../../Components/CoreLib/library';
import { TextFieldWithDataReferences } from '../../../../../../Components/Wysiwyg';
import { StepDataDto } from '../../../../../../dtos';

export interface IEmailToNotifyDataRowProps {
    index: number;
    disableDelete?: boolean;
    emailToNotify: string;
    updateEmailToNotify: (emailToNotifyIndex: number, emailToNotify: string) => void;
    removeEmailToNotify: (emailToNotifyIndex: number) => void;
    dataOptions: StepDataDto[];
}

export const EmailToNotifyDataRow: FC<IEmailToNotifyDataRowProps> = ({
    index,
    emailToNotify,
    disableDelete,
    updateEmailToNotify,
    removeEmailToNotify,
    dataOptions,
}) => {
    const getErrorMessage = useMemo(() => {
        const dataReferenceRegex = /{{[^{}]*}}$/;
        const isDataRef = dataReferenceRegex.test(emailToNotify);
        if (isDataRef) {
            return;
        }
        
        if (!isValidEmail(emailToNotify).isValid || !isNotBlank(emailToNotify).isValid) {
            return 'Please enter a valid email';
        }
    }, [emailToNotify]);

    return (
        <Grid item container direction='row' alignItems='center'>
            <Grid item xs={11}>
                <FormControl error={!!getErrorMessage} fullWidth>
                    <TextFieldWithDataReferences
                        initialValue={emailToNotify}
                        onChange={(value) => updateEmailToNotify(index, value)}
                        dataReferences={dataOptions.map((x) => x.name ?? '')}
                    />
                    <FormHelperText>{getErrorMessage}</FormHelperText>
                </FormControl>
            </Grid>
            <Tooltip title={disableDelete ? 'There must be at least one email specified' : 'Delete'}>
                <Grid item xs={1} display='flex' justifyContent='center'>
                    <IconButton disabled={disableDelete} color='error' onClick={() => removeEmailToNotify(index)}>
                        <RemoveCircle />
                    </IconButton>
                </Grid>
            </Tooltip>
        </Grid>
    );
};
