import { FC, useCallback, useMemo, useState } from 'react';
import {
    DataTableColumn,
    IFilterChip,
    IStandardListViewExtraItems,
    PaginatedProps,
    StandardCardWithHeader,
    StandardListViewV2,
} from '../../../Components/CoreLib/library';
import { StepExecutionDto } from '../../../dtos';
import { useGetPlayByPlayIdExecutionStepQuery } from '../../../store/generated/generatedApi';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { ReactComponent as StepExecutionIcon } from '../../../assets/Step-Executions-Icon.svg'
import { History } from '@mui/icons-material';
import { IStepExecutionFilterOptions } from '../Util/useStepExecutionFilters';
import { StepExecutionFilterDialog } from '../Modals/StepExecutionFilterDialog';
import { formatTimestamp } from '../../../util';
import { NodeTypes } from '../../Plays/NodeTypes/utils';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'NAME',
    sortAsc: false,
    page: 0,
    pageSize: 25,
};


export interface IStepExecutionListView {
    playbookId: string;
    playId: string;
    stepExecutionFilters: IStepExecutionFilterOptions,
    handleSearchTermFilterChange: (updatedFilters: IStepExecutionFilterOptions) => void,
    getFilterChips: () => IFilterChip[],
    handleRemoveFilterChip: (filterChipKey: string) => void
}
export const StepExecutionListView: FC<IStepExecutionListView> = (props) => {
    const { stepExecutionFilters,
        handleSearchTermFilterChange,
        getFilterChips,
        handleRemoveFilterChip, playbookId, playId } = props;
    const navigate = useNavigate();
    const theme = useTheme();
    const [isFilterDialogVisible, setIsFilterDialogVisible] = useState(false);

    const goToPlayHistory = useCallback((row: StepExecutionDto) => navigate(`/playbook/${playbookId}/play/${playId}/history?runId=${row.playId}&stepKey=${row.key}`), [navigate, playId, playbookId]);
    const clickableRowStyles = useCallback(
        () => ({
            cursor: 'pointer',
        }),
        []
    );

    const rowMenu = (row: StepExecutionDto) => {
        return (
            <>
                <Tooltip title="View">
                    <IconButton onClick={ () => {goToPlayHistory(row);} } className='hover-content'>
                        <History fontSize='inherit' />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const formatMinutes = function (totalMinutes: number) {
        const sign = Math.sign(totalMinutes);
        var absTotal = Math.abs(totalMinutes);
        var mins = absTotal % 60;
        var hours = Math.floor(absTotal / 60);
        var days = Math.floor(hours / 24);
        hours = hours % 24;
        return (sign < 0 ? '-' : '') + (days !== 0 ? days + 'd ' : '') + (hours !== 0 ? hours + 'h ' : '') + mins + 'm';
    };

    const formatType = function (type: string) {
        return type === "DEFAULT" ? 'Standard' : 'Automation';
    };

    const tableColumns: DataTableColumn<StepExecutionDto>[] = [
        { label: 'Step Name', key: 'stepName', sortKey: 'NAME', fieldMapper: (row) => row.name },
        { label: 'Run Number', key: 'runNumber', sortKey: "RUN_NUMBER", fieldMapper: (row) => row.runNumber},
        { label: 'Step Type', key: 'stepType', sortKey: 'TYPE', fieldMapper: (row) => formatType(row.type) },
        { label: 'Assignment Type', key: 'assignmentType', sortKey: 'IS_EXTERNAL', fieldMapper: (row) => (row.type !== NodeTypes.AUTOMATION ? (row.isExternal ? 'External' : 'Internal') : '') },
        {
            label: 'Assigned Job Function',
            key: 'jobFunction',
            sortKey: 'JOB_FUNCTION',
            fieldMapper: (row) => (row.assignedJobFunction ? row.assignedJobFunction.name : ''),
        },
        {
            label: 'Assigned To',
            key: 'assignedToEmail',
            sortKey: 'ASSIGNED_TO_EMAIL',
            fieldMapper: (row) => (row.assignedToEmail ? row.assignedToEmail : ''),
        },
        {
            label: 'Start Time',
            key: 'startedOn',
            sortKey: 'STARTED_ON',
            fieldMapper: (row) => (row.startedOn ? formatTimestamp(row.startedOn) : ''),
        },
        {
            label: 'End Time',
            key: 'completedOn',
            sortKey: 'COMPLETED_ON',
            fieldMapper: (row) => (row.completedOn ? formatTimestamp(row.completedOn) : ''),
        },
        {
            label: 'Execution Time',
            key: 'actualExecutionTime',
            sortKey: 'EXECUTION_TIME_IN_MINUTES_ACTUAL',
            fieldMapper: (row) => (row.executionTimeInMinutesActual ? formatMinutes(row.executionTimeInMinutesActual) : ''),
        },
        {
            label: 'Estimated Execution Time',
            key: 'estimatedExecutionTime',
            sortKey: 'EXECUTION_TIME_IN_MINUTES_ESTIMATED',
            fieldMapper: (row) => (row.executionTimeInMinutesEstimated && row.type !== "Automation" ? formatMinutes(row.executionTimeInMinutesEstimated) : ''),
        },
        {
            label: 'Lag Time',
            key: 'averageLagTime',
            sortKey: 'EXECUTION_TIME_IN_MINUTES_LAG',
            fieldMapper: (row) => (
                row.executionTimeInMinutesLag &&
                row.type !== "Automation" ? 
                (<span style={{ color: row.executionTimeInMinutesLag !== 0 ? (row.executionTimeInMinutesLag < 0 ? theme.palette.success.main : theme.palette.error.main) : 'primary' }}>
                    {' '}
                    {formatMinutes(row.executionTimeInMinutesLag)}
                </span> ) : ''
            ),
        },
        { label: '', key: '', sortKey: '', fieldMapper: row => rowMenu(row) }
    ];

    const handleRowClick = useCallback(
        (stepId: string, row: StepExecutionDto) => {
            if (row.isActive && row.key) {
                goToPlayHistory(row);
            }
        },
        [goToPlayHistory]
    );

    const verifyOnlyOneIsSelected = useCallback((selectedItems: StepExecutionDto[]) => {
        return selectedItems.length === 1;
    }, []);

    const handleViewMenuClick = useCallback(
        (selectedItems: StepExecutionDto[]) => {
            if (selectedItems.length !== 1) {
                return;
            }
            goToPlayHistory(selectedItems[0]);
        },
        [goToPlayHistory]
    );

    const handleFilterClicked = useCallback(() => {
        setIsFilterDialogVisible(true);
    }, [setIsFilterDialogVisible]);

    const additionalTableButtons: IStandardListViewExtraItems[] = useMemo(() => [
        {
            enabledCheck: verifyOnlyOneIsSelected,
            onClick: handleViewMenuClick,
            text: 'View'
        },
        {
            onClick: handleFilterClicked,
            text: 'Filter Step Executions'
        }
    ], [handleFilterClicked, handleViewMenuClick, verifyOnlyOneIsSelected]);

    return (
        <>
        <StandardCardWithHeader headerTitle='Step Executions' headerIcon={ <StepExecutionIcon/> }>
            <StandardListViewV2
                headerIcon={<></>}
                headerTitle=''
                defaultPaginationProps={defaultPaginationProps}
                getDataQuery={useGetPlayByPlayIdExecutionStepQuery}
                tableColumns={tableColumns}
                additionalQueryParameters={{
                    playId: playId,
                    playExecutionId: stepExecutionFilters.runId,
                    stepKey: stepExecutionFilters.stepKey,
                    startedBefore: stepExecutionFilters.startedBefore ?? '',
                    startedAfter: stepExecutionFilters.startedAfter ?? '',
                    endedBefore: stepExecutionFilters.endedBefore ?? '',
                    endedAfter: stepExecutionFilters.endedAfter ?? ''
                }}
                entityNameSingular={'Step Execution'}
                permissionName={'playExecutions'}
                disableGutters
                overrideEnableChipGutters
                hideMenuDivider
                handleRowClick={handleRowClick}
                customRowStyling={clickableRowStyles}
                filterChips={getFilterChips()}
                handleFilterChipDelete={handleRemoveFilterChip}
                handleFilterClicked={handleFilterClicked}
                additionalMenuItems={additionalTableButtons}
            />
        </StandardCardWithHeader>
        <StepExecutionFilterDialog
            isVisible={isFilterDialogVisible}
            onClose={() => setIsFilterDialogVisible(false)}
            filterProps={stepExecutionFilters}
            setFilterProps={handleSearchTermFilterChange}
            playId={playId}
        />
        </>
    );
};
