import { AddCircle } from '@mui/icons-material';
import { Box, FormControlLabel, Grid, IconButton, Switch } from '@mui/material';
import _ from 'lodash';
import { FC, useContext, useMemo } from 'react';
import { AuthenticatedComponent } from '../../../../../../Components/CoreLib/library';
import { StepDataDto } from '../../../../../../dtos';
import { StepDataType } from '../../../../../../dtos/generated/StepDataType';
import { emptyGuid } from '../../../../../../util';
import { StepEditorContext } from '../../../../Utils/StepEditorContext';
import { StepDataInputRow } from './StepDataInputRow';

export interface IEditStepDataInputProps {
    stepId: string;
    stepData: StepDataDto[];
    updateStepData: (updatedData: StepDataDto[]) => void;
    description: string;
    hideChatDataCollectionToggle?: boolean;
}

export const EditStepDataInput: FC<IEditStepDataInputProps> = ({
    stepId,
    stepData: allStepDataEntries,
    updateStepData,
    description,
    hideChatDataCollectionToggle,
}) => {
    const { formStep, handleChatDataCollectionChange } = useContext(StepEditorContext);

    const orderedStepDataEntries = useMemo(() => allStepDataEntries.sort((a, b) => {
        if (a.name && b.name) {
            return a.name.localeCompare(b.name);
        }
        return 0;
    }), [allStepDataEntries]);

    const addData = () => {
        var newStep: StepDataDto = {
            id: emptyGuid,
            stepId: stepId,
            name: '',
            isRequired: false,
            orderIndex: orderedStepDataEntries.length,
            dataType: StepDataType.TEXT,
            isFutureDate: true,
        };
        var updatedStepData = [...orderedStepDataEntries, newStep];
        updateStepData(updatedStepData);
    };

    const removeStep = (stepEntryIndex: number) => {
        var updatedStepData = [...orderedStepDataEntries];
        updatedStepData.splice(stepEntryIndex, 1);
        updateStepData(updatedStepData);
    };

    const updateSelectedStepData = (stepEntryIndex: number, newSelectedStepData: StepDataDto) => {
        var updatedStepData = _.cloneDeep(orderedStepDataEntries);
        updatedStepData.splice(stepEntryIndex, 1, newSelectedStepData);
        updateStepData(updatedStepData);
    };

    return (
        <>
            <Grid item mb={3} display='flex'>
                <Box>{description}</Box>
                {!hideChatDataCollectionToggle && (
                    <AuthenticatedComponent requiredPermissions={['feature:chatDataCollection']}>
                        <Box marginLeft='auto'>
                            <FormControlLabel
                                control={<Switch checked={formStep.isUsingChatDataCollection} onChange={(_, value) => handleChatDataCollectionChange(value)} />}
                                label='[BETA] Collect Data with AI Chat'
                                labelPlacement='end'
                            />
                        </Box>
                    </AuthenticatedComponent>
                )}
            </Grid>
            {orderedStepDataEntries.map((stepData, i) => (
                <StepDataInputRow
                    key={`step-data-${i}`}
                    index={i}
                    stepData={stepData}
                    allStepDataEntries={orderedStepDataEntries}
                    updateSelectedStepData={updateSelectedStepData}
                    isUsingChatDataCollection={formStep.isUsingChatDataCollection}
                    removeStep={removeStep}
                />
            ))}
            <Grid item>
                <IconButton color='primary' onClick={addData}>
                    <AddCircle />
                </IconButton>
            </Grid>
        </>
    );
};
