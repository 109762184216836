import { FormControl, FormLabel, Select, MenuItem } from '@mui/material';
import {FC} from 'react';
import { StepDataType } from '../../dtos';
import { StepDataTypeToName } from '../../util';

export interface IDataTypeSelectProps {
    value: StepDataType,
    onChange: (value: StepDataType) => void;
};

export const DataTypeSelect: FC<IDataTypeSelectProps> = ({
    value,
    onChange
}) => {

    return (
        <FormControl fullWidth required>
            <FormLabel>Type</FormLabel>
            <Select
                value={value}
                onChange={(e) => onChange(e.target.value as StepDataType)}>
                <MenuItem value={StepDataType.TEXT}>{StepDataTypeToName.get(StepDataType.TEXT)}</MenuItem>
                <MenuItem value={StepDataType.DATE}>{StepDataTypeToName.get(StepDataType.DATE)}</MenuItem>
                <MenuItem value={StepDataType.FILE}>{StepDataTypeToName.get(StepDataType.FILE)}</MenuItem>
                <MenuItem value={StepDataType.BOOLEAN}>{StepDataTypeToName.get(StepDataType.BOOLEAN)}</MenuItem>
            </Select>
        </FormControl>
    );
};