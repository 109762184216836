import { Edit, PlayArrow, MoreVert, Visibility, Delete, ControlPointDuplicate, DriveFileMove } from '@mui/icons-material';
import { Grid, Divider, Button, Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import {
    AuthenticatedComponent,
    LoadingBackdrop,
    useFailedActionSnackbar,
    useSuccessfulActionSnackbar
} from '../../../../Components/CoreLib/library';
import { PlayDto } from '../../../../dtos';
import { useNavigate } from 'react-router-dom';
import { useConfirmDelete, useMovePlayStarter, usePlayStarter } from '../../../../customHooks';
import { useCreatePlayCloneMutation, usePlayByIdArchiveMutation } from '../../../../store/generated/generatedApi';
import { usePermissionChecker } from '../../../../Hooks';

export interface ITitleBarEndSlotProps {
    handleViewOtherPage: () => void;
    playDetails: PlayDto;
    otherPageName: string;
    otherPageIcon: React.ReactNode;
}

export const TitleBarEndSlot: FC<ITitleBarEndSlotProps> = (props) => {
    const { handleViewOtherPage, playDetails, otherPageName, otherPageIcon } = props;
    const [createClonePlay, { data: newPlay, isLoading: isCloning, isSuccess: isCloneSuccess, isError: isCloneError, reset: resetClonePlay }] =
        useCreatePlayCloneMutation();
    const { handleStartPlay, renderStartPlayModal, isStartingPlay } = usePlayStarter();
    const { handleMovePlay, renderMovePlayModal } = useMovePlayStarter();
    const { canUserEditPlay, hasAllPermissions } = usePermissionChecker();
    const navigate = useNavigate();

    useSuccessfulActionSnackbar('cloned', 'Play', isCloneSuccess, resetClonePlay);
    useFailedActionSnackbar('clone', 'Play', isCloneError, resetClonePlay);

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        if (newPlay) {
            navigate(`/playbook/${newPlay.playbookId}/play/${newPlay.id}/dashboard`);
        }
    }, [newPlay, navigate]);

    useEffect(() => {
        if (!playDetails.isActive) {
            navigate(`/playbook/${playDetails.playbookId}/plays`);
        }
    }, [playDetails, navigate]);


    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    };

    const handleEditPlay = () => {
        if (!playDetails) {
            return;
        }
        navigate(`/playbook/${playDetails.playbookId}/play/${playDetails.id}`);
    };

    const handleStartPlayClicked = useCallback(() => {
        if (!playDetails) {
            return;
        }
        handleStartPlay(playDetails);
    }, [playDetails, handleStartPlay]);

    const handleMovePlayClicked = useCallback(() => {
        if (!playDetails) {
            return;
        }
        handleMovePlay(playDetails);
    }, [playDetails, handleMovePlay]);

    const handleClone = useCallback(() => {
        if (playDetails) {
            createClonePlay(playDetails);
        }
    }, [createClonePlay, playDetails]);


    const { setObjectToDelete, isDeletingObject, renderDeleteConfirmationModal } = useConfirmDelete('Play', usePlayByIdArchiveMutation);

    const handleShowDeleteConfirmationModal = useCallback(
        () => {
            setObjectToDelete(playDetails);
        },
        [playDetails, setObjectToDelete]
    );


    return (
        <>
            {renderDeleteConfirmationModal}
            <Grid item container direction='row' alignItems='start' py={1}>
                <Grid item display='flex' alignItems='center' justifyContent={'end'} xs={12} sx={{ gap: 1 }} pr={2}>
                    <AuthenticatedComponent requiredPermissions={[`delete:plays`]}>
                        <Tooltip title='Delete'>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleShowDeleteConfirmationModal();
                                }}
                                disabled={isDeletingObject}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </AuthenticatedComponent>
                    {canUserEditPlay(playDetails) &&
                        <>                        <Tooltip title='Clone'>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClone();
                                }}
                            >
                                <ControlPointDuplicate />
                            </IconButton>
                        </Tooltip>
                            <Tooltip title='Move'>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleMovePlayClicked();
                                    }}
                                >
                                    <DriveFileMove />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                    <Divider flexItem orientation='vertical' />
                    <Button color='primary' startIcon={otherPageIcon} variant='contained' size='small' disableElevation onClick={handleViewOtherPage}>
                        View {otherPageName}
                    </Button>
                    {canUserEditPlay(playDetails) ? (
                        <Button color='primary' startIcon={<Edit />} variant='contained' size='small' disableElevation onClick={handleEditPlay}>
                            Edit Play
                        </Button>
                    ) : hasAllPermissions([`read:plays`]) ? (
                        <Button color='primary' startIcon={<Visibility />} variant='contained' size='small' disableElevation onClick={handleEditPlay}>
                            View Play
                        </Button>
                    ) : (
                        <></>
                    )}
                    <Divider flexItem orientation='vertical' />
                    <AuthenticatedComponent requiredPermissions={[`execute:plays`]}>
                        <Tooltip title={playDetails.isValid ? '' : 'Invalid plays cannot be started'}>
                            <Box>
                                <Button
                                    color='secondary'
                                    startIcon={<PlayArrow />}
                                    variant='contained'
                                    size='small'
                                    disableElevation
                                    onClick={handleStartPlayClicked}
                                    disabled={!playDetails.isValid}>
                                    Start Play
                                </Button>
                            </Box>
                        </Tooltip>
                        <Divider flexItem orientation='vertical' />
                    </AuthenticatedComponent>
                    <IconButton onClick={handleMenuOpen}>
                        <MoreVert fontSize='inherit' />
                    </IconButton>
                    <Menu
                        anchorEl={menuAnchorEl}
                        open={!!menuAnchorEl}
                        onClose={handleMenuClose()}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        <MenuItem onClick={handleMenuClose(handleViewOtherPage)}>View {otherPageName}</MenuItem>
                        {canUserEditPlay(playDetails) ? (
                            <>
                                <MenuItem onClick={handleMenuClose(handleEditPlay)}>Edit Play</MenuItem>
                            </>
                        ) : hasAllPermissions([`read:plays`]) ? (
                            <>
                                <MenuItem onClick={handleMenuClose(handleEditPlay)}>View Play</MenuItem>
                            </>
                        ) : (
                            <></>
                        )}
                        <AuthenticatedComponent requiredPermissions={[`execute:plays`]}>
                            <MenuItem onClick={handleMenuClose(handleStartPlayClicked)} disabled={!playDetails.isValid}>
                                Start Play
                            </MenuItem>
                        </AuthenticatedComponent>
                        {canUserEditPlay(playDetails) &&
                            <AuthenticatedComponent requiredPermissions={[`delete:plays`]}>
                                <Divider />
                            </AuthenticatedComponent>
                        }
                        <AuthenticatedComponent requiredPermissions={[`delete:plays`]}>
                            <MenuItem onClick={handleMenuClose(handleShowDeleteConfirmationModal)}>Delete Play</MenuItem>
                        </AuthenticatedComponent>


                        {canUserEditPlay(playDetails) &&
                            <>
                                <MenuItem onClick={handleMenuClose(handleClone)}>Clone Play</MenuItem>
                                <MenuItem onClick={handleMenuClose(handleMovePlayClicked)}>Move Play</MenuItem>
                            </>
                        }

                    </Menu>
                </Grid>
            </Grid >
            <LoadingBackdrop
                loadingOptions={[
                    { isLoading: isCloning, loadingMessage: 'Cloning play...' },
                    { isLoading: isStartingPlay, loadingMessage: 'Starting play...' },
                ]}
            />
            {renderStartPlayModal}
            {renderMovePlayModal}
        </>
    );
};
