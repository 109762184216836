import { FC, useContext, useMemo } from 'react';
import { PlayEditorContext } from '../../Utils/PlayEditorContext';

export const EstimatedExecutionTimeInfo: FC = () => {
    const { steps } = useContext(PlayEditorContext);
    
    const estimatedExecutionTime = useMemo(() => {
        if (!steps) {
            return 0;
        }
        var totalMinutes = 0;
        for (let i = 0; i < steps?.length; i++) {
            if (steps[i].estimatedExecutionTimeInMinutes) {
                totalMinutes += steps[i].estimatedExecutionTimeInMinutes;
            }
        }
        return totalMinutes;
    }, [steps])
    

    return (
        <>
            Estimated Execution Time: {estimatedExecutionTime} min.
        </>
    )
}