import { FormControl, FormHelperText, FormLabel, Grid, IconButton, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { IStepDataInputProps } from './StepDataInput';
import { stepDataFieldLabelBuilder } from './Utils';
import { Clear } from '@mui/icons-material';

export const BooleanStepDataInput: FC<IStepDataInputProps> = ({
    stepData,
    onChange,
    errorMessage,
    fieldLabelSuffix,
    disabled,
    showDataLabel
}) => {
    const fieldLabel = useMemo(() => stepDataFieldLabelBuilder(stepData.name ?? 'Value', { isRequired: stepData.isRequired, labelSuffix: fieldLabelSuffix }), [stepData, fieldLabelSuffix]);
    
    const handleChange = useCallback((e: SelectChangeEvent<string>) => {
        onChange(e.target.value);
    }, [onChange]);

    return (
        <Grid item>
            <FormControl error={!!errorMessage} fullWidth disabled={disabled}>
                {showDataLabel && <FormLabel>{fieldLabel}</FormLabel>}
                <Select value={stepData.value} onChange={handleChange} endAdornment={stepData.value && !disabled && 
                    <IconButton
                        sx={{ mr: 2 }}
                        onClick={() => onChange('')}
                    >
                        <Clear />
                    </IconButton>
                }>
                    <MenuItem sx={{ display: 'none' }} value=''></MenuItem>
                    <MenuItem value='True'>True</MenuItem>
                    <MenuItem value='False'>False</MenuItem>
                </Select>
                <FormHelperText>{errorMessage}</FormHelperText>
            </FormControl>
        </Grid>
    );
};