import {
    FormControl,
    FormHelperText,
    FormLabel
} from '@mui/material';
import { FC } from 'react';
import { TextFieldWithDataReferences } from '../../../../../../Components/Wysiwyg';
import { StepDataDto } from '../../../../../../dtos';

export interface IConditionDataInputProps {
    operand: string;
    handleOperandChange: (value: string) => void;
    errorMessage?: string;
    dataOptions: StepDataDto[];
}

export const ConditionDataInput: FC<IConditionDataInputProps> = (props) => {
    const { errorMessage, operand, handleOperandChange, dataOptions } = props;

    return (
        <FormControl error={!!errorMessage} fullWidth required>
            <FormLabel>Value</FormLabel>
            <TextFieldWithDataReferences initialValue={operand} onChange={handleOperandChange} dataReferences={dataOptions.map((x) => x.name ?? '')} />
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    );
};
