import { Link as LinkIcon } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, Link } from '@mui/material';
import { ContentBlock, ContentState, DraftDecoratorComponentProps } from 'draft-js';
import { ChangeEvent, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ModalSaveCloseFooter, ModalTitle } from '../../../Views/Plays/Components/SubComponents';
import { FormInput } from '../../CoreLib/library';
import { DraftEditorContext, LinkDecoratorEditState } from '../DraftEditorContext';
import { isValidUrl } from '../../../util';

// The original strategy and decorator component were based off of this example: https://github.com/facebookarchive/draft-js/blob/main/examples/draft-0-10-0/link/link.html
export const findLinkStrategy = (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
    contentBlock.findEntityRanges((character: any) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
};

export const LinkDecorator: FC<PropsWithChildren<DraftDecoratorComponentProps>> = ({ contentState, children, entityKey }) => {
    const { setLinkDecoratorState } = useContext(DraftEditorContext);
    const entityData = contentState.getEntity(entityKey!).getData();

    const editLink = useCallback(() => {
        if (!entityKey) {
            console.error('No entity key found for link. Edit cannot be performed.');
            return;
        }
        setLinkDecoratorState({
            entityKey: entityKey,
            currentLinkValue: entityData.href,
        });
    }, [entityData.href, entityKey, setLinkDecoratorState]);

    return (
        <>
            <Link href={entityData.href} onClick={editLink} sx={{ cursor: 'pointer' }}>
                {children}
            </Link>
        </>
    );
};

interface ILinkDecoratorEditModalProps {
    linkDecoratorState?: LinkDecoratorEditState;
    onUpdate: (updatedLink: string) => void;
    onCancel: () => void;
    onDelete: () => void;
}

export const LinkDecoratorEditModal: FC<ILinkDecoratorEditModalProps> = ({ linkDecoratorState, onUpdate, onCancel, onDelete }) => {
    const [updatedLink, setUpdatedLink] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const isNew = useMemo(() => linkDecoratorState?.currentLinkValue === '', [linkDecoratorState]);

    // This is a very crude validation method. If we add more fields to this form we should use a hook like other forms.
    const validate = useCallback(() => {
        if (!isValidUrl(updatedLink)) {
            setErrorMessage('Must be a valid url');
            return false;
        } else {
            setErrorMessage('');
            return true;
        }
    }, [updatedLink]);

    const handleSaveClicked = useCallback(() => {
        const isValid = validate();
        if (isValid) {
            onUpdate(updatedLink);
        }
    }, [updatedLink, onUpdate, validate]);

    const handleLinkChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setUpdatedLink(e.target.value);
    }, []);

    useEffect(() => {
        setUpdatedLink(linkDecoratorState?.currentLinkValue ?? '');
    }, [linkDecoratorState?.currentLinkValue]);

    return (
        <Dialog open={!!linkDecoratorState} fullWidth maxWidth='sm'>
            <ModalTitle icon={<LinkIcon sx={{ mr: 1 }} />} title={`${isNew ? 'Add' : 'Edit'} Link`} />
            <DialogContent>
                <FormInput
                    label='Link'
                    value={updatedLink}
                    onChange={handleLinkChange}
                    errorText={errorMessage}
                    onBlur={validate}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <ModalSaveCloseFooter
                    isFormDirty={updatedLink !== linkDecoratorState?.currentLinkValue}
                    onDeleteClicked={onDelete}
                    onCancelCloseClicked={onCancel}
                    onSaveClicked={handleSaveClicked}
                    isSaveDisabled={!updatedLink || updatedLink === linkDecoratorState?.currentLinkValue}
                />
            </DialogActions>
        </Dialog>
    );
};
