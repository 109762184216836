import { useCallback, useEffect, useState } from "react";
import { Node, NodeMouseHandler } from "reactflow";
import { StepDto, StepDataDto, PlayVariableDto } from "../../../dtos";
import { emptyGuid } from "../../../util";
import { NodeTypeFactory } from "../NodeTypes/utils";
import { mapNodesToSteps } from "../Utils";
import { useSearchParams } from "react-router-dom";

export function useSelectedNodeManager(nodes: Node<StepDto, string | undefined>[], getDependentNodeKeys: (nodeKey: string) => string[], playVariables: PlayVariableDto[]) {
    const [searchParams, setSearchParams] = useSearchParams();
    const paramStepId = searchParams.get('stepId');
    const [selectedStep, setSelectedStep] = useState<StepDto | undefined>(undefined);
    const [stepDataCollectedSoFarForSelectedStep, setStepDataCollectedSoFarForSelectedStep] = useState<StepDataDto[] | undefined>(undefined);
    
    const handleNodeClick: NodeMouseHandler = useCallback((_, node) => {
        var nodeStep: StepDto = node.data;
        if (!node.type) {
            return;
        }
        var nodeTypeFactory = new NodeTypeFactory();
        var nodeType = nodeTypeFactory.getNodeTypeForStepType(node.type);
        if (!nodeType.isEditable) {
            return;
        }
        var dependentNodeKeys = getDependentNodeKeys(nodeStep.key!);
        var dataCollectedSoFar = dependentNodeKeys
            .filter((depKey) => depKey !== nodeStep.key) // remove current step (it is not dependent on itself)
            .map((depKey) => nodes.find((node) => node.data.key === depKey)) // convert keys to nodes
            .flatMap((depNode) => depNode!.data.stepData) // extract all the step data from those nodes
            .sort((a, b) => ((a?.name ?? '') > (b?.name ?? '') ? 1 : -1)); // order them ny name
        var outputDataSoFar = dependentNodeKeys
            .filter((depKey) => depKey !== nodeStep.key && nodes.find((node) => node.data.key === depKey)?.data.outputDataLabel) // get all nodes with an outputDataLabel
            .map((depKey) => nodes.find((node) => node.data.key === depKey)) // convert keys to nodes
            .flatMap((depNode) => depNode!.data.outputDataLabel) // extract the outputDataLabel from those nodes
            .sort((a, b) => ((a?.toString ?? '') > (b?.toString ?? '') ? 1 : -1)) // order them ny name
            .map((mapping): StepDataDto => {
                return { id: emptyGuid, stepId: emptyGuid, name: mapping?.toString(), isRequired: false, orderIndex: 0, dataType: playVariables.find(pv => pv.name === mapping?.toString())?.dataType };
            });
        var subplayOutputDataSoFar = dependentNodeKeys
            .filter((depKey) => depKey !== nodeStep.key && nodes.find((node) => node.data.key === depKey)?.data.subplayId) // get all the subplay nodes before the current node
            .map((depKey) => nodes.find((node) => node.data.key === depKey)) // convert the keys to nodes
            .flatMap((depNode) => depNode!.data.subplayDataOutputConfig!) // extract all configs
            .sort((a, b) => ((a?.destinationFieldName ?? '') > (b?.destinationFieldName ?? '') ? 1 : -1)) // order by name
            .map((mapping): StepDataDto => {
                return { id: emptyGuid, stepId: emptyGuid, name: mapping.destinationFieldName, isRequired: false, orderIndex: 0, dataType: playVariables.find(pv => pv.name === mapping.destinationFieldName)?.dataType };
            }); // map to step data dtos
        

        
        setStepDataCollectedSoFarForSelectedStep(dataCollectedSoFar.concat(outputDataSoFar).concat(subplayOutputDataSoFar));
        setSelectedStep(nodeStep);
    }, [getDependentNodeKeys, nodes, playVariables]);

    const updateStep = (updatedStep: StepDto) => {
        const updatedNodes = nodes.map((node) => {
            if (node.id === updatedStep.key) {
                node.data = updatedStep;
            }
            return node;
        });
        setSelectedStep(undefined);
        setStepDataCollectedSoFarForSelectedStep(undefined);
        return updatedNodes;
    };

    const clearSelectedStep = () => {
        setSelectedStep(undefined);
        setStepDataCollectedSoFarForSelectedStep(undefined);
    };

    useEffect(() => {
        if (paramStepId) {
            const step = mapNodesToSteps(nodes).find((step) => step.id === paramStepId);
            setSelectedStep(step);
            setSearchParams();
        }
    }, [paramStepId, nodes, setSearchParams]);

    return {
        selectedStep,
        handleNodeClick,
        updateStep,
        stepDataCollectedSoFarForSelectedStep,
        clearSelectedStep
    }
}