import { FC } from 'react';
import { StepExecutionDataDto, StepDataType, PlayExecutionDataInputDto } from '../../../dtos';
import { DateStepDataInput } from './DateStepDataInput';
import { FileStepDataInput } from './FileStepDataInput';
import { TextStepDataInput } from './TextStepDataInput';
import { BooleanStepDataInput } from './BooleanStepDataInput';

export interface IStepDataInputProps {
    stepData: StepExecutionDataDto | PlayExecutionDataInputDto;
    onChange: (newValue: string) => void;
    entityId?: string;
    errorMessage?: string;
    fieldLabelSuffix?: string;
    disabled?: boolean;
    showDataLabel?: boolean;
}

// Map stepData.type to corresponding components
const inputComponentMap: Record<StepDataType, FC<IStepDataInputProps>> = {
    0: TextStepDataInput,
    1: DateStepDataInput,
    2: FileStepDataInput,
    3: BooleanStepDataInput,
};

export const StepDataInput: FC<IStepDataInputProps> = ({ entityId, stepData, onChange, errorMessage, fieldLabelSuffix, disabled, showDataLabel = true }) => {
    const InputComponent = inputComponentMap[stepData.dataType ?? ''];

    if (!InputComponent) {
        return null;
    }

    return <InputComponent entityId={entityId} stepData={stepData} onChange={onChange} errorMessage={errorMessage} fieldLabelSuffix={fieldLabelSuffix} disabled={disabled} showDataLabel={showDataLabel} />;
};
