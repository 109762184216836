import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, useReactFlow } from 'reactflow';

export const DeletableEdge: FC<EdgeProps> = (props) => {
    const { id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = { strokeWidth: '2px' }, markerEnd, label, labelStyle, selected } = props;
    const [edgePath, labelX, labelY] = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });
    const reactFlowInstance = useReactFlow();

    const onEdgeClick = useCallback((evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        evt.stopPropagation();
        reactFlowInstance.deleteElements({
            edges: [{
                id: id
            }]
        });
    }, [reactFlowInstance, id]);

    const renderLabelContent = useMemo(() => {
        if (selected) {
            return (
                <IconButton
                    disableRipple
                    onClick={onEdgeClick}
                    size='small'
                    sx={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        background: '#eeeeee',
                        pointerEvents: 'all'
                    }}>
                    <Delete fontSize='inherit' />
                </IconButton>
            )
        } else if (label) {
            return (
                <div style={{
                    position: 'absolute',
                    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                    padding: '4px',
                    borderRadius: 4,
                    fontSize: 12,
                    background: '#fafafa',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    ...labelStyle
                }}>
                    {label}
                </div>
            )
        } else {
            return null;
        }
    }, [label, labelX, labelY, onEdgeClick, selected, labelStyle]);

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                {renderLabelContent}
            </EdgeLabelRenderer>
        </>
    );
};
