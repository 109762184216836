import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { SxProps, Theme } from '@mui/material';
import { FC, MouseEventHandler, useMemo } from 'react';

export interface IExpandableSectionHandleProps {
    isExpanded: boolean;
    setIsExpanded: (val: boolean) => void;
    direction: 'right' | 'left';
    backgroundColor?: string;
}

interface IIconProps {
    fontSize: 'small' | 'large' | 'inherit' | 'medium';
    sx: SxProps<Theme>;
    onClick: MouseEventHandler<SVGSVGElement>;
}

export const ExpandableSectionHandle: FC<IExpandableSectionHandleProps> = ({
    isExpanded,
    setIsExpanded,
    direction,
    backgroundColor = '#fafafa'
}) => {

    const iconProps: IIconProps = useMemo(() => {
        var baseStyles: SxProps<Theme> = { width: 24, height: 32, position: 'absolute', top: 'calc(50% - 16px)', border: '2px solid #e4e4e4', cursor: 'pointer', backgroundColor: backgroundColor, zIndex: 4, color: '#808080' }

        switch (direction) {
            case 'right':
                baseStyles.left = '-2px';
                baseStyles.borderRadius = '0px 16px 16px 0px';
                baseStyles.borderLeft = 'none';
                break;
            case 'left':
                baseStyles.right = '-2px';
                baseStyles.borderRadius = '16px 0px 0px 16px';
                baseStyles.borderRight = 'none';
                break;
        }
        
        return {
            fontSize: 'large',
            sx: baseStyles,
            onClick: () => setIsExpanded(!isExpanded)
        }
    }, [setIsExpanded, isExpanded, backgroundColor, direction])

    const ExpandedIcon = useMemo(() => {
        switch (direction) {
            case 'right':
                return KeyboardArrowLeft;       
            case 'left':
                return KeyboardArrowRight;
            default:
                throw new Error("Invalid direction");
                
        }
    }, [direction]);

    const UnexpandedIcon = useMemo(() => {
        switch (direction) {
            case 'right':
                return KeyboardArrowRight;       
            case 'left':
                return KeyboardArrowLeft;
            default:
                throw new Error("Invalid direction");
                
        }
    }, [direction]);

    if (isExpanded) {
        return <ExpandedIcon {...iconProps} />
    } else {
        return <UnexpandedIcon {...iconProps} />
    }
}