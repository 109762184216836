export const generatedApiTags = [
	'AI',
	'Attachment',
	'AutoSavePlay',
	'Client',
	'ClientJobFunction',
	'Coach',
	'Email',
	'Events',
	'Notification',
	'Play',
	'PlayExecution',
	'Playbook',
	'Reminders',
	'Step',
	'StepExecution',
	'User'
]