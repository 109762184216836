import { Help, OpenInNew } from '@mui/icons-material';
import { Grid, IconButton, Switch, Tabs, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useContext } from 'react';
import { ClientPlaySelect } from '../../../../../Components/CommonInputs';
import { PlayDto } from '../../../../../dtos';
import { NameInput, PlayInputDataMappingInput, PlayOutputDataMappingInput, WYSIWYGDescription } from '../../../Components/Modals/EditStepModalForms/StepFormParts';
import { TabWithErrorIndicator } from '../../../Components/Modals/EditStepModalForms/StepFormParts/TabWithErrorIndicator';
import { StepEditorContext } from '../../../Utils/StepEditorContext';
import { IStepEditModalProps } from '../../types';

export const SubplayNodeEditForm: FC<IStepEditModalProps> = ({
    step,
    collectedData
}) => {
    const {
        formStep,
        tabIndex,
        isGeneralValid,
        isInputValid,
        isOutputValid,
        setTabIndex,
        handleChangeTabIndex,
        fieldErrors,
        handleNameChange,
        handleSubplayChange,
        handleIsImmediatelyCompletedChange,
        handleSubplayInputChange,
        handleSubplayOutputChange,
        handleDescriptionChange
    } = useContext(StepEditorContext);

    const excludeCurrentPlayFilter = useCallback((play: PlayDto) => {
        return play.id !== step?.playId;
    }, [step?.playId])

    const handleOpenInNewClicked = useCallback(() => {
        if (formStep.subplay) {
            window.open(`/playbook/${formStep.subplay.playbookId}/play/${formStep.subplayId}`, '_blank');
        }
    }, [formStep.subplay, formStep.subplayId]);

    return (
        <Grid container direction='column' height='calc(100%-30px)' mb={5}>
            <Grid item xs={12} borderBottom={'1px solid #e0e0e0'} zIndex={5} position='sticky' top={0} sx={{ background: 'white' }} className='draggable-ignored'>
                <Tabs value={tabIndex} onChange={handleChangeTabIndex} aria-label='edit step tabs' variant='fullWidth'>
                    <TabWithErrorIndicator label='GENERAL' index={0} isValid={isGeneralValid} setValue={setTabIndex} />
                    <TabWithErrorIndicator label='INPUT' index={1} isValid={isInputValid} setValue={setTabIndex} />
                    <TabWithErrorIndicator label='OUTPUT' index={2} isValid={isOutputValid} setValue={setTabIndex} disabled={formStep.isImmediatelyCompleted} />
                </Tabs>
            </Grid>
            {tabIndex === 0 && (
                <Grid item container direction='row' px={4} pt={3} spacing={1}>
                    <NameInput fieldErrors={fieldErrors} name={formStep.name ?? ''} handleNameChange={handleNameChange} dataOptions={collectedData} />
                    <Grid item xs={12}>
                        <div style={{paddingBottom : 8, paddingTop: 4}}>
                            <WYSIWYGDescription
                                fieldErrors={fieldErrors}
                                description={step?.description ?? ''}
                                handleDescriptionChange={handleDescriptionChange}
                                collectedData={collectedData}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <ClientPlaySelect
                            label='Subplay'
                            selectedPlayId={formStep.subplayId ?? ''}
                            handleSelectedPlayChange={handleSubplayChange}
                            required
                            errorMessage={fieldErrors.get('subplayId')}
                            playFilter={excludeCurrentPlayFilter}
                        />
                    </Grid>
                    <Grid item xs={1} mt={3.5}>
                        <IconButton
                            color='primary'
                            disabled={formStep.subplay === undefined}
                            onClick={handleOpenInNewClicked}>
                            <OpenInNew />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} container direction='row' alignItems='center'>
                        <Switch checked={!formStep.isImmediatelyCompleted} onChange={handleIsImmediatelyCompletedChange} />
                        <Typography>
                            Wait until Subplay is Completed
                        </Typography>
                        <Tooltip title="Must wait until Subplay is completed to collect output data. When this is 'off', the output tab will be disabled." sx={{ marginLeft: 1 }}>
                            <IconButton>
                                <Help />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            )}
            {tabIndex === 1 && (
                <Grid item container direction='row' px={4} pt={3} spacing={1}>
                    <PlayInputDataMappingInput
                        playInputData={formStep.subplay?.playDataInput ?? []}
                        collectedData={collectedData ?? []}
                        dataMappingConfigs={formStep.subplayDataInputConfig ?? []}
                        onDataMappingConfigsUpdated={handleSubplayInputChange}
                    />
                </Grid>
            )}
            {tabIndex === 2 && (
                <Grid item container direction='row' px={4} pt={3} spacing={1}>
                    <PlayOutputDataMappingInput
                        playOutputData={formStep.subplay?.playDataOutput ?? []}
                        dataMappingConfigs={formStep.subplayDataOutputConfig ?? []}
                        onDataMappingConfigsUpdated={handleSubplayOutputChange}
                    />
                </Grid>
            )}
        </Grid>
    );
}