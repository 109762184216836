import { Alert, Grid, TextField } from '@mui/material';
import { FC, useMemo } from 'react';
import { IStepExecutionDetailsProps } from '../../types';
import { StepNameDetails } from '../../../../PlayHistory/Components/StepDetailSections/Components/StepNameDetails';
import { DelayMethod } from '../../../../../dtos/generated/DelayMethod';
import { formatTime, renderWithRequiredIndicator, formatTimestamp } from '../../../../../util';
import { StepDescriptionDetails } from '../../../../PlayHistory/Components/StepDetailSections/Components';

export const DelayNodeExecutionDetails: FC<IStepExecutionDetailsProps> = ({ stepExecution }) => {

    const formattedDelayTime = useMemo(() => {
        return formatTime(stepExecution.delayMinutes, 'long');
    }, [stepExecution.delayMinutes]);

    const formattedCompletionDate = useMemo(() => {
        return stepExecution.completedOn ? formatTimestamp(stepExecution.completedOn) : '';
    }, [stepExecution.completedOn]);

    const getDelayValueOrLabel = useMemo(() => {
        return stepExecution.delayUntilDate ? formatTimestamp(stepExecution.delayUntilDate) : renderWithRequiredIndicator(stepExecution.delayDateLabel ?? '', !!stepExecution.isDelayDateLabelRequired);
    }, [stepExecution]);

    const renderDelayInfo = useMemo(() => {
        switch (stepExecution.delayMethod) {
            case DelayMethod.MINUTES:
                return (
                    <>
                        <Grid item xs={12} pt={2}>
                            Delay Method: Delay for an amount of time
                        </Grid>
                        <Grid item xs={12}>
                            Delay For: {formattedDelayTime}
                        </Grid>
                        <Grid item xs={12}>
                            {stepExecution.isComplete && (
                                <>Delayed Until: {formattedCompletionDate}</>
                            )}
                        </Grid>
                    </>
                );
            case DelayMethod.UNTIL_DATE:
                return (
                    <>
                        <Grid item xs={12} pt={2}>
                            Delay Method: Delay until a date
                        </Grid>
                        <Grid item xs={12}>
                            <TextField InputProps={{readOnly: true}} sx={{pointerEvents: 'none'}} value={getDelayValueOrLabel} />
                        </Grid>
                    </>
                );
        }
    }, [formattedCompletionDate, formattedDelayTime, stepExecution, getDelayValueOrLabel]);

    return (
        <>
            {stepExecution.status.toUpperCase() === 'SKIPPED' &&
                    <Grid item pt={2}>
                        <Alert severity='warning'>
                            Skipped on {formattedCompletionDate} by {stepExecution.completedByUserName}
                        </Alert>
                    </Grid>
            }
            {renderDelayInfo}
            <StepNameDetails name={stepExecution.name ?? ''} />
            <StepDescriptionDetails description={stepExecution.description ?? ''} />
        </>
    );
};
