import { Box } from '@mui/material';
import { CompositeDecorator, ContentState, Editor, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

interface IDraftWysiwygTextFieldProps {
    initialValue: string;
    onContentChange: (newValue: string) => void;
    decorators: CompositeDecorator;
    placeholder?: string;
}

export const DraftWysiwygTextField: FC<IDraftWysiwygTextFieldProps> = ({ initialValue, onContentChange, decorators, placeholder }) => {
    const prevInitialValue = useRef(initialValue);
    const [hasManuallyChangedValue, setHasManuallyChangedValue] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText(initialValue), decorators));

    // There are times where we render the component before the initial value has been set.
    // This will update the state to match the initial value as long as the user has not manually interacted with the field.
    useEffect(() => {
        const hasInitialValueChanged = prevInitialValue.current !== initialValue;
        if (hasInitialValueChanged && !hasManuallyChangedValue) {
            setEditorState(EditorState.createWithContent(ContentState.createFromText(initialValue), decorators));
        }
        prevInitialValue.current = initialValue;
    }, [initialValue, hasManuallyChangedValue, decorators]);

    const handleEditorChange = useCallback(
        (newEditorState: EditorState) => {
            setEditorState(newEditorState);
            onContentChange(newEditorState.getCurrentContent().getPlainText());
            setHasManuallyChangedValue(true);
        },
        [onContentChange]
    );

    return (
        <Box
            sx={{ width: '100%', minHeight: 24, padding: '16px 8px', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '4px' }}
            className='draggable-element'>
            <Editor editorState={editorState} onChange={handleEditorChange} spellCheck placeholder={placeholder} />
        </Box>
    );
};
