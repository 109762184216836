import { Cancel, Delete, Help, MoreVert, Plumbing, Save } from '@mui/icons-material';
import { Button, Divider, FormControlLabel, Grid, IconButton, Menu, MenuItem, Switch, Tooltip } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientJobFunctionDto } from '../../../dtos';
import { useJobFunctionByIdArchiveMutation } from '../../../store/generated/generatedApi';
import {
    AuthenticatedComponent,
    FormInput,
    FormSection,
    IFormProps,
    SlimPageHeader,
    useFailedActionSnackbar,
    useSuccessfulActionSnackbar,
} from '../../CoreLib/library';
import { useJobFunctionForm } from './useJobFunctionForm';

export const JobFunctionForm: FC<IFormProps<ClientJobFunctionDto>> = (props) => {
    const { isLoading } = props;
    const { handleSave, handleCancel, fieldErrors, isFormDirty, formJobFunction, handleTextFieldChange, handleIsActiveChange } = useJobFunctionForm(props);
    const navigate = useNavigate();
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [deleteClientJobFunction, { isLoading: isDeleting, isSuccess: isDeleteSuccess, isError: isDeleteError, reset: resetDelete }] =
        useJobFunctionByIdArchiveMutation();

    useSuccessfulActionSnackbar('deleted', 'Job Function', isDeleteSuccess, () => {
        resetDelete();
        navigate('/jobFunctions');
    });
    useFailedActionSnackbar('delete', 'Job Function', isDeleteError, resetDelete);

    const currentJobFunctionId = useMemo(() => {
        return props.initValues?.id;
    }, [props.initValues?.id]);

    const handleDelete = () => {
        if (currentJobFunctionId) {
            deleteClientJobFunction({
                id: currentJobFunctionId,
            });
        }
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    };

    const displayJobFunctionName = useMemo(() => (formJobFunction.name ? formJobFunction.name : 'New Job Function'), [formJobFunction.name]);

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item>
                <SlimPageHeader
                    icon={<Plumbing />}
                    title={displayJobFunctionName}
                    breadCrumbProps={{
                        links: [
                            { label: 'Home', navLink: '/' },
                            { label: 'Admin', navLink: '', isText: true },
                            { label: 'Job Functions', navLink: '/jobFunctions' },
                        ],
                        currentPageLabel: displayJobFunctionName,
                    }}
                    endTitleSlot={
                        <Tooltip title='A job function can have one person or many. When you run a play, I will rotate step completion between job function members.'>
                            <IconButton>
                                <Help />
                            </IconButton>
                        </Tooltip>
                    }
                    endSlot={
                        <Grid item container direction='row' alignItems='start' py={1}>
                            <Grid item display='flex' alignItems='center' justifyContent={'end'} xs={12} sx={{ gap: 1 }} pr={2}>
                                <Divider flexItem orientation='vertical' />
                                <FormControlLabel
                                    sx={{ mr: 1 }}
                                    control={<Switch checked={formJobFunction.isActive} onChange={handleIsActiveChange} size='small' />}
                                    label='Active'
                                    labelPlacement='start'
                                />
                                {currentJobFunctionId && (
                                    <AuthenticatedComponent requiredPermissions={[`delete:jobFunctions`]}>
                                        <Tooltip title='Delete'>
                                            <IconButton size='large' onClick={handleDelete} disabled={isDeleting} sx={{ pl: 0 }}>
                                                <Delete fontSize='inherit' />
                                            </IconButton>
                                        </Tooltip>
                                    </AuthenticatedComponent>
                                )}
                                <Divider flexItem orientation='vertical' sx={{ mx: 1, ml: 0 }} />
                                <Button variant='outlined' color='primary' size='small' onClick={handleCancel} startIcon={<Cancel />} sx={{ width: 90 }}>
                                    {isFormDirty ? 'Cancel' : 'Close'}
                                </Button>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    onClick={handleSave}
                                    disabled={isLoading}
                                    startIcon={<Save />}
                                    sx={{ width: 90 }}>
                                    Save
                                </Button>
                                <IconButton onClick={handleMenuOpen}>
                                    <MoreVert fontSize='inherit' />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={!!menuAnchorEl}
                                    onClose={handleMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <MenuItem onClick={handleMenuClose(handleSave)}>Save</MenuItem>
                                    <MenuItem onClick={handleMenuClose(handleCancel)}>{isFormDirty ? 'Cancel' : 'Close'}</MenuItem>
                                    {currentJobFunctionId && (
                                        <AuthenticatedComponent requiredPermissions={[`delete:jobFunctions`]}>
                                            <Divider />
                                            <MenuItem onClick={handleMenuClose(handleDelete)}>Delete</MenuItem>
                                        </AuthenticatedComponent>
                                    )}
                                </Menu>
                            </Grid>
                        </Grid>
                    }
                />
            </Grid>
            <Grid item mx={2}>
                <FormSection>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item xs={4}>
                            <FormInput
                                fullWidth
                                required
                                disabled={isLoading}
                                label='Name'
                                value={formJobFunction.name}
                                name='name'
                                onChange={handleTextFieldChange}
                                errorText={fieldErrors.get('name')}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormInput
                                fullWidth
                                disabled={isLoading}
                                label='Description'
                                value={formJobFunction.description}
                                name='description'
                                onChange={handleTextFieldChange}
                                errorText={fieldErrors.get('description')}
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    );
};
