import { Preview } from '@mui/icons-material';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AuthenticatedComponent, LoadingIndicator } from '../../../Components/CoreLib/library';
import { DeepChat } from 'deep-chat-react';
import { useAuth0 } from '@auth0/auth0-react';
import { MessageContentDto, MessageResponseDto, StepExecutionDataDto, StepExecutionMessageResponseDto } from '../../../dtos';
import coachBotAvatar from '../../../assets/Coach-Bot-Avatar.png';
import { useLazyGetPlayExecutionByPlayIdStepByStepIdChatQuery } from '../../../store/generated/generatedApi';

export interface IChatStepDataCollectionProps {
    stepExecutionId: string;
    playExecutionId: string;
    stepData: StepExecutionDataDto[];
    onStepCompleted: () => void;
    initialThreadId?: string;
}

export const ChatStepDataCollection: FC<IChatStepDataCollectionProps> = ({ stepExecutionId, playExecutionId, stepData, onStepCompleted, initialThreadId }) => {
    const { user: auth0User, getAccessTokenSilently } = useAuth0();
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [threadId, setThreadId] = useState<string | undefined>(initialThreadId);
    const threadIdWithNoRerender = useRef<string | null>('');
    const [getExistingChatMessages] = useLazyGetPlayExecutionByPlayIdStepByStepIdChatQuery();
    const [isLoadingChatMessages, setIsLoadingChatMessages] = useState(false);
    const [loadedChatMessages, setLoadedChatMessages] = useState<MessageResponseDto[]>([]);
    const theme = useTheme();

    useEffect(() => {
        if (!threadId || threadIdWithNoRerender.current) {
            return;
        }
        getExistingChatMessages({ playId: playExecutionId, stepId: stepExecutionId })
            .unwrap()
            .then(setLoadedChatMessages)
            .finally(() => setIsLoadingChatMessages(false));
        setIsLoadingChatMessages(true);
    }, [getExistingChatMessages, playExecutionId, stepExecutionId, threadId]);

    useEffect(() => {
        if (accessToken) {
            return;
        }
        (async () => {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    redirect_uri: window.location.origin,
                    scope: 'SCOPE profile email offline_access',
                },
            });
            setAccessToken(accessToken);
        })();
    }, [accessToken, getAccessTokenSilently]);

    const deepChatRequestInterceptor = useCallback(
        (details: any) => {
            details.headers.authorization = `Bearer ${accessToken}`;
            if (threadIdWithNoRerender.current) {
                details.body.threadId = threadIdWithNoRerender.current;
            }
            return details;
        },
        [accessToken]
    );

    const deepChatResponseInterceptor = useCallback(
        (response: StepExecutionMessageResponseDto) => {
            threadIdWithNoRerender.current = response.threadId;
            setThreadId(response.threadId);
            if (response.hasCompletedStep) {
                onStepCompleted();
            }
            return response as any;
        },
        [setThreadId, onStepCompleted]
    );

    const handleThreadClick = () => {
        const url = `https://platform.openai.com/playground/assistants?thread=${threadId}`;
        window.open(url);
    };

    const MemorizedDeepChatComponent = useMemo(() => {
        if (!stepData || stepData.length === 0) {
            return;
        }

        let allFormDataNames = stepData.map((sd) => sd.name).join(', ');
        // replace last comma with and
        const lastCommaIndex = allFormDataNames.lastIndexOf(',');
        if (lastCommaIndex !== -1) {
            allFormDataNames = allFormDataNames.slice(0, lastCommaIndex) + ' and' + allFormDataNames.slice(lastCommaIndex + 1);
        }

        let initialMessages: MessageContentDto[] = [
            { role: 'ai', text: `I'm going to help you provide values for the some play data.` },
            {
                role: 'ai',
                text: `You can start by typing one of the elements listed below, as well as the value you would like to set (e.g., "The email address is sample@email.com")`,
            },
            { role: 'ai', text: `Here's the data we need to collect: ${allFormDataNames}` },
        ];

        if (loadedChatMessages.length > 0) {
            initialMessages = [...initialMessages, ...[...loadedChatMessages].reverse().slice(1)];
        }

        return (
            <DeepChat
                request={{ url: `${process.env.REACT_APP_BASE_URL}/api/playExecution/${playExecutionId}/step/${stepExecutionId}/chat/data` }}
                requestInterceptor={deepChatRequestInterceptor}
                responseInterceptor={deepChatResponseInterceptor}
                initialMessages={initialMessages as any[]}
                style={{ border: 'none', width: '100%', height: '100%' }}
                avatars={{
                    default: {
                        styles: {
                            avatar: {
                                paddingTop: '0px',
                                marginTop: '5px',
                            },
                        },
                    },
                    user: {
                        src: auth0User?.picture ?? undefined,
                        styles: {
                            avatar: {
                                borderRadius: '50%',
                            },
                        },
                    },
                    ai: {
                        src: coachBotAvatar,
                    },
                }}
                messageStyles={{
                    default: {
                        user: {
                            bubble: {
                                backgroundColor: theme.palette.primary.main,
                            },
                        },
                    },
                }}
            />
        );
    }, [auth0User?.picture, deepChatRequestInterceptor, deepChatResponseInterceptor, stepData, stepExecutionId, playExecutionId, theme.palette.primary.main, loadedChatMessages]);

    if (isLoadingChatMessages) {
        return (
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' height='100%'>
                <LoadingIndicator />
                <Typography>Loading chat messages...</Typography>
            </Box>
        )
    }

    return (
        <>
            {MemorizedDeepChatComponent}
            <AuthenticatedComponent requiredPermissions={['read:developer']}>
                {threadId && (
                    <Button onClick={handleThreadClick}>
                        <Preview />
                        {threadId.toUpperCase()}
                    </Button>
                )}
            </AuthenticatedComponent>
        </>
    );
};
