import { Book } from '@mui/icons-material';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import {
    FormInput,
    LoadingBackdrop,
    useFailedCreateSnackbar,
    useFailedUpdateSnackbar,
    useSuccessfulCreateSnackbar,
    useSuccessfulUpdateSnackbar
} from '../../../Components/CoreLib/library';
import { useConfirmDelete } from '../../../customHooks';
import { PlaybookDto } from '../../../dtos';
import { useCreatePlaybookMutation, usePlaybookByIdArchiveMutation, useUpdatePlaybookMutation } from '../../../store/generated/generatedApi';
import { emptyGuid } from '../../../util';
import { ModalSaveCloseFooter, ModalTitle } from '../../Plays/Components/SubComponents';
import { usePlaybookForm } from '../Utils';

export interface IAddEditPlaybookModalProps {
    closeModal: () => void;
    playbook?: PlaybookDto;
}

export const AddEditPlaybookModal: FC<IAddEditPlaybookModalProps> = ({ closeModal, playbook }) => {
    const [createPlaybook, { isError: isCreatePlaybookError, isSuccess: isCreatePlaybookSuccess, isLoading: isCreatingPlaybook, reset: resetCreatePlaybook }] =
        useCreatePlaybookMutation();
    const [updatePlaybook, { isError: isUpdatePlaybookError, isSuccess: isUpdatePlaybookSuccess, isLoading: isUpdatingPlaybook, reset: resetUpdatePlaybook }] =
        useUpdatePlaybookMutation();

    const { setObjectToDelete, isDeletingObject, renderDeleteConfirmationModal } = useConfirmDelete(
        'Playbook',
        usePlaybookByIdArchiveMutation,
        undefined,
        closeModal
    );

    const handleShowDeleteConfirmationModal = useCallback(
        (objectToDelete: any) => {
            setObjectToDelete(objectToDelete);
        },
        [setObjectToDelete]
    );

    const isLoading = useMemo(() => {
        return isCreatingPlaybook || isUpdatingPlaybook || isDeletingObject;
    }, [isCreatingPlaybook, isUpdatingPlaybook, isDeletingObject]);

    useSuccessfulCreateSnackbar('playbook', isCreatePlaybookSuccess, () => {
        resetCreatePlaybook();
        closeModal();
    });
    useFailedCreateSnackbar('playbook', isCreatePlaybookError, resetCreatePlaybook);
    useSuccessfulUpdateSnackbar('playbook', isUpdatePlaybookSuccess, () => {
        resetUpdatePlaybook();
        closeModal();
    });
    useFailedUpdateSnackbar('playbook', isUpdatePlaybookError, resetUpdatePlaybook);

    const handleSave = useCallback(
        (playbookToSave: PlaybookDto) => {
            if (isLoading) {
                return;
            }

            if (playbookToSave.id === emptyGuid) {
                createPlaybook(playbookToSave);
            } else {
                updatePlaybook(playbookToSave);
            }
        },
        [isLoading, createPlaybook, updatePlaybook]
    );

    const {
        fieldErrors,
        isFormDirty,
        handleInitialsChange,
        formPlaybook,
        handleTextFieldChange,
        handleSave: saveForm,
    } = usePlaybookForm({
        save: handleSave,
        cancel: closeModal,
        initValues: playbook,
        isLoading: isLoading,
    });

    const isEdit = useMemo(() => {
        if (playbook?.id && playbook.id !== emptyGuid) {
            return true;
        }
        return false;
    }, [playbook?.id]);

    return (
        <>
            {renderDeleteConfirmationModal}
            <Dialog open={!!playbook} fullWidth maxWidth='md'>
                <ModalTitle icon={<Book sx={{ mr: 1 }} />} title={isEdit ? 'Edit Playbook' : 'New Playbook'} />
                <DialogContent sx={{ padding: 0 }}>
                    <Grid container direction='column' height='100%'>
                        <Grid item container direction='row' height='calc(100% - 40px)'>
                            <Grid item container direction='row' height='calc(100% - 40px)'>
                                <Grid item container direction='column' width='200px' sx={{ background: '#adcbee' }}>
                                    <img src='/assets/Playbook-Cover.png' alt='clipboard that says play book' width={160} style={{ margin: '16px auto' }} />
                                </Grid>
                                <Grid item container direction='column' width='calc(100% - 200px)' p={1} gap={1}>
                                    <Grid item container direction='row' columnSpacing={2} rowSpacing={1}>
                                        <Grid item xs={10}>
                                            <FormInput
                                                fullWidth
                                                required
                                                disabled={isLoading}
                                                label='Name'
                                                value={formPlaybook.name}
                                                name='name'
                                                onChange={handleTextFieldChange}
                                                errorText={fieldErrors.get('name')}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormInput
                                                fullWidth
                                                required
                                                disabled={isLoading}
                                                label='Initials'
                                                value={formPlaybook.initials}
                                                name='initials'
                                                onChange={handleInitialsChange}
                                                errorText={fieldErrors.get('initials')}
                                                maxLength={2}
                                                hideMaxLengthText
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormInput
                                                fullWidth
                                                disabled={isLoading}
                                                label='Description'
                                                value={formPlaybook.description}
                                                name='description'
                                                onChange={handleTextFieldChange}
                                                errorText={fieldErrors.get('description')}
                                                rows={3}
                                            />
                                        </Grid>
                                    </Grid>
                                    <ModalSaveCloseFooter
                                        isFormDirty={isFormDirty}
                                        onSaveClicked={saveForm}
                                        onCancelCloseClicked={closeModal}
                                        onDeleteClicked={() => handleShowDeleteConfirmationModal(playbook)}
                                        hideDeleteButton={!isEdit}
                                        isSaveDisabled={isLoading}
                                        deletePermissions={[`delete:playbooks`]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <LoadingBackdrop loadingOptions={[{ isLoading: isCreatingPlaybook, loadingMessage: 'Creating playbook...' }]} />
        </>
    );
};
