import { DoubleArrow } from '@mui/icons-material';
import { Button, Dialog, DialogContent, Grid, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { ClientJobFunctionDto } from '../../../../dtos';
import { JobFunctionUserListView } from '../../../JobFunction/Components';

export interface IViewJobFunctionMembersModalProps {
    jobFunction?: ClientJobFunctionDto;
    onClose: () => void;
}

export const ViewJobFunctionMembersModal: FC<IViewJobFunctionMembersModalProps> = (props) => {
    const { jobFunction, onClose } = props;
    const theme = useTheme();

    return (
        <Dialog open={!!jobFunction} fullWidth maxWidth='lg' onClose={onClose}>
            <DialogContent sx={{ padding: 0 }}>
                <Grid container direction='column' height='100%'>
                    {/* TOD: Replace with ModalTitle or other shared component */}
                    <Grid
                        item
                        container
                        direction='row'
                        alignItems='center'
                        p={1}
                        sx={{ background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                        position='sticky'
                        top={0}
                        zIndex={5}>
                        <DoubleArrow sx={{ mr: 1 }} />
                        <Typography fontWeight='bold'>{jobFunction?.name} Members</Typography>
                    </Grid>
                    <Grid item container direction='row' height='calc(100% - 40px)'>
                        {jobFunction && <JobFunctionUserListView jobFunctionId={jobFunction.id} jobFunctionName={jobFunction.name} />}
                        <Button variant='contained' color='secondary' sx={{ mr: 2, mb: 2, ml: 'auto' }} onClick={onClose}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
