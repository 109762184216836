import { Autocomplete, TextField, Typography, createFilterOptions } from '@mui/material';
import {FC, useCallback, useContext, useMemo} from 'react';
import { PlayVariableDto, StepDataType } from '../../dtos';
import { AddCircle } from '@mui/icons-material';
import { PlayEditorContext } from '../../Views/Plays/Utils/PlayEditorContext';
import _ from 'lodash';
import { StepDataTypeToName } from '../../util';

export interface IPlayVariableAutocompleteWithAddProps {
    value: PlayVariableDto | null;
    onChange: (newValue: PlayVariableDto | null) => void;
    playVariableOptions: PlayVariableDto[];
    error?: boolean;
    disableAddNew?: boolean;
};

export const PlayVariableAutocompleteWithAdd: FC<IPlayVariableAutocompleteWithAddProps> = ({ value, onChange, playVariableOptions, error, disableAddNew }) => {
    const { playVariables, playId, setPlayVariables } = useContext(PlayEditorContext);
    const canAddNewVariables = useMemo(() => !disableAddNew, [disableAddNew]); 
    const isNewVariable = useCallback((variableName: string) => playVariables.findIndex(pv => pv.name.toLowerCase() === variableName.toLowerCase()) === -1, [playVariables]);
    const dataTypeLabel = useCallback((type: StepDataType | undefined) => {
        if (type === undefined) {
            return '';
        }

        return StepDataTypeToName.get(type);
    }, []);

    const handleStepDataChange = useCallback((value: PlayVariableDto | null) => {
        if (!value) {
            onChange(null);
            return;
        }
        if (canAddNewVariables && isNewVariable(value.name)) {
            setPlayVariables([...playVariables, _.cloneDeep(value)]);
            onChange(value);
        } else {
            onChange(value);
        }
    },
    [setPlayVariables, playVariables, isNewVariable, onChange, canAddNewVariables]
);

    const renderDropDownItem = useCallback((props: React.HTMLAttributes<HTMLLIElement>, option: PlayVariableDto) => {
        if (canAddNewVariables && isNewVariable(option.name)) {
            return <li {...props}><AddCircle color='primary' sx={{paddingRight:'8px'}}/><Typography>Add "{option.name}"</Typography></li>
        } else {
            return <li {...props}><Typography>{option.name} ({dataTypeLabel(option.dataType)})</Typography></li>
        }
    }, [isNewVariable, dataTypeLabel, canAddNewVariables])

    const autocompleteOptionsFilter = createFilterOptions<PlayVariableDto>();

    return (
        <Autocomplete
            value={value}
            onChange={(_, value) => handleStepDataChange(value)}
            autoHighlight
            options={playVariableOptions}
            getOptionLabel={(x) => x.name + ' (' + dataTypeLabel(x.dataType) + ')'}
            renderOption={renderDropDownItem}
            isOptionEqualToValue={(opt, val) => opt.name === val.name}
            renderInput={(params) => <TextField error={error} {...params} />}
            filterOptions={(options, params) => {
                const { inputValue } = params;
                const filteredOptions = autocompleteOptionsFilter(options, params);
                
                if (canAddNewVariables && inputValue && isNewVariable(inputValue)) {
                    filteredOptions.push({
                        name: inputValue,
                        playId: playId,
                        dataType: 0
                    });
                }
                return filteredOptions;
            }}
        />
    );
};