import { useEffect, useState } from 'react';

export const useDragStatus = () => {
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        const handleDragStart = () => {
            setIsDragging(true);
        };

        const handleDragEnd = () => {
            setIsDragging(false);
        };

        document.addEventListener('dragstart', handleDragStart);
        document.addEventListener('dragend', handleDragEnd);

        return () => {
            document.removeEventListener('dragstart', handleDragStart);
            document.removeEventListener('dragend', handleDragEnd);
        };
    }, []);

    return isDragging;
};