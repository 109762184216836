import { AddCircle } from '@mui/icons-material';
import { Button, Dialog, DialogContent, Grid, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { DataTableColumn, PaginatedProps, StandardListViewV2, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../Components/CoreLib/library';
import { UserDto } from '../../../dtos';
import { useGetJobFunctionByJobFunctionIdAddableUsersQuery, useUpdateJobFunctionByJobFunctionIdAddMutation } from '../../../store/generated/generatedApi';

const DEFAULT_PAGINATION_PROPS: PaginatedProps = {
    sortKey: 'FIRST_NAME',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

const TABLE_COLUMNS: DataTableColumn<UserDto>[] = [
    { key: 'firstName', label: 'First Name', sortKey: 'FIRST_NAME' },
    { key: 'lastName', label: 'Last Name', sortKey: 'LAST_NAME' },
    { key: 'role', label: 'User Role', sortKey: 'ROLE' },
    { key: 'email', label: 'Email', sortKey: 'EMAIL' },
    { key: 'phoneNumber', label: 'Phone', sortKey: 'PHONE_NUMBER' },
];

export interface IJobFunctionAddUsersModalProps {
    jobFunctionId: string;
    jobFunctionName: string;
    isOpen: boolean;
    handleClose: () => void;
}

export const JobFunctionAddUsersModal: FC<IJobFunctionAddUsersModalProps> = (props) => {
    const { jobFunctionId, jobFunctionName, isOpen, handleClose } = props;
    const theme = useTheme();
    const [selectedUsers, setSelectedUsers] = useState<UserDto[]>([]);
    const [addUsersToJobFunction, { isSuccess, isError, isLoading: isAddingUsers, error, reset: resetUserBulkAdd }] =
        useUpdateJobFunctionByJobFunctionIdAddMutation();
    useSuccessfulActionSnackbar('added', 'user(s)', isSuccess, () => {
        resetUserBulkAdd();
        handleClose();
    });
    useFailedActionSnackbar('add', 'user(s)', isError, resetUserBulkAdd, error);

    const handleAddUsersToJobFunction = useCallback(() => {
        if (isAddingUsers) {
            return;
        }
        const userIds = selectedUsers.map((u) => u.id);

        addUsersToJobFunction({ params: { jobFunctionId: jobFunctionId }, payload: userIds });
    }, [jobFunctionId, isAddingUsers, addUsersToJobFunction, selectedUsers]);

    return (
        <Dialog open={isOpen} fullWidth maxWidth='lg'>
            <DialogContent sx={{ padding: 0 }}>
                <Grid container direction='column' height='100%'>
                    {/* TOD: Replace with ModalTitle or other shared component */}
                    <Grid
                        item
                        container
                        direction='row'
                        alignItems='center'
                        p={1}
                        sx={{ background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                        position='sticky'
                        top={0}
                        zIndex={2}>
                        <AddCircle sx={{ mr: 1 }} />
                        <Typography fontWeight='bold'>Add Users to {jobFunctionName}</Typography>
                    </Grid>
                    <StandardListViewV2
                        headerIcon={<></>}
                        headerTitle=''
                        breadCrumbs={[]}
                        defaultPaginationProps={DEFAULT_PAGINATION_PROPS}
                        getDataQuery={useGetJobFunctionByJobFunctionIdAddableUsersQuery}
                        tableColumns={TABLE_COLUMNS}
                        entityNameSingular='user'
                        permissionName='users'
                        isShowActiveToggleVisible={true}
                        additionalQueryParameters={{
                            jobFunctionId,
                        }}
                        onSelectedChange={setSelectedUsers}
                        hideMenu
                    />
                    <Grid item container direction='row' justifyContent='end' p={2} spacing={2}>
                        <Grid item>
                            <Button variant='outlined' color='secondary' onClick={handleClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={handleAddUsersToJobFunction}
                                disabled={isAddingUsers || selectedUsers.length === 0}>
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
