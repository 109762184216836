import { FC, useMemo } from 'react';
import { DraftWysiwygEditor } from '../DraftWysiwygEditor';
import { CompositeDecorator } from 'draft-js';
import { DataReferenceSpan, LinkDecorator, dataReferenceStrategy, findLinkStrategy } from '../TextDecorators';

export interface IWysiwygEditorWithDataReferencesProps {
    initialValue: string;
    onChange: (newValue: string) => void;
    dataReferences: string[];
}

export const WysiwygEditorWithDataReferences: FC<IWysiwygEditorWithDataReferencesProps> = ({ initialValue, onChange, dataReferences }) => {
    const decorators = useMemo(() => {
        return new CompositeDecorator([
            {
                strategy: findLinkStrategy,
                component: LinkDecorator,
            },
            {
                strategy: dataReferenceStrategy,
                component: DataReferenceSpan,
                props: { dataReferences },
            },
        ]);
    }, [dataReferences]);

    return <DraftWysiwygEditor initialValue={initialValue} onChange={onChange} decorators={decorators} />;
};
