import { DoubleArrow } from '@mui/icons-material';
import { Button, Dialog, DialogContent, FormControl, FormHelperText, FormLabel, Grid, OutlinedInput, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { StepDataInput } from '../../../../Components/CommonInputs/StepDataInputs';
import { LoadingBackdrop, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../../Components/CoreLib/library';
import { PlayDto, PlayExecutionDataInputDto, StartPlayDto } from '../../../../dtos';
import { useCreatePlayExecuteMutation } from '../../../../store/generated/generatedApi';
import { useStepDataValidator } from '../../Hooks';
import { ISelectedPlayDetails, useStartPlayForm } from '../../Utils';
import { ModalTitle } from '../SubComponents';
import { StepDescriptionDetails, StepFileAttachmentDetails, StepNameDetails } from '../../../PlayHistory/Components/StepDetailSections/Components';
import { EmbeddingsDataView } from '../../../PlayExecution/Components/StepEmbeddingsView';

export interface IStartPlayModalProps {
    selectedPlayDetails?: ISelectedPlayDetails;
    clearSelectedPlayDetails: () => void;
}

export const StartPlayModal: FC<IStartPlayModalProps> = (props) => {
    const { selectedPlayDetails, clearSelectedPlayDetails: clearSelectedPlay } = props;
    const [hasAttemptedSave, setHasAttemptedSave] = useState(false);
    const [startPlay, { isSuccess: isStartPlaySuccess, isError: isStartPlayError, isLoading: isStartingPlay, reset: resetStartPlay }] =
        useCreatePlayExecuteMutation();
    useSuccessfulActionSnackbar('started', 'play', isStartPlaySuccess, () => {
        resetStartPlay();
        clearSelectedPlay();
    });
    useFailedActionSnackbar('start', 'play', isStartPlayError, resetStartPlay);
    const { validateStepData } = useStepDataValidator();

    const handleSave = (payload: StartPlayDto) => {
        if (isStartingPlay) {
            return;
        }
        startPlay(payload);
    };

    const {
        isFormDirty,
        handleSave: saveForm,
        handleCancel,
        handleRoleAssignmentChange,
        formJobFunctionAssignmentErrors,
        formJobFunctionAssignments,
        formCollectedData,
        handleCollectedDataChange,
    } = useStartPlayForm({
        save: handleSave,
        cancel: clearSelectedPlay,
        selectedPlayDetails: selectedPlayDetails,
    });

    const handleSaveClicked = () => {
        if (!hasAttemptedSave) {
            setHasAttemptedSave(true);
        }
        saveForm();
    };

    const getSubplayName = useCallback(
        (assignment: PlayExecutionDataInputDto) => {
            if (!selectedPlayDetails) {
                return '';
            }
            return assignment.sourcePlayId !== selectedPlayDetails.play.id ? ` (${assignment.sourcePlayName})` : '';
        },
        [selectedPlayDetails]
    );

    useEffect(() => {
        if (selectedPlayDetails) {
            setHasAttemptedSave(false);
        }
    }, [selectedPlayDetails]);

    const startStep = useMemo(() => {
        var startStep;
        if (!!selectedPlayDetails?.play.steps) {
            startStep = selectedPlayDetails.play.steps.find((step) => step.type.toUpperCase() === 'START');
            return startStep;
        }
    }, [selectedPlayDetails]);

    const getFileAttachment = (play: PlayDto) => {
        if (!!play.steps) {
            let startStep = play.steps.find((step) => step.type.toUpperCase() === 'START');
            return startStep?.fileAttachments;
        }
    };

    const getEmbeddings = (play: PlayDto) => {
        if (!!play.steps) {
            let startStep = play.steps.find((step) => step.type.toUpperCase() === 'START');
            return startStep?.embedInputs;
        }
    };

    if (!selectedPlayDetails) {
        return null;
    }

    return (
        <Dialog open={!!selectedPlayDetails} fullWidth maxWidth='md'>
            <ModalTitle icon={<DoubleArrow sx={{ mr: 1 }} />} title='Start Play' />
            <DialogContent>
                <Grid container direction='column'>
                    {formJobFunctionAssignments.length > 0 && (
                        <Grid item>
                            <Typography variant='h2'>External Roles</Typography>
                        </Grid>
                    )}
                    {formJobFunctionAssignments.map((assignment, idx) => (
                        <Grid item pt={2} key={`assignment-${assignment.name}`}>
                            {/* TODO: replace with FormInput component */}
                            <FormControl error={hasAttemptedSave && formJobFunctionAssignmentErrors[idx] !== ''} required fullWidth>
                                <FormLabel>
                                    {assignment.name} (Email){getSubplayName(assignment)}
                                </FormLabel>
                                <OutlinedInput value={assignment.value} onChange={(e) => handleRoleAssignmentChange(e.target.value, idx)} />
                                <FormHelperText>{hasAttemptedSave && formJobFunctionAssignmentErrors[idx]}</FormHelperText>
                            </FormControl>
                        </Grid>
                    ))}

                    <StepNameDetails name={!!startStep && !!startStep.name ? startStep.name : 'Start'} />
                    <StepDescriptionDetails description={!!startStep && !!startStep.description ? startStep.description : ''} />
                    {selectedPlayDetails.play && <StepFileAttachmentDetails fileAttachments={getFileAttachment(selectedPlayDetails.play) ?? []} />}
                    <EmbeddingsDataView embeddingInputs={getEmbeddings(selectedPlayDetails.play)} />

                    {formCollectedData.length > 0 && (
                        <Grid item>
                            <br />
                            <Typography variant='h2'>Data</Typography>
                        </Grid>
                    )}
                    {formCollectedData.map((stepData, idx) => (
                        <StepDataInput
                            key={`step-data-${idx}`}
                            stepData={stepData}
                            onChange={(val) => handleCollectedDataChange(idx, val)}
                            errorMessage={validateStepData(stepData)}
                            fieldLabelSuffix={getSubplayName(stepData)}
                        />
                    ))}
                </Grid>
            </DialogContent>
            <Grid item xs={12} display='flex' justifyContent='end' gap={2} position='sticky' bottom={0} pb={2} px={3} sx={{ background: 'white' }}>
                <Button variant='outlined' color='secondary' size='small' onClick={handleCancel} disabled={isStartingPlay} sx={{ width: 90 }}>
                    {isFormDirty ? 'Cancel' : 'Close'}
                </Button>
                <Button variant='contained' color='secondary' size='small' onClick={handleSaveClicked} disabled={isStartingPlay} sx={{ width: 90 }}>
                    Start
                </Button>
            </Grid>
            <LoadingBackdrop loadingOptions={[{ isLoading: isStartingPlay, loadingMessage: 'Starting play...' }]} />
        </Dialog>
    );
};
