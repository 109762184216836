import { FC } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../dtos";
import { NodeType } from "../NodeType";
import { NotificationNodeExecutionDetails, NotificationNodeLibraryListItem, NotificationPlayViewerNode } from "./Components";
import { IStepEditModalProps, IStepExecutionDetailsProps } from "../types";
import { NodeTypes } from "../utils";
import { NotificationNodeEditForm } from "./Components/NotificationNodeEditForm";

export class NotificationNodeType extends NodeType {
    public readonly nodeType: string = NodeTypes.NOTIFICATION;
    
    public readonly PlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = NotificationPlayViewerNode;
    public readonly EditModalContent?: FC<IStepEditModalProps> = NotificationNodeEditForm;
    public readonly ExecutionDetailsContent?: FC<IStepExecutionDetailsProps> = NotificationNodeExecutionDetails;
    public readonly NodeLibraryListItem: FC = NotificationNodeLibraryListItem;
}