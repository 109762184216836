import { Grid } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { FormNumberInput } from '../../../../../../Components/CoreLib/library';

export interface ITimeInputProps {
    error?: string;
    minutesValue: number;
    handleTimeChange: (updatedTime: number) => void;
    label: string;
}

export const TimeInput: FC<ITimeInputProps> = (props) => {
    const { error, minutesValue, handleTimeChange, label } = props;

    const days: number = Math.floor((minutesValue ?? 0) / 24 / 60);
    const hours: number = Math.floor((minutesValue ?? 0) / 60 - days * 24);
    const minutes: number = Math.floor((minutesValue ?? 15) - days * 24 * 60 - hours * 60);

    const updateTime = (event: ChangeEvent<HTMLInputElement>) => {
        const timeBeforeChange = minutesValue;
        var days: number = Math.floor((timeBeforeChange ?? 0) / 24 / 60);
        var hours: number = Math.floor((timeBeforeChange ?? 0) / 60 - days * 24);
        var minutes: number = Math.floor((timeBeforeChange ?? 15) - days * 24 * 60 - hours * 60);
        if (event.target.name === 'days') {
            days = event.target.valueAsNumber;
        } else if (event.target.name === 'hours') {
            hours = event.target.valueAsNumber;
        } else {
            minutes = event.target.valueAsNumber;
        }

        if (Number.isNaN(days)) {
            days = 0;
        }
        if (Number.isNaN(hours)) {
            hours = 0;
        }
        if (Number.isNaN(minutes)) {
            minutes = 0;
        }

        handleTimeChange(days * 24 * 60 + hours * 60 + minutes);
    };

    return (
        <>
            <Grid item container direction='row'>
                <Grid item mb={3}>
                    {label}
                </Grid>
            </Grid>
            <Grid item container direction='row' alignItems='flex-start' spacing={1}>
                <Grid item width={158} className='non-draggable-element'>
                    <FormNumberInput
                        value={days}
                        onChange={updateTime}
                        label='Days'
                        name='days'
                        error={!!error}
                        fullWidth
                        required
                    />
                </Grid>

                <Grid item width={158} className='non-draggable-element'>
                    <FormNumberInput
                        value={hours}
                        onChange={updateTime}
                        label='Hours'
                        name='hours'
                        error={!!error}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item width={158} className='non-draggable-element'>
                    <FormNumberInput
                        value={minutes}
                        onChange={updateTime}
                        label='Minutes'
                        name='minutes'
                        errorText={error}
                        error={!!error}
                        fullWidth
                        required
                    />
                </Grid>
            </Grid>
        </>
    );
};
