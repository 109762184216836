import { Button, Grid, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ApiError, LoadingIndicator, useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { useDeleteUserSlackAccountMutation, useGetUserSlackConfigQuery, useUpdateUserSlackConfigMutation } from '../../store/generated/generatedApi';

export const ConnectSlack: FC = () => {
    const [searchParams] = useSearchParams();
    const memberId = useMemo(() => searchParams.get('memberId'), [searchParams]);
    const username = useMemo(() => searchParams.get('username'), [searchParams]);
    const {data: currentConfig, isLoading: isLoadingCurrentConfig, isError: isErrorLoadingCurrentConfig, refetch: refetchCurrentConfig } = useGetUserSlackConfigQuery();
    const [updateSlackConfig, { isLoading: isUpdatingConfig, isError: isErrorUpdatingConfig, isSuccess: isConfigUpdated, reset: resetUpdateConfig }] = useUpdateUserSlackConfigMutation();
    useFailedActionSnackbar('connect', 'Slack account', isErrorUpdatingConfig, resetUpdateConfig);
    const [deleteSlackConfig, { isLoading: isDeletingSlackConfig, isError: isErrorDeletingConfig, isSuccess: isConfigDeleted, reset: resetDeleteConfig}] = useDeleteUserSlackAccountMutation();
    useFailedActionSnackbar('disconnect', 'Slack account', isErrorDeletingConfig, resetDeleteConfig);

    const handleConnectClicked = useCallback(() => {
        if (isUpdatingConfig || !memberId || !username) {
            return;
        }
        updateSlackConfig({ memberId, username });
    }, [isUpdatingConfig, memberId, username, updateSlackConfig]);

    const handleDisconnectClicked = useCallback(() => {
        if (isDeletingSlackConfig) {
            return;
        }
        deleteSlackConfig()
    }, [isDeletingSlackConfig, deleteSlackConfig]);

    // Not Enough Information Provided
    if (!memberId || !username) {
        return (
            <Grid container direction='column'>
                <Grid item container direction='row' justifyContent='center'>
                    <Grid item pt={2}>
                        <Typography variant='h1'>You Appear To Be Lost</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction='row' justifyContent='center'>
                    <Grid item maxWidth={400} pt={1}>
                        <Typography variant='body1' textAlign='center'>Information required to connect your Slack account is absent from the current URL. Verify you used the correct link when attempting to connect your Slack account. If the issue persists please contact technical support. </Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    // Loading
    if (isLoadingCurrentConfig || isUpdatingConfig || isDeletingSlackConfig) {
        return <LoadingIndicator />
    }

    // Error Loading Config
    if (isErrorLoadingCurrentConfig || !currentConfig) {
        return <ApiError onReloadClick={refetchCurrentConfig} />
    }

    // Successfully Connected
    if (isConfigUpdated) {
        return (
            <Grid container direction='column'>
                <Grid item container direction='row' justifyContent='center'>
                    <Grid item pt={2}>
                        <Typography variant='h1'>Slack Account Connected!</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction='row' justifyContent='center'>
                    <Grid item maxWidth={400} pt={1}>
                        <Typography variant='body1' textAlign='center'>Your Slack account has been successfully connected!</Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    // Successfully Disconnected
    if (isConfigDeleted) {
        return (
            <Grid container direction='column'>
                <Grid item container direction='row' justifyContent='center'>
                    <Grid item pt={2}>
                        <Typography variant='h1'>Slack Account Disconnected</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction='row' justifyContent='center'>
                    <Grid item maxWidth={400} pt={1}>
                        <Typography variant='body1' textAlign='center'>Your Slack account has been successfully disconnected.</Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    // User Currently Not Configured
    if (!currentConfig.memberId) {
        return (
            <Grid container direction='column'>
                <Grid item container direction='row' justifyContent='center'>
                    <Grid item pt={2}>
                        <Typography variant='h1'>Connect Slack Account</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction='row' justifyContent='center'>
                    <Grid item maxWidth={400} pt={1}>
                        <Typography variant='body1' textAlign='center'>Would you like to connect the {username} Slack account to your CoachBot profile?</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction='row' justifyContent='center'>
                    <Grid item pt={2}>
                        <Button variant='contained' color='primary' onClick={handleConnectClicked}>Connect</Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    // User Configured With Different Member Id
    if (currentConfig.memberId !== memberId) {
        return (
            <Grid container direction='column'>
                <Grid item container direction='row' justifyContent='center'>
                    <Grid item pt={2}>
                        <Typography variant='h1'>Update Slack Connection</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction='row' justifyContent='center'>
                    <Grid item maxWidth={400} pt={1}>
                        <Typography variant='body1' textAlign='center'>It looks like this Slack connection is different than your current connection. Would you like to replace your current Slack connection with a new connection to the {username} Slack account?</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction='row' justifyContent='center'>
                    <Grid item pt={2}>
                        <Button variant='contained' color='primary' onClick={handleConnectClicked}>Update Connection</Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    // User Connected With Provided Config
    return (
        <Grid container direction='column'>
            <Grid item container direction='row' justifyContent='center'>
                <Grid item pt={2}>
                    <Typography variant='h1'>Slack Account Connected</Typography>
                </Grid>
            </Grid>
            <Grid item container direction='row' justifyContent='center'>
                <Grid item maxWidth={400} pt={1}>
                    <Typography variant='body1' textAlign='center'>You are currently connected to the {currentConfig.username} Slack account. You can disconnect from this account using the button below if you would like to stop receiving CoachBot messages with that Slack account.</Typography>
                </Grid>
            </Grid>
            <Grid item container direction='row' justifyContent='center'>
                <Grid item pt={2}>
                    <Button variant='contained' color='error' onClick={handleDisconnectClicked}>Disconnect</Button>
                </Grid>
            </Grid>
        </Grid>
    )
};
