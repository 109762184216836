import { FC } from 'react';
import { StepDataDetails, StepDescriptionDetails, StepNameDetails } from '../../../../PlayHistory/Components/StepDetailSections/Components';
import { IStepExecutionDetailsProps } from '../../types';

export const ArtificialIntelligenceNodeExecutionDetails: FC<IStepExecutionDetailsProps> = ({ stepExecution }) => {
    return (
        <>
            <StepNameDetails name={stepExecution.name ?? 'Unnamed Step'} />
            <StepDescriptionDetails description={stepExecution.prompt ?? ''} sectionTitle='Prompt' />
            <StepDataDetails stepDataEntries={stepExecution.stepData}/>
        </>
    );
}