import { Notifications } from '@mui/icons-material';
import { Badge, Box, IconButton, Menu } from '@mui/material';
import { FC, useState } from 'react';
import { useInterval } from '../../Hooks';
import { useGetNotificationCountQuery } from '../../store/generated/generatedApi';
import { NotificationBadgeContext } from './NotificationBadgeContext';
import { TodoList } from './TodoList';

export const NotificationBadge: FC = () => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const { data: notificationCount, refetch: refetchNotificationCount } = useGetNotificationCountQuery();

    // This will re-fetch the notification count every 5 seconds
    useInterval(() => {
        refetchNotificationCount();
    }, 5000);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <NotificationBadgeContext.Provider value={{ closeMenu: handleMenuClose }}>
            <Badge badgeContent={notificationCount} color='error'>
                <IconButton size='small' onClick={handleMenuOpen}>
                    <Notifications fontSize='large' />
                </IconButton>
            </Badge>
            <Menu
                anchorEl={menuAnchorEl}
                open={!!menuAnchorEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <Box width='400px'>
                    <TodoList shouldReloadOnMount />
                </Box>
            </Menu>
        </NotificationBadgeContext.Provider>
    );
};
