import { createContext } from "react";
import { PlayVariableDto, StepDto } from "../../../dtos";
import { IFieldValidationResult } from "../../../Components/CoreLib/library";

export interface IPlayEditorContext {
    playId: string;
    steps: StepDto[];
    updateStep: (updatedStep: StepDto) => void;
    playVariables: PlayVariableDto[];
    setPlayVariables: (updatedPlayVariables: PlayVariableDto[]) => void;
    renamePlayVariable: (oldName: string, newName: string) => void;
    playVariableUseMap: Map<string, string[]>;
    validatePlayVariableName: (varName: string, currentIndex?: number) => IFieldValidationResult
}

export const PlayEditorContext = createContext<IPlayEditorContext>({
    playId: '',
    steps: [],
    updateStep: () => {},
    playVariables: [],
    setPlayVariables: () => {},
    renamePlayVariable: () => {},
    playVariableUseMap: new Map(),
    validatePlayVariableName: () => ({ isValid: true, errorMessage: '' })
});