import { IFormFieldValidationConfig, isNotBlank, isShorterThanMaxLength, runFormValidation } from "../../../../Components/CoreLib/library";
import { StepConditionDto } from "../../../../dtos";
import { isSingleSidedOperator } from "../../../PlayHistory/Utils";

export class StepConditionValidator {
    private readonly validationConfig = new Map<keyof StepConditionDto, IFormFieldValidationConfig>([
        [
            'leftOperand',
            {
                validators: [isNotBlank, isShorterThanMaxLength(250)],
                errorMessageEntityName: 'Value',
            },
        ],
        [
            'operator',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Comparison',
            },
        ],
        [
            'rightOperand',
            {
                validators: [isNotBlank, isShorterThanMaxLength(250)],
                errorMessageEntityName: 'Value',
            },
        ],
    ]);
        
    public validateStepCondition(stepCondition: StepConditionDto): StepConditionValidationResult {
        var { errorMessages } = runFormValidation(stepCondition, this.validationConfig);
        return new StepConditionValidationResult(errorMessages.get('leftOperand') ?? '', errorMessages.get('operator') ?? '', errorMessages.get('rightOperand') ?? '', isSingleSidedOperator(stepCondition.operator));
    }
}

export class StepConditionValidationResult {
    public readonly leftOperandError: string;
    public readonly operatorError: string;
    public readonly rightOperandError: string;
    public readonly isSingleOperand: boolean;

    public constructor(leftOperandError: string, operatorError: string, rightOperandError: string, isSingleOperand: boolean) {
        this.leftOperandError = leftOperandError;
        this.operatorError = operatorError;
        this.rightOperandError = rightOperandError;
        this.isSingleOperand = isSingleOperand;
    }

    public get isLeftOperandError() {
        return !!this.leftOperandError;
    }

    public get isOperatorError() {
        return !!this.operatorError;
    }

    public get isRightOperandError() {
        return !!this.rightOperandError && !this.isSingleOperand;
    }

    public get isError() {
        return this.isLeftOperandError || this.isOperatorError || (this.isRightOperandError && !this.isSingleOperand);
    }
}