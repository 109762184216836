import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Avatar, Divider, Grid, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLazyGetUserAuth0UserIdByIdQuery, useLazyGetUserPermissionsQuery } from '../../store/generated/generatedApi';
import { AuthenticatedComponent, LoadingIndicator, PermissionToggler } from '../CoreLib/library';
import { NavigationDrawerContainer } from '../NavigationDrawerContainer/NavigationDrawerContainer';
import { IShellProps } from './types';
import { NotificationBadge } from '../Notifications';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

const Shell: FC<IShellProps> = (props) => {
    const { isProtected } = props;
    const navigate = useNavigate();
    const { user, isAuthenticated, loginWithRedirect, logout, isLoading: isAuthLoading } = useAuth0();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const profileMenuOpen = Boolean(anchorEl);
    const [getUserData, { data: userFromApi }] = useLazyGetUserAuth0UserIdByIdQuery();

    useEffect(() => {
        if (isAuthenticated) {
            if (user?.sub) {
                getUserData({
                    id: user?.sub,
                });
            }

            const path = localStorage.getItem('redirectUrl');
            if (!!path) {
                navigate(path);
                localStorage.removeItem('redirectUrl');
            }
        }
    }, [user, getUserData, isAuthenticated, navigate]);

    if (isProtected && !isAuthenticated) {
        localStorage.setItem('redirectUrl', window.location.pathname + window.location.search);
        loginWithRedirect();
        return <LoadingIndicator />;
    }

    const handleOrgChange = () => {
        loginWithRedirect();
    };

    const handleLogout = () => {
        setAnchorEl(null);
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };

    const handleSettingsClicked = () => {
        setAnchorEl(null);
        navigate('/settings');
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    const handleFaqOnClick = () => {
        window.open('https://robosource.us/coach-bot-faqs/', '_blank');
    };

    return (
        <>
            <NavigationDrawerContainer>
                <AppBar position='sticky' elevation={0} sx={{ height: '72px', background: 'white' }}>
                    <Toolbar variant='dense' disableGutters sx={{ px: 1, background: 'rgba(85,136,199,.25)' }}>
                        <img src='/Coach-Bot-Logo-Horizontal.png' alt='coachbot logo' height='100%' style={{ padding: 16 }} />
                        {isAuthenticated && (
                            <Grid container direction='row' justifyContent='end' gap={3}>
                                <div>
                                    <NotificationBadge />
                                </div>
                                <div>
                                    <IconButton size='small' onClick={handleFaqOnClick}>
                                        <LiveHelpIcon fontSize='large' />
                                    </IconButton>
                                </div>
                                <div style={{ cursor: 'pointer' }} onClick={handleProfileMenuOpen}>
                                    <Avatar src={user?.picture} />
                                </div>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={profileMenuOpen}
                                    onClose={handleProfileMenuClose}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    PaperProps={{
                                        sx: {
                                            mt: 1.5,
                                            border: 'solid 1px #AAAAAA',
                                            borderRadius: '10px',
                                            padding: '8px',
                                        },
                                    }}>
                                    <Grid container direction='column' alignItems='center' sx={{ width: '240px', fontSize: '14px', marginBottom: '12px' }}>
                                        <Avatar src={user?.picture} />
                                        <Typography sx={{ fontSize: '18px', marginTop: '12px' }}>{user?.name}</Typography>
                                        <Typography>{user?.email}</Typography>
                                        <Typography>{userFromApi?.clientName}</Typography>
                                    </Grid>
                                    <Divider sx={{ marginBottom: '12px' }} />
                                    {userFromApi && userFromApi?.clientCount > 1 && (
                                        <MenuItem onClick={handleOrgChange} sx={{ fontSize: '14px' }}>
                                            Switch Organization
                                        </MenuItem>
                                    )}
                                    <MenuItem onClick={handleSettingsClicked} sx={{ fontSize: '14px' }}>
                                        Settings
                                    </MenuItem>
                                    <MenuItem onClick={handleLogout} sx={{ fontSize: '14px' }}>
                                        Sign Out
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        )}
                    </Toolbar>
                </AppBar>
                <div style={{ minHeight: 'calc(100vh - 72px)' }}>{isAuthLoading || (!isAuthenticated && isProtected) ? <LoadingIndicator /> : <Outlet />}</div>
            </NavigationDrawerContainer>
            <AuthenticatedComponent requiredPermissions={['read:permissionToggle']}>
                <PermissionToggler getPermissionsQuery={useLazyGetUserPermissionsQuery} />
            </AuthenticatedComponent>
        </>
    );
};

export default Shell;
