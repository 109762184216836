import { AddCircle, DataObject } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useContext, useMemo } from 'react';
import { PlayVariableDto, StepDataType } from '../../../dtos';
import { PlayEditorContext } from '../Utils/PlayEditorContext';
import { ModalTitle, PlayVariablePanelRow } from './SubComponents';

export const PlayVariablePanel: FC = () => {
    const { playId, playVariables, setPlayVariables } = useContext(PlayEditorContext);

    const updateVariable = useCallback(
        (stepEntryIndex: number, value: PlayVariableDto) => {
            var updatedPlayVariables = _.cloneDeep(playVariables);
            updatedPlayVariables.splice(stepEntryIndex, 1, value);
            setPlayVariables(updatedPlayVariables);
        },
        [playVariables, setPlayVariables]
    );

    const deleteVariable = useCallback(
        (stepEntryIndex: number) => {
            var updatedPlayVariables = _.cloneDeep(playVariables);
            updatedPlayVariables.splice(stepEntryIndex, 1);
            setPlayVariables(updatedPlayVariables);
        },
        [playVariables, setPlayVariables]
    );

    const addVariable = useCallback(() => {
        var updatedPlayVariables = _.cloneDeep(playVariables);
        updatedPlayVariables.push({
            playId: playId,
            name: '',
            dataType: StepDataType.TEXT,
            isFutureDate: true,
        });
        setPlayVariables(updatedPlayVariables);
    }, [playVariables, setPlayVariables, playId]);

    const isAddingNewVar = useMemo(() => playVariables.some(pv => pv.name === ''), [playVariables]);

    return (
        <Grid width={540} height='100%' container direction='row'>
            <Grid item xs={12} sx={{ overflowY: 'auto', height: '100%' }}>
                <ModalTitle icon={<DataObject sx={{ mr: 1 }} />} title={'Data'} />
                {playVariables.map((playVariable, idx) => (
                    <PlayVariablePanelRow
                        key={`${playVariable.name}-${idx}`}
                        index={idx}
                        playVariable={playVariable}
                        updateVariable={updateVariable}
                        deleteVariable={deleteVariable}
                    />
                ))}
                <Grid item pt={2}>
                    <Tooltip title={isAddingNewVar ? 'Only one new variable can be added at a time' : ''}>
                        <span>
                            <IconButton color='primary' onClick={() => addVariable()} disabled={isAddingNewVar}>
                                <AddCircle />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
};
