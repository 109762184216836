import { Collapse } from '@mui/material';
import { FC, PropsWithChildren, useMemo } from 'react';

export interface IExpandableSectionProps extends PropsWithChildren {
    isExpanded: boolean;
    direction: 'right' | 'left';
}

export const ExpandableSection: FC<IExpandableSectionProps> = ({ isExpanded, direction, children}) => {

    const componentStyles = useMemo(() => {
        switch (direction) {
            case 'right':
                return { borderRight: '2px solid #e4e4e4', height: '100%'}
            case 'left':
                return { borderLeft: '2px solid #e4e4e4', height: '100%'}
            default:
                throw new Error('Invalid direction');
                
        }
    }, [direction]);

    return (
        <Collapse orientation='horizontal' in={isExpanded} collapsedSize={'2px'} sx={componentStyles}>
            {children}
        </Collapse>
    )
}