import { RemoveCircle } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { FC, useMemo } from 'react';
import { OperatorSelect } from '../../../../../../Components/CommonInputs';
import { Comparison, StepConditionDto, StepDataDto } from '../../../../../../dtos';
import { StepConditionValidator } from '../../../../Utils';
import { ConditionDataInput } from './ConditionDataInput';
import { isSingleSidedOperator } from '../../../../../PlayHistory/Utils';

export interface IStepConditionInputRowProps {
    index: number;
    stepCondition: StepConditionDto;
    stepData: StepDataDto[];
    updateLeftOperand: (stepEntryIndex: number, fieldName: string) => void;
    updateOperator: (stepEntryIndex: number, comparison: Comparison) => void;
    updateRightOperand: (stepEntryIndex: number, fieldName: string) => void;
    removeCondition: (stepEntryIndex: number) => void;
}

export const StepConditionInputRow: FC<IStepConditionInputRowProps> = (props) => {
    const { index, stepCondition, stepData, updateLeftOperand, updateOperator, updateRightOperand, removeCondition } = props;

    const stepConditionValidationResult = useMemo(() => {
        const validator = new StepConditionValidator();
        return validator.validateStepCondition(stepCondition);
    }, [stepCondition]);

    return (
        <Grid item container direction='row' alignItems='center' spacing={2}>
            <Grid item xs={4} display='flex' alignItems='center'>
                <ConditionDataInput
                    operand={stepCondition.leftOperand}
                    handleOperandChange={(op) => updateLeftOperand(index, op)}
                    errorMessage={stepConditionValidationResult.leftOperandError}
                    dataOptions={stepData}
                />
            </Grid>
            <Grid item xs={3}>
                <OperatorSelect
                    selectedOperator={stepCondition.operator}
                    handleOperatorChange={(v) => updateOperator(index, v)}
                    errorMessage={stepConditionValidationResult.operatorError}
                />
            </Grid>
            {!isSingleSidedOperator(stepCondition.operator) && (
                <Grid item xs={4} display='flex' alignItems='center'>
                    <ConditionDataInput
                        operand={stepCondition.rightOperand}
                        handleOperandChange={(op) => updateRightOperand(index, op)}
                        errorMessage={stepConditionValidationResult.rightOperandError}
                        dataOptions={stepData}
                    />
                </Grid>
            )}
            <Grid item xs={1}>
                <IconButton color='error' onClick={() => removeCondition(index)} sx={{ mt: 3 }}>
                    <RemoveCircle />
                </IconButton>
            </Grid>
        </Grid>
    );
};
