import { Dispatch, SetStateAction, createContext } from "react";

export type EntityDataUpdater = (entityKey: string, updatedData: any) => void;

export interface LinkDecoratorEditState {
    entityKey: string;
    currentLinkValue: string;
}

export interface IDraftEditorContext {
    updateEntityData: EntityDataUpdater;
    linkDecoratorState?: LinkDecoratorEditState;
    setLinkDecoratorState: Dispatch<SetStateAction<LinkDecoratorEditState | undefined>>;
}

export const DraftEditorContext = createContext<IDraftEditorContext>({
    updateEntityData: () => {},
    setLinkDecoratorState: () => {}
});