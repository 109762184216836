import { Grid, Link, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { FileAttachmentDto } from '../../../../../dtos';
import { AttachFile } from '@mui/icons-material';
import { downloadFile, emptyGuid } from '../../../../../util';
import { useCreateAttachmentByIdDownloadMutation } from '../../../../../store/generated/generatedApi';

export interface IStepFileAttachmentDetailsProps {
    fileAttachments: FileAttachmentDto[];
}

export const StepFileAttachmentDetails: FC<IStepFileAttachmentDetailsProps> = ({ fileAttachments }) => {
    const [createAttachmentDownloadLink] = useCreateAttachmentByIdDownloadMutation();

    const handleFileLinkClicked = useCallback(async (fileName: string) => {
        var downloadLink = await createAttachmentDownloadLink({ id: emptyGuid, fileName }).unwrap();
        downloadFile(downloadLink.link, fileName);
    }, [createAttachmentDownloadLink]);

    return (
        <>
            <Grid item container direction='row' mt={2}>
                <Grid item>
                    <Typography variant='h2'>File Attachments</Typography>
                </Grid>
            </Grid>
            <Grid item container direction='row' sx={{ maxHeight: 240, overflowY: 'auto' }} mb={1}>
                {fileAttachments.map((fileAttachment, idx) => (
                    <Grid item xs={12} key={fileAttachment.blobFileName} display='flex' alignItems='center'>
                        <AttachFile fontSize='small' />
                        <Link href='#' onClick={() => handleFileLinkClicked(fileAttachment.blobFileName)}>{fileAttachment.friendlyFileName}</Link>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}