import { FC, MouseEventHandler, ReactElement, useCallback, useContext, useMemo } from 'react';
import { UserPermissionContext } from '../../../Components/CoreLib/library';
import { IconButton, SxProps, Theme } from '@mui/material';

// It would be nice to have this algorithmically determine the position of the buttons rather than having a hard coded config. Esther found a possible solution here: https://gist.github.com/bycoffe/3404776. I am moving on for now though because I have spent too much time tinkering with this file.

const NODE_RADIUS_OFFSET = 14;
const SHARED_BUTTON_STYLES: SxProps<Theme> = {
    position: 'absolute',
    transition: 'left .3s ease .2s, bottom .3s ease .2s',
    zIndex: -5 
}
const BUTTON_POSITION_MAP = new Map<number, {x: number, y: number}[]>([
    [1, [{x: 0, y: 56}]],
    [2, [{x: -24, y: 50}, {x: 24, y: 50}]],
    [3, [{x: -40, y: 40}, {x: 0, y: 56}, {x: 40, y: 40}]],
    [4, [{x: -52, y: 22}, {x: -22, y: 52}, {x: 22, y: 52}, {x: 52, y: 22}]]
])

export interface INodeInteractionButton {
    icon: ReactElement,
    action: MouseEventHandler<HTMLButtonElement>;
    requiredPermissions?: string[];
    disabled?: boolean;
}

export interface INodeInteractionButtonsProps {
    isVisible: boolean;
    buttonOptions: INodeInteractionButton[];
}

export const NodeInteractionButtons: FC<INodeInteractionButtonsProps> = props => {
    const { isVisible, buttonOptions } = props;
    const { permissions } = useContext(UserPermissionContext);

    const permittedButtonOptions = useMemo(() => {
        return buttonOptions.filter(buttonOption => !buttonOption.requiredPermissions || buttonOption.requiredPermissions.every(requiredPermission => permissions.includes(requiredPermission)));
    }, [buttonOptions, permissions]);

    const getAdjustedButtonPosition = useCallback((idx: number): SxProps<Theme> => {
        if (!isVisible) {
            return {
                left: NODE_RADIUS_OFFSET,
                bottom: NODE_RADIUS_OFFSET
            }
        }

        var configuredPositionOptions = BUTTON_POSITION_MAP.get(permittedButtonOptions.length);
        if (!configuredPositionOptions) {
            throw new Error(`No button position configuration available for ${permittedButtonOptions.length} buttons`);
        }
        var buttonPosition = configuredPositionOptions[idx];
        
        return {
            left: buttonPosition.x + NODE_RADIUS_OFFSET,
            bottom: buttonPosition.y + NODE_RADIUS_OFFSET
        }
    }, [isVisible, permittedButtonOptions.length]);

    return (
        <>
            {permittedButtonOptions.map((buttonOption, idx) => (
                <IconButton key={`node-button-${idx}`} sx={{ ...SHARED_BUTTON_STYLES, ...getAdjustedButtonPosition(idx)}} disabled={buttonOption.disabled} onClick={buttonOption.action}>
                    {buttonOption.icon}
                </IconButton>
            ))}
        </>
    );
}