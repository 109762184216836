import { Box, Divider, List, Typography, useTheme } from '@mui/material';
import {FC} from 'react';
import { useGetNotificationMyTodoQuery } from '../../store/generated/generatedApi';
import { LoadingIndicator } from '../CoreLib/library';
import { TodoTaskListItem } from './TodoTaskListItem';

interface ITodoListProps {
    shouldReloadOnMount?: boolean;
}

export const TodoList: FC<ITodoListProps> = ({ shouldReloadOnMount = false }) => {
    const {data: todoTasks, isLoading: isLoadingTodoTasks} = useGetNotificationMyTodoQuery(undefined, { refetchOnMountOrArgChange: shouldReloadOnMount });
    const theme = useTheme()

    return (
        <Box display='flex' flexDirection='column'>
            <Box alignSelf='center'>
                <Typography color='primary'>TO-DO LIST</Typography>
            </Box>
            <Divider sx={{ borderColor: theme.palette.primary.main, my: 1 }} />
            <Box display='flex' flexDirection='column' maxHeight='400px' sx={{ overflowY: 'auto' }}>
                {isLoadingTodoTasks && <LoadingIndicator />}
                {todoTasks?.length === 0 && <Typography alignSelf='center' fontStyle='italic'>You are all caught up!</Typography>}
                <List>
                    {todoTasks?.map(tdt => <TodoTaskListItem key={tdt.stepExecutionId} todoTask={tdt}/>)}
                </List>
            </Box>
        </Box>
    );
};