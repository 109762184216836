import { FC } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../dtos";
import { NodeType } from "../NodeType";
import { NodeTypes } from "../utils";
import { IStepEditModalProps, IStepExecutionDetailsProps } from "../types";
import { ArtificialIntelligenceNodeEditForm, ArtificialIntelligenceNodeExecutionDetails, ArtificialIntelligenceNodeLibraryListItem, ArtificialIntelligencePlayViewerNode } from "./Components";

export class ArtificialIntelligenceNodeType extends NodeType {
    public readonly nodeType: string = NodeTypes.ARTIFICIAL_INTELLIGENCE;
    
    public readonly PlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = ArtificialIntelligencePlayViewerNode;
    public readonly EditModalContent?: FC<IStepEditModalProps> = ArtificialIntelligenceNodeEditForm;
    public readonly ExecutionDetailsContent?: FC<IStepExecutionDetailsProps> = ArtificialIntelligenceNodeExecutionDetails;
    public readonly NodeLibraryListItem: FC = ArtificialIntelligenceNodeLibraryListItem;
}