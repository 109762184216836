import { Box, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { usePermissionChecker } from '../../Hooks';
import CoachBotBig from '../../assets/Coach-Bot-Big.png';
import { AssistantChatWindow } from './AssistantChatWindow';

export const Home: FC = () => {
    const { hasAllPermissions } = usePermissionChecker();

    if (hasAllPermissions(['feature:deepChat'])) {
        return (
            <Box p={2}>
                <Paper>
                    <AssistantChatWindow />
                </Paper>
            </Box>
        );
    } else {
        return (
            <Box
                p={2}
                display='flex'
                flexDirection='column'
                alignItems='center'
                textAlign='center'
                gap={2}
                justifyContent='center'
                height='calc(100vh - 100px)'>
                <img src={CoachBotBig} alt='CoachBot Robot' height='240px' />
                <Typography maxWidth='480px'>
                    Thank you for letting me help with your plays! <br />
                    I'm still hard at work finding innovative ways to help you get your work done. Come back to this page later to see what I've been working
                    on.
                </Typography>
            </Box>
        );
    }
};
