import {FC, useContext} from 'react';
import { EditPlay } from './EditPlay';
import { EditPlayAutosave } from './EditPlayAutosave';
import { BetaPlayEditorContext } from './Contexts';


export const PlayEditorExperienceRouter: FC = () => {
    const { isPlayEditorBetaEnabled } = useContext(BetaPlayEditorContext);

    if (isPlayEditorBetaEnabled) {
        return <EditPlayAutosave />;
    } else {
        return <EditPlay />;
    }
};