import { DoubleArrow } from '@mui/icons-material';
import { Button, Dialog, DialogContent, FormControl, FormHelperText, FormLabel, Grid, OutlinedInput, Typography, useTheme } from '@mui/material';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { JobFunctionUserSelect } from '../../../Components/CommonInputs';
import { isValidEmail, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../Components/CoreLib/library';
import { UserDto } from '../../../dtos';
import { useUpdatePlayExecutionByPlayIdStepByStepIdReassignMutation } from '../../../store/generated/generatedApi';
import { emptyGuid } from '../../../util';



export interface IStepReassignmentModalProps {
    isOpen: boolean;
    isExternal: boolean;
    playId: string;
    stepId: string;
    jobFunctionId?: string;
    handleClose: (isUpdated?: boolean) => void;
}

export const StepReassignmentModal: FC<IStepReassignmentModalProps> = props => {
    const { isOpen, isExternal, playId, stepId, handleClose, jobFunctionId = emptyGuid } = props;
    const [hasAttemptedSave, setHasAttemptedSave] = useState(false);
    const theme = useTheme();
    const [reassignStep, { isSuccess: isReassignmentSuccess, isError: isReassignmentError, isLoading: isReassigning, reset: resetReassignStep }] = useUpdatePlayExecutionByPlayIdStepByStepIdReassignMutation();
    const [assignee, setAssignee] = useState('');

    useSuccessfulActionSnackbar('reassigned', 'step', isReassignmentSuccess, () => {
        resetReassignStep();
        handleClose(true);
    });
    useFailedActionSnackbar('reassign', 'step', isReassignmentError, resetReassignStep)

    const externalEmailErrors = useMemo(() => {
        if (!isExternal) {
            return '';
        }
        if (!assignee.trim()) {
            return 'Email is required';
        }
        var emailValidationResult = isValidEmail(assignee);
        if (!emailValidationResult.isValid) {
            return emailValidationResult.errorMessageBuilder('Email')
        }
        return '';
    }, [isExternal, assignee]);
    
    const handleCancelClicked = () => {
        setAssignee('');
        resetReassignStep();
        handleClose();
    }

    const handleSubmitClicked = () => {
        setHasAttemptedSave(true);
        if (isReassigning || !assignee.trim() || !!externalEmailErrors) {
            return;
        }
        reassignStep({
            params: { playId, stepId },
            payload: { assignee }
        });
    }

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setAssignee(event.target.value);
    };

    const handleUserChange = (updatedUser: UserDto | undefined) => {
        if (updatedUser) {
            setAssignee(updatedUser.id)
        } else {
            setAssignee('')
        }
    }

    useEffect(() => {
        if (isOpen) {
            setHasAttemptedSave(false);
        }
    }, [isOpen])

    const assigneeInput = useMemo(() => {
        if (isExternal) {
            return (
                <FormControl error={hasAttemptedSave && !!externalEmailErrors} fullWidth>
                    {/* TODO: replace with FormInput component */}
                    <FormLabel>Email</FormLabel>
                    <OutlinedInput value={assignee} onChange={handleEmailChange} />
                    <FormHelperText>{hasAttemptedSave && externalEmailErrors}</FormHelperText>
                </FormControl>
            );
        } else {
            return (
                <JobFunctionUserSelect
                    jobFunctionId={jobFunctionId}
                    selectedUserId={assignee}
                    handleSelectedUserChange={handleUserChange}
                    errorMessage={!assignee.trim() && hasAttemptedSave ? 'User is required' : ''}
                    required
                />
            );
        }
    }, [isExternal, assignee, hasAttemptedSave, externalEmailErrors, jobFunctionId])

    return (
        <Dialog open={isOpen} fullWidth maxWidth='sm'>
            <DialogContent sx={{ padding: 0}}>
                <Grid container direction='column' height='100%'>
                    {/* TOD: Replace with ModalTitle or other shared component */}
                    <Grid
                        item
                        container
                        direction='row'
                        alignItems='center'
                        p={1}
                        sx={{ background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                        position='sticky'
                        top={0}
                        zIndex={2}
                    >
                        <DoubleArrow sx={{ mr: 1 }} />
                        <Typography fontWeight='bold'>Reassign {isExternal ? 'Role' : 'Member'}</Typography>
                    </Grid>
                    <Grid p={2}>
                        {assigneeInput}
                    </Grid>
                    <Grid item xs={12} display='flex' justifyContent='end' gap={2} position='sticky' bottom={0} pb={1} px={2} sx={{ background: 'white' }}>
                        <Button variant='outlined' color='secondary' size='small' onClick={handleCancelClicked} sx={{ width: 90 }}>
                            Cancel
                        </Button>
                        <Button variant='contained' color='secondary' size='small' onClick={handleSubmitClicked} sx={{ width: 90 }}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
