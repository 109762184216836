import { Error } from '@mui/icons-material';
import { Tooltip, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import { FC, useMemo } from 'react';

export interface ITabInput {
    label: string;
    index: number;
    isValid: boolean;
    disabled?: boolean;
    setValue: (newValue: number) => void;
}

export const TabWithErrorIndicator: FC<ITabInput> = (props) => {
    const { label, index, isValid, setValue } = props;
    const theme = useTheme();

    const errorIndicator = useMemo(() => {
        if (!isValid) {
            return (
                <Tooltip title='There are errors with fields in this tab' placement='top'>
                    <Error color='error' />
                </Tooltip>
            );
        }
    }, [isValid]);

    return (
        <Tab
            {...props}
            label={label}
            id={`simple-tab-${index}`}
            aria-controls={`simple-tabpanel-${index}`}
            icon={errorIndicator}
            iconPosition='end'
            onClick={() => setValue(index)}
            sx={{ color: theme.palette.primary.main, opacity: 1, minHeight: '48px' }}
        />
    );
};
