import { DoubleArrow } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../../dtos";
import { PlayViewerNode } from "../../Components/PlayViewerNode";
import { STEP_NODE_DESCRIPTION } from "../../utils";

export const StepPlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = (props) => {
    const isStepValid = useCallback((step: StepDto) => {
        var isAssigned = step.isExternal ? !!step.externalRoleName : !!step.internalJobFunctionId && !!step.internalJobFunctionHasMembers;
        return isAssigned;
    }, []);

    const customColorPallet = useMemo(() => {
        if (props.data.isExternal) {
            return {
                background: '#F7931E',
                textColor: 'white',
            }
        }
    }, [props.data.isExternal]);

    return <PlayViewerNode {...props} icon={<DoubleArrow fontSize='large' />} description={STEP_NODE_DESCRIPTION} additionalStepValidation={isStepValid} activeColorPallet={customColorPallet} />;
}