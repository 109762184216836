import { AddCircle } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import _ from 'lodash';
import { FC } from 'react';
import { Comparison, MatchType, StepConditionDto, StepDataDto } from '../../../../../../dtos';
import { emptyGuid } from '../../../../../../util';
import { StepConditionInputRow } from './StepConditionInputRow';

export interface IEditStepConditionsInputProps {
    stepId: string;
    matchType: MatchType;
    stepData: StepDataDto[];
    stepConditions: StepConditionDto[];
    updateStepConditions: (updatedData: StepConditionDto[]) => void;
}

export const EditStepConditionsInput: FC<IEditStepConditionsInputProps> = (props) => {
    const { stepId, matchType, stepData, stepConditions, updateStepConditions } = props;

    const addCondition = () => {
        var newStep: StepConditionDto = {
            id: emptyGuid,
            stepId: stepId,
            leftOperand: '',
            operator: Comparison.IS_TRUE,
            rightOperand: '',
            orderIndex: stepConditions.length,
        };
        var updatedStepConditions = [...stepConditions, newStep];
        updateStepConditions(updatedStepConditions);
    };

    const removeCondition = (stepEntryIndex: number) => {
        var updatedStepConditions = [...stepConditions];
        updatedStepConditions.splice(stepEntryIndex, 1);
        updateStepConditions(updatedStepConditions);
    };

    const updateLeftOperand = (stepEntryIndex: number, fieldName: string) => {
        var updatedStepCondition = _.cloneDeep(stepConditions);
        updatedStepCondition[stepEntryIndex].leftOperand = fieldName;
        updateStepConditions(updatedStepCondition);
    };

    const updateRightOperand = (stepEntryIndex: number, fieldName: string) => {
        var updatedStepCondition = _.cloneDeep(stepConditions);
        updatedStepCondition[stepEntryIndex].rightOperand = fieldName;
        updateStepConditions(updatedStepCondition);
    };

    const updateOperator = (stepEntryIndex: number, comparison: Comparison) => {
        var updatedStepCondition = _.cloneDeep(stepConditions);
        updatedStepCondition[stepEntryIndex].operator = comparison;
        updateStepConditions(updatedStepCondition);
    };

    return (
        <>
            <Grid item container direction='row'>
                <Grid item>
                    <Typography variant='h2'>Conditions</Typography>
                </Grid>
            </Grid>
            {stepConditions.length === 0 && (
                <Grid item xs={12}>
                    <Typography color='error'>Conditional steps must have at least one condition defined. Click the plus button below to add one.</Typography>
                </Grid>
            )}
            {stepConditions.map((stepCondition, i) => (
                <>
                    <StepConditionInputRow
                        key={`step-condition-${i}`}
                        index={i}
                        stepCondition={stepCondition}
                        stepData={stepData}
                        updateLeftOperand={updateLeftOperand}
                        updateOperator={updateOperator}
                        updateRightOperand={updateRightOperand}
                        removeCondition={removeCondition}
                    />
                    {i !== stepConditions.length - 1 && (
                        <Grid item xs={12} key={`step-condition-${i}-joining-text`} textAlign='left' pl={2}>
                            <Typography>{matchType === MatchType.ALL ? 'And' : 'Or'}</Typography>
                        </Grid>
                    )}
                </>
            ))}
            <Grid item>
                <IconButton color='primary' onClick={addCondition}>
                    <AddCircle />
                </IconButton>
            </Grid>
        </>
    );
};
