import { Close, Dashboard, DoubleArrow, History, Launch, SkipNext, Snooze } from '@mui/icons-material';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactFlowProvider, XYPosition } from 'reactflow';
import { ApiError, LoadingIndicator, SlimPageHeader } from '../../Components/CoreLib/library';
import { emptyGuid } from '../../util';
import { TitleBarEndSlot } from '../Plays/Components/SubComponents/TitleBarEndSlot';
import { NodeTypes } from '../Plays/NodeTypes/utils';
import { PlayViewer, RunHistoryList, StepExecutionDetails, StepSnoozeModal } from './Components';
import { usePlayExecutionLoader } from './Hooks/usePlayExecutionLoader';
import { useSelectedStepManager } from './Hooks/useSelectedStepManager';
import { ExpandableSection } from '../../Components/ExpandableSection/ExpandableSection';
import { ExpandableSectionHandle } from '../../Components/ExpandableSection';
import { StepExecutionSnoozeDto } from '../../dtos';

export const PlayHistoryView: FC = () => {
    const { playId } = useParams();
    const { isLoadingPlayExecution, playExecution, playDetails, isLoadingPlayDetails, isErrorLoadingPlayDetails, refetchPlayDetails, loadPlayExecution } =
        usePlayExecutionLoader(playId ?? emptyGuid);
    const { selectedStepExecution, clearSelectedStep, handleSetSelectedStep, handleSkip } = useSelectedStepManager(playExecution);
    const [viewerFocusCoordinates, setViewerFocusCoordinates] = useState<XYPosition | undefined>();
    const [isSnoozeStepModalOpen, setIsSnoozeStepModalOpen] = useState(false);
    const [isRunHistorySliceExpanded, setIsRunHistorySliceExpanded] = useState(true);
    const navigate = useNavigate();

    // TODO: it is difficult to update these routes because they are so spread out throughout the application. it would be nice to make a useAppNavigator hook and put all the navigation there so it is easy to track and update.
    const goToCompleteStepPage = () => navigate(`/playExecution/${selectedStepExecution?.playId}/step/${selectedStepExecution?.id}`);
    const goToSubplayRunHistoryPage = () => {
        navigate(
            `/playbook/${selectedStepExecution?.subplayPlaybookId}/play/${selectedStepExecution?.subplaySourcePlayId}/history?runId=${selectedStepExecution?.subplayId}`
        );
    };

    // If you have selected a new play execution then clear out the old loaded step
    useEffect(() => {
        if (selectedStepExecution && selectedStepExecution.playId !== playExecution?.id) {
            clearSelectedStep();
        }
    }, [clearSelectedStep, selectedStepExecution, playExecution]);

    // If we have selected a new step then navigate to that step in the viewer
    useEffect(() => {
        if (selectedStepExecution) {
            setViewerFocusCoordinates({
                x: selectedStepExecution.graphXPos,
                y: selectedStepExecution.graphYPos,
            });
        }
    }, [selectedStepExecution]);

    const renderPlayViewer = useMemo(() => {
        if (isLoadingPlayExecution) {
            return <LoadingIndicator />;
        } else if (!playExecution) {
            return <Typography fontSize={24} align='center'>No run selected. Click on a run in the Run History table to see it here.</Typography>;
        } else {
            return <PlayViewer playExecution={playExecution} onStepNodeClicked={handleSetSelectedStep} focusCoordinates={viewerFocusCoordinates} />;
        }
    }, [isLoadingPlayExecution, playExecution, handleSetSelectedStep, viewerFocusCoordinates]);

    const handleViewPlayDashboard = () => {
        if (!playDetails) {
            return;
        }
        navigate(`/playbook/${playDetails.playbookId}/play/${playDetails.id}/dashboard`);
    };

    const getSkipButtonDisabledMessage = useMemo(() => {
        if (selectedStepExecution?.status.toUpperCase() === 'SKIPPED') {
            return 'This step has already been skipped.';
        }
        return '';
    }, [selectedStepExecution?.status]);

    const openSnoozeStepModal = useCallback(() => {
        setIsSnoozeStepModalOpen(true);
    }, []);

    const closeSnoozeStepModal = useCallback(() => {
        setIsSnoozeStepModalOpen(false);
    }, []);

    const currentSnoozeConfig: StepExecutionSnoozeDto | undefined = useMemo(() => {
        if (!selectedStepExecution) {
            return;
        }
        const { snoozeReason = '', snoozeUntil } = selectedStepExecution;
        if (snoozeUntil) {
            return { snoozeReason, snoozeUntil };
        }
    }, [selectedStepExecution]);

    if (isLoadingPlayDetails) {
        return <LoadingIndicator />;
    }

    if (isErrorLoadingPlayDetails || !playDetails) {
        return <ApiError onReloadClick={refetchPlayDetails} />;
    }

    return (
        <Grid container direction='column'>
            <SlimPageHeader
                icon={<History />}
                title={playDetails.name ?? 'Unnamed Play'}
                breadCrumbProps={{
                    links: [
                        { label: 'Home', navLink: '/' },
                        { label: 'Playbooks', navLink: '/playbooks' },
                        { label: playDetails.playbookName ?? 'Playbook', navLink: `/playbook/${playDetails.playbookId}/plays` },
                        { label: playDetails.name ?? 'Unnamed Play', navLink: `/playbook/${playDetails.playbookId}/play/${playDetails.id}/dashboard` },
                    ],
                    currentPageLabel: 'Run History',
                }}
                disableBorderRadius
                endSlot={
                    <TitleBarEndSlot
                        playDetails={playDetails}
                        handleViewOtherPage={handleViewPlayDashboard}
                        otherPageName='Play Dashboard'
                        otherPageIcon={<Dashboard />}
                    />
                }
            />
            <Grid item container direction='row' bgcolor='white' borderTop='1px solid #b1b1b6'>
                <ExpandableSection isExpanded={isRunHistorySliceExpanded} direction='right'>
                    <Grid
                        item
                        container
                        direction='column'
                        sx={{ overflowY: 'auto', overflowX: 'hidden' }}
                        width='480px'
                        height='calc(100vh - 134px)'
                        borderRight='1px solid #b1b1b6'>
                        <RunHistoryList playId={playId ?? emptyGuid} onRunClicked={loadPlayExecution} />
                    </Grid>
                </ExpandableSection>
                <Grid item direction='column' flexGrow={1} position='relative' sx={{ display: 'flex', justifyContent: 'center' }}>
                    <ExpandableSectionHandle isExpanded={isRunHistorySliceExpanded} setIsExpanded={setIsRunHistorySliceExpanded} direction='right' />
                    <ReactFlowProvider>{renderPlayViewer}</ReactFlowProvider>
                </Grid>
                {selectedStepExecution && (
                    <Grid item container direction='column' width='480px' height='calc(100vh - 134px)' overflow='auto' borderLeft='1px solid #b1b1b6'>
                        <Box display='flex' flexDirection='column' width='100%' height='100%'>
                            <SlimPageHeader
                                color='primary'
                                title='View Step'
                                icon={<DoubleArrow />}
                                endSlot={
                                    <>
                                        {selectedStepExecution.type === NodeTypes.STEP && (
                                            <>
                                                {!selectedStepExecution.isComplete && (
                                                    <Tooltip title='Snooze Step'>
                                                        <span>
                                                            <IconButton color='inherit' onClick={openSnoozeStepModal}>
                                                                <Snooze />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                )}
                                                <IconButton color='inherit' onClick={goToCompleteStepPage}>
                                                    <Launch />
                                                </IconButton>
                                            </>
                                        )}
                                        {selectedStepExecution.type === NodeTypes.SUBPLAY && !!selectedStepExecution.startedOn && (
                                            <IconButton color='inherit' onClick={goToSubplayRunHistoryPage}>
                                                <Launch />
                                            </IconButton>
                                        )}
                                        {selectedStepExecution.type === NodeTypes.DELAY && (
                                            <Tooltip title={getSkipButtonDisabledMessage}>
                                                <Box sx={{ display: 'inline' }}>
                                                    <IconButton disabled={!!getSkipButtonDisabledMessage} color='inherit' onClick={handleSkip}>
                                                        <SkipNext />
                                                    </IconButton>
                                                </Box>
                                            </Tooltip>
                                        )}
                                        <IconButton color='inherit' onClick={clearSelectedStep}>
                                            <Close />
                                        </IconButton>
                                    </>
                                }
                                disableBorderRadius
                            />
                            <Box overflow='auto'>
                                <StepExecutionDetails stepExecution={selectedStepExecution} handleStepExecutionUpdate={clearSelectedStep} />
                            </Box>
                        </Box>
                        <StepSnoozeModal isOpen={isSnoozeStepModalOpen} close={closeSnoozeStepModal} currentSnoozeDetails={currentSnoozeConfig} playExecutionId={selectedStepExecution.playId} stepExecutionId={selectedStepExecution.id} />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
