import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useNodes, useReactFlow, Node } from 'reactflow';
import { StepDto } from '../../../../dtos';

export function useDeleteNode() {
    const { enqueueSnackbar } = useSnackbar();
    const reactFlowNodes = useNodes<StepDto>();
    const reactFlowInstance = useReactFlow();

    const deleteNode = useCallback((nodeToDelete: Node<StepDto>) => {
        if (nodeToDelete.deletable === false) {
            enqueueSnackbar('This step cannot be deleted because the outputs of this step are used in later steps.', { variant: 'error' });
            return false;
        }

        reactFlowInstance.deleteElements({
            nodes: [
                {
                    id: nodeToDelete.id,
                },
            ],
        });
        return true;
    }, [enqueueSnackbar, reactFlowInstance])

    const deleteNodeById = useCallback(
        (nodeId: string) => {
            var nodeToDelete = reactFlowNodes.find((node) => node.id === nodeId);
            if (!nodeToDelete) {
                console.warn(`unable to delete node with id ${nodeId} because no matching nodes were found`);
                return false;
            }

            return deleteNode(nodeToDelete);
        },
        [reactFlowNodes, deleteNode]
    );

    const deleteNodeByKey = useCallback(
        (stepKey: string) => {
            var nodeToDelete = reactFlowNodes.find((node) => node.data.key === stepKey);
            if (!nodeToDelete) {
                console.warn(`unable to delete node with key ${stepKey} because no matching nodes were found`);
                return false;
            }

            return deleteNode(nodeToDelete);
        },
        [reactFlowNodes, deleteNode]
    );

    return {
        deleteNodeById,
        deleteNodeByKey
    }
}
