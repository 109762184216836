import { AddCircle, ArrowForward, RemoveCircle } from '@mui/icons-material';
import { FormControl, FormHelperText, FormLabel, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useContext } from 'react';
import { PlayVariableAutocompleteWithAdd } from '../../../../../../Components/CommonInputs';
import { isNotBlank } from '../../../../../../Components/CoreLib/library';
import { DataMappingConfigDto, StepDataDto } from '../../../../../../dtos';
import { StepDataTypeToName } from '../../../../../../util';
import { PlayEditorContext } from '../../../../Utils/PlayEditorContext';

const DEFAULT_DATA_MAPPING_CONFIG: DataMappingConfigDto = {
    sourceFieldName: '',
    destinationFieldName: '',
};

export interface IPlayOutputDataMappingInputProps {
    playOutputData: StepDataDto[];
    dataMappingConfigs: DataMappingConfigDto[];
    onDataMappingConfigsUpdated: (updatedConfigs: DataMappingConfigDto[]) => void;
}

export const PlayOutputDataMappingInput: FC<IPlayOutputDataMappingInputProps> = (props) => {
    const { playOutputData, dataMappingConfigs, onDataMappingConfigsUpdated } = props;
    const { playVariables } = useContext(PlayEditorContext);

    // Form updating
    const handleSubplayDataChange = useCallback(
        (idx: number, val: string) => {
            var updatedConfig = _.cloneDeep(dataMappingConfigs);
            updatedConfig[idx].sourceFieldName = val;

            onDataMappingConfigsUpdated(updatedConfig);
        },
        [onDataMappingConfigsUpdated, dataMappingConfigs]
    );

    const handleMappedToChange = useCallback(
        (idx: number, val: string) => {
            var updatedConfig = _.cloneDeep(dataMappingConfigs);
            updatedConfig[idx].destinationFieldName = val;
            onDataMappingConfigsUpdated(updatedConfig);
        },
        [onDataMappingConfigsUpdated, dataMappingConfigs]
    );

    const handleRemoveDataMappingEntry = useCallback(
        (idx: number) => {
            var updatedDataMappingEntries = [...dataMappingConfigs];
            updatedDataMappingEntries.splice(idx, 1);
            onDataMappingConfigsUpdated(updatedDataMappingEntries);
        },
        [onDataMappingConfigsUpdated, dataMappingConfigs]
    );

    const handleAddDataMappingEntry = useCallback(() => {
        onDataMappingConfigsUpdated([...dataMappingConfigs, _.cloneDeep(DEFAULT_DATA_MAPPING_CONFIG)]);
    }, [onDataMappingConfigsUpdated, dataMappingConfigs]);

    const getPlayVariableByName = useCallback(
        (variableName: string) => {
            return playVariables.find((pv) => pv.name === variableName);
        },
        [playVariables]
    );

    const getDataLabelOptions = useCallback(
        (selectedOption: string) => {
            var allUsedDataLabelOptions = dataMappingConfigs.map((x) => x.sourceFieldName);
            return playOutputData.filter((outputData) => outputData.name === selectedOption || !allUsedDataLabelOptions.includes(outputData.name ?? ''));
        },
        [dataMappingConfigs, playOutputData]
    );

    const getSubplayDataErrorMessage = useCallback(
        (value: string) => {
            var { isValid, errorMessageBuilder } = isNotBlank(value);
            if (!isValid) {
                return errorMessageBuilder('Subplay Data');
            }
            if (!getDataLabelOptions(value).some((data) => data.name === value)) {
                return 'Data previously selected in the Subplay has been modified/removed';
            }
            return '';
        },
        [getDataLabelOptions]
    );

    const getAvailableOutputLabels = useCallback(
        (idx: number) => {
            var variablesUsedByOtherEntries = dataMappingConfigs.map((x) => x.destinationFieldName);
            variablesUsedByOtherEntries.splice(idx, 1);
            return playVariables.filter((pv) => !variablesUsedByOtherEntries.includes(pv.name))
        },
        [dataMappingConfigs, playVariables]
    );

    const getOutputDataLabelErrorMessage = useCallback(
        (mappingConfig: DataMappingConfigDto) => {
            if (!mappingConfig.destinationFieldName) {
                return 'An output data label must be selected';
            }
            return '';
        },
        []
    );

    const renderOutputRow = useCallback(
        (mappingConfig: DataMappingConfigDto, idx: number) => {
            var outputDataLabelErrorMessage = getOutputDataLabelErrorMessage(mappingConfig);

            return (
                <Grid container direction='row' item py={2} alignItems='center' borderBottom='1px solid #e0e0e0' key={`config-mapping-${idx}`}>
                    <Grid item xs={5}>
                        <FormControl error={!!getSubplayDataErrorMessage(mappingConfig.sourceFieldName)} fullWidth className='non-draggable-element'>
                            <FormLabel>Subplay Data</FormLabel>
                            <Select value={mappingConfig.sourceFieldName} onChange={(e) => handleSubplayDataChange(idx, e.target.value)}>
                                {getDataLabelOptions(mappingConfig.sourceFieldName).map((data) => (
                                    <MenuItem key={data.name} value={data.name}>
                                        {`${data.name} (${StepDataTypeToName.get(data.dataType ?? 0)})`}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{getSubplayDataErrorMessage(mappingConfig.sourceFieldName)}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} display='flex' justifyContent='center'>
                        <ArrowForward />
                    </Grid>
                    <Grid item xs={5} display='flex' alignItems='center'>
                        <FormControl error={!!outputDataLabelErrorMessage} fullWidth>
                            <FormLabel>Data</FormLabel>
                            <PlayVariableAutocompleteWithAdd
                                value={getPlayVariableByName(mappingConfig.destinationFieldName) ?? null}
                                onChange={(value) => handleMappedToChange(idx, value?.name ?? '')}
                                playVariableOptions={getAvailableOutputLabels(idx)}
                                error={!!outputDataLabelErrorMessage}
                            />
                            <FormHelperText>{outputDataLabelErrorMessage}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} display='flex' justifyContent='center'>
                        <IconButton color='error' onClick={() => handleRemoveDataMappingEntry(idx)}>
                            <RemoveCircle />
                        </IconButton>
                    </Grid>
                </Grid>
            );
        },
        [
            handleSubplayDataChange,
            handleMappedToChange,
            getSubplayDataErrorMessage,
            handleRemoveDataMappingEntry,
            getDataLabelOptions,
            getAvailableOutputLabels,
            getOutputDataLabelErrorMessage,
            getPlayVariableByName
        ]
    );

    return (
        <Grid container direction='column'>
            <Grid item borderBottom='1px solid #e0e0e0' pb={2}>
                <Typography>Please map data or provide a custom value for any required data outputs for the subplay.</Typography>
            </Grid>
            {dataMappingConfigs.map(renderOutputRow)}
            <Grid item>
                <IconButton color='primary' onClick={handleAddDataMappingEntry}>
                    <AddCircle />
                </IconButton>
            </Grid>
        </Grid>
    );
};
