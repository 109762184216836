import { Box } from '@mui/material';
import { FC } from 'react';
import { StepDataDetails, StepDescriptionDetails, StepNameDetails } from '../../../../PlayHistory/Components/StepDetailSections/Components';
import { IStepExecutionDetailsProps } from '../../types';
import { formatTimestamp } from '../../../../../util';

export const AutomationNodeExecutionDetails: FC<IStepExecutionDetailsProps> = ({ stepExecution }) => {
    return (
        <>
            {stepExecution.completedOn && (
                <Box mt={1}>
                    Completed On: {formatTimestamp(stepExecution.completedOn)}
                </Box>
            )}
            <StepNameDetails name={stepExecution.name ?? 'Unnamed Step'} />
            <StepDescriptionDetails description={stepExecution.description ?? ''} />
            <StepDataDetails stepDataEntries={stepExecution.stepData} stepAttachments={stepExecution.fileAttachments} />
        </>
    );
}