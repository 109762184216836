import { FC, useCallback, useContext } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../../dtos";
import { PlayViewerNode } from "../../Components/PlayViewerNode";
import { Timer } from "@mui/icons-material";
import { DELAY_NODE_DESCRIPTION } from "../../utils";
import { StepValidator } from "../../../Utils";
import { PlayEditorContext } from "../../../Utils/PlayEditorContext";

export const DelayPlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = (props) => {
    const { playVariables } = useContext(PlayEditorContext);

    const isStepValid = useCallback(
        (step: StepDto) => {
            const stepValidator = new StepValidator(step, playVariables);
            const isValid = stepValidator.isValidEnoughToSave && stepValidator.isDelayInputValid;
            return isValid;
        },
        [playVariables]
    );

    return <PlayViewerNode
        {...props}
        icon={<Timer fontSize='large' />}
        description={DELAY_NODE_DESCRIPTION}
        additionalStepValidation={isStepValid}
    />;
}