import { RemoveCircle } from '@mui/icons-material';
import { Grid, FormControl, OutlinedInput, FormHelperText, IconButton } from '@mui/material';
import { FC, useMemo } from 'react';
import { EmbedLinkValidator } from '../../../../Utils/Validators';
import { StepEmbedInputDto } from '../../../../../../dtos';

export interface IEmbeddingInputDataRowProps {
    index: number;
    allEmbeddingInputEntries: StepEmbedInputDto[];
    embedInput: StepEmbedInputDto;
    updateEmbedLink: (embeddingInputIndex: number, embedLink: string) => void;
    removeEmbedLink: (embeddingInputIndex: number) => void;
}

export const EmbeddingInputDataRow: FC<IEmbeddingInputDataRowProps> = (props) => {
    const { index, allEmbeddingInputEntries, embedInput, updateEmbedLink, removeEmbedLink } = props;

    const isValid = useMemo(() => {
        const validator = new EmbedLinkValidator(allEmbeddingInputEntries);
        return validator.validateEmbedLink(embedInput);
    }, [allEmbeddingInputEntries, embedInput]);

    return (
        <Grid item container direction='row' alignItems='center'>
            <Grid item xs={11}>
                {/* TODO: replace with FormInput component */}
                <FormControl error={!isValid} fullWidth className='non-draggable-element'>
                    <OutlinedInput value={embedInput.embedLink} onChange={(e) => updateEmbedLink(index, e.target.value)} onDrag={(e) => e.preventDefault()} />
                    <FormHelperText>{!isValid ? 'Every embedding must be a valid iFrame and unique' : ''}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={1} display='flex' justifyContent='center'>
                <IconButton color='error' onClick={() => removeEmbedLink(index)}>
                    <RemoveCircle />
                </IconButton>
            </Grid>
        </Grid>
    );
};
