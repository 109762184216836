import { AttachFile } from '@mui/icons-material';
import { Box, Button, FormControl, FormHelperText, FormLabel, Grid } from '@mui/material';
import { ChangeEventHandler, DragEvent, FC, useCallback, useMemo } from 'react';
import { useCreateAttachmentByIdDownloadMutation, useCreateAttachmentMutation, useDeleteAttachmentMutation } from '../../../store/generated/generatedApi';
import { downloadFile } from '../../../util';
import { IStepDataInputProps } from './StepDataInput';
import { stepDataFieldLabelBuilder } from './Utils';
import { LoadingIndicator } from '../../CoreLib/library';
import { v4 as uuidv4 } from 'uuid';

export const FileStepDataInput: FC<IStepDataInputProps> = ({ stepData, onChange, errorMessage, fieldLabelSuffix, disabled, showDataLabel, entityId }) => {
    const [createAttachment, { isLoading: isCreatingAttachment }] = useCreateAttachmentMutation();
    const [deleteAttachment, { isLoading: isDeletingAttachment }] = useDeleteAttachmentMutation();
    const [createAttachmentDownloadLink, { isLoading: isCreatingDownloadLink }] = useCreateAttachmentByIdDownloadMutation();
    const uniqueId = useMemo(uuidv4, []);

    const fieldLabel = useMemo(
        () => stepDataFieldLabelBuilder(stepData.name ?? 'File Attachment', { isRequired: stepData.isRequired, labelSuffix: fieldLabelSuffix }),
        [stepData, fieldLabelSuffix]
    );

    const handleFileUpload = useCallback(
        async (fileToUpload: File) => {
            // Remove old file if one was selected
            if (stepData.value) {
                await deleteAttachment({ fileName: stepData.value });
            }
            // Upload new file
            let attachmentFormData = new FormData();
            attachmentFormData.append('file', fileToUpload);
            attachmentFormData.append('name', fileToUpload.name);
            attachmentFormData.append('linkedEntityType', 'StepData');
            if (entityId) {
                attachmentFormData.append('linkedEntityId', entityId);
            }
            try {
                const newFileAttachment = await createAttachment(attachmentFormData).unwrap();
                onChange(newFileAttachment.blobFileName);
            } catch (error) {
                console.error('An error occurred when attempting to create a new file attachment: ', error);
            }
        },
        [createAttachment, deleteAttachment, onChange, stepData.value, entityId]
    );

    const handleFileSelected: ChangeEventHandler<HTMLInputElement> = useCallback(
        async (e) => {
            if (isCreatingAttachment) {
                return;
            }
            if (!e.target.files || !e.target.files.item(0)) {
                console.warn('no file found in change event');
                return;
            }
            let selectedFile = e.target.files.item(0)!;
            await handleFileUpload(selectedFile);
        },
        [handleFileUpload, isCreatingAttachment]
    );

    const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
    }, []);

    const handleDrop = useCallback(
        (event: DragEvent<HTMLDivElement>) => {
            event.stopPropagation();
            event.preventDefault();
            if (isCreatingAttachment) {
                return;
            }
            if (event.dataTransfer.files.length > 0) {
                const droppedFile = event.dataTransfer.files[0];
                handleFileUpload(droppedFile);
            }
        },
        [handleFileUpload, isCreatingAttachment]
    );

    const handleClearSelected = useCallback(async () => {
        // Remove old file from blob storage
        await deleteAttachment({ fileName: stepData.value });
        // Clear out input
        onChange('');
    }, [onChange, deleteAttachment, stepData.value]);

    const handleFileLinkClicked = useCallback(async () => {
        if (isCreatingDownloadLink) {
            return;
        }
        var downloadLink = await createAttachmentDownloadLink({ id: entityId ?? uniqueId, fileName: stepData.value }).unwrap();
        downloadFile(downloadLink.link, stepData.value);
    }, [createAttachmentDownloadLink, stepData.value, isCreatingDownloadLink, entityId, uniqueId]);

    const justFilename = (fullPathAndFilename: string): string => {
        const lastIndex = fullPathAndFilename.lastIndexOf("/");
        return fullPathAndFilename.substring(lastIndex + 1);
    };

    return (
        <Grid item>
            <FormControl error={!!errorMessage} fullWidth>
                {showDataLabel && <FormLabel>{fieldLabel}</FormLabel>}
                {!stepData.value && (
                    <Grid item onDrop={handleDrop} onDragOver={handleDragOver}>
                        <input
                            style={{ display: 'none' }}
                            id={`choose-file-${uniqueId}`}
                            type='file'
                            onChange={handleFileSelected}
                        />
                        <label htmlFor={`choose-file-${uniqueId}`}>
                            <Button
                                variant='outlined'
                                startIcon={isCreatingAttachment ? null : <AttachFile />}
                                sx={{ borderStyle: 'dashed', height: 56 }}
                                fullWidth
                                component='span'
                                size='large'
                                color={!!errorMessage && !isCreatingAttachment ? 'error' : 'primary'}
                                disabled={disabled}>
                                {isCreatingAttachment ? <LoadingIndicator /> : 'Attach File'}
                            </Button>
                        </label>
                    </Grid>
                )}
                {stepData.value && (
                    <Grid item display='flex' alignItems='center' gap={1}>
                        <Button
                            variant='outlined'
                            startIcon={<AttachFile />}
                            sx={{
                                borderStyle: 'dashed',
                                height: 56,
                                minHeight: 56,
                                '&:hover': {
                                    height: 'auto',
                                },
                            }}
                            fullWidth
                            component='span'
                            size='large'
                            color='primary'
                            disabled={disabled}
                            onClick={handleFileLinkClicked}>
                            <Box
                                sx={{
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    '&:hover': {
                                        whiteSpace: 'normal',
                                    },
                                }}>
                                {justFilename(stepData.value)}
                            </Box>
                        </Button>
                        {!disabled && (
                            <Button
                                variant='outlined'
                                sx={{ height: 56, width: 80, textAlign: 'center' }}
                                component='span'
                                size='large'
                                color='error'
                                disabled={isDeletingAttachment}
                                onClick={handleClearSelected}>
                                {isDeletingAttachment ? <LoadingIndicator /> : 'Remove File'}
                            </Button>
                        )}
                    </Grid>
                )}
                {!isCreatingAttachment && <FormHelperText>{errorMessage}</FormHelperText>}
            </FormControl>
        </Grid>
    );
};
