import { Delete } from '@mui/icons-material';
import { Dialog, DialogContent, Button, Typography, Box } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { ModalTitle } from '../Views/Plays/Components/SubComponents';

export interface IDeleteConfirmationModalProps {
    objectType: string;
    showModal: boolean;
    handleResponse: (response: boolean) => void;
}

export const DeleteConfirmationModal: FC<IDeleteConfirmationModalProps> = (props) => {
    const { objectType, showModal, handleResponse } = props;

    const handleCancelDelete = useCallback(() => {
        handleResponse(false);
    }, [handleResponse]);

    const handleConfirmDelete = useCallback(() => {
        handleResponse(true);
    }, [handleResponse]);

    const renderBody = useMemo(() => {
        const lowercaseObjectType = objectType.toLowerCase();
        return (
            <Box flexDirection='row' flexGrow={1} display='flex' mb={2}>
                <Typography>Are you sure you want to delete this {lowercaseObjectType}?<br/><br/>This action can't be undone.</Typography>
            </Box>
        );
    }, [objectType]);

    const renderFooter = useMemo(() => {
        return (
            <Box flexDirection='row' display='flex' width='calc(100% - 223px)' justifyContent='end' gap={2} position='absolute' bottom={0} right={15} pb={1} sx={{ background: 'white' }}>
                <Button variant='outlined' color='secondary' size='small' onClick={handleCancelDelete} sx={{ width: 90 }}>
                    Cancel
                </Button>
                <Button variant='contained' color='secondary' size='small' onClick={handleConfirmDelete} sx={{ width: 90 }}>
                    Confirm
                </Button>
            </Box>
        );
    }, [handleCancelDelete, handleConfirmDelete]);

    return (
        <Dialog
            PaperProps={{
                sx: { height: '200px' }
            }}
            open={showModal}
            maxWidth='sm'>
            <DialogContent sx={{ padding: 0 }}>
                <Box flexDirection='column' display='flex' height='100%'>
                    <ModalTitle icon={<Delete sx={{ mr: 1 }} />} title={`Delete ${objectType}`} />
                    <Box flexDirection='row' flexGrow={1} display='flex' overflow='hidden' justifyContent='start' sx={{ height: '100%', width: '100%' }} px={4} pt={3}>
                        <>
                            {renderBody}
                            {renderFooter}
                        </>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
