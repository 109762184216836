import { useCallback } from 'react';
import { PlayVariableDto, StepDto } from '../../../dtos';

export const usePlayVariableUseTracker = () => {
    const addKeyToMapEntry = useCallback((map: Map<string, string[]>, key: string, value: string) => {
        var keyValues = map.get(key);
        if (!keyValues) {
            map.set(key, [value]);
            return map;
        }
        if (keyValues.includes(value)) {
            return map;
        }
        keyValues.push(value);
        map.set(key, keyValues);
        return map;
    }, []);

    const getAllDataReferencesFromString = (inputString: string) => {
        const regex = /{{[^{}]*}}/g;
        const matches = [];
        let match;
        while ((match = regex.exec(inputString)) !== null) {
            matches.push(match[0].substring(2, match[0].length - 2));
        }
        return matches;
    };

    // TODO: make this more readable
    // this logic is still pretty unreadable but it is now under unit tests at least. I don't have time
    // in this story but this should be refactored to be more readable in a future story
    const getPlayVariableUseMap = useCallback(
        (playVariables: PlayVariableDto[], steps: StepDto[]) => {
            var useMap = new Map<string, string[]>();
            playVariables.forEach((pv) => useMap.set(pv.name, []));
            steps.forEach((s) => {
                if (s.description) {
                    getAllDataReferencesFromString(s.description).forEach((dataRefString) => {
                        if (playVariables.some((pv) => pv.name === dataRefString)) {
                            useMap = addKeyToMapEntry(useMap, dataRefString, s.key!);
                        }
                    });
                }
                s.stepData.forEach((sd) => {
                    useMap = addKeyToMapEntry(useMap, sd.name!, s.key!);
                });
                if (s.delayDateLabel) {
                    useMap = addKeyToMapEntry(useMap, s.delayDateLabel, s.key!);
                }
                s.requestDataLabels?.forEach((rdl) => {
                    useMap = addKeyToMapEntry(useMap, rdl, s.key!);
                });
                s.subplayDataInputConfig?.forEach((sdic) => {
                    // useMap = addKeyToMapEntry(useMap, sdic.sourceFieldName, s.key!);
                    getAllDataReferencesFromString(sdic.sourceFieldName).forEach((dataRefString) => {
                        if (playVariables.some((pv) => pv.name === dataRefString)) {
                            useMap = addKeyToMapEntry(useMap, dataRefString, s.key!);
                        }
                    });
                });
                s.subplayDataOutputConfig?.forEach((sdoc) => {
                    useMap = addKeyToMapEntry(useMap, sdoc.destinationFieldName, s.key!);
                });
                if (s.outputDataLabel) {
                    useMap = addKeyToMapEntry(useMap, s.outputDataLabel, s.key!);
                }
                s.stepConditions?.forEach((stepCondition) => {
                    // useMap = addKeyToMapEntry(useMap, sdic.sourceFieldName, s.key!);
                    getAllDataReferencesFromString(stepCondition.leftOperand).forEach((dataRefString) => {
                        if (playVariables.some((pv) => pv.name === dataRefString)) {
                            useMap = addKeyToMapEntry(useMap, dataRefString, s.key!);
                        }
                    });
                    getAllDataReferencesFromString(stepCondition.rightOperand).forEach((dataRefString) => {
                        if (playVariables.some((pv) => pv.name === dataRefString)) {
                            useMap = addKeyToMapEntry(useMap, dataRefString, s.key!);
                        }
                    });
                });
            });
            return useMap;
        },
        [addKeyToMapEntry]
    );

    return { getPlayVariableUseMap };
};
