import { AttachFile, Error, RemoveCircle } from '@mui/icons-material';
import { Grid, IconButton, Link } from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { FileDropperArea } from '../../../../../../Components/FileManagement';
import { useCreateAttachmentByIdDownloadMutation, useDeleteAttachmentMutation } from '../../../../../../store/generated/generatedApi';
import { downloadFile } from '../../../../../../util';
import { StepEditorContext } from '../../../../Utils/StepEditorContext';

export interface IFileAttachmentInputProps {
    stepId: string;
    stepKey: string;
    fieldErrors?: Map<keyof any, string>;
}

export const FileAttachmentInput: FC<IFileAttachmentInputProps> = ({ stepId, stepKey }) => {
    const { formStep, addFileAttachment, removeFileAttachment, fieldErrors } = useContext(StepEditorContext);
    const [createAttachmentDownloadLink, { isLoading: isCreatingDownloadLink }] = useCreateAttachmentByIdDownloadMutation();
    const [deleteAttachment] = useDeleteAttachmentMutation();

    const handleFileLinkClicked = useCallback(async (fileName: string) => {
        if (isCreatingDownloadLink) {
            return;
        }
        var downloadLink = await createAttachmentDownloadLink({ id: stepId, fileName }).unwrap();
        downloadFile(downloadLink.link, fileName);
    }, [createAttachmentDownloadLink, isCreatingDownloadLink, stepId]);

    const handleRemoveFileClicked = useCallback((idx: number, fileName: string) => {
        deleteAttachment({ fileName });
        removeFileAttachment(idx);
    }, [deleteAttachment, removeFileAttachment]);

    const duplicateFileAttachmentError = useMemo(() => {
        return !!fieldErrors?.get('fileAttachments') ?? false;
    }, [fieldErrors]);

    return (
        <>
            <Grid item mt={2} container direction='row'>
                <Grid item container alignContent='center' spacing={1}>
                    <Grid item xs={2}>
                        File Attachments
                    </Grid>
                    {duplicateFileAttachmentError && <Grid item container alignContent='center' xs={10}>
                        <Grid item>
                            <Error color='error' />
                        </Grid>
                        <Grid item>
                            <span style={{ color: 'red' }}>{fieldErrors.get('fileAttachments')}</span>
                        </Grid>
                    </Grid>}
                </Grid>
            </Grid>
            <Grid item container direction='row' sx={{ maxHeight: 240, overflowY: 'auto' }} mb={1}>

                {formStep.fileAttachments?.map((fileAttachment, idx) => (
                    <Grid item xs={12} key={fileAttachment.blobFileName} display='flex' alignItems='center'>
                        <AttachFile fontSize='small' />
                        <Link href='#' onClick={() => handleFileLinkClicked(fileAttachment.blobFileName)}>{fileAttachment.friendlyFileName}</Link>
                        <IconButton color='error' sx={{ marginLeft: 'auto' }} onClick={() => handleRemoveFileClicked(idx, fileAttachment.blobFileName)}>
                            <RemoveCircle fontSize='small' />
                        </IconButton>
                    </Grid>
                ))}
            </Grid>
            <FileDropperArea entityId={stepId} temporaryEntityId={stepKey} linkedEntityType='Step' addFileAttachment={addFileAttachment} />
        </>
    )
}
