import { Add } from '@mui/icons-material';
import { Button, Dialog, DialogContent, Grid, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { DataTableColumn, PaginatedProps, StandardListViewV2, useBulkActionSnackbar } from '../../../Components/CoreLib/library';
import { ClientJobFunctionDto } from '../../../dtos';
import { useGetUserByUserIdAddableJobFunctionsQuery, useUpdateUserByUserIdAddMutation } from '../../../store/generated/generatedApi';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'NAME',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

const tableColumns: DataTableColumn<ClientJobFunctionDto>[] = [
    { key: 'name', label: 'Name', sortKey: 'NAME' },
    { key: 'memberCount', label: 'Number of Members', sortKey: '', unsorted: true },
];

export interface IUserAddJobFunctionsModalProps {
    userId: string;
    userName: string;
    isOpen: boolean;
    handleClose: () => void;
}

export const UserAddJobFunctionsModal: FC<IUserAddJobFunctionsModalProps> = (props) => {
    const { userId, userName, isOpen, handleClose } = props;
    const theme = useTheme();
    const [selectedJobFunctions, setSelectedJobFunctions] = useState<ClientJobFunctionDto[]>([]);
    const [addJobFunctionsToUser, { data: bulkAddResult, isLoading: isAddingJobFunctions, reset: resetJobFunctionBulkAdd }] =
        useUpdateUserByUserIdAddMutation();
    useBulkActionSnackbar('added', 'add', 'Job Function', 'Job Functions', bulkAddResult, () => {
        resetJobFunctionBulkAdd();
        if ((bulkAddResult?.numSuccessful ?? 0) > 0) {
            handleClose();
        }
    });

    const handleAddUserToJobFunctions = useCallback(() => {
        if (isAddingJobFunctions) {
            return;
        }
        const userIds = selectedJobFunctions.map((u) => u.id);
        addJobFunctionsToUser({ params: { userId: userId }, payload: userIds });
    }, [userId, isAddingJobFunctions, addJobFunctionsToUser, selectedJobFunctions]);

    return (
        <Dialog open={isOpen} fullWidth maxWidth='lg'>
            <DialogContent sx={{ padding: 0 }}>
                {/* TOD: Replace with ModalTitle or other shared component */}
                <Grid container direction='column' height='100%'>
                    <Grid
                        item
                        container
                        direction='row'
                        alignItems='center'
                        p={1}
                        sx={{ background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                        position='sticky'
                        top={0}
                        zIndex={2}>
                        <Add sx={{ mr: 1 }} />
                        <Typography fontWeight='bold'>Add {userName} To Job Functions</Typography>
                    </Grid>
                    <StandardListViewV2
                        headerIcon={<></>}
                        headerTitle=''
                        breadCrumbs={[]}
                        defaultPaginationProps={defaultPaginationProps}
                        getDataQuery={useGetUserByUserIdAddableJobFunctionsQuery}
                        tableColumns={tableColumns}
                        entityNameSingular='job function'
                        permissionName='jobFunctions'
                        isShowActiveToggleVisible={true}
                        additionalQueryParameters={{
                            userId,
                        }}
                        onSelectedChange={setSelectedJobFunctions}
                        hideMenu
                    />
                    <Grid item container direction='row' justifyContent='end' p={2} spacing={2}>
                        <Grid item>
                            <Button variant='outlined' color='secondary' onClick={handleClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={handleAddUserToJobFunctions}
                                disabled={isAddingJobFunctions || selectedJobFunctions.length === 0}>
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
