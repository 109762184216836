import { Help } from '@mui/icons-material';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReactFlow, { Background, Controls, MarkerType, MiniMap } from 'reactflow';
import 'reactflow/dist/style.css';
import Cookies from 'universal-cookie';
import * as Y from 'yjs';
import { FormValidationMethod, IFieldValidationResult, isNotBlank, isShorterThanMaxLength, runFieldValidation } from '../../../Components/CoreLib/library';
import { ExpandableSectionHandle } from '../../../Components/ExpandableSection';
import { ExpandableSection } from '../../../Components/ExpandableSection/ExpandableSection';
import { usePermissionChecker } from '../../../Hooks';
import { PlayDto, StepDto } from '../../../dtos';
import { usePatchAutoSavePlayDetailsMutation } from '../../../store/generated/generatedApi';
import { usePlayVariableRenameUtil, usePlayVariableUseTracker, useSelectedNodeManager } from '../Hooks';
import '../StyleSheets/custom-reactflow-styles.css';
import { mapNodesToSteps, useAutosaveFlowManager } from '../Utils';
import { PlayEditorContext } from '../Utils/PlayEditorContext';
import { EditPlayDetailsModal, PlayDetails } from './Modals';
import { EditStepModal } from './Modals/EditStepModal';
import { NodeLibrary } from './NodeLibrary';
import { PlayEditorAutosaveTitleBar } from './PlayEditorAutosaveTitleBar';
import { PlayVariablePanel } from './PlayVariablePanel';

export interface IPlayEditorAutosaveProps {
    playProjection: PlayDto;
    playDocument: Y.Doc;
}

export const PlayEditorAutosave: FC<IPlayEditorAutosaveProps> = ({ playProjection }) => {
    const [playVariables, setPlayVariables] = useState(playProjection.variables);
    const { canUserEditPlay } = usePermissionChecker();
    const [searchParams, setSearchParams] = useSearchParams();
    const cookies = useMemo(() => new Cookies(null), []);
    const isNodeLibraryOpenInRoute = cookies.get('nl');
    const isPlayVariablePanelOpenInRoute = cookies.get('pvp');
    const isNullOrOne = (val: string | null) => val === null || val === '1';
    const [isNodeLibraryExpanded, setIsNodeLibraryExpanded] = useState(isNullOrOne(isNodeLibraryOpenInRoute));
    const [isDataPanelExpanded, setIsDataPanelExpanded] = useState(isNullOrOne(isPlayVariablePanelOpenInRoute));
    const [updatePlayNameAndDescription] = usePatchAutoSavePlayDetailsMutation();

    const {
        reactFlowWrapper,
        nodes,
        edges,
        onNodesChange,
        onEdgesChange,
        onConnect,
        onDrop,
        onDragOver,
        nodeTypes,
        edgeTypes,
        reloadPlay,
        setNodes,
        getDependentNodeKeys,
        handleNodeMoved,
        handleStepUpdated
    } = useAutosaveFlowManager<StepDto>(playProjection);
    const { getPlayVariableUseMap } = usePlayVariableUseTracker();
    const { renameVariableInStep } = usePlayVariableRenameUtil();
    const [isEditPlayDetailsVisible, setIsEditPlayDetailsVisible] = useState(false);
    const { selectedStep, handleNodeClick, updateStep, stepDataCollectedSoFarForSelectedStep, clearSelectedStep } = useSelectedNodeManager(
        nodes,
        getDependentNodeKeys,
        playVariables
    );

    const playDetails = useMemo(() => ({ name: playProjection.name ?? '', description: playProjection.description ?? '' }), [playProjection.name, playProjection.description]);

    const handleUpdateStepClicked = useCallback((step: StepDto) => {
        handleStepUpdated(step);
        clearSelectedStep();
    }, [handleStepUpdated, clearSelectedStep]);
    
    const handleEditPlayDetailsClicked = useCallback(() => {
        setIsEditPlayDetailsVisible(true);
    }, []);

    const handleUpdatePlayDetails = useCallback(
        (updatedPlayDetails: PlayDetails) => {
            updatePlayNameAndDescription({ playId: playProjection.id, name: updatedPlayDetails.name, description: updatedPlayDetails.description });
            setIsEditPlayDetailsVisible(false);
        },
        [playProjection.id, updatePlayNameAndDescription]
    );

    const handleClosePlayDetailsModal = useCallback(() => {
        setIsEditPlayDetailsVisible(false);
    }, []);

    const userCanEdit = useMemo(() => {
        return canUserEditPlay(playProjection);
    }, [canUserEditPlay, playProjection]);

    const steps = useMemo(() => {
        return mapNodesToSteps(nodes);
    }, [nodes]);

    const playVariableUseMap = useMemo(() => getPlayVariableUseMap(playVariables, steps), [getPlayVariableUseMap, playVariables, steps]);

    const renamePlayVariable = useCallback(
        (oldName: string, newName: string) => {
            // Updating Play Variables Array
            let updatedPlayVariables = _.cloneDeep(playVariables);
            const renamedVarIndex = updatedPlayVariables.findIndex((v) => v.name === oldName);
            updatedPlayVariables.splice(renamedVarIndex, 1, { ...updatedPlayVariables[renamedVarIndex], name: newName });
            setPlayVariables(updatedPlayVariables);

            // Updating Play Nodes
            const updatedNodes = _.cloneDeep(nodes);
            let stepsUsingThisVariable = playVariableUseMap.get(oldName);
            stepsUsingThisVariable?.forEach((stepKey) => {
                let stepNodeIndex = updatedNodes.findIndex((node) => node.data.key === stepKey);
                if (stepNodeIndex === -1) {
                    console.error(`Failed to locate a step with the key ${stepKey} when renaming variable ${oldName} to ${newName}.`);
                    return;
                }
                let updatedNode = _.cloneDeep(updatedNodes[stepNodeIndex]);
                updatedNode.data = renameVariableInStep(_.cloneDeep(updatedNode.data), oldName, newName);
                updatedNodes.splice(stepNodeIndex, 1, updatedNode);
            });
            setNodes(updatedNodes);
        },
        [playVariableUseMap, nodes, setNodes, playVariables, renameVariableInStep]
    );

    const validatePlayVariableName = useCallback(
        (varName: string, currentIndex?: number): IFieldValidationResult => {
            const usedVariableNames = playVariables.map((pv) => pv.name.toLowerCase().trim());
            if (currentIndex !== undefined) {
                // If this is not a new record then we need to remove it from the used variable list so it does not conflict with itself
                usedVariableNames.splice(currentIndex, 1);
            }
            const isNotDuplicate: FormValidationMethod = (val: string) => {
                const isValid = !usedVariableNames.includes(val.toLowerCase());
                const errorMessageBuilder = () => 'Data name must be unique';
                return {
                    isValid,
                    errorMessageBuilder,
                };
            };

            return runFieldValidation(varName.trim(), {
                validators: [isNotBlank, isNotDuplicate, isShorterThanMaxLength(200)],
                errorMessageEntityName: 'Name',
            });
        },
        [playVariables]
    );

    // This useEffect automatically reloads the play editor whenever the play it is based on changes (normally that is just after an create/update request is sent)
    useEffect(() => {
        console.log('reloading play');
        reloadPlay(playProjection);
    }, [playProjection, reloadPlay]);

    // This useEffect keeps the query parameters in the URL in sync with the current state of the expandable panels
    useEffect(() => {
        cookies.set('pvp', isDataPanelExpanded ? '1' : '0');
        cookies.set('nl', isNodeLibraryExpanded ? '1' : '0');
    }, [isNodeLibraryExpanded, isDataPanelExpanded, searchParams, setSearchParams, cookies]);

    return (
        <PlayEditorContext.Provider
            value={{ playId: playProjection.id, steps, updateStep, playVariables, setPlayVariables, renamePlayVariable, playVariableUseMap, validatePlayVariableName }}>
            <Box flexDirection='column' display='flex' overflow='hidden'>
                <PlayEditorAutosaveTitleBar
                    play={playProjection}
                    onEditPlayDetailsClicked={handleEditPlayDetailsClicked}
                />
                <Box flexDirection='row' display='flex' height='calc(100vh - 140px)'>
                    {canUserEditPlay(playProjection) && (
                        <ExpandableSection isExpanded={isNodeLibraryExpanded} direction='right'>
                            <NodeLibrary />
                        </ExpandableSection>
                    )}
                    <Grid item flexGrow={1} height='100%' ref={reactFlowWrapper} position='relative'>
                        <ExpandableSectionHandle
                            isExpanded={isNodeLibraryExpanded}
                            setIsExpanded={setIsNodeLibraryExpanded}
                            direction='right'
                            backgroundColor='#f3f5f6'
                        />
                        <Tooltip title='Drag either a Start, Step, or Start out onto the workspace. Click on the step to set parameters. Pull on the black dot at the side to connect to the next step. To delete a step, select it, then push Delete on your keyboard.'>
                            <IconButton sx={{ position: 'absolute', top: 16, right: 16, zIndex: 2 }} disableRipple={true}>
                                <Help />
                            </IconButton>
                        </Tooltip>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            defaultEdgeOptions={{
                                deletable: true,
                                markerEnd: {
                                    type: MarkerType.ArrowClosed,
                                },
                                type: 'deletable',
                            }}
                            onNodesChange={userCanEdit ? onNodesChange : undefined}
                            onEdgesChange={userCanEdit ? onEdgesChange : undefined}
                            onConnect={onConnect}
                            onDrop={onDrop}
                            onDragOver={userCanEdit ? onDragOver : undefined}
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                            onNodeClick={handleNodeClick}
                            snapToGrid
                            deleteKeyCode={null}
                            onNodeDragStop={handleNodeMoved}
                            >
                            <Controls />
                            <Background />
                            <MiniMap pannable zoomable />
                        </ReactFlow>
                        <ExpandableSectionHandle isExpanded={isDataPanelExpanded} setIsExpanded={setIsDataPanelExpanded} direction='left' />
                    </Grid>
                    {canUserEditPlay(playProjection) && (
                        <ExpandableSection isExpanded={isDataPanelExpanded} direction='left'>
                            <PlayVariablePanel />
                        </ExpandableSection>
                    )}
                </Box>
                <EditStepModal
                    step={selectedStep}
                    collectedData={stepDataCollectedSoFarForSelectedStep}
                    updateStep={handleUpdateStepClicked}
                    clearSelectedStep={clearSelectedStep}
                />
                <EditPlayDetailsModal
                    closeModal={handleClosePlayDetailsModal}
                    onUpdatePlayDetails={handleUpdatePlayDetails}
                    playDetails={playDetails}
                    isOpen={isEditPlayDetailsVisible}
                />
            </Box>
        </PlayEditorContext.Provider>
    );
};
