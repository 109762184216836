import { DoubleArrow, FilterList } from '@mui/icons-material';
import { FC, useCallback, useMemo } from 'react';
import {
    DataTableColumn,
    IStandardListViewExtraItems,
    PaginatedProps,
    StandardCardWithHeader,
    StandardListViewV2,
} from '../../../Components/CoreLib/library';
import { StepDto } from '../../../dtos';
import { useGetPlayByPlayIdStepsQuery } from '../../../store/generated/generatedApi';
import { useNavigate } from 'react-router-dom';
import { Grid, useTheme } from '@mui/material';
import { IStepExecutionFilterOptions } from '../Util/useStepExecutionFilters';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'NAME',
    sortAsc: false,
    page: 0,
    pageSize: 25,
};

export interface IStepsDataListView {
    playbookId: string;
    playId: string;
    stepExecutionFilters: IStepExecutionFilterOptions;
    handleSearchTermFilterChange: (updatedFilters: IStepExecutionFilterOptions) => void;
}
export const StepsDataListView: FC<IStepsDataListView> = (props) => {
    const { stepExecutionFilters, handleSearchTermFilterChange, playbookId, playId } = props;
    const navigate = useNavigate();
    const theme = useTheme();

    const goToEditStepPage = useCallback((stepId: string) => navigate(`/playbook/${playbookId}/play/${playId}?stepId=${stepId}`), [navigate, playId, playbookId]);
    const clickableRowStyles = useCallback(
        () => ({
            cursor: 'pointer',
        }),
        []
    );

    const formatMinutes = function (totalMinutes: number) {
        const sign = Math.sign(totalMinutes);
        var absTotal = Math.abs(totalMinutes);
        var mins = absTotal % 60;
        var hours = Math.floor(absTotal / 60);
        var days = Math.floor(hours / 24);
        hours = hours % 24;
        return (sign < 0 ? '-' : '') + (days !== 0 ? days + 'd ' : '') + (hours !== 0 ? hours + 'h ' : '') + mins + 'm';
    };

    const formatType = function (type: string) {
        return type === "DEFAULT" ? 'Standard' : 'Automation';
    };

    const tableColumns: DataTableColumn<StepDto>[] = [
        { label: 'Step Name', key: 'stepName', sortKey: 'NAME', fieldMapper: (row) => row.name },
        { label: 'Step Type', key: 'stepType', sortKey: 'TYPE', fieldMapper: (row) => formatType(row.type) },
        { label: 'Assignment Type', key: 'assignmentType', sortKey: 'IS_EXTERNAL', fieldMapper: (row) => (row.type !== "Automation" ? (row.isExternal ? 'External' : 'Internal') : '') },
        {
            label: 'Assigned Job Function',
            key: 'jobFunction',
            sortKey: 'JOB_FUNCTION',
            fieldMapper: (row) => (row.internalJobFunction ? row.internalJobFunction.name : ''),
        },
        {
            label: 'Average Execution Time',
            key: 'averageExecutionTime',
            sortKey: 'EXECUTION_TIME_IN_MINUTES_AVERAGE',
            fieldMapper: (row) => (row.executionTimeInMinutesAverage !== 0 ? formatMinutes(row.executionTimeInMinutesAverage) : ''),
        },
        {
            label: 'Estimated Execution Time',
            key: 'estimatedExecutionTime',
            sortKey: 'EXECUTION_TIME_IN_MINUTES_ESTIMATED',
            fieldMapper: (row) => (row.type !== "Automation" ? formatMinutes(row.estimatedExecutionTimeInMinutes) : ''),
        },
        {
            label: 'Average Lag Time',
            key: 'averageLagTime',
            sortKey: 'EXECUTION_TIME_IN_MINUTES_LAG',
            fieldMapper: (row) => (
                row.type !== "Automation" && row.executionTimeInMinutesLag !== 0 ? 
                (<span style={{ color: row.executionTimeInMinutesLag !== 0 ? (row.executionTimeInMinutesLag < 0 ? theme.palette.success.main : theme.palette.error.main) : 'primary' }}>
                    {' '}
                    {formatMinutes(row.executionTimeInMinutesLag)}
                </span> ) : ''
            ),
        },
    ];

    const handleStepChange = useCallback((selectedStep?: StepDto) => {
        handleSearchTermFilterChange({
            ...stepExecutionFilters,
            stepKey: selectedStep?.key ?? '',
            step: selectedStep,
        });
    }, [stepExecutionFilters, handleSearchTermFilterChange]);

    const verifyOnlyOneIsSelected = useCallback((selectedItems: StepDto[]) => {
        return selectedItems.length === 1;
    }, []);

    const handleFilterStepExecutionsClicked = useCallback((selectedItems: StepDto[]) => {
        if (selectedItems.length !== 1) {
            return;
        }
        const selectedItem = selectedItems[0];
        handleStepChange(selectedItem)
    }, [handleStepChange]);

    const additionalTableButtons: IStandardListViewExtraItems[] = useMemo(() => [
        {
            enabledCheck: verifyOnlyOneIsSelected,
            onClick: handleFilterStepExecutionsClicked,
            icon: <FilterList />,
            text: 'Filter Step Executions',
            clearSelectedItems: true
        }
    ], [verifyOnlyOneIsSelected, handleFilterStepExecutionsClicked]);

    const handleRowClick = useCallback(
        (stepId: string, row: StepDto) => {
            if (row.isActive) {
                goToEditStepPage(stepId);
            }
        },
        [goToEditStepPage]
    );

    return (
        <>
        <StandardCardWithHeader headerTitle='Steps' headerIcon={<DoubleArrow />}>
            <Grid container direction='column'>
            <StandardListViewV2
                headerIcon={<></>}
                headerTitle=''
                defaultPaginationProps={defaultPaginationProps}
                getDataQuery={useGetPlayByPlayIdStepsQuery}
                tableColumns={tableColumns}
                additionalQueryParameters={{
                    playId: playId,
                }}
                isShowActiveToggleVisible={true}
                entityNameSingular={'Step'}
                permissionName={'steps'}
                disableGutters
                handleRowClick={handleRowClick}
                customRowStyling={clickableRowStyles}
                handleEdit={handleRowClick}
                hideMenuDivider
                disableEditForInactive
                showInactiveOverrideText='Show Deleted'
                additionalActionItems={additionalTableButtons}
                additionalMenuItems={additionalTableButtons}
            />
            </Grid>
        </StandardCardWithHeader>
        </>
    );
};
