import { useAuth0 } from '@auth0/auth0-react';
import { Book, BugReport, Plumbing, Home, Person } from '@mui/icons-material';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useLazyGetUserAuth0UserIdByIdQuery } from '../../store/generated/generatedApi';
import { LoadingIndicator } from '../CoreLib/library';
import NavigationDrawer from '../NavigationDrawer';
import { NavigationDrawerRouteDTO } from './types';

export const NavigationDrawerContainer: FC<PropsWithChildren> = (props) => {
    const { user, isLoading, error } = useAuth0();
    const [getUserData] = useLazyGetUserAuth0UserIdByIdQuery();

    useEffect(() => {
        if (user?.sub) {
            getUserData({
                id: user?.sub
            });
        }
    }, [user, getUserData]);

    const publicRoutes: NavigationDrawerRouteDTO[] = [
        {
            icon: <Home />,
            name: 'Home',
            route: '/',
        },
        {
            icon: <Book />,
            name: 'Playbooks',
            route: '/playbooks',
            requiredPermissions: ['read:playbooks']
        }
    ];

    const reportRoutes: NavigationDrawerRouteDTO[] = [];

    const adminRoutes: NavigationDrawerRouteDTO[] = [
        {
            icon: <Person />,
            name: 'Users',
            route: '/users',
            requiredPermissions: ['read:users']
        },
        {
            icon: <Plumbing />,
            name: 'Job Functions',
            route: '/jobFunctions',
            requiredPermissions: ['read:jobFunctions']
        },
        {
            icon: <BugReport />,
            name: 'Debug',
            route: '/developerDebug',
            requiredPermissions: ['read:developer']
        }
    ];

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <p>{error.message}</p>;
    }
    return (
        <NavigationDrawer routes={publicRoutes} adminRoutes={adminRoutes} reportRoutes={reportRoutes}>
            {props.children}
        </NavigationDrawer>
    );
};
