import { Book, Delete, Edit, Help } from '@mui/icons-material';
import { Box, Grid, IconButton, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { AuthenticatedComponent, CardListView, LoadingBackdrop } from '../../Components/CoreLib/library';
import { PlaybookDto } from '../../dtos';
import { useGetPlaybookQuery, usePlaybookByIdDeleteMutation } from '../../store/generated/generatedApi';
import { AddEditPlaybookModal } from './Components';
import { DEFAULT_PLAYBOOK } from './Utils';
import { useNavigate } from 'react-router-dom';
import { useConfirmDelete } from '../../customHooks';

export const Playbooks: FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [selectedPlaybook, setSelectedPlaybook] = useState<PlaybookDto | undefined>(undefined);

    const handleCreateNew = () => {
        setSelectedPlaybook(DEFAULT_PLAYBOOK);
    };

    const handleEdit = (item: PlaybookDto) => {
        setSelectedPlaybook(item);
    };

    const clearSelectedPlaybook = () => {
        setSelectedPlaybook(undefined);
    };

    const { setObjectToDelete, isDeletingObject, renderDeleteConfirmationModal } = useConfirmDelete('Playbook', usePlaybookByIdDeleteMutation);

    const handleShowDeleteConfirmationModal = useCallback(
        (objectToDelete: any) => {
            setObjectToDelete(objectToDelete);
        },
        [setObjectToDelete]
    );

    const renderCard = useCallback(
        (item: PlaybookDto) => (
            <Paper sx={{ height: '100%', cursor: 'pointer' }} className='hover-content-container' onClick={() => navigate(`/playbook/${item.id}/plays`)}>
                <Grid item container direction='column' justifyContent='space-between' height={1}>
                    <Grid item container direction='row' alignItems='center' wrap="nowrap" >
                        <Grid
                            item
                            sx={{
                                background: `url('/assets/playbook-blank.png') ${theme.palette.primary.main}`,
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundColor: '#839FCF',
                                backgroundRepeat: 'no-repeat',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderTopLeftRadius: 4,
                            }}
                            width={60}
                            height={60}>
                            <Typography textTransform='uppercase' fontWeight='bold' color='primary' fontSize={20}>
                                {item.initials}
                            </Typography>
                        </Grid>
                        <Grid item container className='scrollable-text' px={1.5} pt={.1} style={{ maxHeight: '62px' }} >
                            <Typography fontSize={20} fontWeight='bold' style={{ whiteSpace: 'normal', overflow: 'hidden' }}>
                                {item.name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row'  >
                        <Grid item className='scrollable-text' px={1.5} maxHeight={40} pt={.5} >
                            <Typography fontSize={14} style={{ whiteSpace: 'normal', overflow: 'hidden' }}>
                                {item.description}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' justifyContent='space-between' alignItems={'center'} px={1} pb={1}>
                        <Grid item direction='column' >
                            < Tooltip title={`${item.activePlays ?? "0"} Play(s)`}>
                                <Grid item container direction='row' alignItems={'center'} >

                                    <Typography lineHeight={1} fontSize={30} fontWeight='bold' mx={1}>
                                        {item.activePlays}
                                    </Typography>
                                    <img src='/assets/Play-1-Lime.png' alt='sports clipboard' height={20} />
                                </Grid>
                            </Tooltip>
                            <AuthenticatedComponent requiredPermissions={[`read:developer`]}>
                                <Grid item textAlign={'center'} >
                                    <Typography fontWeight='bold' fontSize={10} lineHeight={1}>({item.key})</Typography>
                                </Grid>
                            </AuthenticatedComponent>
                        </Grid>
                        <Grid item  >
                            <AuthenticatedComponent requiredPermissions={[`delete:playbooks`]}>
                                <Tooltip title='Delete'>
                                    <IconButton
                                        size='medium'
                                        sx={{
                                            fontSize: 30
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleShowDeleteConfirmationModal(item);
                                        }}
                                        disabled={isDeletingObject}>
                                        <Delete fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            </AuthenticatedComponent>
                            <AuthenticatedComponent requiredPermissions={[`edit:playbooks`]}>
                                <Tooltip title='Edit'>
                                    <IconButton
                                        size='medium'
                                        sx={{
                                            fontSize: 30
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleEdit(item);
                                        }}>
                                        <Edit fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            </AuthenticatedComponent>
                        </Grid>

                    </Grid>
                </Grid>
                <LoadingBackdrop loadingOptions={[{ isLoading: isDeletingObject, loadingMessage: 'Deleting playbook...' }]} />
            </Paper >
        ),
        [theme, isDeletingObject, navigate, handleShowDeleteConfirmationModal]
    );

    return (
        <>
            {renderDeleteConfirmationModal}
            <Box>
                <CardListView
                    headerIcon={<Book />}
                    headerTitle='Playbooks'
                    breadCrumbs={[{ label: 'Home', navLink: '/' }]}
                    sortKey={'NAME'}
                    getDataQuery={useGetPlaybookQuery}
                    entityNameSingular='Playbook'
                    permissionName='playbooks'
                    handleAdd={handleCreateNew}
                    renderCard={renderCard}
                    endTitleSlot={
                        <Tooltip title='Find the broad categories of your plays here.'>
                            <IconButton>
                                <Help />
                            </IconButton>
                        </Tooltip>
                    }
                    stickyPageHeaderHightOffset={140}
                    stickyPageHeader={true}
                />
                {selectedPlaybook && <AddEditPlaybookModal playbook={selectedPlaybook} closeModal={clearSelectedPlaybook} />}
            </Box>
        </>
    );
};
