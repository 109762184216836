import { AddCircle } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import _ from 'lodash';
import { FC } from 'react';
import { emptyGuid } from '../../../../../../util';
import { EmbeddingInputDataRow } from './EmbeddingInputDataRow';
import { StepEmbedInputDto } from '../../../../../../dtos';

export interface IEmbeddingInputProps {
    stepId: string;
    embeddingInputs: StepEmbedInputDto[];
    updateEmbeddingInputs: (updatedData: StepEmbedInputDto[]) => void;
}

export const EmbeddingInput: FC<IEmbeddingInputProps> = props => {
    const { stepId, embeddingInputs = [], updateEmbeddingInputs } = props;

    const addData = () => {
        var newEmbeddingInput: StepEmbedInputDto = {
            id: emptyGuid,
            stepId: stepId,
            embedLink: '',
            orderIndex: embeddingInputs.length
        };
        var updatedEmbeddingInputs = [...embeddingInputs, newEmbeddingInput];
        updateEmbeddingInputs(updatedEmbeddingInputs);
    };

    const removeEmbedLink = (embeddingInputIndex: number) => {
        var updatedEmbeddingInputs = [...embeddingInputs];
        updatedEmbeddingInputs.splice(embeddingInputIndex, 1);
        updateEmbeddingInputs(updatedEmbeddingInputs);
    };

    const updateEmbedLink = (embeddingInputIndex: number, embeddingLink: string) => {
        var updatedEmbeddingInputs = _.cloneDeep(embeddingInputs);
        updatedEmbeddingInputs[embeddingInputIndex].embedLink = embeddingLink;
        updateEmbeddingInputs(updatedEmbeddingInputs);
    };

    return (
        <>
            <Grid item mt={2} container direction='row'>
                <Grid item>
                    Embeddings
                </Grid>
            </Grid>
            {embeddingInputs?.map((embedLink, i) => (
                <EmbeddingInputDataRow
                    key={`embed-link-${i}`}
                    index={i}
                    allEmbeddingInputEntries={embeddingInputs}
                    embedInput={embedLink}
                    updateEmbedLink={updateEmbedLink}
                    removeEmbedLink={removeEmbedLink}
                /> 
            ))}
            <Grid item>
                <IconButton color='primary' onClick={addData}>
                    <AddCircle />
                </IconButton>
            </Grid>
        </>
    )
}
