import { FC, useCallback } from 'react';
import { ClientJobFunctionDto, UserDto } from '../../dtos';
import { useFailedCreateSnackbar, useSuccessfulActionSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { useCreateJobFunctionMutation, useUpdateJobFunctionByJobFunctionIdAddMutation } from '../../store/generated/generatedApi';
import { QuickJobFunctionForm } from '../../Components/Forms/JobFunctionForm/QuickJobFunctionForm';
import { useFailedActionSnackbar } from '../../Components/CoreLib/library';

export interface IQuickAddJobFunctionViewProps {
    onCloseView: () => void;
    setNewJobFunctionView: (newJobFunction: ClientJobFunctionDto | undefined) => void;
}

export const QuickAddJobFunctionView: FC<IQuickAddJobFunctionViewProps> = (props) => {
    const { onCloseView, setNewJobFunctionView } = props;
    const [createClientJobFunction, { isLoading: isCreateJobFunctionLoading, isError: isCreateJobFunctionError, isSuccess: isCreateJobFunctionSuccess, reset: resetCreateJobFunction }] = useCreateJobFunctionMutation();
    const [addUsersToJobFunction, { isLoading: isAddUsersLoading, isError: isAddUsersError, isSuccess: isAddUsersSuccess, reset: resetAddUsers }] = useUpdateJobFunctionByJobFunctionIdAddMutation();

    useSuccessfulCreateSnackbar('Job Function', isCreateJobFunctionSuccess);
    useFailedCreateSnackbar('job function', isCreateJobFunctionError, resetCreateJobFunction);
    useSuccessfulActionSnackbar('assigned', 'job function users', isAddUsersSuccess);
    useFailedActionSnackbar('assign', 'job function users', isAddUsersError, resetAddUsers);

    const handleSave = useCallback((values: ClientJobFunctionDto, usersToAdd: UserDto[]) => {
        createClientJobFunction(values).unwrap().then((createJobFunctionResult) => {
            if (usersToAdd.length > 0) {
                addUsersToJobFunction({ params: { jobFunctionId: createJobFunctionResult.id }, payload: usersToAdd.map(u => u.id) }).unwrap().then(() => {
                    setNewJobFunctionView({ ...createJobFunctionResult, memberCount: usersToAdd.length });
                });
            } else {
                setNewJobFunctionView(createJobFunctionResult)
            }
        });
    }, [addUsersToJobFunction, createClientJobFunction, setNewJobFunctionView]);

    const handleCancel = () => {
        onCloseView();
    };

    return <QuickJobFunctionForm save={handleSave} cancel={handleCancel} isLoading={isCreateJobFunctionLoading || isAddUsersLoading} />;
};
