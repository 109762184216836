import { ContentCopy, Help } from '@mui/icons-material';
import { Grid, Typography, Tooltip, Box, Table, TableHead, TableRow, TableCell, TableBody, Link, IconButton } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback } from 'react';
import { StepDataType, StepExecutionDataDto } from '../../../dtos';
import { downloadFile, formatTimestamp } from '../../../util';
import { useCreateAttachmentByIdDownloadMutation } from '../../../store/generated/generatedApi';

export interface ICollectedDataPanelProps {
    collectedData: StepExecutionDataDto[];
}

export const CollectedDataPanel: FC<ICollectedDataPanelProps> = ({ collectedData }) => {
    const [createAttachmentByIdDownload, { isLoading: isCreatingDownloadLink }] = useCreateAttachmentByIdDownloadMutation();

    const handleFileLinkClicked = useCallback(
        async (stepExecutionId: string, fileName: string) => {
            if (isCreatingDownloadLink) {
                return;
            }
            var downloadLink = await createAttachmentByIdDownload({ id: stepExecutionId, fileName }).unwrap();
            downloadFile(downloadLink.link, fileName);
        },
        [createAttachmentByIdDownload, isCreatingDownloadLink]
    );

    return (
        <Grid item container direction='column' width='300px' sx={{ background: '#adcbee' }} height='100%' overflow='auto'>
            <Typography
                fontSize='0.875rem'
                fontWeight='500'
                display='flex'
                justifyContent='center'
                alignItems='center'
                gap={0.5}
                p={1.7}
                borderBottom='1px solid rgba(0, 0, 0, .2)'>
                DATA COLLECTED
                <Tooltip title='These are the data values that have been collected by previous steps in this play.'>
                    <Help fontSize='inherit' />
                </Tooltip>
            </Typography>
            <Box sx={{ maxHeight: '75vh', overflowY: 'auto', p: 1 }}>
                <Table sx={{ background: 'white' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Label</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {collectedData.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={3}>No Data Collected So Far</TableCell>
                            </TableRow>
                        ) : (
                            _.uniqBy(collectedData, 'name').map((cd) => (
                                <TableRow key={cd.id}>
                                    <TableCell size='small'>{cd.name}</TableCell>
                                    <TableCell size='small' style={{wordBreak: 'break-word', whiteSpace: 'normal'}}>
                                        {cd.dataType === StepDataType.FILE ? (
                                            <Link href='#' underline='hover' onClick={() => handleFileLinkClicked(cd.stepId, cd.value)}>
                                                {cd.value}
                                            </Link>
                                        ) : (
                                            formatTimestamp('', cd)
                                        )}
                                    </TableCell>
                                    <TableCell size='small'>
                                        <IconButton
                                            onClick={() => {
                                                navigator.clipboard.writeText(cd.value);
                                            }} size='small'>
                                            <ContentCopy fontSize='small' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </Box>
        </Grid>
    );
};
