import { useState } from "react";
import { Edge, Node } from "reactflow";
import { useFailedCreateSnackbar, useFailedUpdateSnackbar, useSuccessfulCreateSnackbar, useSuccessfulUpdateSnackbar } from "../../../Components/CoreLib/library";
import { PlayDto, PlayVariableDto, StepDto } from "../../../dtos";
import { useCreatePlayMutation, useUpdatePlayMutation } from "../../../store/generated/generatedApi";
import { mapEdgesToDependencies, mapNodesToSteps, removeDependenciesWithMissingNodes } from "../Utils";
import { useNavigate } from "react-router-dom";
import { emptyGuid } from "../../../util";
import { PlayDetails } from "../Components";

export function usePlayUpdater(originalPlay: PlayDto, nodes: Node<StepDto, string | undefined>[], edges: Edge<any>[], playVariables: PlayVariableDto[]) {
    const navigate = useNavigate();
    const [playDetails, setPlayDetails] = useState<PlayDetails>({ name: originalPlay.name ?? '', description: originalPlay.description ?? ''});
    const [updatePlayMutation, { isError: isUpdateError, isSuccess: isUpdateSuccess, isLoading: isUpdating, reset: resetUpdate }] = useUpdatePlayMutation();
    useSuccessfulUpdateSnackbar('play', isUpdateSuccess, resetUpdate);
    useFailedUpdateSnackbar('play', isUpdateError, resetUpdate);
    const [createPlayMutation, { data: createdPlay, isError: isCreateError, isSuccess: isCreateSuccess, isLoading: isCreating, reset: resetCreate }] = useCreatePlayMutation();
    useSuccessfulCreateSnackbar('play', isCreateSuccess, () => navigate(`/playbook/${createdPlay!.playbookId}/play/${createdPlay!.id}`));
    useFailedCreateSnackbar('play', isCreateError, resetCreate);

    const savePlay = () => {
        if (isUpdating || isCreating) {
            return;
        }
        const updatedSteps = mapNodesToSteps(nodes);
        const updatedPlay: PlayDto = {
            ...originalPlay,
            name: playDetails.name,
            description: playDetails.description,
            steps: updatedSteps,
            stepDependencies: removeDependenciesWithMissingNodes(
                mapEdgesToDependencies(edges, originalPlay.stepDependencies ?? []),
                updatedSteps?.map((s) => s.key ?? '') ?? []
            ),
            variables: playVariables
        };
        if (updatedPlay.id === emptyGuid) {
            createPlayMutation(updatedPlay);
        } else {
            updatePlayMutation(updatedPlay);
        }
    };

    return {
        savePlay,
        playDetails,
        setPlayDetails,
        isUpdating,
        isCreating
    }
}