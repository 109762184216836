import { FC } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../dtos";
import { NodeType } from "../NodeType";
import { NodeTypes } from "../utils";
import { IStepEditModalProps, IStepExecutionDetailsProps } from "../types";
import { StepNodeEditForm, StepNodeExecutionDetails, StepNodeLibraryListItem, StepPlayViewerNode } from "./Components";

export class StepNodeType extends NodeType {
    public readonly nodeType: string = NodeTypes.STEP;
    
    public readonly PlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = StepPlayViewerNode;
    public readonly EditModalContent?: FC<IStepEditModalProps> = StepNodeEditForm;
    public readonly ExecutionDetailsContent?: FC<IStepExecutionDetailsProps> = StepNodeExecutionDetails;
    public readonly NodeLibraryListItem: FC = StepNodeLibraryListItem;
}