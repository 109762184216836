import { FC } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../dtos";
import { NodeType } from "../NodeType";
import { NodeTypes } from "../utils";
import { ParallelNodeLibraryListItem, ParallelPlayViewerNode } from "./Components";
import { INodeRules } from "../types";

export class ParallelNodeType extends NodeType {
    public readonly nodeType: string = NodeTypes.PARALLEL;
    public override readonly validationRules: INodeRules = { maxInputs: 1, maxOutputs: 9999 }

    public readonly PlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = ParallelPlayViewerNode;
    public readonly NodeLibraryListItem: FC = ParallelNodeLibraryListItem;
}