import _ from "lodash";
import { useCallback, useState } from "react";
import { DEFAULT_STEP } from "../Views/Plays/Utils";
import { EventWrapper, MatchType, PlayDto, StepDto } from "../dtos";

const DEFAULT_PLAY: PlayDto = {
    id: '',
    name: '',
    description: '',
    playbookId: '',
    steps: [],
    stepCount: 0,
    stepDependencies: [],
    violations: [],
    variables: [],
    isValid: false,
    containsAutomationSteps: false,
    containsAISteps: false,
    isActive: true,
    createdOn: new Date(),
};

enum StepType {
    DEFAULT,
    START,
    STOP,
    CONDITIONAL,
    CONDITIONAL_MERGE,
    PARALLEL,
    PARALLEL_JOIN,
    POST,
    SUB_PLAY,
    DELAY,
    NOTIFICATION,
    ARTIFICIAL_INTELLIGENCE,
}

export function usePlayProjector() {
    const [playProjection, setPlayProjection] = useState<PlayDto>(DEFAULT_PLAY);
    // TODO: handle PlayVersionCreated event (we probably want to make a separate PlayDTO so we can add new fields like version, etc.)
    const applyEvents = useCallback((eventsToApply: EventWrapper[]) => {
        console.log('Applying events', eventsToApply);
        // order events by storeVersion
        eventsToApply = eventsToApply.sort((a, b) => a.storeVersion - b.storeVersion);
        // apply events to document
        setPlayProjection((play) => {
            play = _.cloneDeep(play);
            eventsToApply.forEach((event) => {
                // parse eventData as an object
                const data = JSON.parse(event.eventData);
                var step: StepDto | null = null;
                switch (event.eventType) {
                    // Play Events
                    case 'PlayVersionCreated': // sample event: {"VersionNumber":1,"PlayId":"cd36d7f2-f016-441f-8839-409b26981602","ClientId":"51aa969d-38c6-4b20-9d73-cc39691d51b8","CommittedByUserId":"c8b3ef1e-4080-4c9a-aeee-8a708a8e1c84","EntityId":"47be2193-8927-4109-a7c8-5076e54ff4fc","CommittedOn":"2024-08-30T17:08:14.409983Z","Version":638606344944.1128}
                        // not sure what to do with this event right now
                        break;
                    case 'PlayPlayVersionCreated': // sample event: {"PlayVersionId":"47be2193-8927-4109-a7c8-5076e54ff4fc","VersionNumber":1,"ClientId":"51aa969d-38c6-4b20-9d73-cc39691d51b8","CommittedByUserId":"c8b3ef1e-4080-4c9a-aeee-8a708a8e1c84","EntityId":"cd36d7f2-f016-441f-8839-409b26981602","CommittedOn":"2024-08-30T17:08:14.531051Z","Version":638606344945.3147}
                    case 'PlayCreated': // sample event: {"PlaybookId":"1cb1bb08-d881-41b8-ae3c-fe5914234315","PlayIdentifier":"000298","Name":"Description Test","Description":"tst","ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"abe974cc-497e-45a8-a0f4-72df902a96d2","CommittedOn":"2024-07-05T11:34:43.395532Z","Version":638557760833.9728}
                        play.playbookId = data.PlaybookId;
                        play.key = data.PlayIdentifier;
                        play.name = data.Name;
                        play.description = data.Description;
                        play.id = data.EntityId;
                        play.createdOn = new Date(data.CommittedOn);
                        play.createdBy = data.CommittedByUserId;
                        break;
                    case 'PlayUpdated': // sample event: {"Name":"Description Test","Description":"tst","ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"abe974cc-497e-45a8-a0f4-72df902a96d2","CommittedOn":"2024-07-05T11:38:32.063356Z","Version":638557763120.6355}
                        console.log('PlayUpdated', data);
                        play.name = data.Name;
                        play.description = data.Description;
                        break;
                    case 'PlayVersionStepAdded':
                    case 'PlayStepAdded': // sample event: {"StepId":"325bc4b8-fc6c-4881-ae30-235a19c55bfe","Identifier":"000299","StepType":1,"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"abe974cc-497e-45a8-a0f4-72df902a96d2","CommittedOn":"2024-07-05T11:34:44.809879Z","Version":638557760848.1294}
                        play.stepCount = play.stepCount! + 1;
                        break;
                    case 'PlayStepDeleted': // sample event: {"StepId":"09f4584d-5cc1-43ca-84fa-2d673a30aca8","ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"153b6b6d-16b9-47b0-8af8-3eca4ba9f6d9","CommittedOn":"2024-07-16T19:38:56.53671Z","Version":638567555365.3673}
                        play.stepCount = play.stepCount! - 1;
                        break;
                    case 'PlayVersionDataToCollectAdded':
                    case 'PlayDataToCollectAdded': // sample event: {"StepIdentifier":"000299","DataToCollect":[],"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"abe974cc-497e-45a8-a0f4-72df902a96d2","CommittedOn":"2024-07-05T11:34:44.810132Z","Version":638557760848.1416}
                        step = play.steps?.find((step) => step.key === data.StepIdentifier) ?? null;
                        if (step) {
                            if (data.DataToCollect) {
                                step.stepData = data.DataToCollect.map((collectedData: any) => {
                                    return {
                                        id: Math.random().toString(36).substring(7),
                                        stepId: step!.id,
                                        name: collectedData.Name,
                                        isRequired: collectedData.IsRequired,
                                        dataType: collectedData.DataType,
                                        isFutureDate: collectedData.IsFutureDate,
                                        orderIndex: collectedData.OrderIndex ?? 0,
                                    };
                                });
                            }
                        }
                        break;
                    case 'PlayVersionDependencyGraphUpdated':
                    case 'PlayDependencyGraphUpdated': // sample event: {"StepKey":"000299","Prerequisites":[],"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"abe974cc-497e-45a8-a0f4-72df902a96d2","CommittedOn":"2024-07-05T11:34:49.9888Z","Version":638557760899.8882}
                        // remove current dependencies for the step
                        play.stepDependencies = play.stepDependencies?.filter((dep) => dep.stepKey !== data.StepKey);
                        // add new dependencies
                        data.Prerequisites.forEach((prerequisite: string) => {
                            play.stepDependencies?.push({
                                // generate a new id
                                id: Math.random().toString(36).substring(7),
                                playId: play.id,
                                stepKey: data.StepKey,
                                dependentUponStepKey: prerequisite,
                            });
                        });
                        break;
                    case 'PlayValidationStarted':
                        // nothing needs to happen for this event
                        break;
                    case 'PlayValidationCompleted':
                        // nothing needs to happen for this event
                        break;
                    case 'PlayValidationErrorDetected': // sample event: {"Message":"Play has no stop step. Please modify the play to contain at least one stop step.","StepIdentifier":null,"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"153b6b6d-16b9-47b0-8af8-3eca4ba9f6d9","CommittedOn":"2024-07-16T19:26:45.848034Z","Version":638567548058.4811}
                        // TODO: need to re-implement validation
                        break;

                    // Step Events
                    case 'StepCreated': // sample event: {"PlayId":"abe974cc-497e-45a8-a0f4-72df902a96d2","Identifier":"000299","StepType":1,"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"325bc4b8-fc6c-4881-ae30-235a19c55bfe","CommittedOn":"2024-07-05T11:34:44.579949Z","Version":638557760845.8313}
                        play.steps?.push({
                            ...DEFAULT_STEP,
                            id: data.EntityId,
                            key: data.Identifier,
                            type: StepType[data.StepType],
                            createdBy: data.CommittedByUserId,
                            createdOn: new Date(data.CommittedOn),
                            playId: play.id,
                        });
                        break;
                    case 'StepDeleted': // sample event: {"IsPermanent":true,"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"ee510a34-9cd1-4c35-81ca-142a23b245bb","CommittedOn":"2024-07-16T19:35:29.431306Z","Version":638567553294.3147}
                    case 'StepDeletedPermanently': // sample event: {"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"09f4584d-5cc1-43ca-84fa-2d673a30aca8","CommittedOn":"2024-07-16T19:38:56.315604Z","Version":638567555363.1562}
                        // get step
                        step = play.steps?.find((step) => step.id === data.EntityId) ?? null;
                        // remove dependencies related to step
                        play.stepDependencies = play.stepDependencies?.filter((dep) => dep.stepKey !== step?.key && dep.dependentUponStepKey !== step?.key);
                        play.steps = play.steps?.filter((step) => step.id !== data.EntityId);
                        break;
                    case 'StepUpdated': // sample event: {"Name":"Start","Description":"","EstimatedExecutionTimeInMinutes":0,"ShouldSendOverdueNotification":false,"DataToCollect":[],"EmbeddingLinks":[],"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"325bc4b8-fc6c-4881-ae30-235a19c55bfe","CommittedOn":"2024-07-05T11:34:44.580517Z","Version":638557760846.0829}
                        step = play.steps?.find((step) => step.id === data.EntityId) ?? null;
                        // create step if it does not already exist
                        if (!step) {
                            play.steps?.push({
                                ...DEFAULT_STEP,
                                id: data.EntityId
                            });
                        }
                        // update step
                        step = play.steps?.find((step) => step.id === data.EntityId) ?? null;
                        if (step) {
                            step.name = data.Name;
                            step.description = data.Description;
                            step.estimatedExecutionTimeInMinutes = data.EstimatedExecutionTimeInMinutes;
                            step.shouldSendOverdueNotification = data.ShouldSendOverdueNotification;                            
                            step.embedInputs = data.EmbeddingLinks;
                        } else {
                            console.log('Unable to apply StepUpdated event. Step not found:', data.EntityId);
                        }
                        break;
                    case 'StepSubPlaySet': // sample event: {"PlayId":"c5352ce4-6303-4604-93ee-98069e693936","IsImmediatelyCompleted":false,"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"daefdf10-e560-4433-b6e2-ad60f2279c99","CommittedOn":"2024-07-05T11:34:46.815111Z","Version":638557760868.1554}
                        step = play.steps?.find((step) => step.id === data.EntityId) ?? null;
                        if (step) {
                            step.subplayId = data.PlayId;
                            step.isImmediatelyCompleted = data.IsImmediatelyCompleted;
                        } else {
                            console.log('Unable to apply StepSubPlaySet event. Step not found:', data.EntityId);
                        }
                        break;
                    case 'StepDataToCollectModified': //{"DataToCollect":null,"ClientId":"51aa969d-38c6-4b20-9d73-cc39691d51b8","CommittedByUserId":"c8b3ef1e-4080-4c9a-aeee-8a708a8e1c84","EntityId":"9ada9d7e-d453-465c-acbb-c51c363ffb54","CommittedOn":"2024-08-30T17:08:16.989592Z","Version":638606344969.9595}
                        // already covered in PlayVersionDataToCollectAdded and PlayDataToCollectAdded handlers
                        break;
                    case 'StepInputDataMappingConfigured': // sample event: {"MappingConfiguration":[],"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"daefdf10-e560-4433-b6e2-ad60f2279c99","CommittedOn":"2024-07-05T11:34:46.822043Z","Version":638557760868.2263}
                        step = play.steps?.find((step) => step.id === data.EntityId) ?? null;
                        if (step) {
                            step.subplayDataInputConfig = data.MappingConfiguration;
                        } else {
                            console.log('Unable to apply StepInputDataMappingConfigured event. Step not found:', data.EntityId);
                        }
                        break;
                    case 'StepOutputDataMappingConfigured':
                        // sample event: {"MappingConfiguration":[],"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"daefdf10-e560-4433-b6e2-ad60f2279c99","CommittedOn":"2024-07-05T11:34:46.829104Z","Version":638557760868.2922}
                        step = play.steps?.find((step) => step.id === data.EntityId) ?? null;
                        if (step) {
                            step.subplayDataOutputConfig = data.MappingConfiguration;
                        } else {
                            console.log('Unable to apply StepOutputDataMappingConfigured event. Step not found:', data.EntityId);
                        }
                        break;
                    case 'StepPredecessorAdded':
                        // we are already handling this in the PlayDependencyGraphUpdated event
                        break;
                    case 'StepPredecessorRemoved':
                        // we are already handling this in the PlayDependencyGraphUpdated event
                        break;
                    case 'StepSuccessorAdded':
                        // we are already handling this in the PlayDependencyGraphUpdated event
                        break;
                    case 'StepSuccessorRemoved':
                        // we are already handling this in the PlayDependencyGraphUpdated event
                        break;
                    case 'StepConditionsSet': // sample event: {"Type":0,"ConditionalStepKey":"000300","NextStepWhenTrue":"000301","NextStepWhenFalse":"000302","Conditions":[{"Left":"one","Comparison":0,"Right":"one"}],"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"d43d9fd4-dab7-4021-8f23-dd4873bbb62b","CommittedOn":"2024-07-05T11:34:56.259478Z","Version":638557760962.6035}
                        step = play.steps?.find((step) => step.id === data.EntityId) ?? null;
                        if (step) {
                            step.matchType = data.Type as MatchType;
                            step.stepConditions = data.Conditions.map((condition: any) => {
                                return {
                                    id: Math.random().toString(36).substring(7),
                                    stepId: step!.id,
                                    leftOperand: condition.Left,
                                    operator: condition.Comparison,
                                    rightOperand: condition.Right,
                                    orderIndex: condition.OrderIndex ?? 0,
                                };
                            });
                            // add custom handle keys (TRUE_HANDLE_ID or FALSE_HANDLE_ID) to dependencies
                            if (data.NextStepWhenTrue) {
                                var existingTrueDependency = play.stepDependencies?.find((dep) => dep.stepKey === data.NextStepWhenTrue && dep.dependentUponStepKey === data.ConditionalStepKey);
                                if (existingTrueDependency) {
                                    existingTrueDependency.customHandleKey = 'true';
                                }
                            }
                            if (data.NextStepWhenFalse) {
                                var existingFalseDependency = play.stepDependencies?.find((dep) => dep.stepKey === data.NextStepWhenFalse && dep.dependentUponStepKey === data.ConditionalStepKey);
                                if (existingFalseDependency) {
                                    existingFalseDependency.customHandleKey = 'false';
                                }
                            }
                        } else {
                            console.log('Unable to apply StepConditionsSet event. Step not found:', data.EntityId);
                        }
                        break;
                    case 'StepDelaySet': // sample event: {"DelayInMinutes":5,"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"b5c6bcbf-f9e3-4910-84ab-08e69b46bb9b","CommittedOn":"2024-07-05T11:38:28.651243Z","Version":638557763086.5125}
                        step = play.steps?.find((step) => step.id === data.EntityId) ?? null;
                        if (step) {
                            step.delayMinutes = data.DelayInMinutes;
                        } else {
                            console.log('Unable to apply StepDelaySet event. Step not found:', data.EntityId);
                        }
                        break;
                    case 'StepValidationStarted':
                        // nothing needs to happen for this event
                        break;
                    case 'StepValidationCompleted':
                        // nothing needs to happen for this event
                        break;
                    case 'StepArtificialIntelligenceOutputLabelSet': // sample event: {"OutputLabel":"eeee","ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"0048ad24-0c0d-48d5-8612-4819a407d638","CommittedOn":"2024-07-16T19:39:16.229187Z","Version":638567555562.2922}
                        step = play.steps?.find((step) => step.id === data.EntityId) ?? null;
                        if (step) {
                            step.outputDataLabel = data.OutputLabel;
                        } else {
                            console.log('Unable to apply StepArtificialIntelligenceOutputLabelSet event. Step not found:', data.EntityId);
                        }
                        break;
                    case 'StepArtificialIntelligencePromptSet': // sample event: {"Prompt":"\u003Cp\u003Edddd\u003C/p\u003E","ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"0048ad24-0c0d-48d5-8612-4819a407d638","CommittedOn":"2024-07-16T19:39:16.229185Z","Version":638567555562.292}
                        step = play.steps?.find((step) => step.id === data.EntityId) ?? null;
                        if (step) {
                            step.prompt = data.Prompt;
                        } else {
                            console.log('Unable to apply StepArtificialIntelligencePromptSet event. Step not found:', data.EntityId);
                        }
                        break;
                    case 'StepPostUriSet': // sample event: {"PostUri":"https://www.google.com/","ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"db72d8af-af77-4d00-8ec3-56af6ff093d3","CommittedOn":"2024-07-16T19:39:07.085565Z","Version":638567555470.8558}
                        step = play.steps?.find((step) => step.id === data.EntityId) ?? null;
                        if (step) {
                            step.targetEndpoint = data.PostUri;
                        } else {
                            console.log('Unable to apply StepPostUriSet event. Step not found:', data.EntityId);
                        }
                        break;
                    case 'StepPostDataSet': // sample event: {"Labels":[],"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"db72d8af-af77-4d00-8ec3-56af6ff093d3","CommittedOn":"2024-07-16T19:39:07.319087Z","Version":638567555473.191}
                        step = play.steps?.find((step) => step.id === data.EntityId) ?? null;
                        if (step) {
                            step.requestDataLabels = data.Labels;
                        } else {
                            console.log('Unable to apply StepPostDataSet event. Step not found:', data.EntityId);
                        }
                        break;
                    case 'StepJobFunctionSet': // sample event: {"JobFunctionId":"2f25210d-8b36-4fc0-9e36-37265a3d4a6a","ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"80b83814-4455-4ab2-9e77-19681f2c677e","CommittedOn":"2024-07-16T19:37:31.197947Z","Version":638567554511.9825}
                        step = play.steps?.find((step) => step.id === data.EntityId) ?? null;
                        if (step) {
                            step.internalJobFunctionId = data.JobFunctionId;
                        } else {
                            console.log('Unable to apply StepJobFunctionSet event. Step not found:', data.EntityId);
                        }
                        break;
                    case 'StepNotificationTypeSet': // sample event: {"Type":1,"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"e453c923-502e-4450-a282-02534dca569b","CommittedOn":"2024-08-13T20:35:01.815221Z","Version":638591781018.1523}
                        step = play.steps?.find((step) => step.id === data.EntityId) ?? null;
                        if (step) {
                            step.notificationType = data.Type;
                        } else {
                            console.log('Unable to apply StepNotificationTypeSet event. Step not found:', data.EntityId);
                        }
                        break;
                    case 'StepEmailsToNotifySet': // sample event: {"Emails":[],"ClientId":"5688f420-1ac4-4fff-a3fa-c4e222ad6776","CommittedByUserId":"69dbd754-11e8-4ca0-85c3-e7f86fda7670","EntityId":"e453c923-502e-4450-a282-02534dca569b","CommittedOn":"2024-08-13T20:35:01.815222Z","Version":638591781018.1526}
                        step = play.steps?.find((step) => step.id === data.EntityId) ?? null;
                        if (step) {
                            step.emailsToNotify = data.Emails;
                        } else {
                            console.log('Unable to apply StepEmailsToNotifySet event. Step not found:', data.EntityId);
                        }
                        break;

                    // Metadata Events
                    case 'StepMoved': // sample event: {"PlayId": "abe974cc-497e-45a8-a0f4-72df902a96d2", "StepKey":"000101","X":0,"Y":0}
                        step = play.steps?.find((step) => step.key === data.StepKey) ?? null;
                        if (step) {
                            step.graphXPos = data.X;
                            step.graphYPos = data.Y;
                        } else {
                            console.log('Unable to play event StepMoved. Step not found: ', data.StepKey);
                        }
                        break;
                    default:
                        console.log('Unknown event type', event.eventType, event.eventData);
                        break;
                }
            });
            return play;
        });
    }, []);

    const resetProjection = useCallback(() => {
        setPlayProjection(DEFAULT_PLAY);
    }, []);

    return { playProjection, applyEvents, resetProjection };
}