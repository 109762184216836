import { Help } from '@mui/icons-material';
import { Box, FormControl, FormHelperText, FormLabel, Grid, IconButton, Tooltip } from '@mui/material';
import { FC } from 'react';
import { WysiwygEditorWithDataReferences } from '../../../../../../Components/Wysiwyg';
import { StepDataDto } from '../../../../../../dtos';

export interface IWYSIWYGInputFieldProps {
    label: string;
    tooltip?: string;
    errorMessage?: string;
    value: string;
    handleValueChange: (value: string) => void;
    collectedData: StepDataDto[];
    required?: boolean;
}

export const WYSIWYGInputField: FC<IWYSIWYGInputFieldProps> = ({ label, tooltip, errorMessage, value, handleValueChange, collectedData, required }) => {
    return (
        <Grid item xs={12}>
            <FormControl error={!!errorMessage} fullWidth required={required}>
                <Box>
                    <FormLabel>{label}</FormLabel>
                    {tooltip && (
                        <Tooltip title={tooltip}>
                            <IconButton>
                                <Help />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
                <WysiwygEditorWithDataReferences initialValue={value} onChange={handleValueChange} dataReferences={collectedData.map((x) => x.name ?? '')} />
                <FormHelperText>{errorMessage}</FormHelperText>
            </FormControl>
        </Grid>
    );
};
