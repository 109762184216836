import { Snooze } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, FormControl, FormHelperText, FormLabel, Grid } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { FC, useCallback } from 'react';
import { FormInput } from '../../../Components/CoreLib/library';
import { StepExecutionSnoozeDto } from '../../../dtos';
import { ModalSaveCloseFooter, ModalTitle } from '../../Plays/Components/SubComponents';
import { useSnoozeStepForm } from '../Hooks/useSnoozeStepForm';

export interface IStepSnoozeModalProps {
    isOpen: boolean;
    close: () => void;
    playExecutionId: string;
    stepExecutionId: string;
    currentSnoozeDetails?: StepExecutionSnoozeDto;
}

export const StepSnoozeModal: FC<IStepSnoozeModalProps> = ({ isOpen, close, playExecutionId, stepExecutionId, currentSnoozeDetails }) => {
    const { isFormDirty, handleSave, handleCancel, fieldErrors, handleSnoozeUntilChange, handleSnoozeReasonChange, snoozeUntil, snoozeReason } =
        useSnoozeStepForm(playExecutionId, stepExecutionId, close, isOpen ? currentSnoozeDetails : undefined);

    const handleCancelClicked = useCallback(() => {
        handleCancel();
        close();
    }, [handleCancel, close]);

    return (
        <Dialog open={isOpen} maxWidth='sm' fullWidth>
            <ModalTitle icon={<Snooze />} title='Snooze Step' />
            <DialogContent>
                <Grid container direction='row'>
                    <Grid item xs={12}>
                        <FormControl error={!!fieldErrors.get('snoozeUntil')} fullWidth required>
                            <FormLabel>Snooze Until</FormLabel>
                            <DateTimePicker
                                disablePast
                                formatDensity='spacious'
                                slotProps={{ textField: { error: !!fieldErrors.get('snoozeUntil') } }}
                                timeSteps={{ minutes: 1 }}
                                value={dayjs(snoozeUntil || null) as any}
                                onChange={handleSnoozeUntilChange}
                                timezone='system'
                            />
                            <FormHelperText>{fieldErrors.get('snoozeUntil')}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormInput
                            value={snoozeReason}
                            onChange={handleSnoozeReasonChange}
                            label='Reason'
                            name='reason'
                            errorText={fieldErrors.get('snoozeReason')}
                            fullWidth
                            rows={5}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ModalSaveCloseFooter
                    isFormDirty={isFormDirty}
                    onCancelCloseClicked={handleCancelClicked}
                    onSaveClicked={handleSave}
                    hideDeleteButton
                />
            </DialogActions>
        </Dialog>
    );
};
