import { useAuth0 } from '@auth0/auth0-react';
import { AttachFile, ChevronRight, Done, DoubleArrow, History } from '@mui/icons-material';
import { Badge, Box, Button, CircularProgress, Divider, Grid, Link, Paper, Tab, Tabs, Typography } from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingIndicator, SlimPageHeader, useFailedActionSnackbar } from '../../../Components/CoreLib/library';
import { ExpandableSectionHandle } from '../../../Components/ExpandableSection';
import { ExpandableSection } from '../../../Components/ExpandableSection/ExpandableSection';
import { StepExecutionDto } from '../../../dtos';
import {
    useCreateAttachmentByIdDownloadMutation,
    useCreatePlayExecutionByPlayExecutionIdNextStepMutation,
    useCreatePlayExecutionByPlayIdStepByStepIdMutation,
    useLazyGetPlayExecutionByPlayIdStepByStepIdQuery,
} from '../../../store/generated/generatedApi';
import { downloadFile } from '../../../util';
import { useCompleteStepForm } from '../Hooks';
import { CollectedDataPanel } from './CollectedDataPanel';
import { StepDataCollectionSection } from './StepDataCollectionSection';
import { EmbeddingsDataView } from './StepEmbeddingsView';

export interface ICompleteStepFormProps {
    stepExecution: StepExecutionDto;
}

const TABS = {
    DESCRIPTION: 'description',
    EMBEDDINGS: 'embeddings',
    ATTACHMENTS: 'attachments',
};

export const CompleteStepForm: FC<ICompleteStepFormProps> = ({ stepExecution }) => {
    // State Variables & Form Hook
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [hasAttemptedSave, setHasAttemptedSave] = useState(false);
    const [hasSuccessfullySaved, setHasSuccessfullySaved] = useState(false);
    const [isCollectedDataPanelExpanded, setIsCollectedDataPanelExpanded] = useState(true);
    const [selectedTab, setSelectedTab] = useState(TABS.DESCRIPTION);
    const isUsingChat = useMemo(() => stepExecution.isUsingChatDataCollection, [stepExecution]);

    const {
        isFormValid,
        handleStepDataChange,
        formStepData,
        formAdditionalStepData,
        validateAdditionalStepLabel,
        handleAdditionalStepDataChange,
        isLoading: isFormValidationLoading,
    } = useCompleteStepForm(stepExecution);
    const goToPlayExecution = useCallback(
        () => navigate(`/playbook/${stepExecution.playbookId}/play/${stepExecution.sourcePlayId}/history?runId=${stepExecution.playId}`),
        [navigate, stepExecution]
    );

    // Callback and API request that must be defined before the other API Success/Error Handlers
    const [getNextStep, { data: nextStep, isLoading: isLoadingNextStep, isError: isErrorGettingNextStep, reset: resetGetNextStep }] =
        useCreatePlayExecutionByPlayExecutionIdNextStepMutation();
    useFailedActionSnackbar('get', 'next step', isErrorGettingNextStep);
    const checkForNextStepForCurrentUser = useCallback(() => {
        if (!stepExecution.isExternal) {
            getNextStep({ playExecutionId: stepExecution.playId });
        }
    }, [stepExecution, getNextStep]);

    const goToNextStep = useCallback(() => {
        if (!!nextStep) {
            navigate(`/playExecution/${nextStep.playExecutionId}/step/${nextStep.stepExecutionId}`);
            setHasSuccessfullySaved(false);
            resetGetNextStep();
        }
    }, [navigate, nextStep, resetGetNextStep]);

    // API Requests and Success/Error Handlers
    const [createAttachmentDownloadLink, { isError: isCreateAttachmentDownloadLinkError }] = useCreateAttachmentByIdDownloadMutation();
    useFailedActionSnackbar('create', 'attachment link', isCreateAttachmentDownloadLinkError);
    const [getStepExecution] = useLazyGetPlayExecutionByPlayIdStepByStepIdQuery();
    const [completeStep, { isLoading: isCompletingStep, isError: isCompleteStepError, reset: resetCompleteStep }] =
        useCreatePlayExecutionByPlayIdStepByStepIdMutation();

    useFailedActionSnackbar('complete', 'step', isCompleteStepError, resetCompleteStep);

    const location = useLocation();
    useEffect(() => {
        setHasSuccessfullySaved(false);
    }, [location.pathname]);

    // This useEffect re-pulls the stepExecution to see if it has been completed yet and sets a flag if it has.
    useEffect(() => {
        if (isCompletingStep && !hasSuccessfullySaved) {
            const intervalId = setInterval(() => {
                getStepExecution({
                    playId: stepExecution.playId,
                    stepId: stepExecution.id,
                })
                    .unwrap()
                    .then((result) => {
                        if (result.isComplete) {
                            setHasSuccessfullySaved(true);
                        }
                    });
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [isCompletingStep, getStepExecution, stepExecution, hasSuccessfullySaved, enqueueSnackbar]);

    const handleDoneClicked = useCallback(() => {
        if (!hasAttemptedSave) {
            setHasAttemptedSave(true);
        }
        if (isFormValid && !isCompletingStep) {
            var updatedExecutionStep = _.cloneDeep(stepExecution);
            updatedExecutionStep.stepData = [...formStepData, ...formAdditionalStepData];
            completeStep({
                params: {
                    playId: updatedExecutionStep.playId,
                    stepId: updatedExecutionStep.id,
                },
                payload: updatedExecutionStep,
            }).then(() => {
                resetCompleteStep();
                checkForNextStepForCurrentUser();
            });
        }
    }, [
        checkForNextStepForCurrentUser,
        completeStep,
        formAdditionalStepData,
        formStepData,
        hasAttemptedSave,
        isCompletingStep,
        isFormValid,
        resetCompleteStep,
        stepExecution,
    ]);

    const handleStepCompleted = useCallback(() => {
        setHasSuccessfullySaved(true);
        checkForNextStepForCurrentUser();
    }, [checkForNextStepForCurrentUser]);

    const handleFileLinkClicked = useCallback(
        async (fileName: string) => {
            var downloadLink = await createAttachmentDownloadLink({ id: stepExecution.id, fileName }).unwrap();
            downloadFile(downloadLink.link, fileName);
        },
        [createAttachmentDownloadLink, stepExecution]
    );

    const isStepEditable = useMemo(
        () => !isCompletingStep && stepExecution.isStarted && !stepExecution.isComplete && stepExecution.status !== 'Cancelled',
        [isCompletingStep, stepExecution]
    );

    const stepStatusDescriptor = useMemo(() => {
        if (stepExecution.status === 'Cancelled') {
            return 'Step Cancelled';
        }
        if (stepExecution.isComplete) {
            return 'Step Completed';
        }
        return 'Complete Step';
    }, [stepExecution]);

    const CompleteStepButton = useCallback(
        (isFullWidth: boolean) => {
            if (isUsingChat && !stepExecution.isComplete) {
                return null;
            }

            return (
                <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    onClick={handleDoneClicked}
                    startIcon={isCompletingStep ? undefined : <Done />}
                    disabled={!isStepEditable}
                    sx={{ width: isFullWidth ? undefined : 90 }}
                    fullWidth={isFullWidth}>
                    {isCompletingStep && !stepExecution.isComplete ? <CircularProgress size={'24px'} /> : 'Done'}
                </Button>
            );
        },
        [isUsingChat, stepExecution, isCompletingStep, isStepEditable, handleDoneClicked]
    );

    useEffect(() => {
        if (stepExecution) {
            setHasAttemptedSave(false);
        }
    }, [stepExecution]);

    if (isFormValidationLoading) {
        return <LoadingIndicator />;
    }

    if (hasSuccessfullySaved && !stepExecution.isExternal) {
        return (
            <Box display='flex' flexDirection='column' alignItems='center' p={2} gap={2}>
                <Typography variant='h2'>Step Complete!</Typography>
                <Box display='flex' flexDirection='column' alignItems='center' gap={1} textAlign='center'>
                    <Typography>You are welcome to leave this page. I'm checking if any more steps in this play are ready for you.</Typography>
                    <Typography variant='body2'>It's okay if you don't wait here, I'll remind you if something comes up.</Typography>
                </Box>
                <Box display='flex' gap={2}>
                    <Button variant='contained' onClick={goToPlayExecution} startIcon={<History />}>
                        View Run
                    </Button>
                    {(isCompletingStep || isLoadingNextStep) && (
                        <Button variant='contained' disabled startIcon={<CircularProgress size='18px' color='inherit' />}>
                            Checking For Steps
                        </Button>
                    )}
                    {!(isCompletingStep || isLoadingNextStep) && nextStep && (
                        <Button variant='contained' color='secondary' onClick={goToNextStep} startIcon={<ChevronRight />}>
                            Continue To Next Step
                        </Button>
                    )}
                    {!(isCompletingStep || isLoadingNextStep) && !nextStep && (
                        <Button variant='contained' disabled startIcon={<Done />}>
                            You're All Done For Now
                        </Button>
                    )}
                </Box>
            </Box>
        );
    }

    return (
        <Grid container direction='column' spacing={3} height='calc(100vh - 48px)'>
            <Grid item>
                <SlimPageHeader
                    icon={<DoubleArrow />}
                    title={`${stepStatusDescriptor}: ${stepExecution.name}`}
                    breadCrumbProps={
                        !stepExecution.isExternal
                            ? {
                                  links: [
                                      { label: 'Home', navLink: '/' },
                                      { label: 'Playbooks', navLink: '/playbooks' },
                                      { label: stepExecution.playbookName ?? 'Playbook', navLink: `/playbook/${stepExecution.playbookId}/plays` },
                                      {
                                          label: stepExecution.playName ?? 'Unnamed Play',
                                          navLink: `/playbook/${stepExecution.playbookId}/play/${stepExecution.sourcePlayId}/dashboard`,
                                      },
                                      {
                                          label: stepExecution.name ?? 'Unnamed  Step',
                                          navLink: `/playbook/${stepExecution.playbookId}/play/${stepExecution.sourcePlayId}/history?runId=${stepExecution.playId}&stepKey=${stepExecution.key}`,
                                      },
                                  ],
                                  currentPageLabel: stepStatusDescriptor,
                              }
                            : undefined
                    }
                    endSlot={
                        <Grid item container direction='row' alignItems='start' py={1}>
                            <Grid item display='flex' alignItems='center' justifyContent={'end'} xs={12} sx={{ gap: 1 }} pr={2}>
                                <Divider flexItem orientation='vertical' sx={{ mx: 1 }} />
                                {(stepExecution.isComplete || !isUsingChat) && CompleteStepButton(false)}
                            </Grid>
                        </Grid>
                    }
                />
            </Grid>
            <Grid flexGrow={1} item container direction='row' sx={{ paddingY: '0 !important' }} height='calc(100% - 84px)' overflow='hidden'>
                {isAuthenticated && (
                    <ExpandableSection isExpanded={isCollectedDataPanelExpanded} direction='right'>
                        <CollectedDataPanel collectedData={stepExecution.collectedData ?? []} />
                    </ExpandableSection>
                )}
                <Grid item direction='column' width={isAuthenticated ? 'calc(100% - 308px)' : '100%'} p={2} flexGrow={1} height='100%' position='relative'>
                    {isAuthenticated && (
                        <ExpandableSectionHandle
                            isExpanded={isCollectedDataPanelExpanded}
                            setIsExpanded={setIsCollectedDataPanelExpanded}
                            direction='right'
                            backgroundColor='#f3f5f6'
                        />
                    )}
                    <Box position='relative' sx={{ height: 'calc(50% - 16px)' }}>
                        <Paper style={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                            <Tabs
                                value={selectedTab}
                                onChange={(_, value) => setSelectedTab(value)}
                                variant='fullWidth'
                                sx={{ position: 'sticky', top: 0, height: '48px' }}>
                                <Tab label='Description' value={TABS.DESCRIPTION} />
                                {stepExecution.embedInputs && stepExecution.embedInputs.length > 0 && (
                                    <Tab
                                        label={
                                            <Badge color='secondary' badgeContent={stepExecution.embedInputs.length}>
                                                <Box sx={{ pr: 1 }}>Embeddings</Box>
                                            </Badge>
                                        }
                                        value={TABS.EMBEDDINGS}
                                    />
                                )}
                                {stepExecution.fileAttachments && stepExecution.fileAttachments.length > 0 && (
                                    <Tab
                                        label={
                                            <Badge color='secondary' badgeContent={stepExecution.fileAttachments.length}>
                                                <Box sx={{ pr: 1 }}>Attachments</Box>
                                            </Badge>
                                        }
                                        value={TABS.ATTACHMENTS}
                                    />
                                )}
                            </Tabs>
                            <Box display='flex' flexDirection='column' overflow='auto' p={2}>
                                {selectedTab === TABS.DESCRIPTION &&
                                    (stepExecution.description ? (
                                        <div dangerouslySetInnerHTML={{ __html: stepExecution.description }}></div>
                                    ) : (
                                        <Typography fontSize={16}>No Description Provided</Typography>
                                    ))}
                                {selectedTab === TABS.EMBEDDINGS && <EmbeddingsDataView embeddingInputs={stepExecution.embedInputs} showTitle={false} />}
                                {selectedTab === TABS.ATTACHMENTS &&
                                    stepExecution.fileAttachments?.map((fileAttachment, idx) => (
                                        <Grid item xs={12} key={`step-data-${idx}`}>
                                            <Grid item xs={12} key={fileAttachment.blobFileName} display='flex' alignItems='center'>
                                                <AttachFile fontSize='small' />
                                                <Link href='#' onClick={() => handleFileLinkClicked(fileAttachment.blobFileName)}>
                                                    {fileAttachment.friendlyFileName}
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    ))}
                            </Box>
                        </Paper>
                    </Box>
                    <Box position='relative' height='calc(50% - 42px)' mt={2}>
                        <Paper sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                            <Tabs value='data' variant='fullWidth' style={{ position: 'sticky', top: 0 }}>
                                <Tab label='Data' value='data' />
                            </Tabs>
                            <Box display='flex' flexDirection='column' overflow='auto' p={2}>
                                <StepDataCollectionSection
                                    mode={stepExecution.isComplete || !isUsingChat ? 'form' : 'chat'}
                                    stepExecutionId={stepExecution.id}
                                    playExecutionId={stepExecution.playId}
                                    stepData={formStepData}
                                    handleStepDataChange={handleStepDataChange}
                                    additionalStepData={formAdditionalStepData}
                                    handleAdditionalStepDataChange={handleAdditionalStepDataChange}
                                    validateAdditionalStepLabel={validateAdditionalStepLabel}
                                    handleStepCompleted={handleStepCompleted}
                                    threadId={stepExecution.dataCollectionThreadId}
                                    readonly={!isStepEditable}
                                />
                            </Box>
                        </Paper>
                    </Box>
                    <Box mt={2}>{CompleteStepButton(true)}</Box>
                </Grid>
            </Grid>
        </Grid>
    );
};
