import { HourglassBottom } from '@mui/icons-material';
import { ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermissionChecker } from '../../Hooks';
import { TodoListNotificationDto } from '../../dtos';
import { NotificationBadgeContext } from './NotificationBadgeContext';

export interface ITodoTaskListItemProps {
    todoTask: TodoListNotificationDto;
}

export const TodoTaskListItem: FC<ITodoTaskListItemProps> = ({ todoTask }) => {
    const { playName, stepName, isOverdue, runNumber, playExecutionId, stepExecutionId } = todoTask;
    const { closeMenu } = useContext(NotificationBadgeContext);
    const navigate = useNavigate();
    const { hasPermission } = usePermissionChecker();

    const goToCompleteStepPage = useCallback(() => {
        navigate(`/playExecution/${playExecutionId}/step/${stepExecutionId}`);
        closeMenu();
    }, [navigate, playExecutionId, stepExecutionId, closeMenu]);

    const stepSubtitle = useMemo(() => {
        let canReadPlays = hasPermission('read:plays');
        let canReadPlayExecutions = hasPermission('read:playExecutions');
        let subtitle = '';
        if (canReadPlays) {
            subtitle += playName;
        }
        if (canReadPlays && canReadPlayExecutions) {
            subtitle += ', ';
        }
        if (canReadPlayExecutions) {
            subtitle += `Run #${runNumber}`;
        }
        return subtitle;
    }, [hasPermission, playName, runNumber]);

    return (
        <ListItemButton onClick={goToCompleteStepPage}>
            <ListItemText primary={stepName} secondary={stepSubtitle} />
            {isOverdue && (
                <Tooltip title='Overdue'>
                    <HourglassBottom color='error' />
                </Tooltip>
            )}
        </ListItemButton>
    );
};
