import { Button, IconButton, FormControlLabel, Grid, Switch } from '@mui/material';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { EditStepAssignmentInput } from './EditStepAssignmentInput';
import { ClientJobFunctionDto, StepDto } from '../../../../../../dtos';
import { ViewJobFunctionMembersModal } from '../../ViewJobFunctionMembersModal';
import { AddCircle, Plumbing } from '@mui/icons-material';
import { QuickAddJobFunctionModal } from '../../QuickAddJobFunctionModal';

export interface IAssignmentInput {
    fieldErrors: Map<keyof StepDto, string>;
    isExternal: boolean;
    handleIsExternalChange: (event: ChangeEvent<HTMLInputElement>, isChecked: boolean) => void;
    externalRoleName: string;
    handleExternalRoleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    formInternalJobFunctionId: string;
    handleInternalJobFunctionChange: (updatedJobFunction: ClientJobFunctionDto | undefined) => void;
    InternalJobFunction?: ClientJobFunctionDto;
}

export const AssignmentInput: FC<IAssignmentInput> = props => {
    const {
        fieldErrors,
        isExternal,
        handleIsExternalChange,
        externalRoleName,
        handleExternalRoleChange,
        formInternalJobFunctionId,
        handleInternalJobFunctionChange,
        InternalJobFunction
    } = props;
    const [selectedJobFunction, setSelectedJobFunction] = useState<ClientJobFunctionDto>();

    const handleViewJobFunctionMembersClicked = useCallback(() => {
        setSelectedJobFunction(InternalJobFunction);
    }, [InternalJobFunction]);

    const [isAddJobFunctionOpen, setAddJobFunctionOpen] = useState<boolean>();
    const handleAddJobFunctionClicked = useCallback(() => {
        setAddJobFunctionOpen(true);
    }, []);

    const handleAddJobFunctionClosed = useCallback(() => {
        setAddJobFunctionOpen(undefined);
    }, []);

    const handleSetNewJobFunction = useCallback((newJobFunction: ClientJobFunctionDto | undefined) => {
        setAddJobFunctionOpen(undefined);
        if (typeof newJobFunction !== "undefined") {
            handleInternalJobFunctionChange(newJobFunction);
        }
    }, [handleInternalJobFunctionChange]);

    const clearSelectedJobFunction = useCallback(() => {
        setSelectedJobFunction(undefined);
    }, []);

    return (
        <>
            <Grid item xs={6} sm={4} md={3} ml={-2} mt={-1} display='flex' justifyContent="flex-start">
                <FormControlLabel
                    control={<Switch checked={isExternal} onChange={handleIsExternalChange} />}
                    label='External'
                    labelPlacement='start'
                />
            </Grid>
            <Grid container item>
                <Grid item xs={isExternal ? 12 : 8}>
                    <EditStepAssignmentInput
                        isExternalStep={isExternal}
                        fieldErrors={fieldErrors}
                        externalRoleName={externalRoleName}
                        handleExternalRoleNameChange={handleExternalRoleChange}
                        internalJobFunctionId={formInternalJobFunctionId}
                        handleInternalJobFunctionChange={handleInternalJobFunctionChange}
                    />
                </Grid>
                {!isExternal && (
                    <Grid item xs={4} sm={1} alignSelf='center' mt={4} px={2}>
                        <IconButton
                            color='primary'
                            onClick={handleAddJobFunctionClicked}>
                            <AddCircle />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
            <QuickAddJobFunctionModal isOpen={isAddJobFunctionOpen} onClose={handleAddJobFunctionClosed} setNewJobFunction={handleSetNewJobFunction}/>
            {!isExternal && (
                <Grid item xs={12} sm={4} alignSelf='center' mt={2}>
                    <Button
                        disabled={!InternalJobFunction}
                        variant='contained'
                        fullWidth
                        startIcon={<Plumbing />}
                        onClick={handleViewJobFunctionMembersClicked}>
                        View Job Function Members
                    </Button>
                </Grid>
            )}
            <ViewJobFunctionMembersModal jobFunction={selectedJobFunction} onClose={clearSelectedJobFunction} />
        </>
    )
}