import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField, Tooltip, Box, IconButton } from '@mui/material';
import { FC, FocusEventHandler, SyntheticEvent, useEffect, useMemo } from 'react';
import { ClientJobFunctionDto } from '../../dtos';
import { useGetJobFunctionQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';
import { Help } from '@mui/icons-material';

export interface IClientJobFunctionSelectProps {
    selectedJobFunctionId: string;
    handleSelectedJobFunctionChange: (selectedJobFunction?: ClientJobFunctionDto) => void;
    required?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    onBlur?: FocusEventHandler<HTMLDivElement>;
    jobFunctionsMustHaveUsers?: boolean;
}

export const ClientJobFunctionSelect: FC<IClientJobFunctionSelectProps> = (props) => {
    const { selectedJobFunctionId, handleSelectedJobFunctionChange, required, errorMessage, disabled, onBlur, jobFunctionsMustHaveUsers = false } = props;
    const {
        data: jobFunctions,
        isLoading: isLoadingJobFunctions,
        isError: isErrorLoadingJobFunctions,
    } = useGetJobFunctionQuery({
        sortKey: 'NAME',
        sortAsc: true,
        page: 0,
        pageSize: 10000,
        includeInactive: false,
        mustHaveMembers: jobFunctionsMustHaveUsers,
    });

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: ClientJobFunctionDto | null) => {
        handleSelectedJobFunctionChange(value ?? undefined);
    };

    const selectedJobFunction = useMemo(() => {
        if (selectedJobFunctionId === '' || selectedJobFunctionId === emptyGuid) {
            return null;
        }
        return jobFunctions?.pageResults.find((x) => x.id === selectedJobFunctionId) ?? null;
    }, [selectedJobFunctionId, jobFunctions]);

    // TODO: I would like to be sure the update method isn't called twice on every single change -- only on list refresh, not when the ID changes
    //       The context for this change is to handle the case where you add a new job function and it is immediately selected but has no members.
    //       You then add members and the list refreshes and contains the updated member count, but the validation message doesn't clear because
    //       selectedJobFunction didn't change, but rather the list we picked the value out of did.
    useEffect(() => {
        if (jobFunctions && selectedJobFunctionId !== '' && selectedJobFunctionId !== emptyGuid) {
            const selected = jobFunctions?.pageResults.find((x) => x.id === selectedJobFunctionId) ?? undefined;
            if (selected) {
                handleSelectedJobFunctionChange(selected);
            }
        }
    }, [handleSelectedJobFunctionChange, jobFunctions, selectedJobFunctionId]);

    return (
        <FormControl error={isErrorLoadingJobFunctions || !!errorMessage} fullWidth required={required} className='non-draggable-element'>
            <Box>
                <FormLabel>Job Function</FormLabel>
                <Tooltip title="Can't find the job function you need? Click the plus button to create a new one.">
                    <IconButton>
                        <Help />
                    </IconButton>
                </Tooltip>
            </Box>
            <Autocomplete
                value={selectedJobFunction}
                onChange={handleSelectChange}
                loading={isLoadingJobFunctions}
                options={jobFunctions?.pageResults ?? []}
                getOptionLabel={(x) => x.name}
                renderInput={(params) => <TextField error={!!errorMessage} {...params} />}
                onBlur={onBlur}
                disabled={disabled}
                onDrop={(e) => e.preventDefault()}
            />
            <FormHelperText>{isErrorLoadingJobFunctions ? 'Failed to load job function options' : errorMessage ?? ''}</FormHelperText>
        </FormControl>
    );
};
