import { FC } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../dtos";
import { INodeRules, IStepEditModalProps, IStepExecutionDetailsProps } from "./types";

const DEFAULT_NODE_RULES: INodeRules = {
    maxInputs: 1,
    maxOutputs: 1
}

export abstract class NodeType {
    public readonly abstract nodeType: string;
    public readonly validationRules: INodeRules = DEFAULT_NODE_RULES;

    public get isVisibleInNodeLibrary() {
        return !!this.NodeLibraryListItem;
    };
    public get isEditable() {
        return !!this.EditModalContent;
    };
    public get isExecutionViewable() {
        return !!this.ExecutionDetailsContent;
    };
        
    // Component Renderers
    public readonly abstract PlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>>;
    public readonly NodeLibraryListItem?: FC = undefined;
    public readonly EditModalContent?: FC<IStepEditModalProps> = undefined;
    public readonly ExecutionDetailsContent?: FC<IStepExecutionDetailsProps> = undefined;

}