import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react'
import { DataTableColumn, IStandardListViewExtraItems, PaginatedProps, StandardListViewV2, useBulkActionSnackbar } from '../../../Components/CoreLib/library'
import { ClientJobFunctionDto } from '../../../dtos';
import { useGetUserByUserIdJobFunctionsQuery, useUpdateUserByUserIdRemoveMutation } from '../../../store/generated/generatedApi';
import { UserAddJobFunctionsModal } from './UserAddJobFunctionsModal';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'NAME',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

const tableColumns: DataTableColumn<ClientJobFunctionDto>[] = [
    { key: 'name', label: 'Name', sortKey: 'NAME' },
    { key: 'description', label: 'Description', sortKey: 'DESCRIPTION' },
];

export interface IUserJobFunctionsListViewProps {
    userId: string;
    userName: string;
}

export const UserJobFunctionsListView: FC<IUserJobFunctionsListViewProps> = props => {
    const { userId, userName } = props;
    const [isQuickAddDialogOpen, setIsQuickAddDialogOpen] = useState(false);
    const [removeUserFromJobFunctions, {data: bulkRemoveResult, isLoading: isRemovingUserFromJobFunctions, reset: resetUserJobFunctionBulkRemove}] = useUpdateUserByUserIdRemoveMutation();
    useBulkActionSnackbar('removed', 'remove', 'job function', 'job functions', bulkRemoveResult, resetUserJobFunctionBulkRemove);

    const removeJobFunctionsEnableCheck = useCallback((selectedItems: ClientJobFunctionDto[]) => {
        return selectedItems.length > 0
    }, []);

    const handleRemoveJobFunctionsFromUser = useCallback((selectedItems: ClientJobFunctionDto[]) => {
        if (isRemovingUserFromJobFunctions) {
            return;
        }
        const jobFunctionIds = selectedItems.map(u => u.id);
        removeUserFromJobFunctions({ params: { userId: userId }, payload: jobFunctionIds })
    }, [userId, isRemovingUserFromJobFunctions, removeUserFromJobFunctions]);

    const openQuickAddDialog = useCallback(() => {
        setIsQuickAddDialogOpen(true);
    }, [setIsQuickAddDialogOpen]);

    const closeQuickAddDialog = useCallback(() => {
        setIsQuickAddDialogOpen(false);
    }, [setIsQuickAddDialogOpen]);

    const extraItems: IStandardListViewExtraItems[] = useMemo(() => [
        {
            requiredPermissions: ['edit:jobFunctions'],
            enabledCheck: removeJobFunctionsEnableCheck,
            onClick: handleRemoveJobFunctionsFromUser,
            text: 'Remove Job Function(s)',
            icon: <RemoveCircle />,
            preventTextTransform: true
        },
        {
            requiredPermissions: ['edit:jobFunctions'],
            onClick: openQuickAddDialog,
            text: 'Add Job Function(s)',
            icon: <AddCircle />,
            preventTextTransform: true
        }
    ], [removeJobFunctionsEnableCheck, handleRemoveJobFunctionsFromUser, openQuickAddDialog]);

    return (
        <Box pb={2}>
            <StandardListViewV2
                headerIcon={<></>}
                headerTitle=''
                breadCrumbs={[]}
                defaultPaginationProps={defaultPaginationProps}
                getDataQuery={useGetUserByUserIdJobFunctionsQuery}
                tableColumns={tableColumns}
                entityNameSingular='job function'
                permissionName='jobFunctions'
                isShowActiveToggleVisible={true}
                additionalQueryParameters={{
                    userId
                }}
                additionalActionItems={extraItems}
                additionalMenuItems={extraItems}
            />
            <UserAddJobFunctionsModal userId={userId} userName={userName} isOpen={isQuickAddDialogOpen} handleClose={closeQuickAddDialog} />
        </Box>
    )
}