import { useSearchParams } from "react-router-dom";
import { useFailedActionSnackbar } from "../../../Components/CoreLib/library";
import { useLazyGetPlayExecutionByIdQuery, useGetPlayByIdQuery } from "../../../store/generated/generatedApi";
import { useCallback, useEffect } from "react";

export function usePlayExecutionLoader(playId: string) {
    const [searchParams, setSearchParams] = useSearchParams();
    const paramRunId = searchParams.get('runId');
    const [getPlayExecutionById, { data: playExecution, isFetching: isLoadingPlayExecution, isError: isErrorLoadingPlayExecution }] =
        useLazyGetPlayExecutionByIdQuery();
    const {
        data: playDetails,
        isLoading: isLoadingPlayDetails,
        isError: isErrorLoadingPlayDetails,
        refetch: refetchPlayDetails,
    } = useGetPlayByIdQuery({ id: playId });
    useFailedActionSnackbar('retrieve', 'run details', isErrorLoadingPlayExecution);
    useFailedActionSnackbar('retrieve', 'play details', isErrorLoadingPlayDetails);

    const loadPlayExecution = useCallback((runId: string) => {
        if (isLoadingPlayExecution || playExecution?.id === runId) {
            return;
        }
        getPlayExecutionById({ id: runId });
        searchParams.set('runId', runId);
        setSearchParams(searchParams);
        // clearSelectedStep();
    }, [isLoadingPlayExecution, playExecution, getPlayExecutionById, searchParams, setSearchParams]);

    useEffect(() => {
        const isRunIdInURLButIsNotLoaded = paramRunId && (!playExecution || playExecution.id !== paramRunId);
        if (isRunIdInURLButIsNotLoaded) {
            getPlayExecutionById({ id: paramRunId! });
            // clearSelectedStep()
        }
    }, [paramRunId, playExecution, getPlayExecutionById]);

    return {
        isLoadingPlayExecution,
        playExecution,
        playDetails,
        isLoadingPlayDetails,
        isErrorLoadingPlayDetails,
        refetchPlayDetails,
        loadPlayExecution
    }
}
