import { FormControl, FormLabel, Select, MenuItem, FormHelperText, SelectChangeEvent } from '@mui/material';
import { FC } from 'react';
import { MatchType } from '../../dtos/generated/MatchType';

export interface IMatchTypeSelectProps {
    errorMessage?: string;
    selectedMatchType: MatchType;
    handleMatchTypeChange: (value: MatchType) => void;
};

export const MatchTypeSelect: FC<IMatchTypeSelectProps> = props => {
    const {
        errorMessage,
        selectedMatchType,
        handleMatchTypeChange
    } = props;

    const onMatchTypeChange = (event: SelectChangeEvent<MatchType>) => {
        handleMatchTypeChange(event.target.value as MatchType);
    }

    return (
        <FormControl fullWidth required error={!!errorMessage}>
            <FormLabel>And / Or</FormLabel>
            <Select value={selectedMatchType} onChange={onMatchTypeChange}>
                <MenuItem value={MatchType.ALL}> And </MenuItem>
                <MenuItem value={MatchType.ANY}> Or </MenuItem>
            </Select>
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    )
}