import { Grid } from '@mui/material';
import { FC } from 'react';
import { ClientJobFunctionSelect } from '../../../../../../Components/CommonInputs';
import { FormInput } from '../../../../../../Components/CoreLib/library';
import { ClientJobFunctionDto, StepDto } from '../../../../../../dtos';

export interface IEditStepAssignmentInputProps {
    isExternalStep: boolean;
    fieldErrors: Map<keyof StepDto, string>;
    externalRoleName: string;
    handleExternalRoleNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    internalJobFunctionId: string;
    handleInternalJobFunctionChange: (updatedJobFunction: ClientJobFunctionDto | undefined) => void;
}

export const EditStepAssignmentInput: FC<IEditStepAssignmentInputProps> = props => {
    const {
        isExternalStep,
        fieldErrors,
        externalRoleName,
        handleExternalRoleNameChange,
        internalJobFunctionId,
        handleInternalJobFunctionChange
    } = props;

    if (isExternalStep) {
        return (
            <Grid item>
                <FormInput
                    value={externalRoleName}
                    onChange={handleExternalRoleNameChange}
                    label='Role Name'
                    name='roleName'
                    errorText={fieldErrors.get('externalRoleName')}
                    fullWidth
                    required
                />
            </Grid>
        )
    } else {
        return (
            <Grid item>
                <ClientJobFunctionSelect
                    selectedJobFunctionId={internalJobFunctionId}
                    handleSelectedJobFunctionChange={handleInternalJobFunctionChange}
                    errorMessage={fieldErrors.get('internalJobFunctionId')}
                    required
                />
            </Grid>
        )
    }
}