import { FC } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../dtos";
import { NodeType } from "../NodeType";
import { NodeTypes } from "../utils";
import { MergeNodeLibraryListItem, MergePlayViewerNode } from "./Components";
import { INodeRules } from "../types";

export class MergeNodeType extends NodeType {
    public readonly nodeType: string = NodeTypes.MERGE;
    public override readonly validationRules: INodeRules = { maxInputs: 9999, maxOutputs: 1 }

    public readonly PlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = MergePlayViewerNode;
    public readonly NodeLibraryListItem: FC = MergeNodeLibraryListItem;
}