import _ from 'lodash';
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { FormValidationConfig, IFormProps, useStandardValidatedFormManager } from '../../../../Components/CoreLib/library';
import {
    ClientJobFunctionDto,
    DataMappingConfigDto,
    FileAttachmentDto,
    PlayDto,
    PlayVariableDto,
    StepDataDto,
    StepDto,
    StepEmbedInputDto,
} from '../../../../dtos';
import { DelayMethod } from '../../../../dtos/generated/DelayMethod';
import { MatchType } from '../../../../dtos/generated/MatchType';
import { NotificationType } from '../../../../dtos/generated/NotificationType';
import { StepConditionDto } from '../../../../dtos/generated/StepConditionDto';
import { emptyGuid } from '../../../../util';
import { DEFAULT_STEP } from '../Constants';
import { useDeleteNode } from '../GraphManagementHooks';
import { PlayEditorContext } from '../PlayEditorContext';
import { StepValidationConfigBuilder, StepValidator } from '../Validators';

const initialErrorMessages = new Map<keyof StepDto, string>([
    ['name', ''],
    ['externalRoleName', ''],
    ['internalJobFunctionId', ''],
    ['description', ''],
    ['stepData', ''],
    ['matchType', ''],
    ['stepConditions', ''],
    ['targetEndpoint', ''],
    ['requestDataLabels', ''],
    ['estimatedExecutionTimeInMinutes', ''],
    ['embedInputs', ''],
    ['subplayId', ''],
    ['isImmediatelyCompleted', ''],
    ['subplayDataInputConfig', ''],
    ['subplayDataOutputConfig', ''],
    ['delayMethod', ''],
    ['delayMinutes', ''],
    ['delayDateLabel', ''],
    ['notificationType', ''],
    ['emailsToNotify', ''],
    ['prompt', ''],
    ['outputDataLabel', ''],
    ['fileAttachments', ''],
]);

export function useStepForm(props: IFormProps<StepDto>, stepKey: string | undefined) {
    const { save, cancel: close, initValues = DEFAULT_STEP } = props;
    const { playVariables } = useContext(PlayEditorContext);
    const [formValidationRules, setFormValidationRules] = useState<FormValidationConfig<StepDto>>(new Map());
    const { formRecord: formStep, setFormRecord: setFormStep, isFormDirty } = useStandardValidatedFormManager(initValues, formValidationRules);

    const [isGeneralValid, setIsGeneralValid] = useState(true);
    const [isAssignmentValid, setIsAssignmentValid] = useState(true);
    const [isDataValid, setIsDataValid] = useState(true);
    const [isInputValid, setIsInputValid] = useState(true);
    const [isOutputValid, setIsOutputValid] = useState(true);
    const [isDevelopmentValid, setIsDevelopmentValid] = useState(true);
    const [isEstimationValid, setIsEstimationValid] = useState(true);
    const [isDelayValid, setIsDelayValid] = useState(true);
    const [tabIndex, setTabIndex] = useState(1);
    // Normally this is provided by the standard form validation hook but because we have a 'valid enough to save' vs 'actually valid' right now they are separate.
    const [fieldErrors, setFieldErrors] = useState(initialErrorMessages);
    const { deleteNodeByKey } = useDeleteNode();

    // This useEffect trims off any outdated subplay input configs
    useEffect(() => {
        if (formStep.subplay && formStep.subplay.playDataInput && formStep.subplayDataInputConfig && formStep.subplayDataInputConfig.length > 0) {
            const filteredConfigs = formStep.subplayDataInputConfig.filter((config) =>
                formStep.subplay!.playDataInput!.some((inputData) => inputData.name === config.destinationFieldName)
            );
            if (filteredConfigs.length !== formStep.subplayDataInputConfig.length) {
                setFormStep((prev) => ({ ...prev, subplayDataInputConfig: filteredConfigs }));
            }
        }
    }, [formStep, setFormStep]);

    useEffect(() => {
        const stepValidator = new StepValidator(formStep, playVariables, formStep.subplay);
        setFieldErrors(stepValidator.validationErrorMessages);
        setIsGeneralValid(
            !stepValidator.validationErrorMessages.get('name') &&
            !stepValidator.validationErrorMessages.get('description') &&
            stepValidator.isEmbeddingInputsValid &&
            stepValidator.isFileAttachmentsValid
        );
        setIsAssignmentValid(
            !stepValidator.validationErrorMessages.get('internalJobFunctionId') && !stepValidator.validationErrorMessages.get('externalRoleName')
        );
        setIsDataValid(stepValidator.isStepDataValid);
        setIsInputValid(stepValidator.isInputMappingValid);
        setIsOutputValid(stepValidator.isOutputMappingValid);
        setIsDevelopmentValid(!stepValidator.validationErrorMessages.get('targetEndpoint') && !stepValidator.validationErrorMessages.get('requestDataLabels'));
        setIsEstimationValid(!stepValidator.validationErrorMessages.get('estimatedExecutionTimeInMinutes'));
        setIsDelayValid(stepValidator.isDelayInputValid);
    }, [formStep, initValues?.type, playVariables]);

    useEffect(() => {
        setTabIndex(0);
    }, [initValues]);

    const handleNameChange = useCallback((updatedName: string) => setFormStep((prev) => ({ ...prev, name: updatedName })), [setFormStep]);

    const handleChatDataCollectionChange = useCallback(
        (newValue: boolean) => setFormStep((prev) => ({ ...prev, isUsingChatDataCollection: newValue })),
        [setFormStep]
    );

    const handleExternalRoleNameChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => setFormStep((prev) => ({ ...prev, externalRoleName: event.target.value })),
        [setFormStep]
    );

    const handleMatchTypeChange = useCallback(
        (updatedMatchType: MatchType) => setFormStep((prev) => ({ ...prev, matchType: updatedMatchType })),
        [setFormStep]
    );

    const handleStepConditionsChange = useCallback(
        (updatedStepConditions: StepConditionDto[]) => setFormStep((prev) => ({ ...prev, stepConditions: updatedStepConditions })),
        [setFormStep]
    );

    const handleRequestDataLabelsChange = useCallback(
        (updatedRequestDataLabels: string[]) => setFormStep((prev) => ({ ...prev, requestDataLabels: updatedRequestDataLabels })),
        [setFormStep]
    );

    const handleTargetEndpointChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => setFormStep((prev) => ({ ...prev, targetEndpoint: event.target.value })),
        [setFormStep]
    );

    const handleIsImmediatelyCompletedChange = useCallback(
        (_: ChangeEvent<HTMLInputElement>, shouldWaitToComplete: boolean) =>
            setFormStep((prev) => ({
                ...prev,
                isImmediatelyCompleted: !shouldWaitToComplete,
                subplayDataOutputConfig: !shouldWaitToComplete ? [] : prev.subplayDataOutputConfig,
            })),
        [setFormStep]
    );

    const handleSubplayInputChange = useCallback(
        (updatedSubplayInputConfig: DataMappingConfigDto[]) =>
            setFormStep((prev) => ({ ...prev, subplayDataInputConfig: _.cloneDeep(updatedSubplayInputConfig) })),
        [setFormStep]
    );

    const handleSubplayOutputChange = useCallback(
        (updatedSubplayOutputConfig: DataMappingConfigDto[]) =>
            setFormStep((prev) => ({ ...prev, subplayDataOutputConfig: _.cloneDeep(updatedSubplayOutputConfig) })),
        [setFormStep]
    );

    const handleDelayMethodChange = useCallback(
        (updatedDelayMethod: DelayMethod) =>
            setFormStep((prev) => ({
                ...prev,
                delayMethod: updatedDelayMethod,
                delayDateLabel: updatedDelayMethod === DelayMethod.MINUTES ? '' : prev.delayDateLabel,
                delayMinutes: updatedDelayMethod === DelayMethod.UNTIL_DATE ? 0 : prev.delayMinutes,
            })),
        [setFormStep]
    );

    const handleDelayMinutesChange = useCallback(
        (updatedDelayMinutes: number) => setFormStep((prev) => ({ ...prev, delayMinutes: updatedDelayMinutes })),
        [setFormStep]
    );

    const handleDelayDateLabelChange = useCallback(
        (updatedDelayDateLabel: string) => setFormStep((prev) => ({ ...prev, delayDateLabel: updatedDelayDateLabel })),
        [setFormStep]
    );

    const handleEstimatedExecutionTimeChange = useCallback(
        (updatedEstimatedExecutionMinutes: number) => setFormStep((prev) => ({ ...prev, estimatedExecutionTimeInMinutes: updatedEstimatedExecutionMinutes })),
        [setFormStep]
    );

    const handleShouldNotifyIfElapsedChange = useCallback(
        (shouldNotify: boolean) => setFormStep((prev) => ({ ...prev, shouldSendOverdueNotification: shouldNotify })),
        [setFormStep]
    );

    const handleEmailsToNotifyChange = useCallback(
        (updatedEmails: string[]) => setFormStep((prev) => ({ ...prev, emailsToNotify: updatedEmails })),
        [setFormStep]
    );

    const handlePromptChange = useCallback((updatedPrompt: string) => setFormStep((prev) => ({ ...prev, prompt: updatedPrompt })), [setFormStep]);

    const handleOutputDataLabelChange = useCallback(
        (newValue: PlayVariableDto | null) => setFormStep((prev) => ({ ...prev, outputDataLabel: newValue?.name ?? '' })),
        [setFormStep]
    );

    const handleIsExternalChange = useCallback((_: any, isChecked: boolean) => setFormStep((prev) => ({ ...prev, isExternal: isChecked })), [setFormStep]);

    const handleDescriptionChange = useCallback((value: string) => setFormStep((prev) => ({ ...prev, description: value })), [setFormStep]);

    const addFileAttachment = useCallback(
        (newFileAttachment: FileAttachmentDto) => setFormStep((prev) => ({ ...prev, fileAttachments: [...(prev.fileAttachments ?? []), newFileAttachment] })),
        [setFormStep]
    );

    const handleInternalJobFunctionChange = (updatedJobFunction: ClientJobFunctionDto | undefined) => {
        if (!updatedJobFunction) {
            setFormStep((prev) => ({
                ...prev,
                internalJobFunctionId: emptyGuid,
                internalJobFunction: undefined,
            }));
        } else {
            var newJobFunctionHasMembers = !!(updatedJobFunction.memberCount && updatedJobFunction.memberCount > 0);
            setFormStep((prev) => ({
                ...prev,
                internalJobFunctionId: updatedJobFunction.id,
                internalJobFunction: updatedJobFunction,
                internalJobFunctionHasMembers: newJobFunctionHasMembers,
            }));
        }
    };

    const handleStepDataChange = useCallback(
        (updatedData: StepDataDto[]) => {
            updatedData = updatedData.map(function (data, i) {
                var newStepData: StepDataDto = { ...data, orderIndex: i };
                return newStepData;
            });
            setFormStep((prev) => ({ ...prev, stepData: updatedData }));
        },
        [setFormStep]
    );

    const handleEmbeddingInputsChange = useCallback(
        (updatedData: StepEmbedInputDto[]) => {
            updatedData = updatedData.map(function (embedInput, i) {
                var newEmbeddingInput: StepEmbedInputDto = {
                    id: embedInput.id,
                    stepId: embedInput.stepId,
                    embedLink: embedInput.embedLink,
                    orderIndex: i,
                };
                return newEmbeddingInput;
            });
            setFormStep((prev) => ({ ...prev, embedInputs: updatedData }));
        },
        [setFormStep]
    );

    const removeFileAttachment = useCallback(
        (attachmentIndex: number) =>
            setFormStep((prev) => {
                let updatedAttachments = _.cloneDeep(prev.fileAttachments ?? []);
                updatedAttachments.splice(attachmentIndex, 1);
                return { ...prev, fileAttachments: updatedAttachments };
            }),
        [setFormStep]
    );

    const handleSubplayChange = (updatedPlay: PlayDto | undefined) => {
        if (!updatedPlay) {
            setFormStep((prev) => ({ ...prev, subplayId: emptyGuid, subplay: undefined, subplayDataInputConfig: [], subplayDataOutputConfig: [] }));
        } else {
            setFormStep((prev) => ({ ...prev, subplayId: updatedPlay.id, subplay: updatedPlay, subplayDataInputConfig: [], subplayDataOutputConfig: [] }));
        }
    };

    const handleNotificationTypeChange = useCallback(
        (updatedNotificationType: NotificationType) =>
            setFormStep((prev) => ({
                ...prev,
                notificationType: updatedNotificationType,
                internalJobFunction: updatedNotificationType === NotificationType.EMAILS ? undefined : prev.internalJobFunction,
                internalJobFunctionId: updatedNotificationType === NotificationType.EMAILS ? emptyGuid : prev.internalJobFunctionId,
                emailsToNotify:
                    updatedNotificationType === NotificationType.ACTIVE_JOB_FUNCTION_MEMBERS ||
                        updatedNotificationType === NotificationType.All_JOB_FUNCTION_MEMBERS
                        ? ['']
                        : prev.emailsToNotify,
            })),
        [setFormStep]
    );

    const handleChangeTabIndex = useCallback((_: React.SyntheticEvent, newValue: number) => setTabIndex(newValue), []);

    const handleSave = () => {
        const stepValidator = new StepValidator(formStep, playVariables, formStep.subplay);
        if (stepValidator.isValidEnoughToSave) {
            const updatedStepRecord: StepDto = {
                ...formStep,
                internalJobFunctionId:
                    !formStep.internalJobFunctionId || formStep.internalJobFunctionId === emptyGuid ? undefined : formStep.internalJobFunctionId,
                // We normally don't map related items on save. However, not including this will cause an issue when re-opening an unsaved step because it will be unable to load the number of members in that job function
                internalJobFunction: formStep.internalJobFunction,
                subplayId: !formStep.subplayId || formStep.subplayId === emptyGuid ? undefined : formStep.subplayId,
                subplay: formStep.subplay,
            };
            // If the step will not be using chat data collection, we need to clear out the data prompts
            if (!updatedStepRecord.isUsingChatDataCollection) {
                updatedStepRecord.stepData = updatedStepRecord.stepData.map((data) => ({ ...data, collectionPrompt: '' }));
            }
            save(updatedStepRecord);
        }
    };

    const handleCancel = () => {
        close();
    };

    const handleDelete = useCallback(() => {
        if (!stepKey) {
            return;
        }
        var isDeleted = deleteNodeByKey(stepKey);
        if (isDeleted) {
            close();
        }
    }, [stepKey, close, deleteNodeByKey]);

    useEffect(() => {
        var validationConfigBuilder = new StepValidationConfigBuilder(formStep);
        setFormValidationRules(validationConfigBuilder.validationConfigRequiredForSave);
    }, [formStep]);

    return {
        formStep,
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleNameChange,
        handleChatDataCollectionChange,
        handleExternalRoleNameChange,
        handleDescriptionChange,
        handleIsExternalChange,
        handleInternalJobFunctionChange,
        handleStepDataChange,
        handleMatchTypeChange,
        handleStepConditionsChange,
        handleTargetEndpointChange,
        handleEstimatedExecutionTimeChange,
        handleShouldNotifyIfElapsedChange,
        isGeneralValid,
        isAssignmentValid,
        isDataValid,
        isInputValid,
        isOutputValid,
        isDevelopmentValid,
        isEstimationValid,
        isDelayValid,
        tabIndex,
        handleChangeTabIndex,
        setTabIndex,
        handleRequestDataLabelsChange,
        handleSubplayChange,
        handleEmbeddingInputsChange,
        handleSubplayInputChange,
        handleSubplayOutputChange,
        handleIsImmediatelyCompletedChange,
        handleDelete,
        handleDelayMethodChange,
        handleDelayMinutesChange,
        handleDelayDateLabelChange,
        handleNotificationTypeChange,
        handleEmailsToNotifyChange,
        handlePromptChange,
        handleOutputDataLabelChange,
        addFileAttachment,
        removeFileAttachment,
    };
}
