import { Grid, Link, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { StepDataDetails, StepDescriptionDetails, StepFileAttachmentDetails, StepNameDetails } from '../../../../PlayHistory/Components/StepDetailSections/Components';
import { IStepExecutionDetailsProps } from '../../types';
import { EmbeddingsDataView } from '../../../../PlayExecution/Components/StepEmbeddingsView';

export const StartNodeExecutionDetails: FC<IStepExecutionDetailsProps> = ({ stepExecution }) => {
    const navigate = useNavigate();
    const goToStartedByPlay = useCallback(() => navigate(`/playbook/${stepExecution.executedByStepPlayBookId}/play/${stepExecution.executedByStepPlayId}/history?runId=${stepExecution.executedByStepPlayExecutionId}`), [stepExecution, navigate]);

    return (
        <>
            <Grid item pt={2}>
                <Typography>Started By: {!!stepExecution.executedByStepPlayExecutionId ? <Link underline='hover' onClick={goToStartedByPlay}>{stepExecution.startedBy}</Link> : stepExecution.startedBy}</Typography>
            </Grid>
            <StepNameDetails name={stepExecution.name ?? 'Unnamed Step'} />
            <StepDescriptionDetails description={stepExecution.description ?? ''} />
            {stepExecution.fileAttachments && <StepFileAttachmentDetails fileAttachments={stepExecution.fileAttachments} />}
            <StepDataDetails stepDataEntries={stepExecution.stepData} stepAttachments={stepExecution.fileAttachments} customEmptyDataMessage='No data needs to be entered for this play.' />
            <EmbeddingsDataView embeddingInputs={stepExecution.embedInputs} />

        </>
    );
}