import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormValidationMethod, IFormFieldValidationConfig, isNotBlank, isShorterThanMaxLength, runFormValidation, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../Components/CoreLib/library';
import { StepExecutionSnoozeDto } from '../../../dtos';
import { useUpdatePlayByPlayIdExecutionByStepExecutionIdSnoozeMutation } from '../../../store/generated/generatedApi';
import dayjs from 'dayjs';
import _ from 'lodash';

export interface ISnoozeFormValues {
    snoozeUntil: string;
    snoozeReason: string;
}

// TODO: refactor this to use the 'name' event attribute so we can reduce duplication and simplify the logic here.
export function useSnoozeStepForm(playExecutionId: string, stepExecutionId: string, close: () => void, initValues?: StepExecutionSnoozeDto) {
    const [snoozeUntil, setSnoozeUntil] = useState<string>(new Date(initValues?.snoozeUntil+'Z').toLocaleString() ?? '');
    const [snoozeReason, setSnoozeReason] = useState(initValues?.snoozeReason ?? '');
    const [snoozeStep, { isLoading: isSnoozingStep, isSuccess: isSnoozeSuccess, isError: isSnoozeError, reset: resetSnoozeMutation }] = useUpdatePlayByPlayIdExecutionByStepExecutionIdSnoozeMutation();
    useSuccessfulActionSnackbar('snoozed', 'step', isSnoozeSuccess, () => {
        resetSnoozeMutation();
        handleCancel();
        close();
    });
    useFailedActionSnackbar('snoozed', 'step', isSnoozeError, resetSnoozeMutation)

    useEffect(() => {
        setSnoozeUntil(new Date(initValues?.snoozeUntil+'Z').toLocaleString() ?? '');
        setSnoozeReason(initValues?.snoozeReason ?? '');
      }, [initValues]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof ISnoozeFormValues, string>>(
        new Map([
            ['snoozeUntil', ''],
            ['snoozeReason', '']
        ])
    );

    const isFutureDate: FormValidationMethod = useCallback((value: any) => {
        let errorMessageBuilder = (fieldName: string) => `${fieldName} must be in the future`
        let isValid = true;
        const dayjsValue = dayjs(value);
        if (dayjsValue.isValid() && dayjsValue.isBefore(new Date())) {
            isValid = false;
        }

        return { errorMessageBuilder, isValid }
    }, []);

    const formFieldValidators = new Map<keyof ISnoozeFormValues, IFormFieldValidationConfig>([
        [
            'snoozeUntil',
            {
                validators: [isNotBlank, isFutureDate],
                errorMessageEntityName: 'Snooze Until Date',
            },
        ],
        [
            'snoozeReason',
            {
                validators: [isShorterThanMaxLength(500)],
                errorMessageEntityName: 'Reason',
            },
        ]
    ]);

    const getCurrentFormValues = (): ISnoozeFormValues => {
        return {
            snoozeUntil: snoozeUntil,
            snoozeReason: snoozeReason
        };
    };

    const validateForm = () => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<ISnoozeFormValues>(formValues, formFieldValidators);
        if (!validationResult.isValid) {
            setFieldErrors(validationResult.errorMessages);
            return false;
        } else if (snoozeUntil === 'Invalid Date') {
            let updatedFieldVariables = _.cloneDeep(fieldErrors);
            updatedFieldVariables.set('snoozeUntil', 'Snooze Until is required')
            setFieldErrors(updatedFieldVariables);
            return false;
        }
        setFieldErrors(validationResult.errorMessages);
        return true;
    };

    const isFormDirty = useMemo(() => {
        var isDirty = snoozeUntil !== dayjs(initValues?.snoozeUntil).toString();
        isDirty = isDirty || snoozeReason !== initValues?.snoozeReason;
        return isDirty;
    }, [initValues, snoozeUntil, snoozeReason]);

    const handleSnoozeUntilChange = (value: any) => {
        setSnoozeUntil(value);
    };

    const handleSnoozeReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSnoozeReason(event.target.value);
    };

    const handleSave = () => {
        if (isSnoozingStep) {
            return;
        }
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedSnoozeConfig: StepExecutionSnoozeDto = {
                snoozeUntil: new Date(formValues.snoozeUntil),
                snoozeReason: formValues.snoozeReason
            };
            snoozeStep({
                params: {
                    playId: playExecutionId,
                    stepExecutionId: stepExecutionId
                },
                payload: updatedSnoozeConfig
            });
        }
    };

    const handleCancel = () => {
        setSnoozeUntil('');
        setSnoozeReason('');
        setFieldErrors(new Map([
            ['snoozeUntil', ''],
            ['snoozeReason', '']
        ]));
    };

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleSnoozeUntilChange,
        handleSnoozeReasonChange,
        snoozeUntil,
        snoozeReason
    };
}
