import { FormControl, FormLabel, FormHelperText, Autocomplete, TextField } from '@mui/material';
import { FC, SyntheticEvent, useMemo } from 'react';
import { Comparison } from '../../dtos';

export interface IOperatorSelectProps {
    errorMessage?: string;
    selectedOperator: Comparison;
    handleOperatorChange: (value: Comparison) => void;
};

export const OperatorSelect: FC<IOperatorSelectProps> = props => {
    const {
        errorMessage,
        selectedOperator,
        handleOperatorChange
    } = props;

    const onOperatorChange = (_: SyntheticEvent<Element, Event>, value: {label: string, id: number, value: Comparison} | null) => {
        if (value !== null) {
            handleOperatorChange(value.value);
        }
    }

    const options = useMemo(() => {
        return [
            { label: "Is True", id: Comparison.IS_TRUE, value: Comparison.IS_TRUE },
            { label: "Is False", id: Comparison.IS_FALSE, value: Comparison.IS_FALSE },
            { label: "Equals", id: Comparison.EQUALS, value: Comparison.EQUALS },
            { label: "Doesn't Equal", id: Comparison.DOES_NOT_EQUAL, value: Comparison.DOES_NOT_EQUAL },
            { label: "Contains", id: Comparison.CONTAINS, value: Comparison.CONTAINS },
            { label: "Doesn't Contain", id: Comparison.DOES_NOT_CONTAIN, value: Comparison.DOES_NOT_CONTAIN },
            { label: "Greater Than", id: Comparison.GREATER_THAN, value: Comparison.GREATER_THAN },
            { label: "Less Than", id: Comparison.LESS_THAN, value: Comparison.LESS_THAN },
            { label: "Greater Than or Equal To", id: Comparison.GREATER_THAN_OR_EQUAL_TO, value: Comparison.GREATER_THAN_OR_EQUAL_TO },
            { label: "Less Than or Equal To", id: Comparison.LESS_THAN_OR_EQUAL_TO, value: Comparison.LESS_THAN_OR_EQUAL_TO },
            { label: "Starts With", id: Comparison.STARTS_WITH, value: Comparison.STARTS_WITH },
            { label: "Doesn't Start With", id: Comparison.DOES_NOT_START_WITH, value: Comparison.DOES_NOT_START_WITH },
            { label: "Ends With", id: Comparison.ENDS_WITH, value: Comparison.ENDS_WITH },
            { label: "Doesn't End With", id: Comparison.DOES_NOT_END_WITH, value: Comparison.DOES_NOT_END_WITH },
            { label: "Exists", id: Comparison.EXISTS, value: Comparison.EXISTS },
            { label: "Doesn't Exist", id: Comparison.DOES_NOT_EXIST, value: Comparison.DOES_NOT_EXIST },
        ];
    }, []);

    const selectedOption = useMemo(() => options.find((option) => option.id === selectedOperator) ?? null, [options, selectedOperator])

    return (
        <FormControl fullWidth required error={!!errorMessage}>
            <FormLabel>Comparison</FormLabel>
            <Autocomplete
                disablePortal
                options={options}
                renderInput={(params) => <TextField {...params} />}
                value={selectedOption}
                onChange={onOperatorChange}
                isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    )
}