import { Help } from '@mui/icons-material';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReactFlow, { Background, Controls, MarkerType, MiniMap } from 'reactflow';
import 'reactflow/dist/style.css';
import { FormValidationMethod, IFieldValidationResult, isNotBlank, isShorterThanMaxLength, runFieldValidation } from '../../../Components/CoreLib/library';
import { ExpandableSectionHandle } from '../../../Components/ExpandableSection';
import { ExpandableSection } from '../../../Components/ExpandableSection/ExpandableSection';
import { usePermissionChecker } from '../../../Hooks';
import { PlayDto, PlayVariableDto, StepDataType, StepDto } from '../../../dtos';
import { usePrompt } from '../../Home';
import { usePlayVariableRenameUtil, usePlayVariableUseTracker, useSelectedNodeManager } from '../Hooks';
import { usePlayUpdater } from '../Hooks/usePlayUpdater';
import '../StyleSheets/custom-reactflow-styles.css';
import { mapNodesToSteps, useFlowManager } from '../Utils';
import { PlayEditorContext } from '../Utils/PlayEditorContext';
import { EditPlayDetailsModal, PlayDetails } from './Modals';
import { EditStepModal } from './Modals/EditStepModal';
import { NodeLibrary } from './NodeLibrary';
import { PlayEditorTitleBar } from './PlayEditorTitleBar';
import { PlayVariablePanel } from './PlayVariablePanel';
import Cookies from 'universal-cookie';

export type DataPiece = {
    name: string | undefined;
    key: string | undefined;
};

export interface IPlayEditorProps {
    play: PlayDto;
}

export const PlayEditor: FC<IPlayEditorProps> = (props) => {
    const { play } = props;
    const [playVariables, setPlayVariables] = useState(play.variables);
    const { canUserEditPlay } = usePermissionChecker();
    const [searchParams, setSearchParams] = useSearchParams();
    const cookies = useMemo(() => new Cookies(null), []);
    const isNodeLibraryOpenInRoute = cookies.get('nl');
    const isPlayVariablePanelOpenInRoute = cookies.get('pvp');
    const isNullOrOne = (val: string | null) => val === null || val === '1';
    const [isNodeLibraryExpanded, setIsNodeLibraryExpanded] = useState(isNullOrOne(isNodeLibraryOpenInRoute));
    const [isDataPanelExpanded, setIsDataPanelExpanded] = useState(isNullOrOne(isPlayVariablePanelOpenInRoute));

    const {
        reactFlowWrapper,
        nodes,
        edges,
        onNodesChange,
        onEdgesChange,
        onConnect,
        onDrop,
        onDragOver,
        nodeTypes,
        edgeTypes,
        reloadPlay,
        setNodes,
        getDependentNodeKeys,
    } = useFlowManager<StepDto>(play);
    const { getPlayVariableUseMap } = usePlayVariableUseTracker();
    const { renameVariableInStep } = usePlayVariableRenameUtil();
    const [isEditPlayDetailsVisible, setIsEditPlayDetailsVisible] = useState(false);
    const [shouldSaveAfterDetailsUpdate, setShouldSaveAfterDetailsUpdate] = useState(false);
    const { selectedStep, handleNodeClick, updateStep, stepDataCollectedSoFarForSelectedStep, clearSelectedStep } = useSelectedNodeManager(
        nodes,
        getDependentNodeKeys,
        playVariables
    );

    // TODO: This hook could be updated to pull several of these values from the PlayEditorContext
    const { savePlay, playDetails, setPlayDetails, isUpdating, isCreating } = usePlayUpdater(play, nodes, edges, playVariables);

    const [playIsSavedAndUnedited, setPlayIsSavedAndUnedited] = useState<boolean>(true);
    usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', !playIsSavedAndUnedited);

    const handleUpdateNode = useCallback(
        (updatedStep: StepDto) => {
            const updatedNodes = updateStep(updatedStep);
            setNodes(updatedNodes);
            setPlayIsSavedAndUnedited(false);
        },
        [setNodes, updateStep]
    );

    const handleSavePlay = useCallback(() => {
        if (!playDetails.name.trim()) {
            setShouldSaveAfterDetailsUpdate(true);
            setIsEditPlayDetailsVisible(true);
            return;
        }
        savePlay();
        setPlayIsSavedAndUnedited(true);
    }, [savePlay, playDetails.name]);

    const handleEditPlayDetailsClicked = useCallback(() => {
        setIsEditPlayDetailsVisible(true);
    }, []);

    const handleUpdatePlayDetails = useCallback(
        (updatedPlayDetails: PlayDetails) => {
            setPlayDetails(updatedPlayDetails);
            setIsEditPlayDetailsVisible(false);
        },
        [setPlayDetails]
    );

    const handleClosePlayDetailsModal = useCallback(() => {
        setIsEditPlayDetailsVisible(false);
        setShouldSaveAfterDetailsUpdate(false);
    }, []);

    const localPlay = useMemo(() => {
        return {
            ...play,
            name: playDetails.name,
            description: playDetails.description,
        };
    }, [play, playDetails]);

    const userCanEdit = useMemo(() => {
        return canUserEditPlay(play);
    }, [canUserEditPlay, play]);

    const steps = useMemo(() => {
        return mapNodesToSteps(nodes);
    }, [nodes]);

    const playVariableUseMap = useMemo(() => getPlayVariableUseMap(playVariables, steps), [getPlayVariableUseMap, playVariables, steps]);

    const renamePlayVariable = useCallback(
        (oldName: string, newName: string) => {
            // Updating Play Variables Array
            let updatedPlayVariables = _.cloneDeep(playVariables);
            const renamedVarIndex = updatedPlayVariables.findIndex((v) => v.name === oldName);
            updatedPlayVariables.splice(renamedVarIndex, 1, { ...updatedPlayVariables[renamedVarIndex], name: newName });
            setPlayVariables(updatedPlayVariables);

            // Updating Play Nodes
            const updatedNodes = _.cloneDeep(nodes);
            let stepsUsingThisVariable = playVariableUseMap.get(oldName);
            stepsUsingThisVariable?.forEach((stepKey) => {
                let stepNodeIndex = updatedNodes.findIndex((node) => node.data.key === stepKey);
                if (stepNodeIndex === -1) {
                    console.error(`Failed to locate a step with the key ${stepKey} when renaming variable ${oldName} to ${newName}.`);
                    return;
                }
                let updatedNode = _.cloneDeep(updatedNodes[stepNodeIndex]);
                updatedNode.data = renameVariableInStep(_.cloneDeep(updatedNode.data), oldName, newName);
                updatedNodes.splice(stepNodeIndex, 1, updatedNode);
            });
            setNodes(updatedNodes);
        },
        [playVariableUseMap, nodes, setNodes, playVariables, renameVariableInStep]
    );

    const validatePlayVariableName = useCallback(
        (varName: string, currentIndex?: number): IFieldValidationResult => {
            const usedVariableNames = playVariables.map((pv) => pv.name.toLowerCase().trim());
            if (currentIndex !== undefined) {
                // If this is not a new record then we need to remove it from the used variable list so it does not conflict with itself
                usedVariableNames.splice(currentIndex, 1);
            }
            const isNotDuplicate: FormValidationMethod = (val: string) => {
                const isValid = !usedVariableNames.includes(val.toLowerCase());
                const errorMessageBuilder = () => 'Data name must be unique';
                return {
                    isValid,
                    errorMessageBuilder,
                };
            };

            return runFieldValidation(varName.trim(), {
                validators: [isNotBlank, isNotDuplicate, isShorterThanMaxLength(200)],
                errorMessageEntityName: 'Name',
            });
        },
        [playVariables]
    );

    // TODO: this logic may be outdated but it is not related to my current story so I will investigate this later
    const getCurrentlyUsedVariablesFromPlay = useCallback(
        (playToSearch: PlayDto) => {
            let usedVars: PlayVariableDto[] = [];
            const steps = playToSearch.steps;
            if (!steps) {
                return usedVars;
            }

            // Loading used step data variables
            usedVars.push(
                ...steps
                    .flatMap((step) => step.stepData)
                    .map((sd) => ({
                        playId: play.id,
                        name: sd.name ?? '',
                        dataType: sd.dataType ?? StepDataType.TEXT,
                        isFutureDate: sd.isFutureDate ?? true,
                    }))
            );

            // Loading used subplay output mapping variables
            usedVars.push(
                ...steps
                    .flatMap((step) => step.subplayDataOutputConfig ?? [])
                    .map((sdoc) => ({
                        playId: play.id,
                        name: sdoc.destinationFieldName ?? '',
                        dataType: StepDataType.TEXT,
                        isFutureDate: true,
                    }))
            );

            // Loading used AI output data label
            usedVars.push(
                ...steps
                    .flatMap((step) => step.outputDataLabel ?? [])
                    .filter((odl) => !!odl)
                    .map((odl) => ({
                        playId: play.id,
                        name: odl ?? '',
                        dataType: StepDataType.TEXT,
                        isFutureDate: true,
                    }))
            );

            return usedVars;
        },
        [play.id]
    );

    // This use effect maintains the list play variables for a play. This really only applies to variables that were not added through the data panel which should only happen for older plays. One day we may be able to remove this.
    useEffect(() => {
        let currentlyUsedVariables = getCurrentlyUsedVariablesFromPlay(play);
        var currentlyUsedVariablesThatAreNotInPlayVariables = currentlyUsedVariables.filter(
            (usedVar) => !play.variables.find((pv) => pv.name === usedVar.name)
        );
        if (currentlyUsedVariablesThatAreNotInPlayVariables.length === 0) {
            return;
        }

        var updatedPlayVariables = _.cloneDeep(play.variables);
        currentlyUsedVariablesThatAreNotInPlayVariables.forEach((missingVariable) => {
            var isAlreadyAdded = updatedPlayVariables.some((playVar) => playVar.name === missingVariable.name);
            if (isAlreadyAdded) {
                return;
            }
            updatedPlayVariables.push({
                playId: play.id,
                name: missingVariable.name ?? '',
                dataType: missingVariable.dataType,
                isFutureDate: missingVariable.isFutureDate,
            });
        });
        setPlayVariables(updatedPlayVariables);
    }, [getCurrentlyUsedVariablesFromPlay, play]);

    // The step data array on each step stores the full stepDataDto for each play variable. This useEffect keeps the types in sync throughout the play.
    useEffect(() => {
        const allStepDataDataTypes = nodes.flatMap((node) =>
            node.data.stepData.map((sd) => ({ name: sd.name, type: sd.dataType, isFutureDate: sd.isFutureDate }))
        );
        const allPlayVariableDataTypes = playVariables.map((pv) => ({ name: pv.name, type: pv.dataType, isFutureDate: pv.isFutureDate }));
        const isATypeMismatchInNodes = allPlayVariableDataTypes.some((pvdt) =>
            allStepDataDataTypes.some((asddt) => asddt.name === pvdt.name && (asddt.type !== pvdt.type || asddt.isFutureDate !== pvdt.isFutureDate))
        );
        if (isATypeMismatchInNodes) {
            const updatedNodes = _.cloneDeep(nodes);
            const playVariableTypeMap = new Map<string, [StepDataType, boolean]>();
            allPlayVariableDataTypes.forEach((playVariableTypeEntry) =>
                playVariableTypeMap.set(playVariableTypeEntry.name, [playVariableTypeEntry.type!, playVariableTypeEntry.isFutureDate!])
            );
            updatedNodes.forEach((node) => {
                node.data.stepData = node.data.stepData.map((sd) => ({
                    ...sd,
                    dataType: playVariableTypeMap.get(sd.name!)![0],
                    isFutureDate: playVariableTypeMap.get(sd.name!)![1],
                }));
            });
            setNodes(updatedNodes);
        }
    }, [playVariables, nodes, setNodes]);

    // This useEffect handles the case where the user tries to save without first setting a name for the play
    useEffect(() => {
        if (shouldSaveAfterDetailsUpdate && !!playDetails.name.trim()) {
            handleSavePlay();
            setShouldSaveAfterDetailsUpdate(false);
        }
    }, [playDetails, handleSavePlay, shouldSaveAfterDetailsUpdate]);

    // This useEffect automatically reloads the play editor whenever the play it is based on changes (normally that is just after an create/update request is sent)
    useEffect(() => {
        reloadPlay(play);
    }, [play, reloadPlay]);

    // This useEffect keeps the query parameters in the URL in sync with the current state of the expandable panels
    useEffect(() => {
        cookies.set('pvp', isDataPanelExpanded ? '1' : '0');
        cookies.set('nl', isNodeLibraryExpanded ? '1' : '0');
    }, [isNodeLibraryExpanded, isDataPanelExpanded, searchParams, setSearchParams, cookies]);

    return (
        <PlayEditorContext.Provider
            value={{ playId: play.id, steps, updateStep, playVariables, setPlayVariables, renamePlayVariable, playVariableUseMap, validatePlayVariableName }}>
            <Box flexDirection='column' display='flex' overflow='hidden'>
                <PlayEditorTitleBar
                    play={localPlay}
                    handleSave={handleSavePlay}
                    onEditPlayDetailsClicked={handleEditPlayDetailsClicked}
                    isSaving={isUpdating || isCreating}
                    playIsSavedAndUnedited={playIsSavedAndUnedited}
                />
                <Box flexDirection='row' display='flex' height='calc(100vh - 140px)'>
                    {canUserEditPlay(play) && (
                        <ExpandableSection isExpanded={isNodeLibraryExpanded} direction='right'>
                            <NodeLibrary />
                        </ExpandableSection>
                    )}
                    <Grid item flexGrow={1} height='100%' ref={reactFlowWrapper} position='relative'>
                        <ExpandableSectionHandle
                            isExpanded={isNodeLibraryExpanded}
                            setIsExpanded={setIsNodeLibraryExpanded}
                            direction='right'
                            backgroundColor='#f3f5f6'
                        />
                        <Tooltip title='Drag either a Start, Step, or Start out onto the workspace. Click on the step to set parameters. Pull on the black dot at the side to connect to the next step. To delete a step, select it, then push Delete on your keyboard.'>
                            <IconButton sx={{ position: 'absolute', top: 16, right: 16, zIndex: 2 }} disableRipple={true}>
                                <Help />
                            </IconButton>
                        </Tooltip>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            defaultEdgeOptions={{
                                deletable: true,
                                markerEnd: {
                                    type: MarkerType.ArrowClosed,
                                },
                                type: 'deletable',
                            }}
                            onNodesChange={userCanEdit ? onNodesChange : undefined}
                            onEdgesChange={userCanEdit ? onEdgesChange : undefined}
                            onConnect={onConnect}
                            onDrop={onDrop}
                            onDragOver={userCanEdit ? onDragOver : undefined}
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                            onNodeClick={handleNodeClick}
                            snapToGrid
                            deleteKeyCode={null}>
                            <Controls />
                            <Background />
                            <MiniMap pannable zoomable />
                        </ReactFlow>
                        <ExpandableSectionHandle isExpanded={isDataPanelExpanded} setIsExpanded={setIsDataPanelExpanded} direction='left' />
                    </Grid>
                    {canUserEditPlay(play) && (
                        <ExpandableSection isExpanded={isDataPanelExpanded} direction='left'>
                            <PlayVariablePanel />
                        </ExpandableSection>
                    )}
                </Box>
                <EditStepModal
                    step={selectedStep}
                    collectedData={stepDataCollectedSoFarForSelectedStep}
                    updateStep={handleUpdateNode}
                    clearSelectedStep={clearSelectedStep}
                />
                <EditPlayDetailsModal
                    closeModal={handleClosePlayDetailsModal}
                    onUpdatePlayDetails={handleUpdatePlayDetails}
                    playDetails={playDetails}
                    isOpen={isEditPlayDetailsVisible}
                />
            </Box>
        </PlayEditorContext.Provider>
    );
};