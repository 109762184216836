import { useCallback, useMemo, useState } from 'react';
import { IFormFieldValidationConfig, isNotBlank, runFormValidation } from '../../../../Components/CoreLib/library';
import { PlayDto, PlaybookDto } from '../../../../dtos';
 
export interface IUseMovePlayFormProps {
    save: (values: PlayDto, playbookId: string) => void;
    cancel: () => void;
    selectedPlay?: PlayDto;
}

export interface IMovePlayFormValues {
    playbookId: string;
}

export function useMovePlayForm(props: IUseMovePlayFormProps) {
    const { save, cancel, selectedPlay } = props;
    const [formPlaybook, setFormPlaybook] = useState<PlaybookDto>();

    const [fieldErrors, setFieldErrors] = useState<Map<keyof IMovePlayFormValues, string>>(
        new Map([
            ['playbookId', ''],
        ])
    );

    const formFieldValidators = useMemo(
        () =>
            new Map<keyof IMovePlayFormValues, IFormFieldValidationConfig>([
                [
                    'playbookId',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Playbook',
                    },
                ],
            ]),
        []
    );

    const getCurrentFormValues = useMemo(() => {
        return (): IMovePlayFormValues => {
            return {
                playbookId: formPlaybook?.id ?? ''
            };
        };
    }, [
        formPlaybook
    ]);

    const validateForm = useCallback(() => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<IMovePlayFormValues>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [formFieldValidators, getCurrentFormValues]);

    const handlePlaybookChange = (newPlaybook: PlaybookDto) => {
        setFormPlaybook(newPlaybook);
    };

    const handleSave = () => {
        if (!selectedPlay) {
            return;
        }
        const isFormValid = validateForm();
        if (formPlaybook?.id !== selectedPlay?.playbookId && isFormValid) {
            save(selectedPlay, formPlaybook!.id);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    return {
        fieldErrors,
        formPlaybook,
        handlePlaybookChange,
        handleSave,
        handleCancel,
    };
}
