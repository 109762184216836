import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { FC, useContext, useMemo } from 'react';
import { PlayVariableAutocompleteWithAdd } from '../../../../../../Components/CommonInputs';
import { TextFieldWithDataReferences } from '../../../../../../Components/Wysiwyg';
import { StepDataDto, StepDataType } from '../../../../../../dtos';
import { PlayEditorContext } from '../../../../Utils/PlayEditorContext';

export interface IDataOrTextInputProps {
    dataType: StepDataType;
    required?: boolean;
    value: string;
    handleValueChange: (value: string) => void;
    dataOptions: StepDataDto[];
    errorMessage?: string;
    disableAddNew?: boolean;
}

export const DataOrTextInput: FC<IDataOrTextInputProps> = (props) => {
    const { dataType, required, errorMessage, value, handleValueChange, dataOptions, disableAddNew } = props;
    const { playVariables } = useContext(PlayEditorContext);

    var selectedPlayVariable = useMemo(() => playVariables.find((pv) => `{{${pv.name}}}` === value) ?? null,[playVariables, value]);
    var playVariableOptions = useMemo(() => dataOptions.map(dataOption => playVariables.find(pv => pv.name === dataOption.name)!), [playVariables, dataOptions]);

    return (
        <>
            <FormControl error={!!errorMessage} fullWidth required={required}>
                {dataType === StepDataType.FILE ? (
                    <>
                        <FormLabel>File Data</FormLabel>
                        <PlayVariableAutocompleteWithAdd
                            value={selectedPlayVariable}
                            onChange={(value) => handleValueChange(value ? `{{${value.name}}}` : '')}
                            playVariableOptions={playVariableOptions}
                            disableAddNew={disableAddNew}
                        />
                    </>
                ) : (
                    <>
                        <FormLabel>Value</FormLabel>
                        <TextFieldWithDataReferences initialValue={value} onChange={handleValueChange} dataReferences={dataOptions.map(x => x.name ?? '')}/>
                    </>
                )}
                <FormHelperText>{errorMessage}</FormHelperText>
            </FormControl>
        </>
    );
};
