import { Grid, Link, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { FileAttachmentDto, StepDataType, StepExecutionDataDto } from '../../../../../dtos';
import { downloadFile, formatTimestamp, renderWithRequiredIndicator } from '../../../../../util';
import { useCreateAttachmentByIdDownloadMutation } from '../../../../../store/generated/generatedApi';

export interface IStepDataDetailsProps {
    stepDataEntries: StepExecutionDataDto[];
    stepAttachments?: FileAttachmentDto[];
    customEmptyDataMessage?: string;
}

export const StepDataDetails: FC<IStepDataDetailsProps> = ({ stepDataEntries, stepAttachments = [], customEmptyDataMessage }) => {
    const [createAttachmentDownloadLink, { isLoading: isCreatingDownloadLink }] = useCreateAttachmentByIdDownloadMutation();

    const handleFileLinkClicked = useCallback(async (fileName: string) => {
        if (isCreatingDownloadLink) {
            return;
        }
        const attachment = stepAttachments.find(a => a.friendlyFileName === fileName)!;
        var downloadLink = await createAttachmentDownloadLink({ id: stepDataEntries[0].stepId, fileName: attachment?.blobFileName ?? fileName }).unwrap();
        downloadFile(downloadLink.link, fileName);
    }, [createAttachmentDownloadLink, isCreatingDownloadLink, stepAttachments, stepDataEntries]);

    return (
        <>
            <Grid item container direction='row' mt={2}>
                <Grid item>
                    <Typography variant='h2'>Data</Typography>
                </Grid>
            </Grid>
            {stepDataEntries.map((stepData, i) => (
                <Grid item xs={12} key={`step-data-${i}`}>
                    <Typography><strong>{renderWithRequiredIndicator(stepData.name ?? '', stepData.isRequired)}:</strong> {stepData.dataType === StepDataType.FILE ? <Link href='#' underline='hover' onClick={() => handleFileLinkClicked(stepData.value)}>{stepData.value}</Link> : formatTimestamp('', stepData)}</Typography>
                </Grid>
            ))}
            {stepDataEntries.length === 0 && (
                <Typography mt={2}>{customEmptyDataMessage ? customEmptyDataMessage : 'No data needs to be entered for this step.'}</Typography>
            )}
        </>
    )
}