import { AccountCircle } from '@mui/icons-material';
import { Grid, Typography, Button } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { StepExecutionDto } from '../../../../../dtos';
import { StepReassignmentModal } from '../../StepReassignmentModal';
import { formatTimestamp } from '../../../../../util';

export interface IStepDetailAssignmentDetailsProps {
    stepExecution: StepExecutionDto;
    handleStepExecutionUpdate: () => void;
}

export const StepAssignmentDetails: FC<IStepDetailAssignmentDetailsProps> = props => {
    const { stepExecution, handleStepExecutionUpdate } = props;

    const [isReassignmentModalOpen, setIsReassignmentModalOpen] = useState(false);

    const handleReassignStepClicked = useCallback(() => {
        setIsReassignmentModalOpen(true);
    }, []);

    const handleReassignStepClose = (isUpdate?: boolean) => {
        setIsReassignmentModalOpen(false);
        if (isUpdate) {
            handleStepExecutionUpdate();
        }
    }

    const renderAssignmentDetails = useMemo(() => {
        if (!stepExecution.isAssigned) {
            return null;
        }
        if (stepExecution.isExternal) {
            return (
                <Grid item container direction='row'>
                    <Grid item>
                        <Typography>Assigned Email: {stepExecution.assignedToEmail}</Typography>
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <Grid item container direction='row'>
                    <Grid item>
                        <Typography>Assigned Member: {`${stepExecution.assignedUserName} (${stepExecution.assignedToEmail})`}</Typography>
                    </Grid>
                </Grid>
            )
        }
    }, [stepExecution]);

    const renderCompletionDetails = useMemo(() => {
        if (!stepExecution.isComplete) {
            return null;
        }

        var completedByName = stepExecution.isExternal ? stepExecution.assignedToEmail : stepExecution.completedByUserName;
        var completedOn = stepExecution.completedOn ? formatTimestamp(stepExecution.completedOn) : '';

        return (
            <>
                <Grid item container direction='row'>
                    <Grid item>
                        <Typography>Completed By: {completedByName}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction='row'>
                    <Grid item>
                        <Typography>Completed On: {completedOn}</Typography>
                    </Grid>
                </Grid>
            </>
        )
    }, [stepExecution]);

    const renderReassignButton = useMemo(() => {
        if (!stepExecution.isComplete && stepExecution.isAssigned) {
            return (
                <Grid item container direction='row' mt={1}>
                    <Grid item>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={handleReassignStepClicked}
                            startIcon={<AccountCircle />}
                        >
                            Reassign {stepExecution.isExternal ? 'Role' : 'Member'}
                        </Button>
                    </Grid>
                </Grid>
            )
        } else {
            return null;
        }
    }, [stepExecution, handleReassignStepClicked]);

    return (
        <>
            <Grid item container direction='row' mt={2}>
                <Grid item>
                    <Typography>Assigned {stepExecution.isExternal ? 'Role' : 'Job Function'}: {stepExecution.isExternal ? stepExecution.externalRoleName : stepExecution.assignedJobFunctionName}</Typography>
                </Grid>
            </Grid>
            { renderAssignmentDetails }
            { renderCompletionDetails }
            { renderReassignButton }
            <StepReassignmentModal isOpen={isReassignmentModalOpen} isExternal={stepExecution.isExternal} jobFunctionId={stepExecution.internalJobFunctionId} playId={stepExecution.playId} stepId={stepExecution.id} handleClose={handleReassignStepClose} />
        </>
    )
}