import { FC } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../dtos";
import { NodeType } from "../NodeType";
import { StopNodeLibraryListItem, StopPlayViewerNode } from "./Components";
import { NodeTypes } from "../utils";
import { INodeRules } from "../types";

export class StopNodeType extends NodeType {
    public readonly nodeType: string = NodeTypes.STOP;
    public override readonly validationRules: INodeRules = { maxInputs: 1, maxOutputs: 0 }

    public readonly PlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = StopPlayViewerNode;
    public readonly NodeLibraryListItem: FC = StopNodeLibraryListItem;
}