import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { generatedApiTags } from "./generated/generatedApiTags";
import { RootState } from "./store";

export const serverApi = createApi({
  reducerPath: "serverApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      if (endpoint !== 'createAttachment') {
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  tagTypes: generatedApiTags,
  endpoints: () => ({}),
});
