import { Book, Help } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CardListView, LoadingBackdrop } from '../../Components/CoreLib/library';
import { usePlayStarter } from '../../customHooks';
import { PlayDto } from '../../dtos';
import { useCreateAutoSavePlayMutation, useGetPlayQuery, useGetPlaybookByIdQuery } from '../../store/generated/generatedApi';
import { PlayCard } from './Components';

export const Plays: FC = () => {
    const { id: playbookId } = useParams();
    const navigate = useNavigate();
    const { data: playbook } = useGetPlaybookByIdQuery({ id: playbookId ?? '' });
    const [createPlay] = useCreateAutoSavePlayMutation();
    const { handleStartPlay, renderStartPlayModal, isStartingPlay } = usePlayStarter();


    const handleCreateNew = () => {
        createPlay({ playbookId: playbookId ?? '' }).unwrap().then((playId) => {
            if (playId) {
                navigate(`/playbook/${playbookId}/play/${playId}`);
            }
        });
    };

    const renderCard = useCallback(
        (item: PlayDto) => <PlayCard play={item} onStartClicked={handleStartPlay} />,
        [handleStartPlay]
    );

    return (
        <Box>
            <CardListView
                headerIcon={<Book />}
                headerTitle={playbook?.name ?? ''}
                breadCrumbs={[
                    { label: 'Home', navLink: '/' },
                    { label: 'Playbooks', navLink: '/playbooks' }
                ]}
                sortKey={'NAME'}
                getDataQuery={useGetPlayQuery}
                entityNameSingular='Play'
                permissionName='plays'
                handleAdd={handleCreateNew}
                renderCard={renderCard}
                additionalQueryParameters={{ playbookId }}
                currentPageLabel={playbook?.name ?? ''}
                endTitleSlot={<Tooltip title="This is where you'll add plays (SOPs) and outline their specific steps."><IconButton><Help /></IconButton></Tooltip>}
                stickyPageHeaderHightOffset={140}
                stickyPageHeader={true}
            />
            {renderStartPlayModal}
            <LoadingBackdrop loadingOptions={[{ isLoading: isStartingPlay, loadingMessage: 'Starting play...' }]} />
        </Box>
    );
};
