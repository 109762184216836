import { FC } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../dtos";
import { NodeType } from "../NodeType";
import { NodeTypes } from "../utils";
import { JoinNodeLibraryListItem, JoinPlayViewerNode } from "./Components";
import { INodeRules } from "../types";

export class JoinNodeType extends NodeType {
    public readonly nodeType: string = NodeTypes.JOIN;
    public override readonly validationRules: INodeRules = { maxInputs: 9999, maxOutputs: 1 }

    public readonly PlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = JoinPlayViewerNode;
    public readonly NodeLibraryListItem: FC = JoinNodeLibraryListItem;
}