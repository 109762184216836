import { StopCircleOutlined } from '@mui/icons-material';
import { FC } from 'react';
import { NodeProps, Position } from 'reactflow';
import { StepDto, StepExecutionDto } from '../../../../../dtos';
import { PlayViewerNode } from '../../Components/PlayViewerNode';
import { NodeColorPalette } from '../../../Utils';
import { STOP_NODE_DESCRIPTION } from '../../utils';

export const StopPlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = (props) => {
    const customColorPallet: NodeColorPalette = {
        background: '#D42F2F',
        textColor: 'white',
    };

    return (
        <PlayViewerNode
            {...props}
            icon={<StopCircleOutlined fontSize='large' />}
            description={STOP_NODE_DESCRIPTION}
            handles={[{ type: 'target', position: Position.Left, id: 'l' }]}
            activeColorPallet={customColorPallet}
            disableEdit
            disableViewExecutionDetails
        />
    );
};
