import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ApiError, LoadingIndicator } from '../../Components/CoreLib/library';
import { useGetPlayExecutionByPlayIdStepByStepIdQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';
import { CompleteStepForm } from './Components';

export const CompleteStepView: FC = () => {
    const {playExecutionId, stepExecutionId} = useParams();
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    const { data: stepExecution, isLoading: isLoadingStepExecution, isError: isErrorGettingStepExecution, refetch: refetchStepExecution } = useGetPlayExecutionByPlayIdStepByStepIdQuery({
        playId: playExecutionId ?? emptyGuid,
        stepId: stepExecutionId ?? emptyGuid,
    });

    const triggerLogin = useCallback(() => {
        localStorage.setItem('redirectUrl', window.location.pathname);
        loginWithRedirect();
    }, [loginWithRedirect]);

    if (isLoadingStepExecution) {
        return <LoadingIndicator />
    }

    if (isErrorGettingStepExecution && !isAuthenticated) {
        return (
            <Box display='flex' flexDirection='column' alignItems='center' pt={2} textAlign='center' rowGap={2}>
                <Typography variant='h2'>Unable To Load Step</Typography>
                <Typography maxWidth='500px'>It looks like an error occurred while attempting to load the step details. You may need to login in order to view this step.</Typography>
                <Box>
                    <Button variant='outlined' onClick={refetchStepExecution}>Reload</Button>
                    <Divider sx={{ my: 1 }} />
                    <Button color='primary' variant='contained' onClick={triggerLogin}>Login</Button>
                </Box>
            </Box>
        );
    }

    if (isErrorGettingStepExecution || !stepExecution) {
        return <ApiError onReloadClick={refetchStepExecution} />;
    }

    return <CompleteStepForm stepExecution={stepExecution} />;
}