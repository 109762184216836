import { Button, Grid } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { ClientJobFunctionDto, UserDto } from '../../../dtos';
import { useGetUserQuery } from '../../../store/generated/generatedApi';
import { DataTableColumn, FormInput, PaginatedProps, StandardListViewV2 } from '../../CoreLib/library';
import { useJobFunctionForm } from './useJobFunctionForm';

const DEFAULT_PAGINATION_PROPS: PaginatedProps = {
    sortKey: 'FIRST_NAME',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

const TABLE_COLUMNS: DataTableColumn<UserDto>[] = [
    { key: 'firstName', label: 'First Name', sortKey: 'FIRST_NAME' },
    { key: 'lastName', label: 'Last Name', sortKey: 'LAST_NAME' },
    { key: 'role', label: 'User Role', sortKey: 'ROLE' },
    { key: 'email', label: 'Email', sortKey: 'EMAIL' },
    { key: 'phoneNumber', label: 'Phone', sortKey: 'PHONE_NUMBER' },
];

export interface IQuickJobFunctionFormProps {
    save: (newClientJobFunction: ClientJobFunctionDto, selectedUsers: UserDto[]) => void;
    cancel: () => void;
    initValues?: ClientJobFunctionDto;
    isLoading?: boolean;
}

export const QuickJobFunctionForm: FC<IQuickJobFunctionFormProps> = (props) => {
    const { isLoading } = props;
    const [isSaveDisabled, setSaveDisabled] = useState(false);
    const {
        handleSave,
        handleCancel,
        fieldErrors,
        isFormDirty,
        formJobFunction,
        handleTextFieldChange,
        setUsersToAdd
    } = useJobFunctionForm(props);

    const handleSaveLocal = useCallback(() => {
        setSaveDisabled(true);
        handleSave();
        setSaveDisabled(false);
    }, [handleSave]);


    return (
        <Grid container direction='column' spacing={3}>
            <Grid item mx={2} mt={3}>
                <Grid item container direction='row' spacing={3}>
                    <Grid item xs={4}>
                        <FormInput
                            fullWidth
                            required
                            disabled={isLoading}
                            label='Job Function Name'
                            value={formJobFunction.name}
                            name='name'
                            onChange={handleTextFieldChange}
                            errorText={fieldErrors.get('name')}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <FormInput
                            fullWidth
                            disabled={isLoading}
                            label='Description'
                            value={formJobFunction.description}
                            name='description'
                            onChange={handleTextFieldChange}
                            errorText={fieldErrors.get('description')}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <StandardListViewV2
                    headerIcon={<></>}
                    headerTitle=''
                    breadCrumbs={[]}
                    defaultPaginationProps={DEFAULT_PAGINATION_PROPS}
                    getDataQuery={useGetUserQuery}
                    tableColumns={TABLE_COLUMNS}
                    entityNameSingular='user'
                    permissionName='users'
                    isShowActiveToggleVisible={true}
                    onSelectedChange={setUsersToAdd}
                    hideMenu
                />
            </Grid>
            <Grid item container justifyContent='flex-end' mb={2}>
                <Grid item xs={12} sm={1} mr={4}>
                    <Button
                        sx={{ ml: 'auto' }}
                        variant='outlined'
                        color='secondary'
                        size='medium'
                        fullWidth
                        onClick={handleCancel}
                    >
                        {isFormDirty ? 'Cancel' : 'Close'}
                    </Button>
                </Grid>
                <Grid item xs={12} sm={1} mr={6}>
                    <Button
                        disableElevation
                        sx={{ ml: 'auto' }}
                        variant='contained'
                        color='secondary'
                        size='medium'
                        fullWidth
                        onClick={handleSaveLocal}
                        disabled={isSaveDisabled || isLoading}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
