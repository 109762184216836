import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { StepEmbedInputDto } from '../../../dtos';

export interface IEmbeddingsDataViewProps {
    embeddingInputs: StepEmbedInputDto[] | undefined;
    showTitle?: boolean;
}

export const EmbeddingsDataView: FC<IEmbeddingsDataViewProps> = ({ embeddingInputs, showTitle = true }) => {
    if (!embeddingInputs) {
        return <></>;
    }

    return (
        <>
            {embeddingInputs.length > 0 && (
                <>
                    {showTitle && (
                        <Grid item container direction='row'>
                            <Grid item>
                                <Typography variant='h2'>Embeddings</Typography>
                            </Grid>
                        </Grid>
                    )}

                    <Grid item container direction='row' width='90%'>
                        {embeddingInputs.map((embedInput, idx) => (
                            <Grid item xs={12} key={`embed-link-${idx}`}>
                                <div dangerouslySetInnerHTML={{ __html: embedInput.embedLink ?? '' }} />
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </>
    );
};
