import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserDto } from '../../dtos';
import { ApiError, LoadingIndicator, StandardCardWithHeader, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { useGetUserByIdQuery, useUpdateUserMutation } from '../../store/generated/generatedApi';
import { UserForm } from '../../Components/Forms/UserForm';
import { Box, Grid } from '@mui/material';
import { Plumbing } from '@mui/icons-material';
import { UserJobFunctionsListView } from './Components/UserJobFunctionsListView';

export const EditUserView: FC = () => {
    const { id: userId } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useGetUserByIdQuery({ id: userId! });
    const [updateUser, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate, error: updateError, isLoading: isUpdating }] = useUpdateUserMutation();

    useSuccessfulUpdateSnackbar('User', isUpdateSuccess, resetUpdate);
    useFailedUpdateSnackbar('user', isUpdateError, resetUpdate, updateError);

    const handleSave = (values: UserDto) => {
        updateUser(values);
    };

    const handleCancel = () => {
        navigate('/users');
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error || !userId || !data) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column'>
            <UserForm save={handleSave} cancel={handleCancel} initValues={data} isLoading={isUpdating} />
            <Box px={2} py={2}>
                <StandardCardWithHeader headerTitle='Job Functions' headerIcon={<Plumbing />}>
                    <UserJobFunctionsListView userId={userId} userName={data.displayName ?? ''} />
                </StandardCardWithHeader>
            </Box>
        </Grid>
    );
};
