import { Help, History, MoreVert } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Checkbox, CircularProgress, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DataTableColumn, PaginatedProps, StandardListViewV2, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../Components/CoreLib/library';
import { BaseDto, PlayExecutionDto } from '../../../dtos';
import { useCreatePlayExecutionBulkCancelMutation, useDeletePlayExecutionByIdMutation, useGetPlayExecutionQuery } from '../../../store/generated/generatedApi';
import { formatTimestamp } from '../../../util';
import { CancelPlayModal } from '../../Plays/Components';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'RUN_NUMBER',
    sortAsc: false,
    page: 0,
    pageSize: 25,
};

export interface IRunHistoryList {
    playId: string;
    onRunClicked: (run: string) => void;
}

export const RunHistoryList: FC<IRunHistoryList> = (props) => {
    const { playId, onRunClicked } = props;
    const [searchParams] = useSearchParams();
    const runIdFromUrl = searchParams.get('runId');
    const [selectedRunId, setSelectedRunId] = useState('');
    const [isCancelConfirmationVisible, setIsCancelConfirmationVisible] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [cancelPlay, { isError: isCancelPlayError, isSuccess: isCancelPlaySuccess, isLoading: isCancelingPlaybook, reset: resetCancelPlay }] =
        useDeletePlayExecutionByIdMutation();
    const [bulkCancel, { isLoading: isBulkCancelLoading, isSuccess: isBulkCancelSuccessful, isError: isBulkCancelUnsuccessful }] =
        useCreatePlayExecutionBulkCancelMutation();

    useSuccessfulActionSnackbar('cancelled', 'play', isCancelPlaySuccess, () => {
        resetCancelPlay();
    });
    useFailedActionSnackbar('cancel', 'play', isCancelPlayError, resetCancelPlay);

    useSuccessfulActionSnackbar('cancelled', 'play(s)', isBulkCancelSuccessful);
    useFailedActionSnackbar('cancel', 'play(s)', isBulkCancelUnsuccessful);

    const isLoading = useMemo(() => {
        return isCancelingPlaybook;
    }, [isCancelingPlaybook]);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    };

    const canNotBeCancelled = (row: PlayExecutionDto): boolean => {
        return isLoading || row.status === 'Completed' || row.status === 'Cancelled';
    };

    const handleCancel = () => {
        setIsCancelConfirmationVisible(true);
    };

    const handleConfirm = () => {
        setIsCancelConfirmationVisible(false);
        cancelPlay({ id: selectedRunId });
    };

    const handleBack = () => {
        setIsCancelConfirmationVisible(false);
    };

    const rowMenu = (row: PlayExecutionDto) => {
        return (
            <>
                <IconButton onClick={handleMenuOpen} disabled={row.status === 'Complete' || row.status === 'Cancelled'}>
                    <MoreVert fontSize='inherit' />
                </IconButton>
                <Menu
                    anchorEl={menuAnchorEl}
                    open={(menuAnchorEl && selectedRunId === row.id) ?? false}
                    onClose={handleMenuClose()}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                    <MenuItem onClick={handleMenuClose(handleCancel)} disabled={canNotBeCancelled(row)}>
                        Cancel Run
                    </MenuItem>
                </Menu>
            </>
        );
    };
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    const handleCheckboxChange = (id: string) => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter((x) => x !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };

    const CheckBoxFieldMapper = (row: PlayExecutionDto) =>
        isBulkCancelLoading && selectedRows.includes(row.id) ? (
            <CircularProgress size={24} color='primary' />
        ) : (
            <Checkbox
                checked={selectedRows.includes(row.id)}
                onClick={() => {
                    handleCheckboxChange(row.id);
                }}
                disabled={row.status === 'Complete' || row.status === 'Cancelled'}
                style={{ padding: 0 }}
            />
        );

    const tableColumns: DataTableColumn<PlayExecutionDto>[] = [
        { label: '', key: 'checkbox', sortKey: '', fieldMapper: (row) => CheckBoxFieldMapper(row) },
        { label: 'Run #', key: 'runNumber', sortKey: 'RUN_NUMBER' },
        { label: 'Start Time', key: 'createdOn', sortKey: 'STARTED_ON', fieldMapper: (row) => (row.startedOn ? formatTimestamp(row.startedOn) : '') },
        { label: 'Finish Time', key: 'completedOn', sortKey: 'COMPLETED_ON', fieldMapper: (row) => (row.completedOn ? formatTimestamp(row.completedOn) : '') },
        { label: 'Status', key: 'status', sortKey: 'STATUS' },
        { label: '', key: '', sortKey: '', fieldMapper: (row) => rowMenu(row) },
    ];

    const handleRowClick = useCallback(
        (runId: string) => {
            setSelectedRunId(runId);
            onRunClicked(runId);
        },
        [onRunClicked]
    );

    useEffect(() => {
        setSelectedRunId(runIdFromUrl ? runIdFromUrl : '');
    }, [runIdFromUrl]);

    const customRowStyling = useCallback(
        (row: BaseDto) => {
            if (row.id === selectedRunId) {
                return {
                    background: 'rgba(85,136,199,.1)',
                };
            }
            return {};
        },
        [selectedRunId]
    );

    const handleBatchDelete = async () => {
        await bulkCancel(selectedRows);
        setSelectedRows([]);
    };

    return (
        <div className='table-container' style={{ overflowX: 'auto', width: '100%', position: 'relative' }}>
            <StandardListViewV2
                headerIcon={<History />}
                headerTitle='Run History'
                headerColor='primary'
                defaultPaginationProps={defaultPaginationProps}
                getDataQuery={useGetPlayExecutionQuery}
                tableColumns={tableColumns}
                additionalQueryParameters={{
                    PlayId: playId,
                }}
                endTitleSlot={
                    <>
                        <Tooltip title="Here's a list of all the times you've run this play. Click on a specific run, then a step to see data about that step.">
                            <IconButton color='inherit' disableRipple={true}>
                                <Help />
                            </IconButton>
                        </Tooltip>
                        <div style={{ position: 'absolute', right: '5px', top: '0px' }}>
                            <Tooltip title={selectedRows.length === 0 ? 'Select Runs to Cancel' : 'Cancel Selected Runs'}>
                                <span>
                                    <IconButton onClick={handleBatchDelete} disabled={selectedRows.length === 0} sx={{ color: 'white' }}>
                                        <CancelIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </div>
                    </>
                }
                entityNameSingular={'Play Execution'}
                permissionName={'playExecutions'}
                hideSearch
                hideMenu
                disableGutters
                disableSelection
                disableRowPerPageSelection
                handleRowClick={handleRowClick}
                customRowStyling={customRowStyling}
            />

            <CancelPlayModal isOpen={isCancelConfirmationVisible} cancel={handleBack} confirm={handleConfirm} />
        </div>
    );
};
