import { FC, useCallback } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../../dtos";
import { PlayViewerNode } from "../../Components/PlayViewerNode";
import { NotificationsActive } from "@mui/icons-material";
import { NOTIFICATION_NODE_DESCRIPTION } from "../../utils";

export const NotificationPlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = (props) => {
    const isStepValid = useCallback((step: StepDto) => {
        const isNotificationTypeSpecified = step.notificationType !== undefined;
        return isNotificationTypeSpecified;
    }, []);
    
    return <PlayViewerNode {...props} icon={<NotificationsActive fontSize='large' />} description={NOTIFICATION_NODE_DESCRIPTION} additionalStepValidation={isStepValid} />;
}