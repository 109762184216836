import { Delete } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { FC } from 'react';
import { AuthenticatedComponent } from '../../../../Components/CoreLib/library';

export interface IModalSaveCloseFooter {
    isFormDirty: boolean;
    onCancelCloseClicked: () => void;
    onSaveClicked: () => void;
    onDeleteClicked?: () => void;
    hideDeleteButton?: boolean;
    isSaveDisabled?: boolean;
    deletePermissions?: string[];
}

export const ModalSaveCloseFooter: FC<IModalSaveCloseFooter> = (props) => {
    const { onCancelCloseClicked, onSaveClicked, isFormDirty, onDeleteClicked, hideDeleteButton, isSaveDisabled = false, deletePermissions = [] } = props;

    return (
        <Box width='100%' display='flex' justifyContent='end' gap={2} py={1} sx={{ background: 'white' }} zIndex={5}>
            {onDeleteClicked && !hideDeleteButton && (
                <AuthenticatedComponent requiredPermissions={deletePermissions}>
                    <Box marginRight='auto'>
                        <Tooltip title='Delete'>
                            <IconButton onClick={onDeleteClicked}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </AuthenticatedComponent>
            )}
            <Button variant='outlined' color='primary' size='small' onClick={onCancelCloseClicked} sx={{ width: 90 }}>
                {isFormDirty ? 'Cancel' : 'Close'}
            </Button>
            <AuthenticatedComponent requiredPermissions={[`delete:steps`]}>
                <Button variant='contained' color='primary' size='small' onClick={onSaveClicked} sx={{ width: 90 }} disabled={isSaveDisabled}>
                    Save
                </Button>
            </AuthenticatedComponent>
        </Box>
    );
};
