import { Comparison } from "../../../dtos";

export function GetComparisonName(con: Comparison) {
    switch (con) {
        case Comparison.EQUALS:
            return 'Equals';
        case Comparison.DOES_NOT_EQUAL:
            return 'Doesn\'t Equal';
        case Comparison.CONTAINS:
            return 'Contains';
        case Comparison.DOES_NOT_CONTAIN:
            return 'Doesn\'t Contain';
        case Comparison.GREATER_THAN:
            return 'Greater than';
        case Comparison.LESS_THAN:
            return 'Less than';
        case Comparison.GREATER_THAN_OR_EQUAL_TO:
            return 'Greater than or Equal to';
        case Comparison.LESS_THAN_OR_EQUAL_TO:
            return 'Less than or equal to';
        case Comparison.STARTS_WITH:
            return 'Starts with';
        case Comparison.DOES_NOT_START_WITH:
            return 'Doesn\'t start with';
        case Comparison.ENDS_WITH:
            return 'Ends with';
        case Comparison.DOES_NOT_END_WITH:
            return 'Doesn\'t end with';
        case Comparison.EXISTS:
            return 'Exists';
        case Comparison.DOES_NOT_EXIST:
            return 'Doesn\'t Exist'
        case Comparison.IS_TRUE:
            return 'Is True';
        case Comparison.IS_FALSE:
            return 'Is False'
        default:
            return 'Invalid Comparison';
    }
}