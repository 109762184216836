import { FC, useEffect, useState } from 'react';
import { IStepExecutionFilterOptions } from '../Util/useStepExecutionFilters';
import { emptyGuid, formatTimestamp } from '../../../util';
import { PlayExecutionDto, StepDto } from '../../../dtos';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, Typography } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { IEntityAutocomplete } from '../../../Components/CoreLib/library';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useGetPlayByPlayIdStepsQuery, useGetPlayExecutionQuery } from '../../../store/generated/generatedApi';

export interface IStepExecutionFilterDialogProps {
    isVisible: boolean;
    onClose: () => void;
    filterProps: IStepExecutionFilterOptions;
    setFilterProps: (updatedFilters: IStepExecutionFilterOptions) => void;
    playId: string;
}

export const StepExecutionFilterDialog: FC<IStepExecutionFilterDialogProps> = (props) => {
    const { isVisible, onClose, filterProps, setFilterProps, playId } = props;
    const [stepExecutionFilters, setStepExecutionFilters] = useState<IStepExecutionFilterOptions>(filterProps);
    const { data: playExecutions, isLoading: playExecutionsLoading } = useGetPlayExecutionQuery({
        playId: playId,
        searchText: '',
        sortKey: 'RUN_NUMBER',
        page: 0,
        pageSize: 100000,
        sortAsc: true,
        includeInactive: false,
    });
    const { data: steps, isLoading: stepsLoading } = useGetPlayByPlayIdStepsQuery({
        playId: playId,
        searchText: '',
        sortKey: 'NAME',
        page: 0,
        pageSize: 100000,
        sortAsc: true,
        includeInactive: true,
    });

    useEffect(() => {
        if (isVisible) {
            setStepExecutionFilters(filterProps);
        }
    }, [filterProps, isVisible]);

    const isFormClean =
        filterProps.stepKey === stepExecutionFilters.stepKey &&
        filterProps.runId === stepExecutionFilters.runId &&
        filterProps.endedAfter === stepExecutionFilters.endedAfter &&
        filterProps.endedBefore === stepExecutionFilters.endedBefore &&
        filterProps.startedAfter === stepExecutionFilters.startedAfter &&
        filterProps.startedBefore === stepExecutionFilters.startedBefore;

    const handleClose = () => {
        onClose();
    };

    const handleFilter = () => {
        setFilterProps({ ...stepExecutionFilters });
        handleClose();
    };

    const handleClear = () => {
        setStepExecutionFilters({
            stepKey: '',
            step: undefined,
            runId: emptyGuid,
            run: undefined,
            endedAfter: null,
            endedBefore: null,
            startedAfter: null,
            startedBefore: null,
        });
    };

    const handleStepChange = (selectedStep?: StepDto) => {
        if (!selectedStep) {
            setStepExecutionFilters({
                ...stepExecutionFilters,
                stepKey: '',
                step: undefined,
            });
        } else {
            setStepExecutionFilters({
                ...stepExecutionFilters,
                stepKey: selectedStep.key ?? '',
                step: selectedStep,
            });
        }
    };

    const handleRunChange = (selectedRun?: PlayExecutionDto) => {
        if (!selectedRun) {
            setStepExecutionFilters({
                ...stepExecutionFilters,
                runId: emptyGuid,
                run: undefined,
            });
        } else {
            setStepExecutionFilters({
                ...stepExecutionFilters,
                runId: selectedRun.id,
                run: selectedRun,
            });
        }
    };

    const handleStartedAfterChange = (newDate: Date | null) => {
        if (!newDate) {
            setStepExecutionFilters({
                ...stepExecutionFilters,
                startedAfter: null,
            });
        } else {
            setStepExecutionFilters({
                ...stepExecutionFilters,
                startedAfter: newDate,
            });
        }
    };

    const handleStartedBeforeChange = (newDate: Date | null) => {
        if (!newDate) {
            setStepExecutionFilters({
                ...stepExecutionFilters,
                startedBefore: null,
            });
        } else {
            setStepExecutionFilters({
                ...stepExecutionFilters,
                startedBefore: newDate,
            });
        }
    };

    const handleEndedBeforeChange = (newDate: Date | null) => {
        if (!newDate) {
            setStepExecutionFilters({
                ...stepExecutionFilters,
                endedBefore: null,
            });
        } else {
            setStepExecutionFilters({
                ...stepExecutionFilters,
                endedBefore: newDate,
            });
        }
    };

    const handleEndedAfterChange = (newDate: Date | null) => {
        if (!newDate) {
            setStepExecutionFilters({
                ...stepExecutionFilters,
                endedAfter: null,
            });
        } else {
            setStepExecutionFilters({
                ...stepExecutionFilters,
                endedAfter: newDate,
            });
        }
    };

    const formatType = function (type: string) {
        return type === "DEFAULT" ? 'Standard' : 'Automation';
    };

    return (
        <Dialog open={isVisible} onClose={handleClose} fullWidth maxWidth='md' PaperProps={{ sx: { width: '100%' } }}>
            {/* TOD: Replace with ModalTitle or other shared component */}
            <DialogTitle>
                <Typography variant='h2'>
                    {<FilterList />} {'Filter Step Executions'}
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ marginTop: 3 }}>
                <Grid container direction='column' spacing={2}>
                    <Grid item container direction='row' justifyContent={'center'} spacing={5}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Step</FormLabel>
                                <IEntityAutocomplete
                                    options={steps?.pageResults}
                                    value={stepExecutionFilters.step}
                                    getOptionLabel={(option: StepDto) =>
                                        `${option.name} - ${formatType(option.type)}`
                                    }
                                    isLoading={stepsLoading}
                                    onChange={(_e, value) => {handleStepChange(value)}}
                                    inactiveOverrideText='Deleted'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Run</FormLabel>
                                <IEntityAutocomplete
                                    options={playExecutions?.pageResults}
                                    value={stepExecutionFilters.run}
                                    getOptionLabel={(option: PlayExecutionDto) =>
                                        `${option.runNumber} - ${
                                            option.startedOn ? formatTimestamp(option.startedOn) : 'Error loading start time'
                                        }`
                                    }
                                    isLoading={playExecutionsLoading}
                                    onChange={(_e, value) => {handleRunChange(value)}}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' justifyContent={'center'} spacing={5}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Started Before</FormLabel>
                                <DateTimePicker value={stepExecutionFilters.startedBefore} onChange={(value) => handleStartedBeforeChange(value)} timezone='system' />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Started After</FormLabel>
                                <DateTimePicker value={stepExecutionFilters.startedAfter} onChange={(value) => handleStartedAfterChange(value)} timezone='system' />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' justifyContent={'center'} spacing={5}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Ended Before</FormLabel>
                                <DateTimePicker value={stepExecutionFilters.endedBefore} onChange={(value) => handleEndedBeforeChange(value)} timezone='system' />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Ended After</FormLabel>
                                <DateTimePicker value={stepExecutionFilters.endedAfter} onChange={(value) => handleEndedAfterChange(value)} timezone='system' />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent={'space-between'}>
                    <Grid item>
                        <Button onClick={handleClear} size='large'>
                            Clear All
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={handleClose}>
                            {isFormClean ? 'Close' : 'Cancel'}
                        </Button>
                        <Button variant='contained' size='medium' onClick={handleFilter} sx={{ color: 'error', backgroundColor: 'primary' }}>
                            Filter
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
