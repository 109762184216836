import { StepDataType, StepExecutionDataDto } from "../dtos";
import { format, isValid } from "date-fns";

export const formatTimestamp = (unformattedTime?: Date | string, stepData?: StepExecutionDataDto): string => {
    if (stepData) {
        return handleUserInputtedValues(stepData);
    } else if (unformattedTime) {
        var localDateString = new Date(unformattedTime+'Z').toLocaleString();
        return formatTimestampForTimeZone(localDateString);
    } else {
        return '';
    }
}

const handleUserInputtedValues = (stepData: StepExecutionDataDto): string => {
    if (stepData.value && stepData?.dataType === StepDataType.DATE && isValid(new Date(stepData.value))) {
        var reconstructedDateString = new Date(stepData.value).toLocaleString();
        return formatTimestampForTimeZone(reconstructedDateString);
    } else {
        return stepData.value;
    }
}

const formatTimestampForTimeZone = (timeStr: string): string => {
    return format(new Date(timeStr), 'M/dd/yyyy, h:mm a');
};
