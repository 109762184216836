import { Box, Grid } from '@mui/material';
import { FC, useContext } from 'react';
import { MatchTypeSelect } from '../../../../../Components/CommonInputs';
import { emptyGuid } from '../../../../../util';
import { EditStepConditionsInput, NameInput, WYSIWYGDescription } from '../../../Components/Modals/EditStepModalForms/StepFormParts';
import { StepEditorContext } from '../../../Utils/StepEditorContext';
import { IStepEditModalProps } from '../../types';
import { MatchType } from '../../../../../dtos';

export const ConditionalNodeEditForm: FC<IStepEditModalProps> = ({
    step,
    collectedData
}) => {
    const {
        formStep,
        fieldErrors,
        handleNameChange,
        handleMatchTypeChange,
        handleStepConditionsChange,
        handleDescriptionChange
    } = useContext(StepEditorContext);
    
    return (
        <Box sx={{ height: '100%', width: '100%' }} px={4} pt={3}>
            <Grid item container direction='row' spacing={2} mb={2}>
                <Grid item container xs={6} sm={8} md={10}>
                    <NameInput fieldErrors={fieldErrors} name={formStep.name ?? ''} handleNameChange={handleNameChange} dataOptions={collectedData} />
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <MatchTypeSelect
                        errorMessage={fieldErrors.get('matchType')}
                        selectedMatchType={formStep.matchType ?? MatchType.ALL}
                        handleMatchTypeChange={handleMatchTypeChange}
                    />
                </Grid>
            </Grid>
            <div style={{paddingBottom : 8}}>
                <WYSIWYGDescription
                    fieldErrors={fieldErrors}
                    description={step?.description ?? ''}
                    handleDescriptionChange={handleDescriptionChange}
                    collectedData={collectedData}
                />
            </div>
            <EditStepConditionsInput
                stepId={step?.id ?? emptyGuid}
                matchType={formStep.matchType ?? MatchType.ALL}
                stepData={collectedData ?? []}
                stepConditions={formStep.stepConditions ?? []}
                updateStepConditions={handleStepConditionsChange}
            />
        </Box>
    );
}