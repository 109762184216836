import { ChangeEvent } from 'react';
import { IFormFieldValidationConfig, IFormProps, isNotBlank, isShorterThanMaxLength, useStandardValidatedFormManager } from '../../../Components/CoreLib/library';
import { PlaybookDto } from '../../../dtos';
import { emptyGuid } from '../../../util';

export interface IPlaybookFormValues {
    name: string;
    initials: string;
    description: string;
}

export const DEFAULT_PLAYBOOK: PlaybookDto = {
    id: emptyGuid,
    name: '',
    initials: '',
    description: '',
    key: '',
    activePlays: 0,
    clientId: emptyGuid,
    plays: [],
    isActive: true,
    createdOn: new Date()
};

const PLAYBOOK_VALIDATION_CONFIG = new Map<keyof IPlaybookFormValues, IFormFieldValidationConfig>([
    [
        'name',
        {
            validators: [isNotBlank, isShorterThanMaxLength(30)],
            errorMessageEntityName: 'Name',
        },
    ],
    [
        'initials',
        {
            validators: [isNotBlank, isShorterThanMaxLength(2)],
            errorMessageEntityName: 'Initials',
        },
    ],
    [
        'description',
        {
            validators: [isShorterThanMaxLength(500)],
            errorMessageEntityName: 'Description',
        },
    ],
]);

export function usePlaybookForm({ save, cancel, initValues = DEFAULT_PLAYBOOK }: IFormProps<PlaybookDto>) {

    const {
        formRecord: formPlaybook,
        setFormRecord: setFormPlaybook,
        fieldErrors,
        validateForm,
        handleTextFieldChange,
        isFormDirty,
        validateField
    } = useStandardValidatedFormManager(initValues, PLAYBOOK_VALIDATION_CONFIG);

    const handleInitialsChange = (event: ChangeEvent<HTMLInputElement>) => {
        const updatedInitials = event.target.value.toUpperCase().substring(0, 2);
        setFormPlaybook(prev => ({ ...prev, initials: updatedInitials}));
        validateField('initials', updatedInitials);
    };

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            save(formPlaybook);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleInitialsChange,
        formPlaybook,
        handleTextFieldChange
    };
}
