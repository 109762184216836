import { RemoveCircle } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { PlayVariableDto } from '../../../../dtos/generated/PlayVariableDto';
import { PlayEditorContext } from '../../Utils/PlayEditorContext';
import { PlayVariableInputRow } from './PlayVariableInputRow';

export interface IPlayVariablePanelRowProps {
    index: number;
    playVariable: PlayVariableDto;
    updateVariable: (stepEntryIndex: number, value: PlayVariableDto) => void;
    deleteVariable: (stepEntryIndex: number) => void;
}

export const PlayVariablePanelRow: FC<IPlayVariablePanelRowProps> = ({ index, playVariable, updateVariable, deleteVariable }) => {
    const { playVariableUseMap, steps } = useContext(PlayEditorContext);

    const stepsUsingThisVariable = useMemo(() => {
        var stepsUsingThis = playVariableUseMap.get(playVariable.name);
        return stepsUsingThis ?? [];
    }, [playVariableUseMap, playVariable.name]);

    const localUpdateVariable = useCallback((value: PlayVariableDto) => {
        return updateVariable(index, value);
    }, [index, updateVariable]);

    return (
        <Grid item xs={12} container direction='row' wrap='nowrap' alignItems='center' gap={1} pt={1}>
            <Grid item container direction='row' flexGrow={1} gap={1}>
                <PlayVariableInputRow playVariable={playVariable} updateVariable={localUpdateVariable} currentIndex={index} />
            </Grid>
            <Grid item pt={2} pr={1}>
                <Tooltip
                    title={
                        stepsUsingThisVariable.length > 0
                            ? `This data label cannot be removed because it is currently used in the following step(s): ${stepsUsingThisVariable.map(stepKey => steps.find(s => s.key === stepKey)?.name ?? '').join(', ')}`
                            : ''
                    }>
                    <span>
                        <IconButton color='error' onClick={() => deleteVariable(index)} disabled={stepsUsingThisVariable.length > 0}>
                            <RemoveCircle />
                        </IconButton>
                    </span>
                </Tooltip>
            </Grid>
        </Grid>
    );
};
