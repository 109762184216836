import { Edge } from "reactflow";

export class DependentNodeKeyFinder {
    private readonly graphEdges: Edge[];

    constructor(graphEdges: Edge[]) {
        this.graphEdges = graphEdges;
    }

    public getDependentNodeKeysForKey(nodeKey: string) {
        return this.getDependentNodeKeys(nodeKey);
    }

    private getDependentNodeKeys(nodeKey: string, collectedKeys?: string[]) {
        var dependentKeys = collectedKeys ?? [];
        var dependentEdges = this.graphEdges.filter((edge) => edge.target === nodeKey);
        dependentEdges.forEach((de) => {
            if (!dependentKeys.includes(de.source)) {
                dependentKeys.push(de.source);
            }
        });
        var unexploredNodeKeys = dependentEdges.map((de) => de.source);
        unexploredNodeKeys.forEach((unexploredNodeKey) => {
            var unexploredNodeDependencies = this.getDependentNodeKeys(unexploredNodeKey, dependentKeys);
            unexploredNodeDependencies.forEach((key) => {
                if (!dependentKeys.includes(key)) {
                    dependentKeys.push(key);
                }
            });
        });
        return dependentKeys;
    }
}