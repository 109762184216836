import { useCallback, useState } from "react";
import { emptyGuid, formatTimestamp } from "../../../util";
import { IFilterChip } from "../../../Components/CoreLib/library";
import { PlayExecutionDto, StepDto } from "../../../dtos";
import { format } from "date-fns";

export interface IStepExecutionFilterOptions {
    stepKey: string,
    step?: StepDto,
    runId: string,
    run?: PlayExecutionDto,
    startedBefore: Date | null,
    startedAfter: Date | null,
    endedBefore: Date | null,
    endedAfter: Date | null
}

export const CHIP_KEYS = {
    step: 'Step',
    run: 'Run',
    startedBefore: 'Started Before',
    startedAfter: 'Started After',
    endedBefore: 'Ended Before',
    endedAfter: 'Ended After'
};

export function useStepExecutionFilters() {
    const [stepExecutionFilters, setStepExecutionFilters] = useState<IStepExecutionFilterOptions>({
        stepKey: '',
        runId: emptyGuid,
        startedBefore: null,
        startedAfter: null,
        endedBefore: null,
        endedAfter: null
    });

    const handleSearchTermFilterChange = (updatedFilters: IStepExecutionFilterOptions) => {
        setStepExecutionFilters(updatedFilters);
    };

    const formatType = function (type: string) {
        return type === "DEFAULT" ? 'Standard' : 'Automation';
    };

    const getFilterChips = useCallback(() => {
        const filterChips: IFilterChip[] = [];
        if (stepExecutionFilters.stepKey !== '' && stepExecutionFilters.step) {
            filterChips.push({
                key: CHIP_KEYS.step,
                text: `${stepExecutionFilters.step.name} - ${formatType(stepExecutionFilters.step.type)}`
            })
        }
        if (stepExecutionFilters.runId !== emptyGuid && stepExecutionFilters.run && stepExecutionFilters.run.startedOn) {
            filterChips.push({
                key: CHIP_KEYS.run,
                text: `${stepExecutionFilters.run.runNumber} - ${formatTimestamp(stepExecutionFilters.run.startedOn)}`
            })
        }
        if (stepExecutionFilters.startedBefore) {
            filterChips.push({
                key: CHIP_KEYS.startedBefore,
                text: format(new Date(stepExecutionFilters.startedBefore.toString()), 'MM/dd/yyyy, h:mm a')
            })
        }
        if (stepExecutionFilters.startedAfter) {
            filterChips.push({
                key: CHIP_KEYS.startedAfter,
                text: format(new Date(stepExecutionFilters.startedAfter.toString()), 'MM/dd/yyyy, h:mm a')
            })
        }
        if (stepExecutionFilters.endedBefore) {
            filterChips.push({
                key: CHIP_KEYS.endedBefore,
                text: format(new Date(stepExecutionFilters.endedBefore.toString()), 'MM/dd/yyyy, h:mm a')
            })
        }
        if (stepExecutionFilters.endedAfter) {
            filterChips.push({
                key: CHIP_KEYS.endedAfter,
                text: format(new Date(stepExecutionFilters.endedAfter.toString()), 'MM/dd/yyyy, h:mm a')
            })
        }
        return filterChips;
    }, [stepExecutionFilters]);

    const handleRemoveFilterChip = useCallback((filterChipKey: string) => {
        switch (filterChipKey) {
            case CHIP_KEYS.step:
                setStepExecutionFilters({
                    ...stepExecutionFilters,
                    stepKey: '',
                    step: undefined
                });
                break;
            case CHIP_KEYS.run:
                setStepExecutionFilters({
                    ...stepExecutionFilters,
                    runId: emptyGuid,
                    run: undefined
                });
                break;
            case CHIP_KEYS.startedAfter:
                setStepExecutionFilters({
                    ...stepExecutionFilters,
                    startedAfter: null
                })
                break;
            case CHIP_KEYS.startedBefore:
                setStepExecutionFilters({
                    ...stepExecutionFilters,
                    startedBefore: null
                })
                break;
            case CHIP_KEYS.endedAfter:
                setStepExecutionFilters({
                    ...stepExecutionFilters,
                    endedAfter: null
                })
                break;
            case CHIP_KEYS.endedBefore:
                setStepExecutionFilters({
                    ...stepExecutionFilters,
                    endedBefore: null
                })
                break;
            default:
                console.warn('Invalid filter chip key provided: ', filterChipKey);
                break;
        }
    }, [stepExecutionFilters]);

    return {
        stepExecutionFilters,
        handleSearchTermFilterChange,
        getFilterChips,
        handleRemoveFilterChip
    }
}