import { Article } from '@mui/icons-material';
import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { StandardCardWithHeader } from '../../../Components/CoreLib/library';
import { StepExecutionHistoryAggregateDto } from '../../../dtos';
import { Grid, Typography } from '@mui/material';
import Highcharts, { PointOptionsObject, SeriesPieOptions } from 'highcharts';
import { useGetPlayByPlayIdMetricsStepTimesByUserQuery } from '../../../store/generated/generatedApi';
import PieChart from "highcharts-react-official";
import { formatTime } from '../../../util';

export interface IDistributionOfAverageTimePerStepProps {
    playId: string;
}

export const DistributionOfAverageTimePerStep: FC<IDistributionOfAverageTimePerStepProps> = props => {
    const { playId } = props;
    const { data: response, isLoading: isLoadingHistory } = useGetPlayByPlayIdMetricsStepTimesByUserQuery({ playId });
    const [showCharts, setShowCharts] = useState(false);
    const [graphRenderKey, setGraphRenderKey] = useState(0);
    const [chartOptions, setChartOptions] = useState<Highcharts.Options>(Highcharts.getOptions());

    const [data, setData] = useState<StepExecutionHistoryAggregateDto[] | undefined>(undefined);

    useEffect(() => {
        if (!response || response.stepExecutionHistory.length === 0) {
            return;
        }

        const copy = [...response.stepExecutionHistory];
        copy.sort((a, b) => a.assignedToEmail > b.assignedToEmail ? 1 : a.assignedToEmail === b.assignedToEmail ? 0 : -1);
        setData(copy);
        setShowCharts(true);
    }, [response, setData]);

    const averageTimePerRun = useCallback(() => {
        if (!response || response.stepExecutionHistory.length === 0) {
            return;
        }
        const totalRunTime = response.stepExecutionHistory
            .map(d => d.totalRunTime)
            .reduce((total, current) => total + current);

        return totalRunTime / (response.totalExecutions * 1.0)
    }, [response]);

    const averageTimePerStep = useCallback(() => {
        if (!data) {
            return;
        }

        const count = data.map(d => d.totalCompleted).reduce((total, current) => total + current);
        const minutes = data.map(d => d.totalRunTime).reduce((total, current) => total + current);
        return minutes / (count * 1.0);
    }, [data]);

    const getUserSeriesOptions = useCallback((): SeriesPieOptions => {
        if (!data) {
            return {
                type: 'pie',
            };
        }

        const totalsByUser = new Map<string, StepExecutionHistoryAggregateDto>();
        data.forEach(d => {
            let user = totalsByUser.get(d.assignedToEmail);
            if (!user) {
                user = {
                    stepName: 'aggregate',
                    totalCompleted: 0,
                    totalRunTime: 0,
                    assignedToEmail: d.assignedToEmail
                };
            }
            user.totalCompleted += d.totalCompleted;
            user.totalRunTime += d.totalRunTime;
            totalsByUser.set(d.assignedToEmail, user);
        });

        const userPoints: PointOptionsObject[] = Array.from(totalsByUser.values())
            .map(u => {
                return {
                    name: u.assignedToEmail,
                    y: Math.round(u.totalRunTime / (u.totalCompleted * 1.0) * 100) / 100
                } as PointOptionsObject;
            });

        return {
            type: 'pie',
            name: 'User',
            data: userPoints,
            size: '60%',
            dataLabels: {
                format: `<b>{point.name}:</b> <span>{y} min</span>`,
                distance: -30
            }
        };
    }, [data]);

    const getStepSeriesOptions = useCallback((): SeriesPieOptions => {
        if (!data) {
            return {
                type: 'pie',
            };
        }

        const userSteps: PointOptionsObject[] = data
            .map(u => {
                return {
                    name: u.stepName,
                    y: Math.round((u.totalRunTime / (u.totalCompleted * 1.0)) * 100) / 100
                } as PointOptionsObject;
            });

        return {
            type: 'pie',
            name: 'Step',
            data: userSteps,
            size: '80%',
            innerSize: '60%',
            dataLabels: {
                format: `<b>{point.name}:</b> <span style="opacity: 0.5">{y} min</span>`,
                style: { fontWeight: 'normal' }
            },
            id: 'steps'
        };
    }, [data]);

    useEffect(() => {
        if (!data) {
            return;
        }

        setGraphRenderKey(x => x + 1);
        setChartOptions({
            ...Highcharts.getOptions(),
            series: [
                getUserSeriesOptions(),
                getStepSeriesOptions()
            ]
        });
    }, [data, getStepSeriesOptions, getUserSeriesOptions]);

    useMemo(() => {
        Highcharts.setOptions({
            title: { text: '' },
            plotOptions: {
                pie: {
                    shadow: false,
                    center: ['50%', '50%']
                }
            }
        });
    }, []);

    if (isLoadingHistory) {
        return null;
    }

    return (
        <StandardCardWithHeader headerTitle='Distribution of Average Time per Step' headerIcon={<Article />}>
            {showCharts && (
                <>
                    <Grid item sx={{ m: 3 }}>
                        <PieChart
                            key={`stepExecutionTimes-${graphRenderKey}`}
                            highcharts={Highcharts}
                            options={chartOptions}
                        />
                    </Grid>
                    <Grid item container justifyContent="space-evenly" sx={{ m: 3 }}>
                        <Grid item>
                            <Typography><b>Average Time Per Step: {formatTime(averageTimePerStep(), "long")}</b></Typography>
                        </Grid>
                        <Grid item>
                            <Typography><b>Average Play Execution Time: {formatTime(averageTimePerRun(), "long")}</b></Typography>
                        </Grid>
                    </Grid>
                </>
            )}
            {!showCharts && (
                <Grid item container justifyContent="space-evenly" sx={{ mb: 4, mt: 2 }}>
                    <Typography variant='h6'>
                        Run this play to begin seeing this report.
                    </Typography>
                </Grid>
            )}
        </StandardCardWithHeader>
    );
}