import { Grid, TextField } from '@mui/material';
import { FC } from 'react';
import { StepExecutionConditionDto } from '../../../../../dtos';
import { GetComparisonName, isSingleSidedOperator } from '../../../Utils';

export interface IStepConditionDetailsLineItemProps {
    stepCondition: StepExecutionConditionDto;
}

export const StepConditionDetailsLineItem: FC<IStepConditionDetailsLineItemProps> = ({ stepCondition }) => {
    return (
        <Grid item container xs={12} alignItems='center'>
            <Grid item xs={4}>
                <TextField disabled size='small' value={stepCondition.leftOperand} variant='outlined' />
            </Grid>
            <Grid item xs={4} textAlign='center' px={2}>
                {GetComparisonName(stepCondition.operator)}
            </Grid>
            {!isSingleSidedOperator(stepCondition.operator) && <Grid item xs={4}>
                <TextField disabled size='small' value={stepCondition.rightOperand} variant='outlined' />
            </Grid>}
        </Grid>
    );
}