import React from 'react';
import {
  CheckCircleOutline,
  PlayCircleOutline,
  CancelOutlined,
  NotStartedOutlined,
  NotificationsPaused,
  Error,
  BlockOutlined,
} from '@mui/icons-material';
import { StepExecutionDto } from '../../../dtos';
import theme from '../../../Theme';
import { Box } from '@mui/material';

export interface IBadgeProps {
  color: 'default' | 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined;
  badgeContent: React.ReactNode | undefined;
  invisible: boolean;
}

export function getStepExecutionBadgeProps(stepExecution: StepExecutionDto): IBadgeProps {
  if (stepExecution.status?.toUpperCase() === 'FAILED') {
    return getStatusBadge(<CancelOutlined color='error' sx={{ background: 'white', borderRadius: 3 }} />);
  }

  if (stepExecution.status?.toUpperCase() === 'SKIPPED') {
    return getStatusBadge(
      <BlockOutlined color='disabled' sx={{ background: 'white', borderRadius: 3 }} />
    );
  }

  if (stepExecution.isComplete) {
    return getStatusBadge(<CheckCircleOutline color='success' sx={{ background: 'white', borderRadius: 3 }} />);
  }

  if (stepExecution.snoozeUntil && new Date(stepExecution.snoozeUntil + 'Z') > new Date()) {
    return getStatusBadge(
      <NotificationsPaused style={{
        height: '20px',
        width: '20px',
        background: 'white',
        borderRadius: '50%',
        fill: theme.palette.secondary.main,
        border: `2px solid ${theme.palette.secondary.main}`,
      }} />,
      true // Add a border
    );
  }

  if (stepExecution.status?.toUpperCase() === 'CANCELLED') {
    return getStatusBadge(<CancelOutlined color='disabled' sx={{ background: 'white', borderRadius: 3 }} />);
  }

  if (stepExecution.errorCode) {
    return { color: 'default', badgeContent: <Error color='error' sx={{ background: 'white', borderRadius: 3 }} />, invisible: false };
  }

  if (stepExecution.isStarted) {
    return getStatusBadge(<PlayCircleOutline color='primary' sx={{ background: 'white', borderRadius: 3 }} />);
  }

  var notStartedBadge = getStatusBadge(<NotStartedOutlined sx={{ background: 'white', borderRadius: 3 }} />);
  notStartedBadge.invisible = true;
  return notStartedBadge;
}

function getStatusBadge(content: React.ReactNode, border?: boolean): IBadgeProps {
  return {
    color: 'default',
    badgeContent: border ? (
      <Box width={24} height={24} borderRadius='50%' border='2px solid white' overflow='hidden'>
        {content}
      </Box>
    ) : (
      content
    ),
    invisible: false,
  };
}

