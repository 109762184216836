import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { isNotBlank, isValidEmail } from '../../../../Components/CoreLib/library';
import { PlayDto, PlayExecutionDataInputDto, PlayStartInputDto, StartPlayDto } from '../../../../dtos';
import { emptyGuid } from '../../../../util';
import { useStepDataValidator } from '../../Hooks';

export interface IUseStartPlayFormProps {
    save: (values: StartPlayDto) => void;
    cancel: () => void;
    selectedPlayDetails?: ISelectedPlayDetails;
}

export interface ISelectedPlayDetails {
    play: PlayDto;
    requestedData: PlayStartInputDto;
}

// TODO: refactor this to use the 'name' event attribute so we can reduce duplication and simplify the logic here.
export function  useStartPlayForm({ save, cancel, selectedPlayDetails }: IUseStartPlayFormProps) {
    const { isStepDataValid } = useStepDataValidator();
    const [formJobFunctionAssignments, setFormJobFunctionAssignments] = useState<PlayExecutionDataInputDto[]>([]);
    const [formCollectedData, setFormCollectedData] = useState<PlayExecutionDataInputDto[]>([]);

    useEffect(() => {        
        setFormJobFunctionAssignments(selectedPlayDetails?.requestedData.jobFunctions ?? []);
        setFormCollectedData(selectedPlayDetails?.requestedData.startData ?? []);
    }, [selectedPlayDetails?.requestedData]);

    const formJobFunctionAssignmentErrors: string[] = useMemo(() => {
        return formJobFunctionAssignments.map((assignment) => {
            var validationResult = isNotBlank(assignment.value);
            if (!validationResult.isValid) {
                return validationResult.errorMessageBuilder(`${assignment.name} email must be provided`);
            }
            validationResult = isValidEmail(assignment.value);
            if (!validationResult.isValid) {
                return validationResult.errorMessageBuilder(`must be a valid email`);
            }
            return '';
        });
    }, [formJobFunctionAssignments]);

    const isFormDirty = useMemo(() => {
        var isDirty = formJobFunctionAssignments.some((assignment) => assignment.value !== '');
        isDirty = isDirty || formCollectedData.some(x => x.value !== '');
        return isDirty;
    }, [formJobFunctionAssignments, formCollectedData]);

    const handleRoleAssignmentChange = (email: string, index: number) => {
        if (formJobFunctionAssignments.length > index) {
            var updatedClientJobFunctions = _.cloneDeep(formJobFunctionAssignments);
            updatedClientJobFunctions[index].value = email;
            setFormJobFunctionAssignments(updatedClientJobFunctions);
        } else {
            console.error('Invalid Assignment Index Provided');
        }
    };

    const handleCollectedDataChange = (index: number, dataValue: string) => {
        if (formCollectedData.length > index) {
            var updatedCollectedData = _.cloneDeep(formCollectedData);
            updatedCollectedData[index].value = dataValue;
            setFormCollectedData(updatedCollectedData);
        } else {
            console.error('Invalid Assignment Index Provided');
        }
    };

    const handleSave = () => {
        const isFormValid = formJobFunctionAssignmentErrors.every((err) => err === '') && formCollectedData.every(sd => isStepDataValid(sd));
        if (isFormValid) {
            var payload: StartPlayDto = {
                playId: selectedPlayDetails?.play?.id ?? emptyGuid,
                assignedClientJobFunctions: formJobFunctionAssignments,
                collectedData: formCollectedData,
            };
            save(payload);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        handleRoleAssignmentChange,
        formJobFunctionAssignmentErrors,
        formJobFunctionAssignments,
        formCollectedData,
        handleCollectedDataChange
    };
}
