import { ContentBlock, DraftDecoratorComponentProps } from 'draft-js';
import { FC, PropsWithChildren } from 'react';

export const dataReferenceStrategy = (contentBlock: ContentBlock, callback: (start: number, end: number) => void): void => {
    const text = contentBlock.getText();
    const regex = /{{[^{}]*}}/g; // Regular expression for matching {{example}} pattern
    let match;
    while ((match = regex.exec(text)) !== null) {
        const start = match.index;
        const end = start + match[0].length;
        
        callback(start, end);
    }
};

export const DataReferenceSpan: FC<PropsWithChildren<DraftDecoratorComponentProps & { dataReferences: string[] }>> = (props) => {
    const { children, decoratedText, dataReferences } = props;
    let isCollected = dataReferences.includes(decoratedText.substring(2, decoratedText.length - 2));
    
    return <span className={isCollected ? 'collected-data' : 'uncollected-data'}>{children}</span>;
};
