import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { DailyDigestDaysConfigDto, UserNotificationConfigDto } from '../../../dtos';
import {
    IFormProps,
} from '../../../Components/CoreLib/library';

const DEFAULT_USER_SETTINGS: UserNotificationConfigDto = {
    shouldNotifyByEmail: true,
    shouldNotifyBySlackMessage: false,
    dailyDigestDaysConfig: {
        sunday: false,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false
    }
}

export function useUserSettingsForm(props: IFormProps<UserNotificationConfigDto>) {
    const { save, cancel, initValues = DEFAULT_USER_SETTINGS } = props;
    const [formNotificationConfig, setFormNotificationConfig] = useState(initValues);

    // keeps form in sync with the data returned from the API
    useEffect(() => {
        setFormNotificationConfig(initValues);
    }, [initValues]);

    const convertDaysToDailyDigestConfig = useCallback((days: string[]): DailyDigestDaysConfigDto => ({
        sunday: days.includes('sunday'),
        monday: days.includes('monday'),
        tuesday: days.includes('tuesday'),
        wednesday: days.includes('wednesday'),
        thursday: days.includes('thursday'),
        friday: days.includes('friday'),
        saturday: days.includes('saturday')
    }), []);

    const convertDailyDigestConfigToDays = useCallback((config: DailyDigestDaysConfigDto): string[] => {
        var days: string[] = [];
        config.sunday && days.push('sunday');
        config.monday && days.push('monday');
        config.tuesday && days.push('tuesday');
        config.wednesday && days.push('wednesday');
        config.thursday && days.push('thursday');
        config.friday && days.push('friday');
        config.saturday && days.push('saturday');
        return days;
    }, [])

    // form value change handlers
    const handleShouldNotifyByEmailChange = useCallback((_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => setFormNotificationConfig(config => ({ ...config, shouldNotifyByEmail: isChecked })), []);
    const handleShouldNotifyBySlackMessageChange = useCallback((_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => setFormNotificationConfig(config => ({ ...config, shouldNotifyBySlackMessage: isChecked })), []);
    const handleDailyDigestDaysConfigChange = useCallback((days: string[]) => setFormNotificationConfig(config => ({ ...config, dailyDigestDaysConfig: convertDaysToDailyDigestConfig(days) })), [convertDaysToDailyDigestConfig]);

    // computed values
    const isFormDirty = useMemo(() => formNotificationConfig !== initValues, [formNotificationConfig, initValues]);
    const isFormValid = useMemo(() => formNotificationConfig.shouldNotifyByEmail || formNotificationConfig.shouldNotifyBySlackMessage, [formNotificationConfig]);
    const isAtLeastOneNotificationOptionEnabled = useMemo(() => !!formNotificationConfig.shouldNotifyByEmail || !!formNotificationConfig.shouldNotifyBySlackMessage, [formNotificationConfig]);
    const dailyDigestDays = useMemo(() => convertDailyDigestConfigToDays(formNotificationConfig.dailyDigestDaysConfig), [convertDailyDigestConfigToDays, formNotificationConfig]);
    
    // form actions
    const handleSave = useCallback(() => isFormValid && save(formNotificationConfig), [isFormValid, save, formNotificationConfig]);
    const handleCancel = useCallback(cancel, [cancel]);

    return {
        formNotificationConfig,
        isFormDirty,
        handleSave,
        handleCancel,
        handleShouldNotifyByEmailChange,
        handleShouldNotifyBySlackMessageChange,
        handleDailyDigestDaysConfigChange,
        isAtLeastOneNotificationOptionEnabled,
        dailyDigestDays
    };
}
