import { createContext } from 'react';
import { StepDto } from '../../../dtos';
import { DEFAULT_STEP } from './Constants';
import { useStepForm } from './FormHooks';

export const StepEditorContext = createContext<ReturnType<typeof useStepForm>>({
    formStep: DEFAULT_STEP,
    isFormDirty: false,
    handleSave: () => {},
    handleCancel: () => {},
    fieldErrors: new Map<keyof StepDto, string>(),
    handleNameChange: () => {},
    handleExternalRoleNameChange: () => {},
    handleTargetEndpointChange: () => {},
    handleDescriptionChange: () => {},
    handleChatDataCollectionChange: () => {},
    handleIsExternalChange: () => {},
    handleInternalJobFunctionChange: () => {},
    handleStepDataChange: () => {},
    handleMatchTypeChange: () => {},
    handleStepConditionsChange: () => {},
    handleEstimatedExecutionTimeChange: () => {},
    handleShouldNotifyIfElapsedChange: () => {},
    isGeneralValid: false,
    isAssignmentValid: false,
    isDataValid: false,
    isInputValid: false,
    isOutputValid: false,
    isDevelopmentValid: false,
    isEstimationValid: false,
    isDelayValid: false,
    tabIndex: 0,
    handleChangeTabIndex: () => {},
    setTabIndex: () => {},
    handleRequestDataLabelsChange: () => {},
    handleSubplayChange: () => {},
    handleEmbeddingInputsChange: () => {},
    handleSubplayInputChange: () => {},
    handleSubplayOutputChange: () => {},
    handleIsImmediatelyCompletedChange: () => {},
    handleDelayMethodChange: () => {},
    handleDelayMinutesChange: () => {},
    handleDelayDateLabelChange: () => {},
    handleDelete: () => {},
    handleNotificationTypeChange: () => {},
    handleEmailsToNotifyChange: () => {},
    handlePromptChange: () => {},
    handleOutputDataLabelChange: () => {},
    addFileAttachment: () => {},
    removeFileAttachment: () => {},
});
