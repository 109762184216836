import { FC } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../dtos";
import { NodeType } from "../NodeType";
import { NodeTypes } from "../utils";
import { IStepEditModalProps, IStepExecutionDetailsProps } from "../types";
import { SubplayNodeEditForm, SubplayNodeExecutionDetails, SubplayNodeLibraryListItem, SubplayPlayViewerNode } from "./Components";

export class SubplayNodeType extends NodeType {
    public readonly nodeType: string = NodeTypes.SUBPLAY;
    
    public readonly PlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = SubplayPlayViewerNode;
    public readonly EditModalContent?: FC<IStepEditModalProps> = SubplayNodeEditForm;
    public readonly ExecutionDetailsContent?: FC<IStepExecutionDetailsProps> = SubplayNodeExecutionDetails;
    public readonly NodeLibraryListItem: FC = SubplayNodeLibraryListItem;
}