import { ChangeEvent, useCallback, useState } from 'react';
import { ClientJobFunctionDto, UserDto } from '../../../dtos';
import { emptyGuid } from '../../../util';
import { IFormFieldValidationConfig, isNotBlank, isShorterThanMaxLength, useStandardValidatedFormManager } from '../../CoreLib/library';
import { IQuickJobFunctionFormProps } from './QuickJobFunctionForm';

export interface IJobFunctionFormValues {
    name: string;
    description: string;
    isActive: boolean;
}

const DEFAULT_JOB_FUNCTION: ClientJobFunctionDto = {
    id: emptyGuid,
    name: '',
    description: '',
    isActive: true,
    createdOn: new Date(),
    clientId: emptyGuid,
};

const JOB_FUNCTION_VALIDATION_CONFIG = new Map<keyof ClientJobFunctionDto, IFormFieldValidationConfig>([
    [
        'name',
        {
            validators: [isNotBlank, isShorterThanMaxLength(250)],
            errorMessageEntityName: 'Name',
        },
    ],
    [
        'description',
        {
            validators: [isShorterThanMaxLength(4000)],
            errorMessageEntityName: 'Description',
        },
    ],
]);

export function useJobFunctionForm({ save, cancel, initValues = DEFAULT_JOB_FUNCTION }: IQuickJobFunctionFormProps) {
    const [usersToAdd, setUsersToAdd] = useState<UserDto[]>([]);

    const {
        formRecord: formJobFunction,
        setFormRecord: setFormJobFunction,
        fieldErrors,
        validateForm,
        handleTextFieldChange,
        isFormDirty
    } = useStandardValidatedFormManager(initValues, JOB_FUNCTION_VALIDATION_CONFIG);

    const handleIsActiveChange = useCallback((_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => setFormJobFunction(prev => ({ ...prev, isActive: isChecked })), [setFormJobFunction]);

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            save(formJobFunction, usersToAdd);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleTextFieldChange,
        handleIsActiveChange,
        formJobFunction,
        setUsersToAdd
    };
}
