import { Box, FormControlLabel, Grid, IconButton, Switch, Tabs, Tooltip, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import { emptyGuid } from '../../../../../util';
import {
    AssignmentInput,
    EditStepDataInput,
    EmbeddingInput,
    FileAttachmentInput,
    NameInput,
    WYSIWYGDescription,
} from '../../../Components/Modals/EditStepModalForms/StepFormParts';
import { TabWithErrorIndicator } from '../../../Components/Modals/EditStepModalForms/StepFormParts/TabWithErrorIndicator';
import { TimeInput } from '../../../Components/Modals/EditStepModalForms/StepFormParts/TimeInput';
import { StepEditorContext } from '../../../Utils/StepEditorContext';
import { IStepEditModalProps } from '../../types';
import { Help } from '@mui/icons-material';

export const StepNodeEditForm: FC<IStepEditModalProps> = ({ step, collectedData }) => {
    const {
        formStep,
        tabIndex,
        setTabIndex,
        handleChangeTabIndex,
        isGeneralValid,
        isAssignmentValid,
        isDataValid,
        isEstimationValid,
        fieldErrors,
        handleNameChange,
        handleDescriptionChange,
        handleEmbeddingInputsChange,
        handleIsExternalChange,
        handleExternalRoleNameChange,
        handleInternalJobFunctionChange,
        handleStepDataChange,
        handleEstimatedExecutionTimeChange,
        handleShouldNotifyIfElapsedChange,
    } = useContext(StepEditorContext);

    return (
        <Grid container direction='column' height='calc(100%-30px)' mb={5}>
            <Grid zIndex={5} item position='sticky' top={0}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', background: 'white' }} className='draggable-ignored'>
                    <Tabs value={tabIndex} onChange={handleChangeTabIndex} aria-label='edit step tabs' variant='fullWidth'>
                        <TabWithErrorIndicator label='GENERAL' index={0} isValid={isGeneralValid} setValue={setTabIndex} />
                        <TabWithErrorIndicator label='ASSIGNMENT' index={1} isValid={isAssignmentValid} setValue={setTabIndex} />
                        <TabWithErrorIndicator label='DATA' index={2} isValid={isDataValid} setValue={setTabIndex} />
                        <TabWithErrorIndicator label='ESTIMATION' index={3} isValid={isEstimationValid} setValue={setTabIndex} />
                    </Tabs>
                </Box>
            </Grid>
            <Grid item>
                <Box sx={{ height: '100%', width: '100%' }} px={4} pt={3}>
                    {/* The visibility of this section is controlled different because if we render/unrender like the others then the WYSIWYG editor loses it's content when it re-initializes: https://stackoverflow.com/questions/6928883/problem-with-tinymce-editor-when-navigating-through-tabs */}
                    <div style={{ display: tabIndex === 0 ? 'block' : 'none' }}>
                        <NameInput fieldErrors={fieldErrors} name={formStep.name ?? ''} handleNameChange={handleNameChange} dataOptions={collectedData} />
                        <WYSIWYGDescription
                            fieldErrors={fieldErrors}
                            description={step?.description ?? ''}
                            handleDescriptionChange={handleDescriptionChange}
                            collectedData={collectedData}
                        />
                        <EmbeddingInput
                            stepId={step?.id ?? emptyGuid}
                            embeddingInputs={formStep.embedInputs}
                            updateEmbeddingInputs={handleEmbeddingInputsChange}
                        />
                        <FileAttachmentInput stepId={step?.id ?? emptyGuid} stepKey={step.key!} fieldErrors={fieldErrors} />
                    </div>
                    {tabIndex === 1 && (
                        <AssignmentInput
                            fieldErrors={fieldErrors}
                            isExternal={formStep.isExternal}
                            handleIsExternalChange={handleIsExternalChange}
                            externalRoleName={formStep.externalRoleName ?? ''}
                            handleExternalRoleChange={handleExternalRoleNameChange}
                            formInternalJobFunctionId={formStep.internalJobFunctionId ?? ''}
                            handleInternalJobFunctionChange={handleInternalJobFunctionChange}
                            InternalJobFunction={formStep.internalJobFunction}
                        />
                    )}
                    {tabIndex === 2 && (
                        <EditStepDataInput
                            stepId={step?.id ?? emptyGuid}
                            stepData={formStep.stepData}
                            updateStepData={handleStepDataChange}
                            description='What (if any) information should be gathered from the person assigned to this step?'
                        />
                    )}
                    {tabIndex === 3 && (
                        <>
                            <TimeInput
                                label='How long do you estimate this step will take?'
                                error={fieldErrors.get('estimatedExecutionTimeInMinutes')}
                                minutesValue={formStep.estimatedExecutionTimeInMinutes}
                                handleTimeChange={handleEstimatedExecutionTimeChange}
                            />
                            <Grid item pt={2}>
                                <FormControlLabel
                                    sx={{ ml: 0 }}
                                    control={
                                        <Switch
                                            checked={formStep.shouldSendOverdueNotification}
                                            onChange={(_, value) => handleShouldNotifyIfElapsedChange(value)}
                                        />
                                    }
                                    label={
                                        <Typography>
                                            Notify if elapsed{' '}
                                            <Tooltip title='Notify the step assignee and the user that started the play if the step goes over its estimated time.'>
                                                <IconButton>
                                                    <Help />
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>
                                    }
                                    labelPlacement='start'
                                />
                            </Grid>
                        </>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};
