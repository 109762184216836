import { FormControl, Grid } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { FormInput } from '../../../../../../Components/CoreLib/library';
import { StepDto } from '../../../../../../dtos';

export interface IEndpointInputProps {
    fieldErrors: Map<keyof StepDto, string>;
    endpoint: string;
    handleEndpointChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
}

export const EndpointInput: FC<IEndpointInputProps> = (props) => {
    const { fieldErrors, endpoint, handleEndpointChange } = props;

    return (
        <Grid item xs={12} className='non-draggable-element'>
            <FormControl fullWidth error={!!fieldErrors.get('targetEndpoint')}>
                <FormInput
                    value={endpoint}
                    onChange={handleEndpointChange}
                    label='Endpoint'
                    name='endpoint'
                    errorText={fieldErrors.get('targetEndpoint')}
                    fullWidth
                    required
                />
            </FormControl>
        </Grid>
    );
};
