import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC, FocusEventHandler, SyntheticEvent, useMemo } from 'react';
import { UserDto } from '../../dtos';
import { useGetJobFunctionByJobFunctionIdUsersQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';

export interface IJobFunctionUserSelectProps {
    selectedUserId: string;
    jobFunctionId: string;
    handleSelectedUserChange: (selectedUser?: UserDto) => void;
    required?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    onBlur?: FocusEventHandler<HTMLDivElement>;
}

export const JobFunctionUserSelect: FC<IJobFunctionUserSelectProps> = props => {
    const { selectedUserId, jobFunctionId, handleSelectedUserChange, required, errorMessage, disabled, onBlur } = props;
    const { data: users, isLoading: isLoadingUsers, isError: isErrorLoadingUsers } = useGetJobFunctionByJobFunctionIdUsersQuery({
        sortKey: 'FIRST_NAME',
        sortAsc: true,
        page: 0,
        pageSize: 10000,
        includeInactive: false,
        jobFunctionId: jobFunctionId
    });

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: UserDto | null) => {
        handleSelectedUserChange(value ?? undefined);
    }

    const selectedJobFunction = useMemo(() => {
        if (selectedUserId === '' || selectedUserId === emptyGuid) {
            return null;
        }
        return users?.pageResults.find(x => x.id === selectedUserId) ?? null;
    }, [selectedUserId, users])

    return (
        <FormControl error={isErrorLoadingUsers || !!errorMessage} fullWidth required={required}>
            <FormLabel>User</FormLabel>
            <Autocomplete
                value={selectedJobFunction}
                onChange={handleSelectChange}
                loading={isLoadingUsers}
                options={users?.pageResults ?? []}
                getOptionLabel={x => x.displayName ?? ''}
                renderInput={(params) => (
                    <TextField
                        error={!!errorMessage}
                        {...params}
                    />
                )}
                onBlur={onBlur}
                disabled={disabled}
            />
            <FormHelperText>{isErrorLoadingUsers ? 'Failed to load job function options' : errorMessage ?? ''}</FormHelperText>
        </FormControl>
    )
}