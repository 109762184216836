import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

export interface IStepDescriptionDetailsProps {
    description: string;
    sectionTitle?: string;
}

export const StepDescriptionDetails: FC<IStepDescriptionDetailsProps> = ({ description, sectionTitle = 'Description' }) => {

    return (
        <>
            <Grid item container direction='row' mt={2}>
                <Grid item>
                    <Typography variant='h2'>{sectionTitle}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {description ? (
                    <div dangerouslySetInnerHTML={{__html: description}}></div>
                ) : (
                    <Typography fontSize={16}>No Description Provided</Typography>
                )}
            </Grid>
        </>
    )
}