import { DriveFileMove } from '@mui/icons-material';
import { Button, Dialog, DialogContent, FormControl, FormHelperText, FormLabel, Grid, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IEntityAutocomplete, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../../Components/CoreLib/library';
import { PlayDto, PlaybookDto } from '../../../../dtos';
import { useCreatePlayMoveByPlaybookIdMutation, useGetPlaybookQuery } from '../../../../store/generated/generatedApi';
import { useMovePlayForm } from '../../Utils/FormHooks/useMovePlayForm';



export interface IMovePlayModalProps {
    play?: PlayDto;
    clearSelectedPlay: () => void;
}

export const MovePlayModal: FC<IMovePlayModalProps> = props => {
    const { play, clearSelectedPlay } = props;
    const [hasAttemptedSave, setHasAttemptedSave] = useState(false);
    const theme = useTheme();
    const [movePlay, { isSuccess: isMovePlaySuccess, isError: isMovePlayError, isLoading: isMovingPlay, reset: resetMovePlay }] = useCreatePlayMoveByPlaybookIdMutation();
    const { data: playbooks, isLoading: playbooksLoading } = useGetPlaybookQuery({ searchText: '', sortKey: 'NAME', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false, filterOutPlaybookId: play?.playbookId ?? '' });

    useSuccessfulActionSnackbar('moved ', 'play', isMovePlaySuccess, () => {
        resetMovePlay();
        clearSelectedPlay();
    });
    useFailedActionSnackbar('move', 'play', isMovePlayError, resetMovePlay)

    const handleSave = (play: PlayDto, playbookId: string) => {
        if (isMovingPlay) {
            return;
        }
        movePlay({ params: { playbookId: playbookId }, payload: play });
    }

    const {
        fieldErrors,
        formPlaybook,
        handleCancel,
        handlePlaybookChange,
        handleSave: saveForm
    } = useMovePlayForm({
        save: handleSave,
        cancel: clearSelectedPlay,
        selectedPlay: play,
    });

    const handleSaveClicked = () => {
        if (!hasAttemptedSave) {
            setHasAttemptedSave(true);
        }
        saveForm();
    }

    useEffect(() => {
        if (play) {
            setHasAttemptedSave(false);
        }
    }, [play])

    if (!play) {
        return null;
    }

    return (
        <Dialog open={!!play} fullWidth maxWidth='md'>
            <DialogContent sx={{ padding: 0 }}>
                <Grid container direction='column' height='100%'>
                    {/* TOD: Replace with ModalTitle or other shared component */}
                    <Grid
                        item
                        container
                        direction='row'
                        alignItems='center'
                        p={1}
                        sx={{ background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                        position='sticky'
                        top={0}
                        zIndex={2}
                    >
                        <DriveFileMove sx={{ mr: 1 }} />
                        <Typography fontWeight='bold'>Move Play</Typography>
                    </Grid>
                    <Grid item px={2} pt={2} pb={1}>
                        Please select a new playbook:
                    </Grid>
                    <Grid item xs={12} px={2} pb={2}>
                        <FormControl fullWidth required error={!!fieldErrors.get('playbookId')}>
                            <FormLabel>Playbook</FormLabel>
                            <IEntityAutocomplete
                                options={playbooks?.pageResults}
                                onChange={(e, value) => { handlePlaybookChange(value ?? null); }}
                                value={formPlaybook}
                                getOptionLabel={(option: PlaybookDto) => `${option.name}`}
                                isLoading={playbooksLoading}
                                error={!!fieldErrors.get('playbookId')}
                            />
                            <FormHelperText>{fieldErrors.get('playbookId')}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} display='flex' justifyContent='end' gap={2} position='sticky' bottom={0} pb={1} px={2} sx={{ background: 'white' }}>
                        <Button variant='outlined' color='secondary' size='small' onClick={handleCancel} disabled={isMovingPlay} sx={{ width: 90 }}>
                            Back
                        </Button>
                        <Button variant='contained' color='secondary' size='small' onClick={handleSaveClicked} disabled={isMovingPlay} sx={{ width: 90 }}>
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
