import { FC } from "react";
import { Button, Dialog, DialogContent, Grid, Typography, useTheme } from "@mui/material";
import { DoNotDisturbAlt } from "@mui/icons-material";

export interface ICancelPlayModalProps {
    isOpen: boolean;
    cancel: () => void;
    confirm: () => void;
}

export const CancelPlayModal: FC<ICancelPlayModalProps> = props => {
    const { isOpen, cancel, confirm } = props;
    const theme = useTheme();

    return (
        <Dialog open={isOpen} fullWidth maxWidth='sm'>
            <DialogContent sx={{ padding: 0 }}>
                <Grid container direction='column' height='100%'>
                    {/* TOD: Replace with ModalTitle or other shared component */}
                    <Grid
                        item
                        container
                        direction='row'
                        alignItems='center'
                        p={1}
                        sx={{ background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                        position='sticky'
                        top={0}
                        zIndex={2}
                    >
                        <DoNotDisturbAlt sx={{ mr: 1 }} />
                        <Typography fontWeight='bold'>Cancel Run</Typography>
                    </Grid>
                    <Grid item p={2}>
                        <p>Are you sure you want to cancel this run?</p>
                        <p>This action can't be undone</p>
                    </Grid>
                    <Grid item xs={12} display='flex' justifyContent='end' gap={2} position='sticky' bottom={0} pb={1} px={2} sx={{ background: 'white' }}>
                        <Button variant='outlined' color='secondary' size='small' onClick={cancel} sx={{ width: 90 }}>
                            Back
                        </Button>
                        <Button variant='contained' color='secondary' size='small' onClick={confirm} sx={{ width: 90 }}>
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};