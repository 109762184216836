import { Edge, Node } from 'reactflow';
import { StepDependencyDto, StepDto, StepExecutionDto } from "../../../../dtos";
import { emptyGuid } from '../../../../util';
import { applyCustomEdgeStyling } from './useFlowManager';
import { NodeTypes } from '../../NodeTypes/utils';

export function mapDependenciesToEdges(stepDependencies: StepDependencyDto[]): Edge[] {
    return stepDependencies.map(stepDependency => ({
        id: `${stepDependency.stepKey}-${stepDependency.dependentUponStepKey}`,
        source: stepDependency.dependentUponStepKey,
        sourceHandle: stepDependency.customHandleKey ? `r-${stepDependency.customHandleKey}` : 'r',
        target: stepDependency.stepKey,
        targetHandle: 'l'
    })).map(edg => applyCustomEdgeStyling(edg));
}

export function mapEdgesToDependencies(edges: Edge[], originalDependencies: StepDependencyDto[]): StepDependencyDto[] {
    return edges.map(edge => {
        var existingDependencyRecord = originalDependencies.find(dep => dep.stepKey === edge.target && dep.dependentUponStepKey === edge.source);
        return {
            id: existingDependencyRecord?.id ?? emptyGuid,
            playId: emptyGuid,
            stepKey: edge.target,
            dependentUponStepKey: edge.source,
            customHandleKey: edge.sourceHandle && edge.sourceHandle.length > 1 ? edge.sourceHandle.substring(2) : undefined
        }
    });
}

export function mapStepsToNodes<T extends StepDto | StepExecutionDto>(steps: T[]): Node<T>[] {
    return steps.map(step => ({
        id: step.key ?? '',
        position: { x: step.graphXPos, y: step.graphYPos },
        data: step,
        type: step.type,
        deletable: step.type !== NodeTypes.START
    }));
}

export function mapNodesToSteps<T extends StepDto | StepExecutionDto>(nodes: Node<T>[]): T[] {
    return nodes.map(node => ({
        ...node.data,
        graphXPos: node.position.x,
        graphYPos: node.position.y
    }));
}
