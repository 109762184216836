import { useTheme } from '@mui/material';
import { useEdges } from 'reactflow';

export interface NodeColorPalette {
    background: string;
    textColor: string;
}

const inactiveColorScheme: NodeColorPalette = {
    background: '#ababab',
    textColor: 'default',
};

export function useStepNodePaletteSelector(nodeId: string, activeColorPalletOverride?: NodeColorPalette) {
    const edges = useEdges();
    const theme = useTheme();
    const defaultColorPallet: NodeColorPalette = {
        background: theme.palette.primary.main,
        textColor: theme.palette.primary.contrastText,
    };
    var hasAtLeastOneConnection = edges.some((edge) => edge.source === nodeId || edge.target === nodeId);

    if (hasAtLeastOneConnection) {
        return activeColorPalletOverride ?? defaultColorPallet
    } else {
        return inactiveColorScheme;
    }
}
