import _ from 'lodash';
import {
    IFormFieldValidationConfig,
    isShorterThanMaxLength,
    isNotBlank,
    isGreaterThanOrEqualTo,
    FormValidationMethod,
    isValidURL,
} from '../../../../Components/CoreLib/library';
import { StepDto, NotificationType, DelayMethod } from '../../../../dtos';
import { NodeTypes } from '../../NodeTypes/utils';

export class StepValidationConfigBuilder {
    private readonly step: StepDto;
    private get stepType() {
        return this.step.type;
    }

    constructor(step: StepDto) {
        this.step = step;
    }

    public get validationConfigRequiredForSave() {
        let validationRules = new Map<keyof StepDto, IFormFieldValidationConfig>();
        validationRules.set('description', {
            validators: [isShorterThanMaxLength(4000)],
            errorMessageEntityName: 'Description',
        });

        // FormValidationMethod to verify that there are no duplicate file attachments
        function isUniqueFileAttachment(formStep: StepDto): FormValidationMethod {
            return (value: StepDto) => {
                const fileNames = formStep.fileAttachments?.map((f) => f.friendlyFileName) ?? [];
                const uniqueFileNames = new Set(fileNames);
                const isValid = fileNames.length === uniqueFileNames.size;
                const errorMessageBuilder = (fieldName: string) => (isValid ? '' : 'Duplicate file names are not allowed.');
                return {
                    isValid,
                    errorMessageBuilder,
                };
            };
        }

        // TODO: [Node Classes] All the validation rules specific to each step type should be moved into their respective NodeType classes
        switch (this.stepType) {
            case NodeTypes.STEP:
                validationRules.set('estimatedExecutionTimeInMinutes', {
                    validators: [isNotBlank, isGreaterThanOrEqualTo(0)],
                    errorMessageEntityName: 'Estimated execution time',
                });
                validationRules.set('fileAttachments', {
                    validators: [isUniqueFileAttachment(this.step)],
                    errorMessageEntityName: 'File attachments',
                });
                break;
            case NodeTypes.SUBPLAY:
                validationRules.set('subplayId', {
                    validators: [isNotBlank],
                    errorMessageEntityName: 'Subplay',
                });
                break;
            case NodeTypes.NOTIFICATION:
                if (this.step.notificationType !== NotificationType.EMAILS) {
                    validationRules.set('internalJobFunctionId', {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Job Function',
                    });
                }
                break;
            case NodeTypes.ARTIFICIAL_INTELLIGENCE:
                validationRules.set('prompt', {
                    validators: [isShorterThanMaxLength(10000)],
                    errorMessageEntityName: 'Prompt',
                });
                validationRules.set('outputDataLabel', {
                    validators: [isShorterThanMaxLength(500)],
                    errorMessageEntityName: 'Data',
                });
                break;
            case NodeTypes.DELAY:
                if (this.step.delayMethod === DelayMethod.MINUTES) {
                    validationRules.set('delayMinutes', {
                        validators: [isNotBlank, isGreaterThanOrEqualTo(0)],
                        errorMessageEntityName: 'Delay time',
                    });
                } else if (this.step.delayMethod === DelayMethod.UNTIL_DATE) {
                    validationRules.set('delayDateLabel', {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Data value',
                    });
                }
                break;
            case NodeTypes.AUTOMATION:
                validationRules.set('targetEndpoint', {
                    validators: [isValidURL()],
                    errorMessageEntityName: 'Endpoint',
                });
                break;
        }

        return validationRules;
    }

    public get fullValidationConfig() {
        var validationRules = _.cloneDeep(this.validationConfigRequiredForSave);

        // This is universal for all steps
        validationRules.set('name', {
            validators: [isNotBlank, isShorterThanMaxLength(250)],
            errorMessageEntityName: 'Name',
        });

        switch (this.stepType) {
            case NodeTypes.STEP:
                if (this.step.isExternal) {
                    validationRules.set('externalRoleName', {
                        validators: [isNotBlank, isShorterThanMaxLength(250)],
                        errorMessageEntityName: 'Role Name',
                    });
                } else {
                    validationRules.set('internalJobFunctionId', {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Job Function',
                    });
                }
                break;
            case NodeTypes.AUTOMATION:
                validationRules.set('targetEndpoint', {
                    validators: [isNotBlank, isShorterThanMaxLength(1000), ...(validationRules.get('targetEndpoint')?.validators ?? [])],
                    errorMessageEntityName: 'Endpoint',
                });
                break;
            case NodeTypes.DELAY:
                validationRules.set('delayMethod', {
                    validators: [isNotBlank],
                    errorMessageEntityName: 'Delay method',
                });
                if (this.step.delayMethod === DelayMethod.MINUTES) {
                    validationRules.set('delayMinutes', {
                        validators: [isNotBlank, isGreaterThanOrEqualTo(0)],
                        errorMessageEntityName: 'Delay time',
                    });
                } else {
                    validationRules.set('delayDateLabel', {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Data value',
                    });
                }
                break;
            case NodeTypes.ARTIFICIAL_INTELLIGENCE:
                validationRules.set('prompt', {
                    validators: [isNotBlank, isShorterThanMaxLength(10000)],
                    errorMessageEntityName: 'Prompt',
                });
                validationRules.set('outputDataLabel', {
                    validators: [isNotBlank, isShorterThanMaxLength(500)],
                    errorMessageEntityName: 'Data',
                });
                break;
        }

        return validationRules;
    }
}
