import { FC } from "react";
import { NodeProps } from "reactflow";
import { StepDto, StepExecutionDto } from "../../../../dtos";
import { NodeType } from "../NodeType";
import { NodeTypes } from "../utils";
import { INodeRules, IStepEditModalProps, IStepExecutionDetailsProps } from "../types";
import { ConditionalNodeEditForm, ConditionalNodeExecutionDetails, ConditionalNodeLibraryListItem, ConditionalPlayViewerNode } from "./Components";


export class ConditionalNodeType extends NodeType {
    public readonly nodeType: string = NodeTypes.CONDITIONAL;
    public override readonly validationRules: INodeRules = { maxInputs: 1, maxOutputs: 2 }

    public readonly PlayViewerNode: FC<NodeProps<StepDto | StepExecutionDto>> = ConditionalPlayViewerNode;
    public readonly EditModalContent?: FC<IStepEditModalProps> = ConditionalNodeEditForm;
    public readonly ExecutionDetailsContent?: FC<IStepExecutionDetailsProps> = ConditionalNodeExecutionDetails;
    public readonly NodeLibraryListItem: FC = ConditionalNodeLibraryListItem;
}