import { Box, Button, Dialog, DialogContent, Divider, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { StepDataInput } from '../../../../../Components/CommonInputs';
import { PlayExecutionDataInputDto, StepDataDto, StepDataType, StepExecutionDataDto } from '../../../../../dtos';
import { ReactComponent as TestConfigureIcon } from '../../../Assets/AIStepTestConfigure.svg';
import { ReactComponent as TestRunIcon } from '../../../Assets/AIStepTestRun.svg';
import { ModalTitle } from '../../../Components/SubComponents';

export interface ISetTestConfigurationModalProps {
    isOpen: boolean;
    handleClose: () => void;
    handleSave: (testConfiguration: Map<string, string>) => void;
    testConfiguration: Map<string, string> | null;
    smartValuesInPrompt: StepDataDto[];
};

export const SetTestConfigurationModal: FC<ISetTestConfigurationModalProps> = ({
    isOpen,
    handleClose,
    handleSave,
    testConfiguration,
    smartValuesInPrompt
}) => {
    const [updatedTestConfiguration, setUpdatedTestConfiguration] = useState<Map<string, string>>(testConfiguration ?? new Map<string, string>());

    useEffect(() => {
        if (testConfiguration) {
            setUpdatedTestConfiguration(testConfiguration);
        }
    }, [testConfiguration]);

    const handleTestValueChanged = useCallback((name: string, value: string) => {
        setUpdatedTestConfiguration((prev) => {
            const updated = new Map(prev);
            updated.set(name, value);
            return updated;
        });
    }, []);

    const mockStepDataValues = useMemo(() => {
        const uniqueDataVariablesMap = new Map();
        smartValuesInPrompt.forEach((smartValue) => {
            if (!uniqueDataVariablesMap.has(smartValue.name)) {
                uniqueDataVariablesMap.set(smartValue.name, {
                    name: smartValue.name ?? '',
                    value: updatedTestConfiguration.get(smartValue.name ?? '') ?? '',
                    dataType: smartValue.dataType ?? StepDataType.TEXT,
                    isFutureDate: smartValue.isFutureDate ?? false,
                } as (StepExecutionDataDto | PlayExecutionDataInputDto));
            }
        });
        return Array.from(uniqueDataVariablesMap.values());
    }, [smartValuesInPrompt, updatedTestConfiguration]);

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <ModalTitle icon={<TestConfigureIcon style={{ marginRight: '8px' }} />} title='Testing Configuration' />
            <DialogContent>
                <Typography variant='body2'>Please provide sample values for the data used in you prompt.</Typography>
                <Divider sx={{ my: 2 }} />
                <Box display='flex' flexDirection='column' gap={1}>
                    {mockStepDataValues.map((mockStepData, idx) => (
                        <StepDataInput
                            key={`step-data-${idx}`}
                            stepData={mockStepData}
                            onChange={(val) => handleTestValueChanged(mockStepData.name, val)}
                        />
                    ))}
                </Box>
            </DialogContent>
            <Box width='100%' display='flex' justifyContent='end' gap={2} p={1} sx={{ background: 'white' }} zIndex={5}>
                <Button onClick={handleClose} variant='outlined' color='primary'>
                    CANCEL
                </Button>
                <Button onClick={() => handleSave(updatedTestConfiguration)} variant='contained' color='secondary' startIcon={<TestRunIcon />}>
                    TEST
                </Button>
            </Box>
        </Dialog>
    );
};