import { isValid } from "date-fns";
import { useCallback } from "react";
import { PlayExecutionDataInputDto, StepDataType, StepExecutionDataDto } from "../../../dtos";

export function useStepDataValidator() {
  const validateStepData = useCallback((stepData: StepExecutionDataDto | PlayExecutionDataInputDto) => {
    // Check if the value is required and empty
    if (stepData.isRequired && !stepData.value) {
      return `${stepData.name || 'Value'} is required`;
    }

    // Check for validation based on data type
    if (stepData.dataType === StepDataType.DATE && !!stepData.value) {
      const inputDate = new Date(stepData.value);

      // Use the helper function to check if it's a valid date
      if (!isValid(inputDate)) {
        return 'Please enter a valid date.';
      }

      if (stepData.isFutureDate) {
        if (inputDate < new Date()) {
          return `${stepData.name} must be in the future`;
        }
      }
    }

    // If no validation issues are found, return an empty string
    return '';
  }, []);

  const isStepDataValid = useCallback((stepData: StepExecutionDataDto | PlayExecutionDataInputDto) => {
    // Use the same validation logic to check if stepData is valid
    return validateStepData(stepData) === '';
  }, [validateStepData]);

  return { validateStepData, isStepDataValid };
}