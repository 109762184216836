import { Box } from '@mui/material';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import { usePlayProjector } from '../../projectors';
import { useYjsDocumentManager } from '../../util';
import { PlayEditorAutosave } from './Components';

export const EditPlayAutosave: FC = () => {
    const { id } = useParams();
    const streamName = useMemo(() => `playentity-${id}`, [id]);
    const { playProjection, applyEvents, resetProjection } = usePlayProjector();
    const { doc } = useYjsDocumentManager(streamName, applyEvents, resetProjection);

    const loadingMessage = useMemo(() => {
        if (doc === null) {
            return 'Initializing Document Connection...';
        }
        if (playProjection.id === '') {
            return 'Loading Play Projection...';
        }
        return '';
    }, [doc, playProjection.id]);

    return (
        <Box display='flex' flexDirection='column'>
            <Box>
                {loadingMessage ? (
                    <div>{loadingMessage}</div>
                ) : (
                    <ReactFlowProvider>
                        <PlayEditorAutosave playProjection={playProjection} playDocument={doc!} />
                    </ReactFlowProvider>
                )}
            </Box>
        </Box>
    );
};
