import { AccountBox, Cancel, Delete, MoreVert, Save } from '@mui/icons-material';
import {
    Alert,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Switch,
    Tooltip,
} from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserDto } from '../../../dtos';
import { useUserByIdDeleteMutation } from '../../../store/generated/generatedApi';
import { emptyGuid } from '../../../util';
import {
    AuthenticatedComponent,
    BasicTransferList,
    FormInput,
    FormSection,
    IFormProps,
    LoadingIndicator,
    SlimPageHeader,
    useFailedActionSnackbar,
    useSuccessfulActionSnackbar,
} from '../../CoreLib/library';
import { useUserFormManager } from './useUserForm';
import { LoadingButton } from '@mui/lab';

const DEFAULT_USER: UserDto = {
    id: emptyGuid,
    auth0UserId: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    roles: [],
    isActive: true,
    createdOn: new Date(),
    title: '',
    clientName: '',
    clientCount: 0,
};

export const UserForm: FC<IFormProps<UserDto>> = ({ save, cancel, initValues = DEFAULT_USER, isLoading }) => {
    const {
        formUser,
        isNew,
        isFormDirty,
        fieldErrors,
        validateForm,
        handleTextFieldChange,
        handleRolesChange,
        currentUserRolesAsTransferListItems,
        allRoleOptionsAsTransferListItems,
        isLoadingUserRoles,
        handleIsActiveChange,
        isPendingInvitation,
        userDisplayName,
    } = useUserFormManager(initValues);
    const navigate = useNavigate();
    const [archiveUser, { isLoading: isDeleting, isSuccess: isSuccessfullyDeleted, isError: isErrorDeleting, reset: resetDelete }] =
        useUserByIdDeleteMutation();
    useSuccessfulActionSnackbar('deleted', 'user', isSuccessfullyDeleted, () => {
        resetDelete();
        navigate('/users');
    });
    useFailedActionSnackbar('delete', 'user', isErrorDeleting, resetDelete);

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(event.currentTarget), []);
    const handleMenuClose = useCallback(
        (afterClose?: () => void) => () => {
            setMenuAnchorEl(null);
            if (afterClose) {
                afterClose();
            }
        },
        []
    );

    const handleDeleteClicked = useCallback(() => archiveUser({ id: formUser.id }), [archiveUser, formUser.id]);
    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            save(formUser);
        }
    }, [validateForm, formUser, save]);

    if (isLoadingUserRoles) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item>
                <SlimPageHeader
                    icon={<AccountBox />}
                    title={userDisplayName ? userDisplayName : 'New User'}
                    breadCrumbProps={{
                        links: [
                            { label: 'Home', navLink: '/' },
                            { label: 'Admin', navLink: '', isText: true },
                            { label: 'Users', navLink: '/users' },
                        ],
                        currentPageLabel: userDisplayName ? userDisplayName : 'New User',
                    }}
                    endSlot={
                        <Grid item container direction='row' alignItems='start' py={1}>
                            <Grid item display='flex' alignItems='center' justifyContent={'end'} xs={12} sx={{ gap: 1 }} pr={2}>
                                <FormControlLabel
                                    control={<Switch checked={formUser.isActive} onChange={handleIsActiveChange} size='small' disabled={!isNew && isPendingInvitation}/>}
                                    label='Active'
                                    labelPlacement='start'
                                />
                                {!isNew && (
                                    <AuthenticatedComponent requiredPermissions={[`delete:users`]}>
                                        <Tooltip title='Delete'>
                                            <span>
                                                <IconButton size='large' onClick={handleDeleteClicked} disabled={isDeleting}>
                                                    <Delete fontSize='inherit' />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </AuthenticatedComponent>
                                )}
                                <Divider flexItem orientation='vertical' sx={{ mx: 1 }} />
                                <Button variant='outlined' color='primary' size='small' onClick={cancel} startIcon={<Cancel />} sx={{ width: 90 }}>
                                    {isFormDirty ? 'Cancel' : 'Close'}
                                </Button>
                                <LoadingButton
                                    loading={isLoading}
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    onClick={handleSave}
                                    startIcon={<Save />}
                                    sx={{ width: 90 }}
                                    disabled={!isNew && isPendingInvitation}>
                                    Save
                                </LoadingButton>
                                <IconButton onClick={handleMenuOpen}>
                                    <MoreVert fontSize='inherit' />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={!!menuAnchorEl}
                                    onClose={handleMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <MenuItem onClick={handleMenuClose(handleSave)} disabled={isLoading && !isNew && isPendingInvitation}>
                                        Save
                                    </MenuItem>
                                    <MenuItem onClick={handleMenuClose(cancel)}>{isFormDirty ? 'Cancel' : 'Close'}</MenuItem>
                                    {!isNew && (
                                        <AuthenticatedComponent requiredPermissions={[`delete:users`]}>
                                            <Divider />
                                            <MenuItem onClick={handleMenuClose(handleDeleteClicked)}>Delete</MenuItem>
                                        </AuthenticatedComponent>
                                    )}
                                </Menu>
                            </Grid>
                        </Grid>
                    }
                />
            </Grid>
            <Grid item mx={2}>
                <FormSection>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item xs={4}>
                            <FormInput
                                fullWidth
                                required
                                label='First Name'
                                value={formUser.firstName}
                                name='firstName'
                                onChange={handleTextFieldChange}
                                errorText={fieldErrors.get('firstName')}
                                disabled={!isNew && isPendingInvitation}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                fullWidth
                                required
                                label='Last Name'
                                value={formUser.lastName}
                                name='lastName'
                                onChange={handleTextFieldChange}
                                errorText={fieldErrors.get('lastName')}
                                disabled={!isNew && isPendingInvitation}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput fullWidth label='Title' value={formUser.title} name='title' onChange={handleTextFieldChange} disabled={!isNew && isPendingInvitation} />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item xs={4}>
                            <FormInput
                                fullWidth
                                required
                                label='Email'
                                value={formUser.email}
                                name='email'
                                onChange={handleTextFieldChange}
                                errorText={fieldErrors.get('email')}
                                disabled={!isNew && isPendingInvitation}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                fullWidth
                                label='Phone'
                                value={formUser.phoneNumber}
                                name='phoneNumber'
                                onChange={handleTextFieldChange}
                                errorText={fieldErrors.get('phoneNumber')}
                                disabled={!isNew && isPendingInvitation}
                            />
                        </Grid>
                        <AuthenticatedComponent requiredPermissions={['assign:userRoles']}>
                            <Grid item xs={12}>
                                {!isNew && isPendingInvitation ? (
                                    <Alert severity='info'>This user can be modified once {formUser.firstName} has accepted the invitation</Alert>
                                ) : (
                                    <FormControl error={!!fieldErrors.get('roles')} fullWidth required>
                                        <FormLabel>Roles</FormLabel>
                                        <BasicTransferList
                                            targetColumnName='Active Roles'
                                            sourceColumnName='Available Roles'
                                            currentItems={currentUserRolesAsTransferListItems}
                                            allItems={allRoleOptionsAsTransferListItems}
                                            onCurrentItemsChanged={handleRolesChange}
                                        />
                                        <FormHelperText>{fieldErrors.get('roles')}</FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                        </AuthenticatedComponent>
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    );
};
