import { useCallback, useMemo, useState } from "react";
import { useCreatePlayExecuteMutation, useLazyGetPlayByPlayIdStartDataQuery } from "../store/generated/generatedApi";
import { useFailedActionSnackbar, useStandardSnackbar, useSuccessfulActionSnackbar } from "../Components/CoreLib/library";
import { StartPlayModal } from "../Views/Plays/Components";
import { PlayDto } from "../dtos";
import { ISelectedPlayDetails } from "../Views/Plays/Utils";

export function usePlayStarter() {
    const [selectedPlayDetails, setSelectedPlayDetails] = useState<ISelectedPlayDetails | undefined>();
    const [getPlayStartData, {isError: isGetStartDataError}] = useLazyGetPlayByPlayIdStartDataQuery();
    const [startPlay, { isSuccess: isStartPlaySuccess, isError: isStartPlayError, isLoading: isStartingPlay, reset: resetStartPlay }] = useCreatePlayExecuteMutation();
    
    const clearSelectedPlayDetails = () => {
        setSelectedPlayDetails(undefined);
    }
    
    useSuccessfulActionSnackbar('started', 'play', isStartPlaySuccess, () => {
        resetStartPlay();
        clearSelectedPlayDetails();
    });
    useFailedActionSnackbar('start', 'play', isStartPlayError, resetStartPlay);
    useStandardSnackbar(isGetStartDataError, 'This play cannot be started. Please check for any potential loops in sub-plays.', 'error');
    
    const handleStartPlay = useCallback(async (play: PlayDto) => {
        if (isStartingPlay) {
            return;
        }
        
        var playStartData = (await getPlayStartData({ playId: play.id })).data;
        
        if (playStartData) {
            var isAdditionalDataRequiredToStartPlay = playStartData.jobFunctions.length > 0 || playStartData.startData.length > 0;
            if (isAdditionalDataRequiredToStartPlay) {
                setSelectedPlayDetails({
                    play: play,
                    requestedData: playStartData
                });
            } else {
                startPlay({
                    playId: play.id,
                    assignedClientJobFunctions: [],
                    collectedData: [],
                });
            }
        }
    }, [isStartingPlay, startPlay, getPlayStartData]);

    const renderStartPlayModal = useMemo(() => {
        return <StartPlayModal selectedPlayDetails={selectedPlayDetails} clearSelectedPlayDetails={clearSelectedPlayDetails} />
    }, [selectedPlayDetails])

    return {
        isStartingPlay,
        handleStartPlay,
        renderStartPlayModal
    }
}