import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import { ApiError, LoadingIndicator } from '../../Components/CoreLib/library';
import { useGetPlayByIdQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';
import { PlayEditor } from './Components';

export const EditPlay: FC = () => {
    const { id } = useParams();
    const { data: play, isLoading, isError, refetch } = useGetPlayByIdQuery({
        id: id ?? emptyGuid
    });

    if (isLoading) {
        return <LoadingIndicator />
    }

    if (isError || !play) {
        return <ApiError onReloadClick={refetch} />
    }
    
    return (
        <ReactFlowProvider>
            <PlayEditor play={play} />
        </ReactFlowProvider>
    );
};