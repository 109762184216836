import { renderWithRequiredIndicator } from '../../../../util';

export interface StepDataLabelModifiers {
    isRequired: boolean;
    labelSuffix?: string;
}

export function stepDataFieldLabelBuilder(stepDataName: string, modifiers: StepDataLabelModifiers): string {
    const labelWithRequiredIndicator = renderWithRequiredIndicator(stepDataName, modifiers.isRequired);

    if (modifiers.labelSuffix) {
        return `${labelWithRequiredIndicator} ${modifiers.labelSuffix}`;
    }

    return labelWithRequiredIndicator;
}
