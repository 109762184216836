import { Code, FormatBold, FormatItalic, FormatListBulleted, FormatListNumbered, FormatUnderlined, Link, Redo, Undo } from '@mui/icons-material';
import { Box, Button, MenuItem, Select, styled } from '@mui/material';
import { EditorState, RichUtils } from 'draft-js';
import { FC, useCallback, useContext, useMemo } from 'react';
import { DraftEditorContext } from './DraftEditorContext';

const TitleBarButton = styled(Button)({
    minWidth: 12,
});

type LabelType = string | JSX.Element;
type StyleType = 'inline' | 'block' | 'link';

interface IDraftWysiwygTitleBarProps {
    editorState: EditorState;
    setEditorState: (editorState: EditorState) => void;
}

interface DecoratorButtonConfig {
    label: LabelType;
    style: string;
    type: StyleType;
    isDisabledChecker?: (currentState: EditorState) => boolean;
}

interface ToolbarConfigOption {
    sectionLabel?: string;
    options: DecoratorButtonConfig[];
}

const DECORATOR_CONFIGS: ToolbarConfigOption[] = [
    {
        sectionLabel: 'Block',
        options: [
            { label: 'Body', style: 'unstyled', type: 'block' },
            { label: 'Heading 1', style: 'header-one', type: 'block' },
            { label: 'Heading 2', style: 'header-two', type: 'block' },
            { label: 'Heading 3', style: 'header-three', type: 'block' },
            { label: 'Heading 4', style: 'header-four', type: 'block' },
            { label: 'Heading 5', style: 'header-five', type: 'block' },
            { label: 'Heading 6', style: 'header-six', type: 'block' }
        ],
    },
    { options: [{ label: <FormatBold />, style: 'BOLD', type: 'inline' }] },
    { options: [{ label: <FormatItalic />, style: 'ITALIC', type: 'inline' }] },
    { options: [{ label: <FormatUnderlined />, style: 'UNDERLINE', type: 'inline' }] },
    { options: [{ label: <Code />, style: 'CODE', type: 'inline' }] },
    {
        options: [
            {
                label: <Link />,
                style: 'LINK',
                type: 'link',
                isDisabledChecker: (currentState) => currentState.getSelection().isCollapsed() || RichUtils.currentBlockContainsLink(currentState),
            },
        ],
    },
    { options: [{ label: <FormatListBulleted />, style: 'unordered-list-item', type: 'block' }] },
    { options: [{ label: <FormatListNumbered />, style: 'ordered-list-item', type: 'block' }] },
    // TODO: alignment is not currently working so we will need to add this functionality later.
    // { options: [{ label: <FormatAlignLeft />, style: 'left', type: 'block' }] },
    // { options: [{ label: <FormatAlignCenter />, style: 'center', type: 'block' }] },
    // { options: [{ label: <FormatAlignRight />, style: 'right', type: 'block' }] },
    // { options: [{ label: <FormatAlignJustify />, style: 'justify', type: 'block' }] },
];

export const DraftWysiwygTitleBar: FC<IDraftWysiwygTitleBarProps> = ({ editorState, setEditorState }) => {
    const { setLinkDecoratorState } = useContext(DraftEditorContext);
    const getStyleConfigByStyleName = useCallback((style: string) => {
        const buttonConfigs = DECORATOR_CONFIGS.flatMap((buttonConfig) => buttonConfig.options);
        return buttonConfigs.find((option) => option.style === style)!;
    }, []);

    const currentBlockType = useMemo(() => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const block = contentState.getBlockForKey(selection.getStartKey());
        return block.getType();
    }, [editorState]);

    const currentInlineStyles = useMemo(() => {
        const currentStyle = editorState.getCurrentInlineStyle();
        return currentStyle.toArray();
    }, [editorState]);

    const onToggle = useCallback(
        (style: string) => {
            const styleConfig = getStyleConfigByStyleName(style);
            switch (styleConfig.type) {
                case 'inline':
                    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
                    break;
                case 'block':
                    setEditorState(RichUtils.toggleBlockType(editorState, style));
                    break;
                case 'link':
                    setLinkDecoratorState({
                        entityKey: 'new', // when creating a new link this value isn't actually used but it needs to have some value
                        currentLinkValue: '',
                    });
                    break;
            }
        },
        [editorState, getStyleConfigByStyleName, setEditorState, setLinkDecoratorState]
    );

    const onUndo = () => {
        setEditorState(EditorState.undo(editorState));
    };

    const onRedo = () => {
        setEditorState(EditorState.redo(editorState));
    };

    const getSectionValue = useCallback(
        (sectionName: string) => {
            switch (sectionName) {
                case 'Block':
                    return currentBlockType;
                // case 'Color':
                //     return currentInlineStyles.find((style) => CUSTOM_COLOR_NAMES.includes(style)) ?? 'DEFAULT';
            }
        },
        [currentBlockType]
    );

    return (
        <Box
            sx={{ p: '16px 8px 8px 8px', boxShadow: '0 2px 2px -2px rgba(34,47,62,.1),0 8px 8px -4px rgba(34,47,62,.07)' }}
            display='flex'
            gap={1}>
            <TitleBarButton onMouseDown={onUndo} size='small' color='inherit' sx={{ minWidth: 12 }}>
                <Undo />
            </TitleBarButton>
            <TitleBarButton onMouseDown={onRedo} size='small' color='inherit'>
                <Redo />
            </TitleBarButton>
            {DECORATOR_CONFIGS.map((decoratorConfig, index) =>
                decoratorConfig.options.length > 1 ? (
                    <Select
                        key={index}
                        value={getSectionValue(decoratorConfig.sectionLabel!)}
                        onChange={(e) => onToggle(e.target.value)}
                        size='small'
                        sx={{ width: 164 }}
                        placeholder={decoratorConfig.sectionLabel!}>
                        {decoratorConfig.options.map((option, i) => (
                            <MenuItem key={i} value={option.style} disabled={option.isDisabledChecker && option.isDisabledChecker(editorState)}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    <TitleBarButton
                        key={index}
                        onMouseDown={(e) => {
                            e.preventDefault();
                            decoratorConfig.options[0].style && onToggle(decoratorConfig.options[0].style);
                        }}
                        size='small'
                        color={
                            currentBlockType === decoratorConfig.options[0].style || currentInlineStyles.includes(decoratorConfig.options[0].style)
                                ? 'primary'
                                : 'inherit'
                        }
                        disabled={decoratorConfig.options[0].isDisabledChecker && decoratorConfig.options[0].isDisabledChecker(editorState)}>
                        {decoratorConfig.options[0].label}
                    </TitleBarButton>
                )
            )}
        </Box>
    );
};
